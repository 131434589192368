import { 
  Popover, Typography, useTheme
}from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalFileHistoryProps } from "./ModalFileHistory.types";
import { 
  Icon
} from "../../components";
import { ExercisesCardRevisionsTable } from "../../components/ExercisesCard";

export function ModalFileHistory({
  closeDrawer,
  onClose,
  open,
  editionId,
  exerciseName,
  exerciseId,
  teacherName,
  teacherImg,
  revisions,
  teachers,
  ...props
}: ModalFileHistoryProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Popover
        className={ "popover-modal-history" }
        anchorEl={ "anchorEl" in props ? props.anchorEl : null }
        open={ open }
        onClose={ onClose }
        sx={ {
          ".MuiPopover-paper":{
            background: theme.linearGradients.gradientB,
            border: "none",
            borderRadius:"4px !important",
            boxShadow:`50px 50px 80px 0px ${theme.customColors.backgroundDisabled}`,
            height: "auto",
            maxWidth: "968px",
            overflow:"visible",
            padding: "24px 40px",
            top: "10% !important",   
            
            [theme.breakpoints.down("sm")]: {
              border: `1px solid ${theme.customColors.borderAccent} !important`,
              borderRadius:"8px 8px 0 0 !important",
              bottom: "60px",
              boxShadow: `0px 0px 4px 0px ${theme.customColors.borderAccent}`,
              left: "2px !important",
              margin: "0 auto",
              maxHeight: "100%",
              maxWidth: "calc(100% - 4px)",
              padding: "16px 20px",
              top: "unset !important"
            }
          },

          ".MuiStack-root ": {
            gap: "0",

            "&:hover": {
              "&::-webkit-scrollbar-thumb":{
                backgroundColor: theme.customColors.backgroundSecondary
              }
            },
            "::-webkit-scrollbar": {
              width: "6px"
            },
            "::-webkit-scrollbar-thumb":{
              backgroundColor: "transparent",
              borderRadius: "8px"
            }
          },

          ".icon-close": {
            display:"flex !important",
            marginBottom: "24px",
            marginLeft: "auto",

            [theme.breakpoints.down("sm")]: {
              marginBottom: "8px"
            }
          }
        } }
        { ...props }
      >
        <Icon
          className="icon-close"
          icon={ "close" }
          size={ "19px" }
          cursor="pointer"
          color={ theme.palette.primary?.main }
          onClick={ ()=> {
            if(closeDrawer){
              closeDrawer();
            }
          } }
        />
        <Typography
          fontSize={ "2rem" }
          fontWeight={ 700 }
          color={ theme.customColors.textPrimary }
          textAlign={ "center" }
        > 
          { t("file_history") }
        </Typography>
        <Typography
          borderBottom={ `1px solid ${theme.customColors.border}` }
          fontSize={ "1.25rem" }
          fontWeight={ 400 }
          lineHeight={ "1.5" }
          color={ theme.customColors.textPrimary }
          paddingBottom={ "24px" }
          textAlign={ "center" }>
          { t("file_history_description") }
        </Typography>
        <ExercisesCardRevisionsTable 
          teachers={ teachers }
          revisions={ revisions } 
          editionId={ editionId }
          exerciseName={ exerciseName }
          exerciseId={ exerciseId }
          teacherName={ teacherName }
          teacherImg={ teacherImg }>
        </ExercisesCardRevisionsTable>
      </Popover>
    </>
  );
}

