import {
  Stack,
  StackProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";

export const TimeSplitsStack = styled(Stack)<
  StackProps & {
    $active?: boolean
  }
>(({ $active=false, theme }) => ({
  alignItems: "center",
  // eslint-disable-next-line max-len
  border: `${theme.spacing(0.2)} solid ${$active ? theme.customColors.accentVariantA : theme.customColors.systemPrimary01}`,
  color: $active ? theme.customColors.accentVariantA: theme.palette.text?.primary,
  justifyContent: "center",
  padding: theme.spacing(1.5),

  [theme.breakpoints.down("sm")]:{
    borderRadius: "2px",
    minHeight: "64px",
    padding: theme.spacing(1, 2)
  }
}));


export function TimeSplit({
  active=false,
  children,
  onClick,
  ...props
}: PropsWithChildren<
  StackProps & {
    active: boolean
    onClick?: () => void
  }
>) {
  return (
    <TimeSplitsStack
      key={ "timesplit--none" }
      $active={ active }
      onClick={ onClick }
      { ...props }
    >
      { children }
    </TimeSplitsStack>
  );
}
