import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  PopUpTeacherBox,
  DescriptionTypography,
  ImageBox,
  TextContent,
  TitleTypography
} from "./PopUpTeacher.styles";
import { PopUpTeacherProps } from "./PopUpTeacher.types";
import { Icon } from "../Icon";
import { Link } from "../Link";

export function PopUpTeacher({
  shortDescription,
  coverPublicURL,
  title,
  email,
  onClose,
  ...props
}: PopUpTeacherProps) {
  const theme = useTheme();

  return (
    <PopUpTeacherBox
      sx={ {
        overflowY: "auto",

        "&:hover": {
          "&::-webkit-scrollbar-thumb":{
            backgroundColor: theme.customColors.backgroundSecondary
          }
        },
        "::-webkit-scrollbar": {
          width: "6px"

        },
        "::-webkit-scrollbar-thumb":{
          backgroundColor: "transparent",
          borderRadius: "8px"
        }
      } }
      container
      gap={ 3 }
      { ...props }
    >
      <Icon
        icon={ "close" }
        height={ "20px" }
        width={ "20px" }
        onClick={ ()=> {if(onClose){onClose()}} }
      />
      <ImageBox
        $coverPublicURL={ coverPublicURL }
      >
        { coverPublicURL ?
          <img src={ coverPublicURL } alt="card teacher"/> :
          <Typography
            fontWeight={ 700 }
            fontSize={ "4rem" }
            color={ theme.customColors.backgroundPrimaryLighter }
            height={ "fit-content" }
            margin={ "auto" }
          >
            { title?.split(" ").map(i => i.charAt(0)).toString().toUpperCase().split(",") }
          </Typography>
        }
      </ImageBox>
      <TextContent>
        <TitleTypography>
          { title }
        </TitleTypography>
        <Link
          href={ `mailto:${email}` }
          rel="noreferrer"
          target={ "_self" }
          sx={ {
            color: theme.customColors.textPrimary,
            fontSize: "1rem",
            textDecoration: "underline"
          } }
        >
          { email }
        </Link>
        {
          shortDescription ? 
            <DescriptionTypography>
              { shortDescription }
            </DescriptionTypography> : null
        }
       
      </TextContent>
    </PopUpTeacherBox>
  );
}
