import { Outlet } from "react-router-dom";
import { LoginStack } from "./Access.styles";
import { Background } from "../../components";

export function Access() {

  return (
    <Background >
      <LoginStack component="form">
        <Outlet />
      </LoginStack>
    </Background>
  );
}
