import { create } from "zustand";

export type ComunityState = {
  status:"initialized" | "escaped" | "", 
  time: number | null
}

type ComunityActions = {
  readonly setComunityState: (props:ComunityState) => void
}

const defaultComunityState: ComunityState = {
  status:"",
  time:null
};

export const useComunityStore = create<ComunityState & ComunityActions>((set) => ({
  ...defaultComunityState,
  setComunityState:({ status, time }:ComunityState) => set ({ status,time })
}));

