import {
  useMutation,
  useQuery,
  useQueryClient,
  type QueryKey
} from "@tanstack/react-query";
import {
  ERRORS,
  LEVEL
} from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type {
  Level,
  MetaData
} from "../types";
import { getEnvironmentVariables } from "../utils/general";

const { basePath } = getEnvironmentVariables();

type Favourite = {
  dateOfAdditionToFavourites: string
  learningObjectId: string
  learningObjectType: string
}

type FavouritesResponse = {
  corporateId: number
  domainId: number
  learnerId: string
  learningObject: Favourite[]
  metadata: MetaData
}

/**
 * NOTE, this is redundant and replaceble with a call to the GET raring in most cases
 * this is only useful if you need to know all the users who liked the LO
 * https://core-learning-social-rating-v1.openlearning-svil.digitedacademy.net/swagger-ui/#/Favourites/
 * @param param0, the parameters of the query
 * @returns a react-query query result object
 */
export function useFavouritesQuery({
  enabled = true,
  learningObjectId,
  learningObjectType,
  queryKey=[]
} : {
  enabled?: boolean,
  learningObjectId: string,
  learningObjectType: string,
  queryKey?: string[]
}) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const learnerId = useAuthStore(state => state.userData?.id_user) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  
  const uri = `/favourites/learner/${learnerId}/learning-object/${learningObjectId}/favourite`;
  const params =
    `corporate-id=${corporateId}&domain-id=${initiativeId}&learning-object-type=${learningObjectType}`;
  
  return useQuery<
    unknown,
    unknown,
    FavouritesResponse,
    QueryKey
  >({
    enabled,
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: FavouritesResponse) => {
        return apiAdapter([data])[0];
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, params, ...queryKey]
  });
}
  
/**
   * https://core-learning-social-rating-v1.openlearning-svil.digitedacademy.net/swagger-ui/#/Favourites/
   * @param param0, the parameters of the mutation
   * @returns a react-query mutation result object
   */
export function useFavouritesMutation({
  grandParentId,
  invalidateKeys=[],
  learningObjectId,
  learningObjectTypology,
  parentId,
  method
} : {
  grandParentId?:number | null,
  invalidateKeys?: string[]
  learningObjectId: number,
  learningObjectTypology: Level,
  parentId?:number | null,
  method: "DELETE" | "POST",
}) {
  const queryClient = useQueryClient();
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const learnerId = useAuthStore(state => state.userData?.id_user) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();

  const objectType = getLearningObjectType(learningObjectTypology);

  const uri = `/favourites/learner/${learnerId}/learning-object/${learningObjectId}/favourite`;
  const params =
    // eslint-disable-next-line max-len
    `corporate-id=${corporateId}&domain-id=${initiativeId}&learning-object-type=${objectType}&parentId=${parentId ?? ""}&grandParentId=${grandParentId ?? ""}`;

  const uriGet = "/social-rating/rating-statistics";
  const paramsGet =
    // eslint-disable-next-line max-len
    `corporate-id=${corporateId}&domain-id=${initiativeId}&learnerId=${learnerId}&learning-object-id=${learningObjectId}&learning-object-type=${objectType}`;

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [sessionToken, uriGet, paramsGet, ...invalidateKeys]
      });

      const previousRating = queryClient.getQueryData(
        [sessionToken, uriGet, paramsGet, ...invalidateKeys]
      );

      queryClient.setQueryData(
        [sessionToken, uriGet, paramsGet, ...invalidateKeys],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (old: any) => {
          if (method === "DELETE") {
            // const toDeleteIndex = old.learnerObject.findIndex((isFavourite: Favourite) => {
            //   favourite.learningObjectId === learningObjectId &&
            //   favourite.learningObjectType === learningObjectType;
            // });
            // old.learnerObject.splice(toDeleteIndex, 1);
            old.userRating.isFavouriteForLearner = 0;
          } else if (method === "POST") {
            // old.learnerObject.push({
            //   learningObjectId,
            //   learningObjectType
            // });
            old.userRating.isFavouriteForLearner = 1;
          } else { 
            throw new Error("invalid mutation method for favourites");
          }
          return old;
        }
      );

      return { previousRating };
    },
    onSettled: () => {
      // invalidate previous rating get
      queryClient.invalidateQueries({
        queryKey: [sessionToken, uriGet, paramsGet, ...invalidateKeys]
      });
    }
  });

  function getLearningObjectType(learningObjectTypology: Level) {
    switch (learningObjectTypology) {
    case LEVEL.COURSE:
    case LEVEL.PATH:
      return learningObjectTypology?.toLowerCase();
    default :
      return "activity";
    }
  }
}
