import {
  alpha,
  Box,
  Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRef, useState } from "react";
import {
  OverlayDivider,
  OverlayGroupStack,
  OverlayStack,
  OverlayTypography,
  CtaOverlayIcon
} from "./CtaOverlay.styles";
import { CtaOverlayProps } from "./CtaOverlay.types";
import {
  CreateContentModal,
  Spinner
} from "../../components";
import { POST_TYPE } from "../../consts";
import {
  useFavouritesMutation,
  // useFavouritesQuery,
  useRatingMutation,
  useRatingQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import type { LearningObject } from "../../types";
import { getLearningObjectType } from "../../utils/general";
import { Icon } from "../Icon";

export function CtaOverlay({
  // bookmarked,
  // comments,
  courseId,
  learningObjectTypology,
  id,
  isStandAlone,
  // favourite,
  //share,
  rootId,
  rating
} : CtaOverlayProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = useAuthStore(state => state.isLoading);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const [visibilityRating, setVisibilityRating] = useState(false);
  const [commentsModalIsOpen, setCommentModalIsOpen] = useState(false);
  const learningObjectType = getLearningObjectType(learningObjectTypology);
  const anchorElRef = useRef<HTMLDivElement | null>(null);
  const elAnchor = anchorElRef?.current as HTMLElement;

  // const { data: favouriteStatistic } = useFavouritesQuery({
  //   enabled: Boolean(id),
  //   learningObjectId: id ?? "",
  //   learningObjectType
  // });
  
  // TO ADD IN PARAMS IN FAVOURITES 
  const parentId = !isStandAlone ? (!courseId && rootId) ? rootId : courseId ?? null : null;
  const grandParentId = (!isStandAlone && parentId && parentId !== rootId) ? rootId : null ;
  const {
    data: ratingStatistic,
    isLoading: ratingIsLoading
  } = useRatingQuery({
    enabled: Boolean(id),
    learningObjectId: id ?? "",
    learningObjectTypology:learningObjectType
  });
  const {
    mutate: addFavouriteMutation,
    isLoading: isAddingFavouriteLoading 
  } = useFavouritesMutation({
    grandParentId:grandParentId,
    learningObjectId: id ?? "",
    learningObjectTypology,
    method: "POST",
    parentId:parentId
  });
  const {
    mutate: removeFavouriteMutation,
    isLoading: isRemovingFavouriteLoading
  } = useFavouritesMutation({
    learningObjectId: id ?? "",
    learningObjectTypology,
    method: "DELETE"
  });

  const loStatistic = {
    averageRating: ratingStatistic?.objectStatistics.averageRating || 0,
    userFavourite: Boolean(ratingStatistic?.userRating.isFavouriteForLearner && 
      ratingStatistic?.objectStatistics?.learningObjectId),
    userRating: ratingStatistic?.userRating?.rating || 0
  };


  return (
    <>
      {
        (
          (smartConfiguration?.funcFavourites || smartConfiguration?.funcRating || 
            (smartConfiguration?.funcCommunity && smartConfiguration?.communityShareContent) )
          && (!ratingIsLoading && !isLoading)
        ) ?
          (
            
            <OverlayStack
              component={ "span" }
              gap={ 2 }
              ref={ anchorElRef  }
              $commentModalOpen={ commentsModalIsOpen }
            >
              {
                // TODO: add smart conf check for comments
                // eslint-disable-next-line no-constant-condition
                smartConfiguration?.funcRating || 
                (smartConfiguration?.funcCommunity && smartConfiguration?.communityShareContent) 
                  ? (
                    <OverlayGroupStack
                      divider={
                        <OverlayDivider
                          orientation={ isMobile ? "vertical" : "horizontal" }
                        />
                      }
                      gap={ isMobile ? 0 : 0 }
                    >
                      {
                        smartConfiguration?.communityShareContent ? 
                          <Stack
                            alignItems="center"
                            onClick={ () => { setCommentModalIsOpen(true) } }
                          >
                            <CtaOverlayIcon 
                              className="withpadding"
                              icon={ "icon_share" } size={ 20 }/>
                          </Stack> : null
                      }
                  
                      {
                        smartConfiguration?.funcRating ? (
                          <Box
                            position={ "relative" }
                            onMouseLeave={ ()=> {
                              setVisibilityRating(false);
                            } }
                            onMouseEnter={ ()=> {
                              setVisibilityRating(true);
                            } }
                            sx={ {
                              "&:before":{
                                background: "transparent",
                                content: "''",
                                display: "block",
                                height: "36px",
                                width: "12px",
        
                                pointerEvents: visibilityRating ? "auto" : "none",
                                position: "absolute",
                                right: "48px",

                                [`${theme.breakpoints.down("sm")}`]:{
                                  bottom:"40px",
                                  right: "23px",

                                  height: "12px",
                                  width: "36px"
                                }
                              }
                            } }
                          >
                            <StarsIcons
                              id={ id ?? "" }
                              userRating={ loStatistic.userRating }
                              learningObjectTypology={ learningObjectTypology }
                              visibility={ visibilityRating }
                              maxRating = { smartConfiguration?.ratingMax || 1 }
                            />
                            <Stack alignItems="center" flexDirection={ isMobile ? "row" : "column" }>
                              <CtaOverlayIcon className="star" icon={ getStarIcon({ rating }) } size={ 30 }/>
                              <OverlayTypography paddingBottom={ "12px" }>
                                { `${loStatistic?.averageRating ?? rating}/${smartConfiguration.ratingMax}` }
                              </OverlayTypography>
                            </Stack>
                          </Box>
                        ) : null
                      }
                    </OverlayGroupStack>
                  ) : null
              }
              {
                smartConfiguration?.funcFavourites && ((!rootId || learningObjectTypology === "PATH") && (!courseId  || learningObjectTypology === "COURSE")) ? (
                  <OverlayGroupStack
                    divider={ <OverlayDivider/> }
                  >
                    <Stack alignItems="center">
                      { 
  
                        (isAddingFavouriteLoading || isRemovingFavouriteLoading ) ? 
                          <Spinner size={ 20 } padding={ 0 }/> : 
                          (loStatistic.userFavourite) ? (
                            <CtaOverlayIcon
                              className="withpadding"
                              icon={ "Icons_salva-preferito_glow" }
                              size={ isMobile ? 24 : 20 }
                              onClick={ () => {
                                removeFavouriteMutation();
                              } }/>
                          ): (
                            <CtaOverlayIcon
                              icon={ "plus" }
                              className="withpadding"
                              size={ 20 }
                              onClick={ () => {
                                addFavouriteMutation();
                              } }/>
                          )
                      }
                    </Stack>
                  </OverlayGroupStack>
                ) : null
              }
            </OverlayStack>
          ) : null
      }
      {
        commentsModalIsOpen ? 

          <CreateContentModal
            selectedFilter={ null }
            selectedTopic={ null }
            anchorPosition={ {
              left:0,
              top:isMobile ? 200 : 0
            } }
            anchorOrigin={ {
              horizontal: -24,
              vertical: "top"
            } }
            transformOrigin={ {
              horizontal: "right",
              vertical: isMobile ? "bottom" : "top"
            } }
            anchorEl={ elAnchor }
            closeDrawer={ () => setCommentModalIsOpen(false) }
            onClose={ () => {setCommentModalIsOpen(false)} }
            open={ commentsModalIsOpen }
            postData={ {
              loAuthor: "abc123",
              loId: id.toString(),
              loType: learningObjectTypology
            } }
            postType={ POST_TYPE.SHARED }
          /> : null
      }
    
    </>
  );

  function getStarIcon({ rating } : Pick<CtaOverlayProps, "rating">) {
    if (rating <= 0 || (rating > (smartConfiguration ? smartConfiguration?.ratingMax : 1) )) {
      return "Icons_rating_active";
    }
    const grade = rating / (smartConfiguration ? smartConfiguration?.ratingMax : 1);
    if (grade === 1) {
      return "Icons_rating-1";
    } else if (grade < 0.1) {
      return "Icons_rating_active";
    } else {
      return "Star3-5";
    }
  }
}

function StarsIcons({
  id,
  learningObjectTypology,
  maxRating,
  userRating,
  visibility
} : Pick<
    LearningObject,
    | "id"
    | "learningObjectTypology"
  > & {
  maxRating:number
  userRating: number
  visibility: boolean
}) {
  const theme = useTheme();
  const [selectedStar, setSelectedStar] = useState<number>(userRating);
  const [hoveredStar, setHoveredStar] = useState<number| null>(null);
  const iconsRating = getIconsRating(maxRating);
  const learningObjectType = getLearningObjectType(learningObjectTypology);

  const { mutate: addRating } = useRatingMutation({
    learningObjectId: id.toString(),
    learningObjectType
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display={ "flex" }
      flexDirection={ "row-reverse" }
      gap={ 1 }
      padding={ theme.spacing(1, 1.5) }
      position={ "absolute" }
      right={ isMobile ? "unset" : "60px" }
      width={ "fit-content" }
      sx={ {
        alignItems: "center",
        backgroundColor: theme.customColors.backgroundPrimary && alpha(theme.customColors.backgroundPrimary, 0.8),
        borderBottomLeftRadius: "0.5rem",
        borderTopLeftRadius: "0.5rem",
        boxShadow: `0.25rem 0.25rem 0.75rem ${theme.palette.common?.black && alpha(theme.palette.common?.black, 0.25)}`,
        opacity: visibility ? 1 : 0,
        pointerEvents: visibility ? "auto" : "none",
        
        [theme.breakpoints.down("sm")]: {
          left:"-66px",
          top: "-102px",
          transform: "rotate(90deg)"
        }
        
      } }
    >
      {
        iconsRating.map((icon, index) => {
          return <Stack
            key={ `star-${index.toString()}` }

            sx={ {
              ".icon path": {
                transform:
                  (hoveredStar && hoveredStar >= icon.point)
                    ? "scale(1.6) translate(-190px, -190px)"
                    : (!hoveredStar && selectedStar && selectedStar >= icon.point)
                      ? "scale(1.6) translate(-190px, -190px)"
                      : undefined
              } }
            }
            onClick={ () => {
              addRating({ rating: icon.point });
              setSelectedStar(icon.point);
            } }
            onMouseOver={ ()=> {
              setHoveredStar(icon.point);
            }
            }
            onMouseLeave={ ()=>{
              setHoveredStar(null);
            } }
          >
            <Icon
              icon={
                (hoveredStar && hoveredStar >= icon.point)
                  ? "Icons_rating-1"
                  : (!hoveredStar && selectedStar && selectedStar >= icon.point)
                    ? "Icons_rating-1"
                    : "Icons_rating"
              }
              size={ 20 }
              color={ theme.palette.primary?.main }
            />
          </Stack>;
        })
      }
    </Box>
  );

  function getIconsRating(ratingMax:number) {

    const iconArray  = Array.from({ length: ratingMax }, (key, index) => ({
      icon: "Icons_rating",
      point: index + 1
    }));
    return iconArray;
  }
}
