import {
  Box,
  Stack
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const DeleteContentModalBox = styled(Stack)(({ theme }) => ({
  alignItems:" flex-end",
  flexDirection: "column",
  gap: "24px",
  maxHeight:"calc(100% - 32px)",
  overflowX: "hidden",
  overflowY: "auto",
  padding:"24px 40px",

  [theme.breakpoints.down("sm")]:{  
    padding:" 16px 20px"
  },

  "&:hover": {
    "&::-webkit-scrollbar-thumb":{
      backgroundColor: theme.customColors.systemPrimary01
    }
  },
  "::-webkit-scrollbar": {
    width: "6px"
  },
  "::-webkit-scrollbar-thumb":{
    backgroundColor: "transparent",
    borderRadius: "8px"
  },

  "& .editor-container": {
    backgroundColor: "white",
    borderRadius: "2px",
    color: "black",
    padding: "0 16px 16px"
  },

  "& .rdw-editor-toolbar": {
    border: "none",
    margin: "0px",
    padding: "16px 0px"
  },

  "& .rdw-option-wrapper": {
    border: "none"
  },

  "& .rdw-editor-main": {
    height: "200px",

    "&:hover": {
      "&::-webkit-scrollbar-thumb":{
        backgroundColor: theme.customColors.border
      }
    },
    "::-webkit-scrollbar": {
      width: "6px"
    },
    "::-webkit-scrollbar-thumb":{
      backgroundColor: "transparent",
      borderRadius: "8px"
    }
  },

  ".public-DraftStyleDefault-block": {
    margin: "0px"
  }

}));




export const StyledError = styled(Box)(({ theme }) => ({
  alignItems:"center",
  alignSelf:"flex-start",
  color:theme.customColors.systemSecondary03,
  display:"flex",
  gap:"8px"
}));
