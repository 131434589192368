import { Progress as ProgressComponent } from "./Progress.component";
import { ProgressProps } from "./Progress.types";
import {
  Completed as ProgressCompletedProps,
  InProgress as ProgressInProgressProps,
  New as ProgressNewProps
} from "./Progress.variants";

export const Progress = ProgressComponent;

export const ProgressCompleted = ({ ...props }: Partial<ProgressProps>) => (
  <Progress { ...{ ...ProgressCompletedProps, ...props } } />
);

export const ProgressInProgress = ({ ...props }: Partial<ProgressProps>) => (
  <Progress { ...{ ...ProgressInProgressProps, ...props } } />
);

export const ProgressNew = ({ ...props }: Partial<ProgressProps>) => (
  <Progress { ...{ ...ProgressNewProps, ...props } } />
);
