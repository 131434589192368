/* eslint-disable sort-keys */
/* eslint-disable max-len */
import {
  useQuery,
  type QueryKey,
  useQueryClient,
  useMutation
} from "@tanstack/react-query";
import type { ExercisesList, PresignedType } from "types";
import {
  ERRORS
} from "../consts";
import {
  fetcherFactory, queryClient
} from "../services";
import { useAuthStore } from "../stores";
import { getEnvironmentVariables } from "../utils/general";

const { basePath } = getEnvironmentVariables();

//GET EXERCISES LIST REVISIONS
export function useGetExercisesList({
  editionId,
  enabled = true,
  queryKey=[]
} : {
    editionId?:number
    enabled?: boolean,
    queryKey?: string[]
  }) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
    
  //const uri = `/learning-catalogue/${corporateId}/editions/119/exercises/revisions`;
  const uri = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/revisions`;
  
  return useQuery<
      unknown,
      unknown,
      ExercisesList,
      QueryKey
    >({
      enabled:Boolean(
        enabled
        && editionId
        && idToken 
        && sessionToken
        && corporateId
      ),
      queryFn: () => fetcher(
        `${basePath}${uri}`,
        {
          headers: {
            "authorization": idToken,
            "x-ada-session-token": sessionToken
          },
          method: "GET"
        })
        .then((res) => {
          return res.json();
        })
        .then((data: ExercisesList) => {
          return data;
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error;
          } else {
            throw error;
          }
        }),
      queryKey: [sessionToken, uri, ...queryKey, "getExercisesList"]
    });
}

//DELETE LEARNER ASSIGNMENT
export function useDeleteAssignment(editionId: number) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();
  const queryClient = useQueryClient();
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";

  const uriToInvalidate = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/revisions`;

  return useMutation({
    mutationFn: ({ assignmentId, exerciseId, revisionId }:{assignmentId: number | null, exerciseId: number | null, revisionId: number | null})  => {
      const uri = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/${exerciseId}/revisions/${revisionId}/assignments/${assignmentId}`;
      return fetcher(
        `${basePath}${uri}`,
        {
          headers:{
            "authorization": idToken,
            "x-ada-session-token": sessionToken
          },
          method: "DELETE"
        }
      )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey:[sessionToken, uriToInvalidate, "getExercisesList"] });
    }
  });
}

//UPLOAD GET LEARNER ASSIGNMENT PRESIGNED URL
export function useGetUploadPresignedUrl({
  editionId,
  exerciseId,
  fileName,
  type
} : {
    editionId: number
    exerciseId: number
    fileName: string,
    type: PresignedType
  }) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const uri = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/${exerciseId}/presignedurl`;
  const params =`fileName=${fileName}&type=${type}`;

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      }
    )
      .then((res) => {
        if( res ){
          return res.json();
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      })
  });
}

//UPLOAD POST LEARNER ASSIGNMENT
export function usePostUploadFile({
  editionId,
  exerciseId,
  fileId,
  fileName
} : {
    editionId:number
    exerciseId:number
    fileId:string,
    fileName: string
  }) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const uri = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/${exerciseId}/revisions/upload`;
  const uriToInvalidate = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/revisions`;

  const params = `fileId=${fileId}`;
  const body = {
    "name": fileName,
    "mediaType": "ASSIGNMENT",
    "description": "",
    "notes": "",
    "keywords": [],
    "purpose": "",
    "learningResourceType": null,
    "learningResourceContent": "",
    "durationDescription": ""
  };
  
  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        if( res && res?.bodyUsed){
          return res.json();
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey:[sessionToken, uriToInvalidate, "getExercisesList"] });
    }
  });
}

//UPLOAD PUT LEARNER ASSIGNMENT
export function usePutUploadFile({
  editionId,
  exerciseId,
  fileId,
  fileName,
  revisionId
} : {
    editionId:number
    exerciseId:number
    fileId:string,
    fileName: string,
    revisionId: number
  }) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const uri = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/${exerciseId}/revisions/${revisionId}/upload`;
  const uriToInvalidate = `/learning-catalogue/${corporateId}/editions/${editionId}/exercises/revisions`;

  const params = `fileId=${fileId}&type=ASSIGNMENT`;
  const body = {
    "name": fileName,
    "mediaType": "ASSIGNMENT",
    "description": "",
    "notes": "",
    "keywords": [],
    "purpose": "",
    "learningResourceType": null,
    "learningResourceContent": "",
    "durationDescription": ""
  };
  
  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then((res) => {
        if( res && res?.bodyUsed){
          return res.json();
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [sessionToken, uriToInvalidate, "getExercisesList"] });
    }
  });
}

//UPLOAD PUT S3 ASSIGNMENT
export function usePutS3Assignment(fileUlr: string, assignmentFile: File /*fileName: string*/) {
  //const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  //const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();

  /* const formData = new FormData();
  formData.append(`${fileName}`, assignmentFile);*/

  return useMutation({
    mutationFn: () => fetcher(
      `${fileUlr}`,
      {
        body: assignmentFile,
        /*headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },*/
        method: "PUT"
      }).then((res) => {
      return res;
    })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      })
  });
}

//DOWNLOAD LEARNER ASSIGNMENT/ TEACHER EXERCISE/ TEACHER REVISION
export function useDownloadExercise(editionId: number) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
    
  return useMutation({
    mutationFn: ({ exerciseId, mediaId, type }:{exerciseId: number, mediaId: number, type: string}) => fetcher(
      `${basePath}/learning-catalogue/${corporateId}/editions/${editionId}/exercises/${exerciseId}/revisions/download?mediaId=${mediaId}&type=${type}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      }).then(async (res) => {
      return res.json();
    })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      })
  });
}
