import {
  Box,
  BoxProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

type GridBoxProps1 =
  BoxProps & {
    columns: number,
    gap?: number
  }

type GridBoxProps2 =
  BoxProps & {
    cellwidth: string,
    gap?: number
  }

export type GridBoxProps = GridBoxProps1 | GridBoxProps2

export const GridBox = styled(Box)<GridBoxProps>((
  {
    gap=1.5,
    theme,
    ...props
  }
) => {
  // cfr. https://stackoverflow.com/questions/49514794/centering-grid-items-in-an-auto-fill-container
  let gridTemplateColumnsValue;
  let gapValue;
  if ("columns" in props) {
    gridTemplateColumnsValue = `repeat(${props.columns}, 1fr)`;
    gapValue = theme.spacing(gap);
  } else if ("cellwidth" in props) {
    gridTemplateColumnsValue =
      `repeat(auto-fit, minmax(calc(${props.cellwidth} + ${theme.spacing(gap)}), max-content))`;
  }

  return {
    display: "grid",
    gap: gapValue,
    gridTemplateColumns: gridTemplateColumnsValue,
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(gap)
      // gridTemplateColumns: "initial"
    }
  };
}
);
