/* eslint-disable sort-keys */
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExercisesCardTable } from "../../components/ExercisesCard";
import type { ExercisesList, Teacher } from "../../types";

export function TabsContentExercises(
  { 
    teachers,
    editionId,
    exercises
  }:{
    teachers: Teacher[],
    editionId:number
    exercises:ExercisesList
  }
) {


  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Typography
        color={ theme.customColors.textPrimary }
        fontSize={ "1.25rem" }
        fontWeight={ 400 }
        marginBottom={ theme.spacing(3) }
        lineHeight={ "1.5rem" }
      >
        { t("upload_exercises_description") }
      </Typography>
      <ExercisesCardTable 
        teachers={ teachers }
        editionId={ editionId }
        exercises= { exercises  }>
      </ExercisesCardTable>
    </>
  );
}
