import { Box, Grid, Stack, styled } from "@mui/material";

export const FileActionsContainer = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap"
  }
}));

export const FileActionsWrapper = styled(Stack)(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  rowGap: "4px !important",
  width: "100%"
}));

export const RowsWrapper = styled(Stack)(() => ({
  maxHeight: "375px",
  overflow: "auto"
}));

export const FileUpload = styled(Box)(({ theme }) => ({
  alignItems: "center",
  border: `1px dashed ${theme.customColors.border}`,
  borderRadius: "2px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  height: "auto",
  justifyContent: "center",
  minHeight: "56px",
  padding: "16px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    padding: "8px"
  }
}));

export const IconsContainer = styled(Stack)(() => ({
  alignItems: "center",
  marginLeft: "auto"
}));

export const FileActions = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",

  ".file-name": {
    marginRight: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "auto"
  }
}));

export const ExercisesCardContainer = styled(Box)<{ $isModal?: boolean }>(({ theme, $isModal }) => ({
  background: $isModal ? "none" : theme.linearGradients.gradientB,
  borderBottom: $isModal ? `1px solid ${theme.customColors.border}` : "none",
  borderRadius: $isModal ? "none" : "8px",
  display: "flex",
  justifyContent: "space-between",
  marginBlock: $isModal ? "0" : "16px",
  minHeight: $isModal ? "unset" : "92px",
  padding: $isModal? "32px 0" : "16px 24px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    padding: $isModal ? "16px 0" : "16px 8px"
  },

  ".icon": {
    cursor: "pointer"
  },

  ".icon-wrap": {
    maxHeight: "24px",

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      display: "flex",
      maxHeight: "14px"
    },

    "&.disabled": {
      cursor: "not-allowed !important",

      ".icon": {
        maxHeight: "24px",
        pointerEvents: "none"
      }
    },

    ".icon-replace": {
      maxHeight: "24px",

      [theme.breakpoints.down("sm")]: {
        maxHeight: "14px"
      }
    }
  },

  ".icons_impostazioni": {
    marginLeft: "auto"
  },

  ".icon-download": {
    minWidth: "24px"
  }
}));
