import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledCoverVideo = styled(Box)<
  BoxProps & { $image?:string }
>(({ theme, $image }) => ({
  backgroundImage: $image
    ? `${theme.linearGradients.gradientCAlternative02}, url(${$image})`
    : undefined,
  backgroundPosition:"center",
  backgroundRepeat:"no-repeat",
  backgroundSize:"cover",
  inset:0,
  overflow:"hidden",
  position:"absolute",

  height: "100%",
  width:"100%",

  "&:before":{
    //background: theme.linearGradients.gradientCAlternative02,
    content: "''" ,
    display:"flex",
    height:"100%",
    inset:0,
    position:"absolute",
    width:"100%",
    zIndex:1
  },

  "video":{
    height: "100%",
    width:"100%",

    objectFit:"cover"
  }
}));
