import {
  Box,
  type BoxProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { cardWidth } from "../../components/CarouselCard/CarouselCard.styles";

export const CardsBox = styled(Box)<BoxProps & { $columns?: number }>(({ $columns, theme }) => ({
  alignItems: "center",
  display: "grid",
  gridGap: "1rem",
  gridRowGap: "2.5rem",
  gridTemplateColumns: `repeat(${$columns ?? "auto-fill"}, ${cardWidth}px)`,
  justifyContent: "space-around",
  paddingBottom:"80px",
  width:"100%",


  "&:has(.carousel-card--active)": {

    ">div:not(.carousel-card--active)":{
      pointerEvents:"none"
    }

  },

  [theme.breakpoints.down("sm")]:{
    gridTemplateColumns: "repeat(1, 1fr)"
  }
}));


export const NoResultsBox = styled(Box)(({ theme }) => ({
  marginTop: "2.5rem",
  maxWidth: "100%",
  position:"relative",
  width: "100%",

  [theme.breakpoints.down("sm")]:{
    margin:"1.25rem",
    width: "calc(100% - 2.5rem)"
  }
}));
