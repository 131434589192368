/* eslint-disable max-len */
import { 
  Box,
  LinearProgress,
  Popover, Stack, Typography, useTheme
}from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { StyledUpload, UploadedFile, UploadedFileInfo } from "./AddNewFileModal.styles";
import { AddNewFileModalProps } from "./AddNewFileModal.types";
import { 
  ButtonText,
  Icon,
  Spinner
} from "../../components";
import { AddNewFileModalSuccessError } from "../../components/AddNewFileModalSuccessError";
import { formatBytes } from "../../components/ExercisesCard/FormatBytes";
import { useGetUploadPresignedUrl, usePostUploadFile, usePutS3Assignment, usePutUploadFile } from "../../queries/exercises";

export function AddNewFileModal({
  closeDrawer,
  onClose,
  open,
  editionId,
  exerciseId,
  isReplace,
  revisionId,
  ...props
}: AddNewFileModalProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [anchorEl, setAnchorElSelect] = useState<null | HTMLElement>(document.body);
  const [currentFile, setCurrentFile] = useState<File[]>([]);
  const [progress, setProgress] = React.useState(0);

  //PRESIGNED AND S3 UPLOAD
  const { mutate: mutateUploadPresigned, isLoading: presignedLoading, isSuccess: presignedSuccess, data: presignedResult, isError:presignedError } = useGetUploadPresignedUrl({ editionId: editionId, exerciseId: exerciseId, fileName: currentFile[0]?.name, type: "ASSIGNMENT" });
  const { mutate: mutateUploadPutS3, isLoading: uploadS3Loading, isSuccess: putS3Success, isError: putS3Error } = usePutS3Assignment(presignedResult?.url, currentFile[0]/*, currentFile[0]?.name*/);

  //POST AND PUT UPLOAD
  const { mutate: mutateUploadPost, isLoading: uploadPostLoading, isSuccess: uploadPostSuccess, isError: postError } = usePostUploadFile({ editionId: editionId, exerciseId: exerciseId, fileId: presignedResult?.fileId, fileName: currentFile[0]?.name });
  const { mutate: mutateUploadPut, isLoading: uploadPutLoading, isSuccess: uploadPutSuccess, isError: putError } = usePutUploadFile({ editionId: editionId, exerciseId: exerciseId, fileId: presignedResult?.fileId, fileName: currentFile[0]?.name, revisionId: revisionId });

  const handleClose = () => {
    setIsOpenModal(false);
    closeDrawer();
  };

  function saveFile() {
    mutateUploadPresigned();
  }

  useEffect(() => {
    if(!!presignedSuccess && !!presignedResult) {
      mutateUploadPutS3();
    }
  }, [presignedSuccess, presignedResult, mutateUploadPutS3]);

  useEffect(() => {
    if(putS3Success) {
      if(isReplace) {
        mutateUploadPut();
      } else {
        mutateUploadPost();
      }
    }
  }, [isReplace, putS3Success, mutateUploadPost, mutateUploadPut ]);

  useEffect(() => {
    if(uploadPostSuccess || uploadPutSuccess || postError || putError || putS3Error ||  presignedError) { 
      setIsOpenModal(true);
      setAnchorElSelect(null);
    }
  },[
    uploadPostSuccess, 
    uploadPutSuccess, 
    postError, 
    putError, 
    putS3Error, 
    presignedError]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ maxFiles: 1 });

  useEffect(() => {
    setCurrentFile(acceptedFiles);

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (!acceptedFiles.length) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 40);

    return () => {
      clearInterval(timer);
    };
  }, [acceptedFiles]);

  function removeFile(file: File) {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setCurrentFile([]);
  }

  return (
    <>
      <Popover
        className={ "popover-goals" }
        anchorEl={ "anchorEl" in props ? props.anchorEl : null }
        open={ open && 
          !uploadPutSuccess && 
          !uploadPostSuccess && 
          !putS3Error && 
          !presignedError && 
          !postError && 
          !putError }
        onClose={ onClose }
        classes={ { paper:"scrollable" } }
        sx={ {
          ".MuiPopover-paper":{
            background: theme.linearGradients.gradientB,
            border: "none",
            borderRadius:"4px !important",
            boxShadow:`50px 50px 80px 0px ${theme.customColors.backgroundDisabled}`,
            height: "auto",
            maxWidth: "662px",
            overflow:"auto",
            padding: "24px 40px",
            top: "10% !important",   
              
            [theme.breakpoints.down("sm")]: {
              border: `1px solid ${theme.customColors.borderAccent} !important`,
              borderRadius:"8px 8px 0 0 !important",
              bottom: "60px",
              boxShadow: `0px 0px 4px 0px ${theme.customColors.borderAccent}`,
              left: "2px !important",
              margin: "0 auto",
              maxHeight: "100%",
              maxWidth: "calc(100% - 4px)",
              padding: "16px 20px",
              top: "unset !important"
            }
          },
  
          ".icon-close": {
            display:"flex !important",
            marginBottom: "24px",
            marginLeft: "auto",
  
            [theme.breakpoints.down("sm")]: {
              marginBottom: "8px"
            }
          }
        } }
        { ...props }
      >
        <Icon
          className="icon-close"
          icon={ "close" }
          size={ "19px" }
          cursor="pointer"
          color={ theme.palette.primary?.main }
          onClick={ ()=> {
            if(closeDrawer){
              closeDrawer();
            }
          } }
        />
        <Typography
          fontSize={ "2rem" }
          fontWeight={ 700 }
          color={ theme.customColors.textPrimary }
          textAlign={ "center" }
        > 
          { t("add_new_file") }
        </Typography>
        <Typography
          borderBottom={ `1px solid ${theme.customColors.border}` }
          fontSize={ "1.25rem" }
          fontWeight={ 400 }
          lineHeight={ "1.5" }
          color={ theme.customColors.textPrimary }
          paddingBottom={ "24px" }
          textAlign={ "center" }>
          { t("add_new_file_description") }
        </Typography>
        <StyledUpload { ...getRootProps() }>
          <input { ...getInputProps() } />
          <Icon
            className="icon-upload"
            icon={ "Icons_upload" }
            width={ "29px" }
            height={ "29px" }
            color={ theme.customColors.systemPrimary02 }
          />
          <Typography
            fontSize={ "1rem" }
            fontWeight={ 700 }
            lineHeight={ "1.3125rem" }
            color={ theme.customColors.textPrimaryCta }
            marginTop={ "16px" }
            marginBottom={ "4px" }>
            { t("upload_file_drag") }
          </Typography>
          <Typography
            fontSize={ "0.75rem" }
            fontWeight={ 400 }
            lineHeight={ "1.5rem" }
            color={ theme.customColors.textPrimaryCta }>
            { t("upload_file_from") }
          </Typography>
        </StyledUpload>
        { !!currentFile.length && <UploadedFile>
          <UploadedFileInfo>
            <Typography className="file-name"
              fontSize={ "1rem" }
              fontWeight={ 700 }
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { currentFile[0]?.name?.substring(0, currentFile[0]?.name?.lastIndexOf(".")) }
            </Typography>
            <Typography className="file-size"
              fontSize={ "0.875rem" }
              fontWeight={ 400 }
              lineHeight={ "1.125rem" }
              color={ theme.customColors.textPrimary }>
              { `${currentFile[0]?.name?.substring(currentFile[0]?.name?.lastIndexOf(".") + 1)} ${formatBytes(Number(acceptedFiles[0]?.size))}` }
            </Typography>
            <Box sx={ { marginBlock: "8px" , width: "100%" } }>
              <LinearProgress variant="determinate" value={ progress } sx={ {
                borderRadius: 5,
                height: 10,
                maxWidth: "70%"
              } } />
            </Box>
          </UploadedFileInfo>
          <Icon
            className="icon-close"
            icon={ "close" }
            size={ "19px" }
            cursor="pointer"
            color={ theme.palette.primary?.main }
            onClick={ ()=> {
              removeFile(acceptedFiles[0]);
            } }
          />
        </UploadedFile> }
        { presignedLoading || 
          uploadS3Loading ||
          uploadPostLoading ||
          uploadPutLoading ? <Spinner/> : 
          <Stack
            marginTop={ "32px" }
            alignItems={ "center" }
            justifyContent="center"
            width={ "100%" }
            direction="row" 
            gap={ 2 }>
            <ButtonText variant="outlined"
              onClick={ ()=> {
                if(closeDrawer){
                  closeDrawer();
                }
              } }>
              { t("cancel") }
            </ButtonText>
            <ButtonText
              disabled={ !currentFile.length || progress !== 100 }               
              onClick={ () => {
                saveFile();
              } }>
              { t("save") }
            </ButtonText> 
          </Stack> 
        }
      </Popover>
      {
        isOpenModal ? (
          <AddNewFileModalSuccessError
            anchorEl={ anchorEl }
            isError={ postError || putError  || putS3Error || presignedError }
            anchorOrigin={ {
              horizontal: "center",
              vertical: "center"
            } }
            transformOrigin={ {
              horizontal: "center",
              vertical: "top"
            } }
            closeDrawer={ handleClose }
            onClose={  handleClose }
            open={ isOpenModal }
          />
        ) : null
      }
    </>
  );
}
  
  
