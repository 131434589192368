import { 
  Popover, useTheme
}from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GoalsModalProps } from "./GoalsModal.types";
import { 
  ButtonText,
  Icon
} from "../../components";
import { GoalSelection } from "../../components/GoalSelection/GoalSelection.component";
import { usePutLearnerGoalsQuery } from "../../queries";

export function GoalsModal({
  detectSuccess,
  closeDrawer,
  onClose,
  open,
  ...props
}: GoalsModalProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [planGoalValue, setPlanGoalValue] = useState("");


  const changePlan = (planGoalValue: string) => {
    setPlanGoalValue(planGoalValue);
  };

  const { mutate: mutateLearnerGoals, isSuccess:updateGoalSuccess } = usePutLearnerGoalsQuery(planGoalValue);



  return (
    <>
      <Popover
        className={ "popover-goals" }
        anchorEl={ "anchorEl" in props ? props.anchorEl : null }
        open={ open }
        onClose={ onClose }
        sx={ {
          ".MuiPopover-paper":{
            background: theme.linearGradients.gradientB,
            border: "none",
            borderRadius:"4px !important",
            boxShadow:`50px 50px 80px 0px ${theme.customColors.backgroundDisabled}`,
            height: "auto",
            maxHeight: "531px",
            maxWidth: "494px",
            overflow:"visible",
            padding: "24px 40px",
            top: "200px !important",   
            
            [theme.breakpoints.down("sm")]: {
              border: `1px solid ${theme.customColors.borderAccent} !important`,
              borderRadius:"8px 8px 0 0 !important",
              bottom: "60px",
              boxShadow: `0px 0px 4px 0px ${theme.customColors.borderAccent}`,
              left: "2px !important",
              margin: "0 auto",
              maxHeight: "100%",
              maxWidth: "calc(100% - 4px)",
              padding: "16px 20px",
              top: "unset !important"
            }
          },

          ".MuiStack-root ": {
            gap: "0"
          },

          ".icon-close": {
            display:"flex !important",
            marginBottom: "24px",
            marginLeft: "auto",

            [theme.breakpoints.down("sm")]: {
              marginBottom: "8px"
            }
          }
        } }
        { ...props }
      >
        <Icon
          className="icon-close"
          icon={ "close" }
          size={ "19px" }
          cursor="pointer"
          color={ theme.palette.primary?.main }
          onClick={ ()=> {
            if(closeDrawer){
              closeDrawer();
            }
          } }

        />
        <GoalSelection changePlan={ changePlan }></GoalSelection>
        <ButtonText 
          disabled={ !planGoalValue }
          sx={ {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "24px",
            maxWidth: "128px",

            [theme.breakpoints.down("sm")]: {
              marginTop: "32px"
            }
          } }
          onClick={ ()=> {
            mutateLearnerGoals();
            detectSuccess(updateGoalSuccess);
            if(closeDrawer){
              closeDrawer();
            }
          } }
        >
          {
            t("goal_plan_save")
          }
        </ButtonText>
      </Popover>
    </>
  );
}

