import {
  NavigateFunction,
  useNavigate as routerUseNavigate
} from "react-router-dom";
import { SOURCE } from "../consts";
import type {
  LearningObject,
  Source
} from "../types";

export function useNavigate() {
  type ExtendedNavigateFunction =
  NavigateFunction & {
    getLink: typeof getLink
    toDetail: typeof navigateToDetail
  }
  type OptionalExtendedNavigateFunction =
    & NavigateFunction
    & Partial<Pick<ExtendedNavigateFunction, "getLink" | "toDetail">>

  const navigate: OptionalExtendedNavigateFunction = routerUseNavigate();
  navigate.getLink = getLink;
  navigate.toDetail = navigateToDetail;

  function getLink(
    content: Pick<LearningObject, "id"> & { source?: Source}
  ) {
    switch(content.source) {
    case SOURCE.BRIGHTCOVE:
      return `/esplora/guarda/${content.id}`;
    case SOURCE.SCORM:
      return `/esplora/scorm/${content.id}`;
    default:
      // throw new Error("Can't navigate to unknown Content source");
      return `/esplora/guarda/${content.id}`; // FIXME: viene richiamato dal pulsante inizia presente nel corso dell'agenda (ma manca la parte finale dell'url /${course.learningObjectTypology}) dovrebbe essere ad esempio /esplora/guarda/3422/COURSE
    }
  }

  function navigateToDetail(
    content: Pick<LearningObject, "id"> & { source?: Source}
  ) {
    navigate(getLink(content));
  }

  return navigate as ExtendedNavigateFunction;
}

