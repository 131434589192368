import { Box, Stack, Table, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CookieStack = styled(Stack)(({ theme }) => ({
  margin:" 0 auto",
  paddingTop: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(7),
    width:"100%"
  }
}));


export const TextContent = styled(Box)(() => ({
  display:"flex",
  flexDirection:"column",
  gap: "32px"

}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
  justifyContent: "center",
  lineHeight: "30px",
  [theme.breakpoints.down("sm")]: {
    fontSize:"14px",
    lineHeight: "18px"
  }
 
}));


export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  gap:"32px",
  justifyContent: "center",
  lineHeight: "21px",
  [theme.breakpoints.down("sm")]: {
    fontSize:"12px",
    lineHeight: "14px"
  }
}));

export const SubTitleTypography = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  gap:"24px",
  justifyContent: "center",
  lineHeight: "21px",
  [theme.breakpoints.down("sm")]: {
    fontSize:"12px",
    lineHeight: "14px"
  }
}));

export const TableCookie = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: 0,
  width: "100%",
  
  "& td, th": {
    border: `1px solid ${theme.customColors.borderTag}`   ,
    padding: "8px"
  }
}));
