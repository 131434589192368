export function apiAdapter(
  items: Record<string, unknown>[]
) {
  return (items || []).map((item) => {
    return Object.entries(item).reduce((acc, [label, value]) => {

      if (
        label === "percentageOfCompletion"
        && typeof value === "string"
        && value.split("%").length > 0
      ) {
        const newValue = value.split(".")[0];
        acc[label] = newValue.split("%").length < 2
          ? `${newValue}%`
          : newValue;
      } else if(
        (label === "courses" || 
        label === "learningActivities") 
        && Array.isArray(value) ){       
        acc[label] = apiAdapter(value);
      } else if (
        (label === "urlCorporateDomain" || label === "urlDomain")
        && (typeof value === "string" || value instanceof String)
      ) {
        acc[label] = value.toLowerCase();
      } else {
        acc[label] = value;
      }
      return acc; 
    }, {} as Record<string, unknown>);
  });
}
