// if (import.meta.env.DEV) {
//   import("@axe-core/react")
//     .then(({ default: axe }) => {
//       axe(React, ReactDOM, 1000);
//     });
// }
import "./setup";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// React Query Devtools are only included in bundles if process.env.NODE_ENV === 'development'
import React from "react";
import ReactDOM from "react-dom/client";
import { Spinner } from "./components";
import "./services/i18n";
import {
  queryClient,
  RouterProvider
} from "./services";
import { domain1 } from "./styles/global_styles";

ReactDOM
  .createRoot(document.getElementById("root") as HTMLElement)
  .render(
    <React.StrictMode>
      <React.Suspense fallback={ <Spinner /> }>
        <QueryClientProvider client={ queryClient }>
          <ThemeProvider theme={ domain1 }>
            <CssBaseline />
            <RouterProvider />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={ false } />
        </QueryClientProvider>
      </React.Suspense>
    </React.StrictMode>
  );
