import {
  FormControl,
  TextField,
  TextFieldProps,
  useTheme
} from "@mui/material";
import React from "react";
// import { useTranslation } from "react-i18next";
import { InputBaseComponentInterface } from "./types";

const InputCst: React.FC<TextFieldProps & InputBaseComponentInterface> = (
  props
) => {
  const theme = useTheme();
  // const { t } = useTranslation(["input"]);

  const {
    id,
    label,
    value,
    onChange,
    customWidth,
    error = false,
    errorMessage = "",
    disabled = false,
    sx,
    maxValue,
    max,
    endAdornment
  } = props;

  return (
    <FormControl
      variant="standard"
      sx={ {
        "& .MuiFormLabel-root": {
          "&[data-shrink='true']": {
            maxWidth: "calc(133% - 40px)"
          },
          color: theme.customColors.textPrimary,
          maxWidth: "calc(100% - 40px)"
        },
        "& .MuiInputBase-root fieldset": {
          minHeight: "60px",
          borderColor: theme.customColors.border
        },
        alignItems: "center",
        display: "flex",
        minWidth: "200px",
        width: customWidth ? customWidth : "auto",
        marginBottom: "5px",
        ...sx
      } }
    >
      <TextField
        { ...props }
        sx={ {
          width: "100%",
          "& .MuiInputLabel-root": {
            top: "62%", // Position label vertically centered
            transform: "translateY(-50%)", // Move label up to vertically center it
            left: "12px", // Optional: adjust based on padding for visual alignment
            position: "absolute", // Ensure it doesn't shift with content
            pointerEvents: "none" // Prevent interaction
          },
          "& .MuiInputLabel-root.Mui-focused": {
            top: 0, // Reset position when focused
            transform: "translateY(-100%) scale(0.75)", // Scale and move label up
            left: "12px",
            color: theme.customColors.borderPrimaryCta
          },
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            top: 15, // When input is focused or has content
            transform: "translateY(-100%) scale(0.75)",
            left: "15px"
          },
          "& .MuiInputBase-input": {
            transform: "translateY(20%)"
          }
        } }
        size={ "small" }
        error={ error }
        helperText={
          <span
            style={ {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 0
            } }
          >
            { error ? errorMessage : "" }
          </span>
        }
        id={ id }
        label={ label }
        InputProps={ {
          endAdornment: endAdornment
        } }
        value={ value }
        onChange={ onChange }
        disabled={ disabled }
        inputProps={ {
          max: max,
          maxLength: maxValue,
          min: 0
        } }
      />
    </FormControl>
  );
};

export default InputCst;
