import {
  Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";



export const ModulesListStyledComponent= styled(Grid)(({ theme }) => ({
  marginTop:"60px",

  [theme.breakpoints.down("sm")]:{
    marginTop:"24px"
  }
}));

export const ModulesListGrid = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  gap: theme.spacing(3),
  paddingBottom:"117px",

  [theme.breakpoints.down("sm")]:{
    paddingBottom:"80px"
  }
}));

export const ModuleWrapperStyled = styled(Grid)(({ theme }) => ({
  borderBottom:`1px solid ${theme.customColors.border}`,
  display:"flex",
  paddingBottom:"1.5rem",
  width:"100%",

  ".number": {
    alignItems:"center",
    display:"flex",
    justifyContent:"center",

    fontSize:"2rem",
    fontWeight:"700",
    minWidth: "43px",
    padding:"0 80px 0 20px"
  },
  [theme.breakpoints.down("sm")]:{

    ".number":{
      alignItems:"flex-start",
      fontSize:"1.25rem",
      padding:"8px 24px 0 0"
    }

  }
}));

