import { useTheme } from "@mui/material/styles";
import React from "react";
import { CertificateButton } from "./CertificateButtonContainer.styles";
import { CertificateButtonPros } from "./CertificateButtonContainer.types";
import { Badge } from "../Badge";

export const CertificateButtonContainer = ({
  iconURL,
  small,
  downloadHandler,
  isDownloading
}: CertificateButtonPros) => {
  const theme = useTheme();
  return (
    <CertificateButton loading={ isDownloading } sx={ {
      "& .MuiLoadingButton-loadingIndicator": {
        color: theme.customColors.textPrimary
      }
    } } onClick={ ()=> !isDownloading && downloadHandler() }>
      <Badge
        loading={ isDownloading }
        iconURL={ iconURL }
        small={ small }
      />
    </CertificateButton>
  );
};
