import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { FavouriteFormats, YourGoalCard, CardsContainer } from "./ProfilePreferences.styles";
import {
  Carousel,
  ContentFormat,
  GuardedLink,
  Modal,
  ModalOnboardingSkip,
  PreferenceStack,
  Spinner

} from "../../components";
import { GoalSelection } from "../../components/GoalSelection/GoalSelection.component";
import { StyledBottomBar } from "../../components/OnBoarding/OnBoarding.styles";
import { CAROUSEL_TYPE } from "../../consts";
import {
  useGetFormats,
  useGetLearnerGoalsConfigsQuery,
  usePreferencesMutation,
  usePreferencesQuery,
  usePutLearnerGoalsQuery
} from "../../queries";
import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";
import { getError } from "../../utils/error";
import { Progress } from "../Progress/Progress.component";


export function ProfilePreferences() {
  const { t }= useTranslation();
  const {
    state: routerState
  } = useLocation();
  const navigate = useNavigate();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const[openModalSkip, setOpenModalSkip]=useState(false);
  const { data: formats, isLoading } = useGetFormats({});

  const { data: preferences } = usePreferencesQuery({});
  const {
    mutate: mutatePreferences,
    isLoading: mutatePreferencesIsLoading,
    isSuccess: mutatePreferencesIsSuccess,
    error: mutatePreferencesError
  } = usePreferencesMutation();
  const [selectedFormats, setSelectedFormats] = useState<Set<number>>(new Set());
  const [showError, setShowError] = useState(false);
  const [planGoalValue, setPlanGoalValue] = useState("");

  const changePlan = (planGoalValue: string) => {
    setPlanGoalValue(planGoalValue);
  };

  const { mutate: mutateLearnerGoals, isSuccess:updateGoalSuccess } = usePutLearnerGoalsQuery(planGoalValue);
  const { data: planGoalsConfigs, isSuccess: planGoalsConfigsSuccess } = useGetLearnerGoalsConfigsQuery();
 
  const onBoardingEnabled =   
  planGoalsConfigsSuccess && 
  planGoalsConfigs.enabled && 
  planGoalsConfigs?.yourGoals.enabled;


  useEffect(() => {
    if (preferences?.formats) {
      setSelectedFormats((prev) => {
        const newFormats = new Set(prev);
        preferences.formats.forEach(({ id }) => {
          newFormats.add(id);
        });
        return newFormats;
      });
    }
  }, [preferences]);

  useEffect(() => {
    if (mutatePreferencesIsSuccess || updateGoalSuccess) {
      navigate(  routerState?.isSummary ? 
        "../summary" : !routerState?.first ? "/profile" : "../summary", { state: routerState });
    }

  }, [
    mutatePreferencesIsSuccess, 
    updateGoalSuccess, navigate, routerState
  ]);

  useEffect(() => {
    if (mutatePreferencesError) {
      setShowError(true);
    }
  }, [mutatePreferencesError]);

  return (
    <Stack
      alignItems="center"
      gap={ 2 }
      width={ "100%" }
      display={ "flex" }
      flexDirection={ "column" }
      sx={ {
        [theme.breakpoints.down("sm")]:{
          margin: "16px 20px 24px 20px"
        }
      } }
    >
      {
        (isLoading || mutatePreferencesIsLoading) ? (
          <Spinner />
        ) : (
          <>
            <Stack
              alignItems={ "flex-start" }
              gap={ isMobile ? 1 : 2 }
              width={ "100%" }
            >
              <Typography
                color="primary"
                fontSize={ isMobile ? "1.25rem" : "2rem" }
                fontWeight={ 700 }
                lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
                textAlign={ "left" }
                sx={ {
                  [theme.breakpoints.down("sm")]:{
                    paddingTop: "18px"
                  }
                } }
              >
                { t("preferences_indicate") }
              </Typography>
              <Stack alignItems={ "left" }>
                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                  textAlign={ "left" }
                >
                  { t("preferences_method") }
                </Typography>
                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                  textAlign={ "left" }
                >
                  { t("preferences_modify") }
                </Typography>
              </Stack>
            </Stack>
           
            <CardsContainer>

              {
                onBoardingEnabled ? 
                  <YourGoalCard>
                    <GoalSelection 
                      isOnBoarding={ true } 
                      changePlan={ changePlan }/>
                  </YourGoalCard> : null
              }
          
              <FavouriteFormats>
                <PreferenceStack
                  description={ t("preference_indicate")  || undefined }
                  gap={ 2 }
                  title={ t("your_formats") || undefined  }
                  sx={ {
                    background: "transparent"
                  } }
                >
                  {
                    (Array.isArray(formats) && formats.length > 0) ? (
                      <Carousel
                        className="favourites-carousel"
                        carouselType={ CAROUSEL_TYPE.DEFAULT }
                        id={ "favourites" }
                        hasNavigation={ false }
                        isFetching= { !formats }
                        label={ "" }
                      >
                        { formats?.map((format) => (
                          <ContentFormat
                            key={ `format--${format.id}` }
                            active={ selectedFormats.has(format.id) }
                            image={ format.cover ?? "" }
                            onClick={ () => setSelectedFormats((prev) => {
                              const newFormats = new Set(prev);
                              if (newFormats.has(format.id)) {
                                newFormats.delete(format.id);
                              } else {
                                newFormats.add(format.id);
                              }
                              return newFormats;
                            }) }
                          >
                            { format.description }
                          </ContentFormat>
                        ))
                        }
                      </Carousel>
                    ) : null
                  }
                </PreferenceStack>
              </FavouriteFormats>
            </CardsContainer>
            <StyledBottomBar
              sx={ {
                justifyContent:!routerState?.first ? "center" : undefined
              } }
            >

              {
                routerState?.first ? 
                  <Progress percentageOfCompletion={ 
                    !planGoalValue && (!selectedFormats.size || !formats?.length)  ? 
                      routerState?.onBoardingPercentage : "100%" 
                  }
                  /> : 
                  null
              }
             
              <Box
                display={ "flex" }
                gap={ 2 }
              >
                {
                  routerState?.first ? 
                    <GuardedLink
                      variant="outlined"
                      href={ "" }
                      onClick={ () => {
                        setOpenModalSkip(true);
                        return false;
                      } }
                    >
                      { t("later") }
                    </GuardedLink> : null

                }
             
                <GuardedLink
                  disabled={ !planGoalValue && (!selectedFormats || !formats?.length) }
                  href={ routerState?.isSummary ? 
                    "../summary" : !routerState?.first ? "/profile" : "../summary" }
                  onClick={ handleSubmit }
                  state= { { ...routerState } }
                >
                  { t(routerState?.first ? "continue" : "confirm") }
                </GuardedLink>
              </Box>
            </StyledBottomBar>
          </>
        )
      }
      {
        <Modal
          description={ getError(mutatePreferencesError)?.[0]?.message ?? "" }
          onClose={ () => setShowError(false) }
          open={  showError }
          title={ getError(mutatePreferencesError)?.[0]?.name ?? "" }
        />
      }
      {
        <ModalOnboardingSkip 
          open={ openModalSkip }
          onClose={ () =>  setOpenModalSkip(false) }
        />
      }
    </Stack>
  );

  function handleSubmit() {
    mutatePreferences({
      formats: Array.from(selectedFormats),
      hour_preferences: []
    });

    if(planGoalValue) {
      mutateLearnerGoals();
    }
    
    return false;
  }
}
