import {
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardGrid = styled(Grid)(({ theme }) => ({
  margin:"29px 0 80px",

  "> *": {
    padding: theme.spacing(1.5),
    paddingBlock: theme.spacing(0.25)
  }

}));

export const CardBodyGrid = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth:"calc(100% - 316px)",
  padding:"0 16px 0 32px ",
  width:"100%",


  [theme.breakpoints.down("sm")]:{
    gap:theme.spacing(1),
    maxWidth:"100%",
    width:"100%",

    padding:"24px 0 0 ",

    "button ":{
      fontSize:"0.75rem",
      padding:"0",

      ".icon":{
        margin:"0 1rem",

        "path":{
          fill:theme.palette.primary?.main
        }
      }
    }
  }
}));


export const ImageContainer = styled(Grid)(() => ({
  height:  "237px" ,
  padding:  "6px 0 ",
  paddingBlock: " 0 !important" ,
  width:  "316px"
}));

export const ImageBox = styled("img")(() => ({
  aspectRatio: "9 / 7",
  borderRadius: "8px" ,
  objectFit: "cover",

  height: "100%",
  width: "100%"

}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: 1.3125,

  [theme.breakpoints.down("sm")]:{
    fontSize:"1.5rem"
  }
}));

