import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StyledModal } from "./ModalOnboardSkip.styles";
import {  GuardedLink, Icon } from "../../components";

import { useAuthStore } from "../../stores";


export function ModalOnboardingSkip({
  onClose,
  open
}:{
    onClose: () => void
    open: boolean
}){
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const postOnBoardingCompleted = useAuthStore(state => state.postOnBoardingCompleted);


  return (
    <StyledModal
      aria-labelledby="modal"
      open={ open }
      onClose={ onClose }
    >
      <Stack 
        alignItems="center" sx={ { cursor:"pointer" } }
        gap={ 1 }
      >
        <Stack alignSelf={ "flex-end" }
          onClick={ onClose }
          marginBottom={ 2 }
        >  
          <Icon 
            icon="close" 
            size={ 18 } 
            color={ theme.customColors.systemPrimary02 }/>
        </Stack>
    
        <Typography
          className="title"
        >
          { t("skip_step_onboarding") }
        </Typography>
        <Typography
          className="description"
        >
          { t("skip_step_description") }
        </Typography>
        <Stack
          flexDirection={ "row" }
          gap={ 2 }
          justifyContent={ "center" }
          marginTop={ 3 }
        >
          <GuardedLink
            href={ "" }
            variant={ "outlined" }
            onClick={ ()=> {
              completeOnboarding();
            } }
          >
            { t("yes_skip") }
          </GuardedLink>
          <GuardedLink
            href={ "" }
            onClick={ ()=> {
              onClose();
              return false;
            } }
          >
            { t("no_continue") }
          </GuardedLink>
          
        </Stack>
      
      </Stack>
    </StyledModal>

  
  );
  function completeOnboarding(){
    postOnBoardingCompleted();
    setTimeout(()=> {
      navigate("/");
    },2000);
    return false;
  }
}

