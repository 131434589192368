import {
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardGrid = styled(Grid)(({ theme }) => ({
  margin: "0 0 40px",

  "> *": {
    background: theme.linearGradients.gradientB,
    borderRadius: "8px",
    maxWidth: "unset",
    padding: "16px"
  }
}));

export const CardBodyGrid = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  gap: theme.spacing(1),

  ">div, >h3":{
    marginBottom:theme.spacing(1)
  },

  [theme.breakpoints.down("sm")]:{
    gap: theme.spacing(1)
  }
}));


export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.15,
  [theme.breakpoints.down("sm")]:{
    fontSize: "1.5rem"
  }
}));
