import { 
  Typography, 
  useMediaQuery, 
  useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { YourPlanContainer, ProfileBox, ProfileImage, PlanDescription, YourGoals } from "./SignupSummary.styles";
import { CardUserGoals } from "../../components/CardUserGoals";

export function SignupSummaryPlan({
  profileImage,
  name
}:{
   profileImage:string
   name:string
}){
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  return (

    <YourPlanContainer>
      <ProfileBox>
        {
          profileImage ? 
            <ProfileImage sx={ {
              backgroundImage:  `url(${profileImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            } }>
              <span className="profile-name">
                { name }
              </span>
            </ProfileImage> : null
        }
       
        <PlanDescription>
          <Typography 
            className="plan-title"
            color={ theme.customColors.textPrimary }
            fontSize={ isMobile ? "1.25rem" : "2rem" }
            fontWeight={ 700 }
            textAlign={ "start" }
            lineHeight={ "1.1875" }
          >
            { t("plan_title") }
          </Typography>
          <Typography 
            className="plan-subtitle"
            color={ theme.customColors.textPrimary }
            fontSize={ isMobile ? "0.875" : "1.25rem" }
            fontWeight={ 400 }
            textAlign={ "start" }
            lineHeight={ "1.05" }
          >
            { t("plan_subtitle") }
          </Typography>
          <Typography 
            className="plan-description"
            color={ theme.customColors.textPrimary }
            fontSize={ isMobile ? "0.875" : "1.25rem"  }
            fontWeight={ 400 }
            textAlign={ "start" }
            lineHeight={ "1.05" }
          >
            { t("plan_description") }
          </Typography>
        </PlanDescription>
      </ProfileBox>
      <YourGoals>
        <CardUserGoals isProfilePage={ false } isOnBoarding={ true }/>
      </YourGoals>
    </YourPlanContainer>
  );
}
