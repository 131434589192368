import {
  Box,
  Modal,
  Stack,
  StackProps,
  Step,
  StepProps
} from "@mui/material";
import {
  alpha,
  styled
} from "@mui/material/styles";
import type { ElementType } from "react";

export const OnBoardingStack = styled(Stack)<
  StackProps & {
    /*
      there's something wrong with the type implementation of
      OverridableComponent and Stack when extending the default Stack export
    */
    component?: ElementType
  }
>(({ theme }) => ({
  alignItems: "center",
  background:  alpha(theme.customColors.backgroundPrimary, 0.2),
  borderRadius: theme.spacing(1),
  boxShadow: "25px 25px 33px rgba(0, 0, 0, 0.25)",
  marginBottom: "200px",
  position:"relative",
  width: "clamp(83%, 1300px, 83%)",
  zIndex:1,

  "&:before":{
    backdropFilter:"blur(10px)",
    borderRadius: theme.spacing(1),
    content:"''",
    inset:0,
    position:"absolute",
    zIndex:-1
  },

  [`${theme.breakpoints.down("sm")}`]:{
    padding:theme.spacing(1,2,4),
    width: "clamp(90%, 1200px, 80%)"
  }
}));

export const StyledStep = styled(Step)<
  StepProps & { component?: ElementType }
>(({ active, theme }) => ({
  alignItems:"center",
  color: active ? theme.customColors.accentVariantA : undefined,
  display:"flex",
  fontSize: "0.875rem",
  gap:theme.spacing(0.5),
  lineHeight: "9 / 7",
  margin: 0,
  padding: 0,
  position: "relative",

  "&:after": {
    border: `${theme.spacing(0.2)} solid ${theme.customColors.accentVariantA}`,
    bottom: theme.spacing(-0.2),
    boxShadow: `0px 0px 4px ${theme.customColors.systemSecondary01}`,
    content: active ? "''" : undefined,
    left: 0,
    position: "absolute",
    width: "100%"
  },

  [theme.breakpoints.down("sm")]:{
    fontSize: "0.75rem"
  }
}));

export const StyledBottomBar = styled(Box)(({ theme }) =>( {
  backdropFilter:"blur(20px)",
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.1),
  borderRadius:theme.spacing(1),
  bottom:theme.spacing(3),
  display:"flex",
  flexWrap:"wrap",
  gap:theme.spacing(1),
  justifyContent:"space-between",
  left:0,
  margin:"0 auto",
  minHeight:"72px",
  padding:theme.spacing(2,17),
  position:"fixed",
  right:0,
  width:"clamp(85%, 1250px, 85%)",
  zIndex:1200,

  [`${theme.breakpoints.down("sm")}`]:{
    bottom:0,
    justifyContent:"center",
    padding:theme.spacing(1,3,2),
    width:"100%",

    ".progress-component":{
      maxWidth:"unset",
      width:"calc(100% - 60px)"
    }
  }
  
})); 

export const StyledModal = styled(Modal)(({ theme }) => ({
  alignItems:"center",
  backdropFilter:"blur(20px)",
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.1),
  borderRadius:theme.spacing(1),
  boxShadow: "24px",
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(2),
  height: "fit-content",
  left: "50%",
  padding: theme.spacing(4),
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  ".MuiModal-backdrop":{
    backdropFilter:"none",
    background:"none",
    borderRadius:theme.spacing(1)

  }
}));
