//import { useMediaQuery, useTheme, Box } from "@mui/material";
//import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DetailTypography,
  HeroCardStack,
  TextGrid,
  TitleTypography
} from "./HeroCardSearch.styles";
import { HeroCardSearchProps } from "./HeroCardSearch.types";
//import imageHeroCard from "../../assets/HeroCardSearch1.png";


export function HeroCard({
  coverPublicURL,
  shortDescription,
  title,
  ...props
}: HeroCardSearchProps) {
  const { t } = useTranslation();
  //const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (<>
    <HeroCardStack
      component="section"
      coverPublicURL={ coverPublicURL }
      gap={ 1.5 }
      { ...props }
    >
      <TextGrid>
        <TitleTypography>
          { t(title) }
        </TitleTypography>
        <DetailTypography>
          { t(shortDescription) }
        </DetailTypography>
      </TextGrid>
    </HeroCardStack>
  </>
  );
 
}
