import {
  Accordion,
  Box,
  Grid,
  GridProps,
  SwipeableDrawer
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const StyledBucketGridItem = styled(Grid)<
  GridProps & { $active?: boolean }
>(({
  $active=false,
  theme
}) => ({
  alignItems:"flex-start",
  background: alpha(theme.customColors.backgroundSecondary, 0.05),
  borderRadius: theme.spacing(1),
  columnGap:theme.spacing(3.5),
  display:"flex",
  flexWrap:"wrap",
  height:"33%",
  opacity: $active ? 0.8 : 1,
  padding: theme.spacing(2),
  position:"relative",
  rowGap:theme.spacing(2.5),
  width:"100%",

  [`${theme.breakpoints.down("sm")}`]:{
    height:"180px",
    padding: theme.spacing(1,1.5)
  }
}));

export const StyledKnowledgeWrapper = styled(Grid)<
  GridProps 
>(({ theme }) => ({
  position:"relative",
  width:"40%",

  maxHeight:"100%",
  overflowX:"visible",

  [`${theme.breakpoints.down("sm")}`]:{
    width:"100%"
  }

}));

export const ExecutivesKnowledgeAreasContainer = styled(Grid)<
  GridProps & { $active?: boolean }
>(({
  $active=false,
  theme
}) => ({
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.05),
  borderRadius:theme.spacing(1),
  color:$active ? theme.customColors.textPrimaryAlternative : theme.customColors.accentVariantB,
  padding:theme.spacing(2,3,2,2),

  width:"100%",

  height:"100%",


  [`${theme.breakpoints.down("sm")}`]:{
    padding:theme.spacing(1)
  }
}));

export const StyledOpenPersonalSkillsButton = styled(Grid)<
  GridProps>(({ theme }) => ({
    background:theme.customColors.backgroundPrimaryCta,
    borderRadius: "0.25rem 0 0 0.25rem",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.7)",
    cursor:"pointer",
    lineHeight:0,
    padding: theme.spacing(1.75,1.375),
    position:"absolute",
    top:0,
    zIndex: "1200",

    "&.left":{
      left:"-30px"
    },
    "&.right":{
      right:"-20px"
    },

    [`${theme.breakpoints.down("sm")}`]:{
      top:"44px",

      "&.left":{
        left:0
      },
      "&.right":{
        right:0
      }
    }
  }));

export const StyledKnowledgeAreasAccordion = styled(Accordion)(({
  theme
}) => ({
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.10),
  borderRadius:"8px !important",
  marginBottom:theme.spacing(2),




  "&:before":{
    content:"none"
  },


  ".MuiAccordionSummary-root":{
    minHeight:"unset",
    padding:theme.spacing(2),

    ".MuiAccordionSummary-expandIconWrapper":{
      transition:"all .3s linear"
    },

    "& .MuiAccordionSummary-content ":{ margin:0 } 
  },

  ".MuiAccordionDetails-root":{
    columnGap:theme.spacing(3.25),
    display:"flex",
    flexWrap:"wrap",
    maxHeight:"400px",
    rowGap:theme.spacing(2.5)


  },

  [`${theme.breakpoints.down("sm")}`]:{
    ".MuiAccordionSummary-root":{
      padding:theme.spacing(2,1),


      "& .MuiAccordionSummary-content ":{ 
        "p":{
          fontSize:theme.spacing(2),
          lineHeight:"21px"
        }
      } 
    }
  }

}));

export const StyledBucketsContainer = styled(Grid)(({ theme }) => ({
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.05),
  borderRadius:theme.spacing(1),
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(0.5),
  padding:theme.spacing(2,3),
  width:"60%",
  
  [`${theme.breakpoints.down("sm")}`]:{
    padding:theme.spacing(1),
    width:"100%"
  }
}));

export const PersonalSkillsBox = styled(Box)(({ theme })=> ({
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.05),
  borderRadius:theme.spacing(1),
  display:"flex",
  flexDirection:"column",
  padding:theme.spacing(6,4,2),
  
  ".tag":{
    color:theme.customColors.textPrimaryCta,
    fontSize:theme.spacing(1.75),
    fontWeight:700
  },
  [`${theme.breakpoints.down("sm")}`]:{
    minHeight:"340px",
    padding:theme.spacing(2,4)

  }

}));
  
export const PersonalSkillsItemsBox = styled(Box)(({ theme })=> ({
  display:"flex",
  flexDirection:"row",
  flexWrap:"wrap",
  gap:theme.spacing(2)
}));

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    alignItems:"center",
    backdropFilter:"blur(40px)",
    background: "transparent",
    backgroundColor:alpha(theme.customColors.backgroundDisabledAlternative, 0.1),
    borderLeft:`2px solid ${theme.customColors.accentVariantB}`,
    borderRight:`2px solid ${theme.customColors.accentVariantB}`,
    borderTop:`2px solid ${theme.customColors.accentVariantB}`,
    borderTopLeftRadius:theme.spacing(1),
    borderTopRight:`2px solid ${theme.customColors.accentVariantB}`,
    borderTopRightRadius:theme.spacing(1),
    bottom:0,
    boxShadow:`0px 0px 4px 0px ${theme.customColors.accentVariantB}`,
    boxSizing: "border-box",
    display:"flex",
    flexDirection:"column",
    gap:theme.spacing(2),
    height: "70%",
    justifyContent:"center",
    padding:theme.spacing(3)
  }
}));
