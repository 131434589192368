import {
  Box,
  BoxProps
} from "@mui/material";
import {
  forwardRef,
  Ref
} from "react";

export const _Box = forwardRef((
  props: BoxProps & { $small?: boolean },
  ref: Ref<HTMLDivElement>
) => (
  <Box { ...props } ref={ ref }/>
));
_Box.displayName = "Box";
