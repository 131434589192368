import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetcherFactory } from "../services/fetcher";
import { getEnvironmentVariables } from "../utils/general";
const {
  basePath
} = getEnvironmentVariables();

export interface StepResponse {
  action: null
  enabled: boolean
  id: number
  steps:RegistrationStep[]
}

export interface RegistrationStep {
  label: string;
  description?: string | null;
  fields: RegistrationStepField[];
  order:number
}

export interface RegistrationStepField {
  label: string;
  key: string;
  required: boolean;
  type: FieldType;
  row: boolean;
  order?: number;
  options?: Option[];
  depends_on?: string[];
  visible_on?: string;
  fields?: RegistrationStepField[];
  file_name?: string;
  file_link?: string;
  selected?: string;
  upload_max_size?: number;
}

type FieldType = "TEXT" | "LIST" | "EMAIL" | "DATE" | "BOOLEAN" | "PARAGRAPH" | "DIVIDER" | "INTEGER" | "RADIO" | "GROUP_ADD" | "GROUP" | "MULTI_SELECT" | "DOWNLOAD" | "UPLOAD";

export interface Option {
  key?: number;
  label: string;
  value: string;
}

interface OptionResponse{
  key:string,
  options:Option[]
}

interface SubmitFormRequest {
  formType: string;
  data: Record<string, unknown>;
}

export function useSubmitForm(idCorporate: number) {
  const fetcher = fetcherFactory();

  const queryClient = useQueryClient();

  const { basePath } = getEnvironmentVariables();

  return useMutation({
    mutationFn: ({ formType, data }: SubmitFormRequest) =>
      fetcher(`${basePath}/usermgmt/corporates/${idCorporate}/forms/${formType}/submit`, {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST"
      })
        .then((res) => res.json())
        .catch((error) => {
          throw error;
        }),
    onSuccess: (data, { formType }) => {
      // Invalida le query pertinenti per aggiornare i dati dopo la sottomissione del form
      queryClient.invalidateQueries({ queryKey: [`/usermgmt/corporates/${idCorporate}/forms/${formType}`] });
    }
  });
}

export const useFormSteps = ({
  enabled = true,
  formType,
  idCorporate
}: {
  enabled?: boolean;
  formType: string;
  idCorporate: number;
}) => {
  const fetcher = fetcherFactory();

  const uri = `${basePath}/usermgmt/corporates/${idCorporate}/forms/${formType}`;
  return useQuery<
    unknown,
    unknown,
    StepResponse,
    QueryKey
  >({
    enabled: Boolean(enabled && idCorporate && formType),
    queryFn: () => fetcher(`${uri}`,   {
      headers: {
        "accept": "application/json"
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((data: StepResponse) => {
        return data;
      })
      .catch((error) => {
        throw error;
      }),
    queryKey: ["useFormSteps", idCorporate, formType]
  });
};


export const useOptionsList = ({
  enabled = true,
  formType,
  dependsOn,
  fieldKey,
  idCorporate
}: {
  enabled?: boolean;
  formType: string;
  fieldKey?:string;
  dependsOn?: { [key: string]: string }[];
  idCorporate: number
}) => {
  const fetcher = fetcherFactory();
  const uri = `${basePath}/usermgmt/corporates/${idCorporate}/forms/${formType}/fields/${fieldKey}/options?`;
  let queryString = "";

  if (dependsOn) {
    queryString = dependsOn
      .map(item => Object.entries(item).map(([key, value]) => `${key}=${value}`))
      .flat()
      .join("&");
  }
  return useQuery<
    unknown,
    unknown,
    OptionResponse,
    QueryKey
  >({
    enabled: Boolean(enabled && idCorporate && formType && fieldKey),
    queryFn: () => fetcher(`${uri}${queryString}`, {
      body: null,
      headers: {
        "Content-Type": "application/json"
      },
      method: "GET"
    })
      .then((res) => {
        return res.json();
      })
      .then((data: OptionResponse) => {
        return data;
      })
      .catch((error) => {
        throw error;
      }),
    queryKey: ["useOptionsList", idCorporate, formType, fieldKey,dependsOn]
  });
};
