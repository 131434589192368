import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const VideoCaptionStyles = styled(Stack)(({ theme }) => ({
  position:"absolute",

  bottom:"20px",
  left:"42px",

  fontSize:"2rem",
  margin:"0 0 110px",
  maxWidth:"70%",
  padding:"0 0 110px",
  zIndex:"1",

  [theme.breakpoints.down("sm")]: {
    left: "20px",
    margin:"0 0 30px",
    padding: "0"
  }
}));
