
import { 
  Avatar, 
  Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {  useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { CardShareTabsProps } from "./CardShareTabs.types";
import { ButtonLink, Icon, ModifyContentModal, TopicCardSignature } from "../../components";
import { DeleteContentModal } from "../../components/DeleteContentModal";
import { 
  ActionLink,
  BodyTypography, 
  OwnerNameTypography, 
  TopicCardBody, 
  TopicCardContainerStack, 
  TopicCardMainInfo, 
  TopicCardTop 
} from "../../components/TopicCard/TopicCard.styles";
import { POST_TYPE, ROLES } from "../../consts";

import { useAuthStore } from "../../stores";
import { 
  stringAvatar, 
  useGetCreatedLabel 
} from "../../utils/community";


export function CardShareTabs({
  body,
  created,
  loId,
  ownerImage,
  ownerName,
  postId,
  topicOwner
}: CardShareTabsProps) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isModifyOpen, setIsModifyOpen] = useState(false);
  const userId = useAuthStore(state => state.userData?.id_user);
  const userData = useAuthStore(state => state.userData);

  const isModerator = userData?.roles.some(role => role === ROLES.BUSINESS_ADMIN) ;
  const isEditable =  userId && topicOwner === userId.toString() || isModerator;


  return (
    <TopicCardContainerStack>
      <TopicCardTop>
        <TopicCardMainInfo>
          <Avatar
            { ...stringAvatar(ownerName) }
            src={ ownerImage }
            sx={ {
              height: isMobile ? "32px" : "56px",
              width: isMobile ? "32px" : "56px"
            } }
          />
          <Stack 
            gap={ 1 } 
            flexGrow={ 1 }
          >
            <Stack 
              direction={ "row" } 
              justifyContent={ "space-between" } 
              alignItems={ "center" }>
              <OwnerNameTypography>{ ownerName }</OwnerNameTypography>
      
            </Stack>
      
            <TopicCardSignature
              created={ useGetCreatedLabel(created) }
              size={ "medium" }
            />
    
          </Stack>
        </TopicCardMainInfo>
        { 
          isEditable ? 
            <Stack 
              direction="row"
              alignItems={ "flex-end" }
              gap={ "1rem" }
            >
              <ActionLink
                onClick={ () => setIsModifyOpen(true) }
              >
                { t("modify") }
              </ActionLink> 
              <ActionLink 
                onClick={ () => setIsDeleteOpen(true) }
              >
                { t("delete") }
              </ActionLink> 
            </Stack>  : 
            null
        }
      </TopicCardTop>

      <TopicCardBody>
        <BodyTypography>
          { body }
        </BodyTypography>
        <ButtonLink
          onClick={ () => {
            navigate(
              `/community?scrollTo=${postId}`
            );
          }
          }
        >
          { t("open_community") }
          <Icon 
            color={ theme.palette.primary?.main }
            icon={ "arrow_right_horizontal" } 
            size={ 20 } 
          />
        </ButtonLink> 
      </TopicCardBody>
      {
        isDeleteOpen && loId ? 

          <DeleteContentModal 
            selectedFilter={ null }
            selectedTopic={ null }
            id={ postId }
            postType={ POST_TYPE.SHARED }
            loId={ loId }
            closeDrawer={ ()=> {
              setIsDeleteOpen(false);
            } }
            open={ isDeleteOpen }
            isModerator={ Boolean(isModerator) }

          /> : 
          null
      }



      {
        isModifyOpen ? 
          <ModifyContentModal
            selectedFilter={ null }
            selectedTopic={ null }
            open={ isModifyOpen }
            loId={ loId }
            onClose={ () => setIsModifyOpen(false) }
            id={ postId }
            postType={ POST_TYPE.SHARED }
            body={ body }
            closeDrawer={ () => setIsModifyOpen(false) }
          /> : null

      }
    </TopicCardContainerStack>
  );
}
