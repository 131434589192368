import { useTheme } from "@mui/material/styles";
import { TypeTypography } from "./CalendarCarouselCard.style";
import {
  useType
} from "../../hooks";
import type { ContentType, Level } from "../../types";

export function TypeLabel({
  learningObjectType,
  learningObjectTypology
}: {
  learningObjectType: ContentType
  learningObjectTypology?: Level
}) {
  const theme = useTheme();
  const typeLabel = useType(learningObjectType, learningObjectTypology);

  return <TypeTypography sx={ { color: theme.customColors.textMandatory } }>{ typeLabel }</TypeTypography>;
}

