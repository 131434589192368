import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonLink } from "../../components";

export const CommunityDetailsMainStack = styled(Stack)(() => ({
  width: "100%"
}));

export const BackButton = styled(ButtonLink)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize:"1.141rem",
  fontWeight:"400",
  gap:"9px",
  justifyContent: "left",
  padding:"0 0 122px 0",
  width: "100%",

  [theme.breakpoints.down("sm")]:{  
    padding:"0 0 40px 0"
  },

  "&:hover":{
    background:"transparent"
  },
            
  ".icon":{
    height:"18px",
    width:"13px",

    "& path": {
      fill: theme.customColors.systemPrimary01
    }
  }
}));
