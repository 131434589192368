import {
  useQuery,
  useMutation,
  // useQueryClient,
  type QueryKey,
  useQueryClient
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
import type { UserGoals, UserGoalsConfigs, UserProgress } from "../types";
import { getEnvironmentVariables } from "../utils/general";

const { basePath } = getEnvironmentVariables();

export function useLearnerProgressQuery() {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  const fetcher = fetcherFactory();
    
  const uri = `/runtastic/learnerProgress/${corporateId}/${userId}`;
  
  return useQuery<
      unknown,
      unknown,
      UserProgress,
      QueryKey
    >({
      enabled: Boolean(
        userId
        && accessToken
        && sessionToken
        && corporateId
      ) && !isSeedingSession,
      queryFn: () => fetcher(
        `${basePath}${uri}`,
        {
          headers: {
            "Authorization": accessToken,
            "x-ada-session-token": sessionToken
          }
        })
        .then((res) => {
          return res.json();
        })
        .then((data: UserProgress) => {
          return { 
            data
          };
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        }),
      queryKey: [accessToken, sessionToken, uri, isSeedingSession, "userProgress"]
    });
}
  
export function useGetLearnerGoalsQuery() {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
    
  const fetcher = fetcherFactory();
  const uri = `/runtastic/learnerGoals/${corporateId}/${userId}`;
  
  return useQuery<
      unknown,
      unknown,
      UserGoals,
      QueryKey
    >({
      enabled: Boolean(
        userId
        && accessToken
        && sessionToken
        && corporateId
      ) && !isSeedingSession,
      queryFn: () => fetcher(
        `${basePath}${uri}`,
        {
          headers: {
            "Authorization": accessToken,
            "x-ada-session-token": sessionToken
          },
          method: "GET"
        })
        .then((res) => {
          return res.json();
        })
        .then((data: {data: UserGoals}) => {
          return data;
  
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        }),
      queryKey: [accessToken, sessionToken, uri, isSeedingSession, "getUserGoals"]
    });
}
  
export function useGetLearnerGoalsConfigsQuery() {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
    
  const fetcher = fetcherFactory();
  const uri = `/runtastic/configs/${corporateId}`;
  
  return useQuery<
      unknown,
      unknown,
      UserGoalsConfigs,
      QueryKey
    >({
      enabled: Boolean(
        userId
        && accessToken
        && sessionToken
        && corporateId
      ) && !isSeedingSession,
      queryFn: () => fetcher(
        `${basePath}${uri}`,
        {
          headers: {
            "Authorization": accessToken,
            "x-ada-session-token": sessionToken
          },
          method: "GET"
        })
        .then((res) => {
          return res.json();
        })
        .then((data: UserGoalsConfigs) => {
          return data;
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        }),
      queryKey: [accessToken, sessionToken, uri, isSeedingSession, "getUserGoalsConfigs"]
    });
}
  
export function usePutLearnerGoalsQuery(plan: string) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
    
  const fetcher = fetcherFactory();
  const uri = `/runtastic/learnerGoals/${corporateId}/${userId}`;
  
  const queryClient = useQueryClient();
  
  return useMutation({
  
    mutationFn: () => fetcher(
      `${basePath}${uri}`,
      {
        body: JSON.stringify({
          plan: plan.toUpperCase()
        }),
        headers:{
          "authorization": accessToken,
          "content-type":"application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([accessToken, sessionToken, uri,  isSeedingSession, "getUserGoals"]);
    }
  });
}
