import { Box, Grid } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddSkillBookTemplateProps,
  Errors,
  Field,
  FormValues, MultiSelectOptionsProps, SkillBook
} from "./AddSkillBook.types";
import { ButtonGroups } from "../../../components/ButtonGroups";
import DataPickerCst from "../../../components/InputCst/DataPickerCst";
import InputCst from "../../../components/InputCst/InputCst";
import InputGroup from "../../../components/InputCst/InputGroup";
import MultiSelectCst from "../../../components/InputCst/MultiSelectCst";
import { DataResponse, Options } from "../../../components/InputCst/MultiSelectCst/types";
import SelectCst from "../../../components/InputCst/SelectCst";
import { VisuallyHiddenInput } from "../../../components/InputCst/UploadInput";
import OutputMessage from "../../../components/Message/OutputMessage.component";
import { ModalBasic } from "../../../components/ModalBasic";
import {
  useCapabilitiesOptionsQuery,
  useKnowledgeAreasOptionsQuery,
  useKnowledgeDomainsOptionsQuery,
  useCourseTypesOptionsQuery, usePostCertificate
} from "../../../queries/skillbook";
import { form_skillBook_add } from "../../../utils/form_configurations";
import { handleFileChange, onSubmit } from "../../../utils/skillBook";

const AddSkillBookTemplate: React.FC<AddSkillBookTemplateProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = React.useState<FormValues>({});
  const [errors, setErrors] = React.useState<Errors>({});
  const [knowledgeAreas, setKnowledgeAreas] = useState<MultiSelectOptionsProps[]>([]);
  const [knowledgeDomains, setKnowledgeDomains] = useState<MultiSelectOptionsProps[]>([]);
  const [capabilities, setCapabilities] = useState<MultiSelectOptionsProps[]>([]);
  const [courseTypes, setCourseTypes] = useState<Options[]>([]);
  const [isOutputModalOpen, setIsOutputModalOpen] = React.useState(false);
  const [submitSucceeded, setSubmitSucceeded] = useState<boolean>();
  const [selectedArea, setSelectedArea] = useState<{ isUpdating: boolean; value?: string | number }>({
    isUpdating: false,
    value: ""
  });
  const formData = form_skillBook_add.fields; //FORM FIELDS

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => { //ONCHANGE INPUT
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleChangeMultiSelect = ( //ONCHANGE MULTIPLE SELECT
    event: { label: string; value: string | number }[],
    accessorKey: string
  ) => {
    if (!["knowledgeArea", "knowledgeDomain", "capability"].includes(accessorKey as string)) return;
    let newValues: Options[] = [];

    if (formValues[accessorKey]) {
      const formValueArray = formValues[accessorKey] as Options[] || [];
      const formValueDomains = formValues["knowledgeDomain"] as Options[] || [];

      const itemsToAdd = event.filter((ev) => !formValueArray.includes(ev));

      const itemsToRemove = formValueArray.filter(
        (area) => {
          return !event.some((ev) => ev.value === area.value );
        }
      );

      if (accessorKey === "knowledgeArea" && itemsToAdd[0])
        setSelectedArea({ isUpdating: true, value: itemsToAdd[0].value });

      if (accessorKey === "knowledgeArea" && itemsToRemove[0]) {
        const updatedDomains = knowledgeDomains.filter(
          (domain: MultiSelectOptionsProps) => itemsToRemove.every((item) => domain!.parent !== item.value)
        );
        const updatedSelectedDomains = formValueDomains.filter(
          domain => itemsToRemove.every((item) => domain!.parent !== item.value)
        );

        setKnowledgeDomains(updatedDomains);
        setFormValues((prevState) => ({
          ...prevState,
          "knowledgeDomain": updatedSelectedDomains
        }));
      }

      newValues = [
        ...formValueArray.filter(
          (area) => !itemsToRemove.includes(area)
        ),
        ...itemsToAdd
      ];
    } else {
      newValues = [...event];
      if (accessorKey === "knowledgeArea")
        setSelectedArea({ isUpdating: true, value: event[0].value });
    }

    setFormValues(prevState => ({
      ...prevState,
      [accessorKey]: newValues
    }));

  };

  const handleDateChange = (name: string) => (date: Date | null) => { //ONCHANGE DATE FIELD
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: formattedDate
      }));
    }
  };

  const validateForm = () => {
    let newErrors: Errors = {};
    const today = new Date();
    const startDate = new Date(formValues.startDate as string);
    const endDate = new Date(formValues.endDate as string);
    const validationStartDate = new Date(formValues.validationStartDate as string);
    const validationEndDate = new Date(formValues.validationEndDate as string);
    formData.forEach((field: Field) => {
      if (field.required && !formValues[field.name]) {
        newErrors[field.name] = t(`${field.name}_required`);
      }
    });
    if (startDate > today) {
      newErrors.startDate = t("date_in_future_error");
    }
    if (endDate > today) {
      newErrors.endDate = t("date_in_future_error");
    }
    if (endDate < startDate) {
      newErrors.endDate = t("endDate_before_startDate_error");
    }
    if (validationEndDate < today) {
      newErrors.validationEndDate = t("certificate_expired");
    }
    if (validationEndDate < validationStartDate) {
      newErrors.validationEndDate = t("validationEndDate_before_validationStartDate_error");
    }
    if (validationStartDate < endDate) {
      newErrors.validationStartDate = t("validation_start_date_before_end_course");
    }

    if (formValues.duration && (Number(formValues.duration) <= 0)) {
      newErrors.duration = t("duration_negative_value_error");
    }
    if (formValues.duration && !Number(formValues.duration)) {
      newErrors.duration = t("duration_type_value_error");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useCapabilitiesOptionsQuery({
    onError: (error) => {
      console.error("Error fetching data Capabilities:", error);
    },
    onSuccess: (data: { output: [] }) => {
      const newCapabilities = data.output.map((item: DataResponse) => ({
        label: item.name,
        value: item.id
      }));

      setCapabilities(newCapabilities as MultiSelectOptionsProps[]);
    }
  });

  useCourseTypesOptionsQuery({
    onError: (error) => {
      console.error("Error fetching data Course Types:", error);
    },
    onSuccess: (data: unknown) => {
      const newCourseTypes = (data as DataResponse[]).map((item: DataResponse) => ({
        label: item.name || item.label,
        value: item.id ? String(item.id) : item.value ? String(item.value) : ""
      }));

      return setCourseTypes(newCourseTypes as Options[]);
    }
  });

  useKnowledgeAreasOptionsQuery({
    onError: (error) => {
      console.error("Error fetching data Areas:", error);
    },
    onSuccess: (data: { output: DataResponse[] }) => {
      const newKnowledgeArea = data.output.map((item: DataResponse) => ({
        label: item.name,
        value: item.id
      }));

      setKnowledgeAreas(newKnowledgeArea as MultiSelectOptionsProps[]);
    }
  });

  useKnowledgeDomainsOptionsQuery({
    onError: (error) => {
      console.error("Error fetching data Domains:", error);
    },
    onSuccess: (data: { output: DataResponse[] }) => {
      const areaId = selectedArea.isUpdating && selectedArea.value ? selectedArea.value.toString() : "";
      if (!areaId) return;
      const newKnowledgeDomain = data.output.map((item: DataResponse) => ({
        label: item.name,
        parent: selectedArea.value,
        value: item.id
      }));

      // newKnowledgeDomain.push(...knowledgeDomains as []);

      setKnowledgeDomains(newKnowledgeDomain as MultiSelectOptionsProps[]);
      setSelectedArea({ isUpdating: false });
      console.log("Data domain fetched successfully:", data);
    },
    knowledgeAreaId: selectedArea.isUpdating && selectedArea.value ? selectedArea.value.toString() : ""
  });

  const getOptions = (type: string) => {
    switch (type) {
    case "knowledgeArea":
      return knowledgeAreas || [];
    case "knowledgeDomain":
      return knowledgeDomains || [];
    case "capability": {
      return capabilities || [];
    }
    case "trainingType": {
      return courseTypes || [];
    }
    default:
      return [];
    }
  };

  const { mutate } = usePostCertificate({
    onSuccess: (data: unknown) => {
      console.log("Successo:", data);
      setSubmitSucceeded(true);
      setIsOutputModalOpen(true);
    },
    onError: (error) => {
      console.error("Errore:", error);
      setSubmitSucceeded(false);
      setIsOutputModalOpen(true);
    }
  });

  const handleConfirm = async () => {
    if (!validateForm()) {
      setErrors((prevErrors) => ({ ...prevErrors }));
      return;
    }
    onSubmit(formValues as unknown as SkillBook, mutate as unknown as (data: FormData) => void);
  };

  const onCancel = () => {
    setFormValues({});
    closeModal();
  };

  const handleCloseConfirmModal = () => {
    setIsOutputModalOpen(false);
    closeModal();
  };

  return (
    <Box
      sx={ {
        flexDirection: "column",
        justifyContent: "center",
        maxHeight: "495px",
        paddingX: "20px",
        paddingY: "10px",
        overflowY: "auto"
      } }
    >
      <Grid container spacing={ 3 }>
        { formData && formData.map((data: Field, index) => {
          switch (data.type) {
          case "text":
            return (
              <Grid key={ index } item xs={ 12 } sm={ 6 }>
                <InputCst
                  name={ data.name }
                  type={ data.type }
                  label={ t(`${data.name}_field`) }
                  required={ data.required }
                  disabled={ false }
                  maxValue={ data.maxValue }
                  onChange={ handleInputChange }
                  error={ !!errors[data.name] }
                  errorMessage={ errors[data.name] }
                />
              </Grid>
            );
          case "select":
            return (
              <Grid key={ index } item xs={ 12 } sm={ 6 }>
                <SelectCst
                  options={ getOptions(data.name) as Options[] }
                  value={ formValues[data.name] ? (formValues[data.name] as { value: string }).value as string : "" }
                  onChange={ (e: ChangeEvent<{ value: string | number }>) => {
                    const target = e.target;
                    const value = target.value;
                    const labelElement = document.querySelector(`li[data-value="${value}"]`) as HTMLElement;
                    const label = labelElement?.getAttribute("data-label") || "";

                    setFormValues((prevState) => ({
                      ...prevState,
                      [data.name]: {
                        label: label,
                        value: e.target.value
                      }
                    }));
                  } }
                  name={ data.name }
                  label={ t(`${data.name}_field`) }
                />
              </Grid>
            );
          case "multi-select":
            return (
              <Grid item xs={ 12 } sm={ 6 }>
                <MultiSelectCst
                  options={ getOptions(data.name) || [] }
                  selectedOptions={ formValues[data.name] as MultiSelectOptionsProps[] ?? [] }
                  handleChange={ (e: MultiSelectOptionsProps[]) => handleChangeMultiSelect(e, data.name) }
                  label={ t(`${data.name}_field`) }
                  disabled={ (data.name === "knowledgeDomain") ? getOptions(data.name).length === 0 : false }
                />
              </Grid>
            );
          case "date":
            return (
              <Grid key={ index } item xs={ 12 } sm={ 6 }>
                <DataPickerCst
                  id={ data.name }
                  label={ t(`${data.name}_field`) }
                  name={ data.name }
                  value={ null }
                  onChange={ handleDateChange(data.name) }
                  required={ data.required }
                  error={ !!errors[data.name] }
                  errorMessage={ errors[data.name] }
                />
              </Grid>
            );
          case "groups":
            return (
              <Grid key={ data.name } item xs={ 12 }>
                <InputGroup
                  label={ t(`${ data.name }_field`) }
                  fields={ [
                    {
                      label: "Knowledge Area",
                      accessorKey: "knowledge_area",
                      type: "select",
                      labelHeader: {
                        en: "Knowledge Area",
                        it: "Area di Conoscenza"
                      },
                      options: getOptions("knowledgeArea"),
                      required: false
                    },
                    {
                      label: "Knowledge Domain",
                      accessorKey: "knowledge_domain",
                      type: "select",
                      labelHeader: {
                        en: "Knowledge Domain",
                        it: "Dominio di Conoscenza"
                      },
                      options: getOptions("knowledgeDomain"),
                      dependentOn: "knowledge_area",
                      required: false
                    }
                  ] }
                  onChange={ (newValue) => setFormValues((prevState) => ({
                    ...prevState,
                    [data.name]: newValue
                  })) }
                  value={ formValues[data.name] || [] }
                  onFieldChange={ (e) =>
                    setSelectedArea({ isUpdating: true, value: e.toString() })
                  }
                />
              </Grid>
            );
          case "file":
            return (
              <Grid key={ data.name } item xs={ 12 } sm={ 6 }>
                <VisuallyHiddenInput
                  name={ data.name }
                  label={ formValues[data.name] ? `${ t("file_uploaded") } ${ formValues["file_name"] }` : t(`${data.name}_field`) }
                  type={ data.type }
                  accept=".pdf, .jpg, .jpeg, .png"
                  onChange={ (e) => {
                    handleFileChange(
                      e,
                      data.name,
                      52428800,
                      1024,
                      errors,
                      setErrors,
                      setFormValues
                    );
                  }
                  }
                  error={ !!errors["file"] }
                  errorMessage={ t(`${ errors["file"] }`) }
                />
              </Grid>
            );
          default:
            return <></>;
          }
        } ) }
      </Grid>
      <ButtonGroups onClickCancel={ onCancel } onClickConfirm={ handleConfirm } />
      { /*TODO: REFACTORING*/ }
      <ModalBasic
        open={ isOutputModalOpen }
        title={ "" }
        content={ <OutputMessage success={ submitSucceeded ?? false } closeModal={ closeModal }/> }
        size={ {
          width: {
            xs: "100vw",
            sm: "40vw"
          }
        } }
        handleClose={ handleCloseConfirmModal }
      />
    </Box>
  );
};

export default AddSkillBookTemplate;
