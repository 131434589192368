import {
  CardModule as CardModuleComponent
} from "./CardModule.component";
import * as CardModuleVariants from "./CardModule.variants";
import type { Module } from "../../utils/explore";
// import { CardModuleProps } from "./CardMoudle.types";

export const DefaultCardModule = ({ ...props }: Module)=>(
  <CardModuleComponent { ...{ ...CardModuleVariants.BlendedCardModule, ...props } }/>
);

// export type { CardModuleProps } from "./CardMoudle.types";
export const CardModule = CardModuleComponent;
