
import { Grid } from "@mui/material";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { ButtonText, GridMain } from "../../components";
import { BodyInputBase } from "../../components/CreateContentModal/CreateContentModal.styles";
import { OnBoardingStack } from "../../components/OnBoarding/OnBoarding.styles";
import { PERMISSIONS } from "../../consts";
import { useAuthStore } from "../../stores";



export function Test() {
  const userData = useAuthStore(state => state.userData);
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  const isAdmin = userData?.permissions?.some(permission => (
    permission === PERMISSIONS["admin.dashboard.access"]
  ));
  if(isAdmin){
    setSpinnerLoginVisible(false);
  }
  const localStorageColor = localStorage.getItem("storageColors");
  const [themeColors, setThemeColors]=useState(localStorageColor ? JSON.stringify(
    JSON.parse(localStorageColor),undefined,4) : ""
  );

  return (
    <>
      {
        isAdmin  ?

          <GridMain>
            <OnBoardingStack
              sx={ {
                "---color":"systemPrimary",
                gap:4,
                margin:"auto",
                padding:"32px"
              } }
            >
              <BodyInputBase
                multiline
                value={ themeColors }
                onChange={ (changeEvent) => setThemeColors(changeEvent.target.value) }
                placeholder={ "inserisci json color" }  
              />
              <Grid 
                display={ "flex" }
                gap={ 4 }
              >
                <ButtonText
                  variant="outlined"
                  onClick={ ()=> {
                    setThemeColors("");
                    localStorage.removeItem("storageColors");
                    window.location.reload();
                  } }
                >rimuovi</ButtonText>

                <ButtonText
                  onClick={ ()=> {
                    localStorage.setItem("storageColors",JSON.stringify(JSON.parse(themeColors)));
                    window.location.reload();
                  } }
                >invio
                </ButtonText>

              </Grid>
            
            </OnBoardingStack>
      
 
          </GridMain> : 

          <Navigate to={ "/" }/>
      }
    </>   
   
  );
}
