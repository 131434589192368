import { Box, Stack, Typography } from "@mui/material";
import { Img } from "@storybook/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OutputRegisterProps } from "./types";
import ErrorIcon from "../../assets/error_circle.svg";
import CheckIcon from "../../assets/success_circle.svg";
import { ButtonText } from "../Button";

const OutputRegister: React.FC<OutputRegisterProps> = ({ output, status, onClick, initNewForm }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={ {
          display: "flex",
          justifyContent: "center",
          my: "30px"
        } }
      >
        <Img
          src={ (!!status && (status !== 200)) ? ErrorIcon : CheckIcon }
          alt="Icon"
          width={ 80 }
          height={ 80 }
        />
      </Box>
      <Typography
        variant="h4"
        sx={ {
          textAlign: "center",
          mb: "10px"
        } }
      >
        { t(output?.label) || t("form_completed_title") }
      </Typography>
      <Typography
        variant="h6"
        sx={ {
          textAlign: "center",
          mb: "10px"
        } }
      >
        { t(output?.description, { detail: output?.detail || "" }) || t("form_completed_description") }
      </Typography>
      { status && (
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          mt={ 3 }
          sx={ { width: "65%", mx: "auto", marginTop: "30px" } }
        >
          <ButtonText
            variant="outlined"
            onClick={
              (status !== 200) ?
                onClick :
                ( !output?.newForm ? () => navigate("/access/login") : initNewForm )
            }
          >
            { (status !== 200) ?
              t("back") :
              ( !output?.newForm ? t("back_to_login") : output.newForm.label )
            }
          </ButtonText>
        </Stack>
      ) }
    </>
  );
};

export default OutputRegister;
