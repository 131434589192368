/* eslint-disable max-len */
import { Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  DescriptionTypography,
  TermsCondidionsStack, SubTitleTypography, TextContent
} from "./T&C.styles";
import { BoxCorners, GridMain } from "../../components";
import { useAuthStore } from "../../stores";

export function TermsAndCondition() {
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
 
  return (
    <>
      <GridMain
        sx={ {
          margin: theme.spacing(10, 0),
          position:"relative",
          [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(3.5, 0),

            ".box-el": {
              transform: "scale(0.4)"
            }
          }
        } }

      >
        <BoxCorners $filled={ true } $cornersPosition={ "topRight" } />
        <BoxCorners $filled={ true } $cornersPosition={ "bottomLeft" } />
        <TermsCondidionsStack >
          <Typography
            margin={ "0 auto" }
            sx={ {
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "30px",
              marginBottom: "80px",
              textAlign: "center",
              top:"120px",
              [theme.breakpoints.down("sm")]: {
                fontSize:"16px",
                lineHeight: "21px",
                maxWidth: "334px",
                textWrap: "balance"
              }
            } }
          >
            { "Termini e condizioni per l’utilizzo della Piattaforma" } 
          </Typography>
          <TextContent>
            <DescriptionTypography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  "& span": {
                    fontSize:"14px",
                    lineHeight: "21px"
                  },
                  fontSize:"14px"
                }
              } }
            >
              <span>
                I presenti termini e condizioni (di seguito, <b>“T&C”</b>) disciplinano l’utilizzo della piattaforma software per l’erogazione di formazione sincrona e asincrona (di seguito, la <b>“Piattaforma”</b>) di proprietà esclusiva di Digit’Ed S.p.A. a socio unico, con sede legale in via San Vigilio n. 1, Milano, iscritta al registro delle Imprese di Milano Monza Brianza Lodi, numero di iscrizione e codice fiscale 00902000769, REA n. MI-1948007, P.I. 07490560633, capitale sociale pari a Euro 774.600,00 i.v., soggetta a direzione e coordinamento di Digit’Ed Holding S.p.A. (di seguito, il <b>“Fornitore”</b>).  Il Fornitore può essere contattato al seguente indirizzo e-mail: [●].
              </span>
              <br></br>
              { "Cliccando sui due pulsanti di accettazione posti in calce alle presenti T&C, si dà atto di aver letto attentamente e di accettare integralmente il testo dei presenti T&C." }
            </DescriptionTypography>
            <div>
              <ol>
                <li className="subtitle"> 
                  <SubTitleTypography>
                    { "Ambito di applicazione ed accesso alla Piattaforma " }
                  </SubTitleTypography>
                  <p>
                    L’utilizzo della Piattaforma è regolato in modo più ampio da termini contrattuali che sono stati sottoscritti con il Fornitore (di seguito, il <b>“Contratto”</b>) e di cui ogni utente  a cui è consentito l’utilizzo della Piattaforma (di seguito, gli <b>“Utenti Autorizzati”</b>) deve essere stato reso edotto. L’utilizzo della Piattaforma è consentito, fatto salvo quanto previsto dal successivo Articolo 6 dei presenti T&C, per la durata indicata nel Contratto. 
                    L’accesso alla piattaforma è riservato ai soli Utenti Autorizzati che abbiano accettato i presenti T&C.
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Processo di autenticazione " }
                  </SubTitleTypography>
                  <p>
                    L’Utente Autorizzato riconosce e accetta che i soggetti che possono autenticarsi alla Piattaforma, secondo i limiti, anche numerici, previsti dal Contratto, sono esclusivamente i seguenti:
                    <ol className="auth">
                      <li> i dipendenti e/o collaboratori della persona giuridica o fisica (che non sia in alcun modo soggetta all’applicazione del D.lgs. 206/2005 e successive modificazioni o integrazioni) indicata nel Contratto (di seguito, per semplicità, <b>“Società”</b>), a condizione che il Contratto medesimo sia ancora efficace alla data di registrazione dell’Utente Autorizzato;</li>
                      <li>altri soggetti che hanno ricevuto dal Fornitore un’autorizzazione specifica in forma scritta, eventualmente limitata nel tempo (di seguito, l’<b>“Autorizzazione”</b>).</li>
                    </ol>
                  </p>
                  <p>
                    Per quanto riguarda le modalità di autenticazione per accedere alla Piattaforma, è possibile che (i) sia integrato il sistema di SSO utilizzato dalla Società, con la conseguenza che potranno accedere tutti gli Utenti Autorizzati identificati dalla Società nei limiti e secondo le condizioni del Contratto; oppure che, in alternativa (ii) sia prescelto dalla Società il sistema di accesso di default della Piattaforma, con credenziali applicative e multi-factor authentication; in questo caso sarà la Società a fornire al Fornitore la lista degli Utenti Autorizzati da abilitare. Al primo accesso alla Piattaforma sarà in ogni caso richiesto il cambio password. 
                  </p>
                  <p>
                    In sede di primo accesso alla Piattaforma sarà altresì richiesta a ciascun Utente Autorizzato l’accettazione dei T&C e della relativa documentazione privacy. 
                  </p>
                  <p>
                    Resta inteso che l’account di ciascun Utente Autorizzato, insieme ai relativi strumenti di autenticazione, è strettamente personale e che, in quanto tale, non deve essere comunicato a terzi. 
                    L’Utente Autorizzato, difatti, è responsabile (i) della riservatezza delle proprie chiavi di accesso alla Piattaforma; e (ii) di qualsivoglia attività effettuata tramite il proprio account e degli eventuali danni, diretti e indiretti, che dovessero essere subiti dal Fornitore, suoi danti causa e/o terzi a causa di utilizzi e/o attività non autorizzate. A tal fine, l’Utente Autorizzato si impegna ad avvisare immediatamente la Società di qualsiasi utilizzo non autorizzato del proprio account di cui dovesse venire a conoscenza. 
                    L’Utente Autorizzato è altresì responsabile della correttezza e veridicità dei dati forniti ed è quindi tenuto a comunicare prontamente alla Società qualsiasi variazione di tali dati. 
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Utilizzo della Piattaforma e della Community " }
                  </SubTitleTypography>
                  <p>
                    Ciascun Utente Autorizzato  sarà inquadrato all’interno di una specifica categoria di appartenenza e potrà visualizzare esclusivamente le sezioni della Piattaforma al medesimo accessibili in base a tale categoria, come descritta nella documentazione tecnica allegata al Contratto. L’Utente Autorizzato inquadrato all’interno della categoria “Amministratore” ha diritti sulla gestione degli Utenti Autorizzati che hanno accesso alla Piattaforma nonché sul caricamento dei contenuti e dei documenti all’interno della Piattaforma medesima (ivi inclusi i Contenuti Generati, come di seguito definiti). 
                    L’Utente Autorizzato riconosce ed accetta che l’utilizzo della Piattaforma potrà essere monitorato e che la Piattaforma medesima contiene degli strumenti in grado di monitorare il numero e la tipologia di utenti che la utilizzano.
                    La Piattaforma può rendere inoltre disponibile (i) una sezione, denominata “Community”, in cui gli Utenti Autorizzati avranno a disposizione diverse funzionalità che consentiranno ai medesimi di interagire tra loro, pubblicare commenti, recensioni ed elementi testuali, grafici e di altra natura, ivi inclusi dati personali; e (ii) la possibilità di caricare contenuti audio, video e multimediali in linea con la natura e le caratteristiche della Piattaforma (di seguito, collettivamente, i <b>“Contenuti Generati”</b>). 
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Obblighi dell’Utente Autorizzato " }
                  </SubTitleTypography>
                  <p>
                    Nell’utilizzo della Piattaforma (ivi inclusa la Community, ove disponibile), l’Utente Autorizzato si impegna a: 
                  </p> 
                  <ol className="auth">
                    <li>
                      rispettare i termini del Contratto, i presenti T&C (nella versione tempo per tempo vigente), gli eventuali termini e condizioni o altra documentazione relativa a prodotti di terzi utilizzati dalla Piattaforma nonché ogni eventuale ulteriore documento ed istruzione che siano forniti dal Fornitore; 
                    </li>
                    <li>
                      non alterare in qualsivoglia modo le indicazioni obbligatorie per legge presenti nella versione della Piattaforma resa disponibile dal Fornitore e le indicazioni relative alla protezione dei diritti di proprietà intellettuale e/o industriale del Fornitore e relativi danti causa; 
                    </li>
                    <li>
                      non utilizzare la Piattaforma al fine di depositare, conservare, inviare, pubblicare, trasmettere e/o condividere Contenuti Generati o qualsiasi dato, applicazione o documento informatico che (i) siano in contrasto o violino diritti di terzi, ivi inclusi diritti di proprietà intellettuale ed industriale; (ii) abbiano contenuti discriminatori, diffamatori, calunniosi o minacciosi; (iii) contengano materiale pornografico, pedopornografico, osceno o comunque in contrasto con l’ordine pubblico e/o il buon costume; (iv) contengano virus worm, malware o comunque elementi informatici pregiudizievoli; (v) costituiscano attività di spamming, phishing o analoghe; (vi) siano in ogni caso in contrasto con le disposizioni normative e/o regolamentari applicabili; 
                    </li>
                    <li>      
                      non violare o tentare di violare la sicurezza della Piattaforma (ivi inclusa la Community, ove disponibile), in qualunque modo, e.g. per il tramite di attività volte a testare la vulnerabilità Piattaforma  e/o di attività volte a violare le misure di sicurezza o di autenticazione della Piattaforma stessa; 
                    </li>
                    <li>
                      modificare periodicamente la propria password anche secondo le eventuali istruzioni fornite al riguardo dal Fornitore. 
                    </li>
                  </ol>
                </li>
                <br></br>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Dichiarazioni e garanzie dell’Utente Autorizzato; Rimozione dei Contenuti Generati" }
                  </SubTitleTypography>
                  <p>
                    L’Utente Autorizzato dichiara e garantisce di essere titolare e/o di essere legittimato all’utilizzo dei Contenuti Generati ed in generale dei dati immessi nella Piattaforma, assicurando altresì che gli stessi (i) non rientrano tra i contenuti vietati di cui al precedente Articolo 4, e che (ii) sono completi, veritieri e riferiti alla propria utenza. Pertanto, l’Utente Autorizzato, riconoscendo la propria esclusiva responsabilità al riguardo, si obbliga a non immettere nella Piattaforma alcun dato e/o contenuto in violazione di quanto previsto agli Articoli 4 e 5. 
                    Fermo quanto precede, l’Utente Autorizzato riconosce ed accetta, per quanto di propria competenza, che il Fornitore (i) potrà utilizzare i Contenuti Generati nei limiti e per gli scopi previsti dalle Condizioni Generali; e (ii) non potrà essere ritenuto in alcun modo responsabile per ogni conseguenza pregiudizievole derivante dai Contenuti Generati, che sarà integralmente a carico dell’Utente Autorizzato che ha proceduto al caricamento degli stessi nella Piattaforma. 
                    Resta inteso che il Fornitore si riserva il diritto, senza tuttavia alcun obbligo in tal senso, di rimuovere, in tutto o in parte, i Contenuti Generati che risultino in violazione del presente Articolo ed in generale dei T&C, della documentazione dagli stessi richiamata e/o della normativa applicabile nonché il diritto di adottare qualsiasi misura e/o iniziativa ritenga necessaria per la tutela dei propri diritti in conformità alla normativa applicabile. 
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Sospensione e disabilitazione dell’utenza  " }
                  </SubTitleTypography>
                  <p>
                    L’utenza di ciascun Utente Autorizzato potrà essere sospesa, parzialmente o totalmente, in via provvisoria o definitiva, dal Fornitore nei seguenti casi, senza pregiudizio per ogni altro rimedio previsto dalla legge, dal Contratto, da eventuali documenti di terzi resi disponibili all’Utente Autorizzato tramite la Piattaforma e/o dai presenti T&C:
                  </p>
                  <ol className="auth">
                    <li>
                      nei casi previsti dal Contratto per violazioni da parte della Società;
                    </li>
                    <li>
                      cessazione, per qualsiasi ragione, del Contratto (in questo caso tutte le utenze relative alla Società saranno disabilitate) o dell’Autorizzazione, fermo restando che l’Autorizzazione potrà cessare anche per decisione discrezionale del Fornitore in qualsiasi momento;
                    </li>
                    <li>
                      violazione, anche parziale, dei presenti T&C e/o dei documenti dagli stessi richiamati da parte dell’Utente Autorizzato o comunque riconducibile, direttamente o indirettamente, all’Utente Autorizzato medesimo; nel caso di violazioni imputabili alla Società, il Fornitore potrà, a propria discrezione, disabilitare tutti gli Utenti Autorizzati relativi alla Società medesima; 
                    </li>
                    <li>
                      cessazione del rapporto di lavoro o collaborazione tra l’Utente Autorizzato e la Società;  
                    </li>
                    <li>
                      richiesta in tal senso da parte dell’autorità giudiziaria o amministrativa; 
                    </li>
                    <li>
                      in caso di richiesta dell’Utente Autorizzato. 
                    </li>
                  </ol>
                  <p>
                    La sospensione dell’utenza avrà effetto a seguito dell’invio di un avviso da parte del Fornitore, fatti salvi i casi di necessità o urgenza che, a discrezione del Fornitore, rendano necessario procedere alla sospensione senza una previa informativa. In conseguenza di ciò, l’Utente Autorizzato non potrà utilizzare la Piattaforma (ivi inclusa la Community, ove disponibile) ed il relativo contenuto (i) definitivamente nel caso di sospensione totale (ossia disabilitazione) dell’utenza; o (ii) per il tempo indicato nell’e-mail di avviso in caso di sospensione parziale dell’utenza.  
                    Fermo restando quanto precede, l’utenza potrà essere sospesa anche per interventi di aggiornamento e/o per implementazioni tecnologiche della Piattaforma (ivi incluse, a titolo esemplificativo, eventuali problematiche che non siano rimediabili senza aver previamente sospeso gli accessi) o, in presenza di motivati problemi di sicurezza. 
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Riservatezza " }
                  </SubTitleTypography>
                  <p>
                    Tutti i contenuti presenti e resi disponibili nella Piattaforma (ivi inclusa la Community, ove disponibile) da parte del Fornitore costituiscono informazioni riservate. Fatti salvi gli usi strettamente funzionali allo svolgimento del proprio ruolo da parte dell’Utente Autorizzato, quest’ultimo si impegna a mantenerne la riservatezza, adottando a tal fine le misure più idonee ed opportune, e a non comunicarle a qualsivoglia terzo.
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Proprietà Intellettuale" }
                  </SubTitleTypography>
                  <p>
                    Qualunque marchio, logo, slogan ed altro segno distintivo, registrato o meno, utilizzato e visualizzato sulla Piattaforma deve ritenersi di titolarità esclusiva del Fornitore e/o relativi danti causa, ad eccezione dei Contenuti Generati. L’Utente Autorizzato riconosce ed accetta che (i) la Piattaforma (ivi inclusi, a titolo esemplificativo, i relativi codici oggetto e codici sorgente, algoritmi, eventuali sistemi di intelligenza artificiale, interfacce nonché contenuti eventualmente caricati dal Fornitore) sono protetti da diritti di proprietà intellettuale e/o industriale di titolarità esclusiva del Fornitore e/o relativi danti causa; (ii) la facoltà di accedere alla, ed utilizzare la, Piattaforma secondo quanto previsto dal Contratto e dai presenti T&C non comporta alcun trasferimento della titolarità di diritti di proprietà intellettuale e/o industriale; (iii) ogni utilizzo non autorizzato (ivi incluse eventuali attività di modifica e/o alterazione dei loghi e delle informazioni riportate all’interno della Piattaforma) deve intendersi espressamente vietato.
                    L’Utente Autorizzato, pertanto, si impegna, tra l’altro, a: 
                  </p>
                  <ol className="auth">
                    <li>
                      rispettare i diritti di proprietà intellettuale e/o industriale del Fornitore e relativi danti causa e non contestarne in alcun modo la titolarità e/o validità; 
                    </li>
                    <li>
                      non estrarre, reimpiegare, elaborare, pubblicare, riprodurre, copiare, tradurre, trasferire, disassemblare, decompilare e/o decodificare, neanche parzialmente, la Piattaforma né effettuare operazioni di reverse engineering sulla Piattaforma medesima;  
                    </li>
                    <li>
                      non comunicare al pubblico e comunque utilizzare in qualsivoglia modo la Piattaforma, al di fuori di quanto espressamente autorizzato ai sensi del Contratto e dei presenti T&C;   
                    </li>
                    <li>
                      non sviluppare, adattare, modificare, implementare e/o personalizzare in alcun modo la Piattaforma e non creare, a partire dalla Piattaforma, opere derivate di alcun genere;   
                    </li>
                    <li>
                      non creare o tentare di creare codici sorgente a partire dal codice oggetto della Piattaforma; 
                    </li>
                    <li>
                      non aggirare o tentare di aggirare le misure di sicurezza tecniche relative alla Piattaforma; 
                    </li>
                    <li>
                      non commercializzare, cedere, noleggiare, concedere in sub-licenza o altrimenti rendere disponibile, distribuire o trasferire in alcun modo a soggetti terzi, neanche parzialmente, la Piattaforma.
                    </li>
                  </ol>
                </li>
                <br></br>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Manleva da parte dell’Utente Autorizzato " }
                  </SubTitleTypography>
                  <p>
                    L’Utente Autorizzato si obbliga a tenere integralmente manlevati ed indenni il Fornitore e relativi danti causa (ivi inclusi, a titolo esemplificativo, i terzi i cui materiali, prodotti e/o servizi siano forniti attraverso la Piattaforma) da qualsiasi contestazione, azione e/o richiesta risarcitoria da parte di qualsiasi terzo per tutti i danni, di qualsivoglia natura (ivi incluse le spese legali), subiti dagli stessi in conseguenza (i) della violazione, da parte dell’Utente Autorizzato, dei presenti T&C e della documentazione dagli stessi richiamata; (ii) dell’uso da parte di terzi degli strumenti di autenticazione alla Piattaforma, indipendentemente dal fatto che tale azione sia stata o meno autorizzata dall’Utente Autorizzato; (iii) della violazione di diritti di proprietà intellettuale e/o industriale relativi alla Piattaforma o comunque di titolarità esclusiva del Fornitore e relativi danti causa; (iv) della violazione di normative e regolamentazioni applicabili e/o di diritti di terze parti, ivi inclusi, a titolo esemplificativo, diritti di proprietà intellettuale e/o industriale.
                  </p>
                </li>
                <li className="subtitle"> 
                  <SubTitleTypography>
                    { "Esclusione di garanzie e responsabilità del Fornitore " }
                  </SubTitleTypography>
                  <p>
                    L’Utente Autorizzato riconosce ed accetta che, nella misura massima consentita dalla legge applicabile e fatti salvi in ogni caso il dolo e la colpa grave, il Fornitore:
                  </p>
                  <ol className="auth">
                    <li>
                      fornisce l’accesso alla Piattaforma (ivi inclusa la Community, ove disponibile) “as is”, senza garanzie di alcun tipo, espresse o implicite (tra cui, a titolo esemplificativo, garanzie in relazione all’operatività senza interruzioni; all’assenza di malfunzionamenti; all’adeguatezza, precisione, completezza delle informazioni ivi disponibili); 
                    </li>
                    <li>
                      non avrà alcuna responsabilità per qualsivoglia danno, diretto e/o indiretto, costo e spesa sofferti dall’Utente Autorizzato e derivanti: 
                      <ol className="roman">
                        <li>
                          da eventuali errori, omissioni e/o inesattezze presenti nella Piattaforma (ivi inclusa la Community, ove disponibile); 
                        </li>
                        <li>
                          dal mancato uso e/o dall’uso non conforme della Piattaforma (ivi inclusa la Community, ove disponibile) nonché dall’uso della Piattaforma in combinazione o associazione con software, prodotti e/o servizi non forniti dal Fornitore; 
                        </li>
                        <li>
                          dalla sospensione o disabilitazione dell’utenza secondo quanto previsto dal Contratto ed all’Articolo 6 dei presenti T&C;
                        </li>
                        <li>
                          dalla mancata adozione di opportune cautele da parte dell’Utente Autorizzato nell’utilizzo della Piattaforma, e.g., nella custodia degli strumenti di autenticazione per l’accesso alla Piattaforma; 
                        </li>
                        <li>
                          da malfunzionamenti e/o inadempimenti causati da terzi, dal sistema informatico e/o connettività dell’infrastruttura della Società e/o del dispositivo utilizzato dall’Utente Autorizzato;     
                        </li>
                        <li>
                          da guasti, sovraccarichi, interruzioni delle linee telefoniche, elettriche o riferite alla rete Internet ed in generale da impedimenti oggettivi che esulino dalla sfera del proprio diretto ed immediato controllo o da cause di forza maggiore (ivi inclusi, a titolo esemplificativo, eventi bellici o minacce di guerra, epidemie, pandemie e relative evoluzioni, embargo su importazioni o esportazioni, incidenti, incendi); 
                        </li>
                        <li>
                          da ogni altro atto e/o omissione che non sia imputabile esclusivamente al Fornitore.   
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br></br>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Modifiche unilaterali " }
                  </SubTitleTypography>
                  <p>
                    Il Fornitore si riserva la facoltà di apportare in qualunque momento modifiche alla Piattaforma e/o ai presenti T&C, a propria discrezione, in qualsiasi tempo e senza preavviso, a titolo esemplificativo al fine di tenere conto delle modifiche occorse nella tecnologia e negli standard di settore. Le modifiche ai presenti T&C entreranno in vigore al momento della relativa pubblicazione all’interno della Piattaforma. È obbligo di ciascun Utente Autorizzato tenersi aggiornato sui T&C applicabili, verificandoli periodicamente tramite accesso alla Piattaforma.
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Dati personali " }
                  </SubTitleTypography>
                  <p>
                    I dati personali dell’Utente Autorizzato saranno raccolti e trattati dal Fornitore conformemente a quanto indicato nella relativa documentazione privacy, disponibile al seguente <Link color= { theme.customColors.textPrimaryCta }
                      href="/privacy"
                      target="_blank"
                      padding={ "0px" }>
                      link
                    </Link> [●].
                  </p>
                </li>
                <li className="subtitle">
                  <SubTitleTypography>
                    { "Legge applicabile e Foro esclusivo " }
                  </SubTitleTypography>
                  <p>
                    I presenti T&C sono stati redatti in conformità a e sono regolati dalla legge italiana, pertanto, è espressamente escluso il ricorso alle norme di diritto internazionale privato . Per ogni controversia, azione o procedimento relativi ai presenti T&C o all’utilizzo da parte dell’Utente Autorizzato della Piattaforma (ivi inclusa la Community ove disponibile) sarà competente in via esclusiva il foro di Milano
                  </p>
                </li>
              </ol>
            </div>           
            <div>
              <p>
                L’Utente Autorizzato dichiara di aver letto e compreso e di accettare i presenti T&C, che saranno salvati e conservati per il proprio archivio personale. 
              </p>
              <label>
                <input type="checkbox" name="myCheckbox" /> ACCETTO 
              </label> 
            </div>
            <div>
              <p>
                Ai sensi e per gli effetti degli artt. 1341 e 1342 c.c., l’Utente Autorizzato dichiara di aver preso visione e di approvare espressamente e specificatamente quanto contenuto nei seguenti Articoli [•] [Nota: clausole vessatorie da inserire a carico digit’ed]. 
              </p>
              <label>
                <input type="checkbox" name="myCheckbox" /> ACCETTO 
              </label> 
            </div>  
          </TextContent>
        </TermsCondidionsStack>
      </GridMain>
    </>
  );
}
