/* eslint-disable */
import { 
  Avatar,
  Box,
  styled } from "@mui/material";
// import chatBoxbg from "../../assets/Subtract.png";

export const BoxCmt = styled(Box)(({theme}) => ({
  width: "500px",
  height: "508px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "375px",
    height: "405px"
  },
  "svg": {
    position: "absolute",
    width: "100%",
    height: "100%"
  }
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border:`1px solid ${theme.customColors.systemSecondary01}`,  
  borderRadius:"60px",
  boxShadow:`0px 0px 4px 0px ${theme.customColors.systemSecondary01}`,
  position:"absolute",
  height:"64px",
  width:"64px",
  left:"37px",
  top: "0",
  translate: "0 -28%",
  [theme.breakpoints.down("md")]: {
    left: "34px"
  }
}));

export const BoxContent = styled(Box)(({theme}) => ({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  padding: "1.25rem 2rem 2rem",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "2rem 1.5rem"
  }
}));
