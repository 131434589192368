
import {
  NotFoundStack
} from "./NotFound.styles";
import backgroundImage from "../../assets/access.png";
import {
  GridMain,
  NotFoundComponent
} from "../../components";
import { useAuthStore } from "../../stores";

export function NotFoundPage() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);

  return (
  
    <NotFoundStack bg={ backgroundImage }>
      <GridMain>
        <NotFoundComponent/>
      </GridMain>
    </NotFoundStack>
   

  );
}
