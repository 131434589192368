import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { 
  useEffect,
  useState
} from "react";

import { useDrag, useDrop , DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import { useTranslation } from "react-i18next";
import {
  AnimatedButton,
  StyledBucketGridItem, 
  StyledCapabilityCard,
  StyledCapabilityCardsHide,
  StyledKnowledgeWrapper,
  StyledSwipeableDrawer
} from "./DndCapabilities.styles";
import type {
  AssignedCapability,
  BucketGridItemProps,
  DndCategoryStackProps
} from "./DndCapabilities.types";
import { CapabilitiesDecoratedBox } from "../../components/Chip/Chip.component";
import { Icon } from "../../components/Icon";

import { CAPABILITY_TYPE } from "../../consts";
import { setLevelCapabilityBucket } from "../../utils/dndStack";
import {
  Chip,
  ChipProps
} from "../Chip";



export function DndCapabilityStack({
  buckets,
  hasNextPage,
  fetchNextPage,
  totalElements,
  state,
  ...props
}: DndCategoryStackProps) {

  const [capabilities, setCapabilities] = state;
  const [activeCapability, setActiveCapability] = useState<string>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isTouchDevice = matchMedia("(hover: none)").matches;



  return (
    <Stack
      gap={ 2 }
      display={ "flex" }
      flexDirection={ "row" }
      justifyContent={ "center" }
      marginTop={ isMobile ? 2 : 0 }
      { ...props }
    >
      <DndProvider backend={ isTouchDevice ? TouchBackend : HTML5Backend }>
        <StyledKnowledgeWrapper>
    
        
          {
            capabilities && capabilities?.filter(({ bucket }) => ( bucket === null )) ? 
              <BucketStartGridItem 
                setActiveCategory={ setActiveCapability }
                contents={ capabilities &&  capabilities?.filter(({ bucket }) => ( bucket === null )) }
                fetchNextPage={ fetchNextPage }
                hasNextPage={ hasNextPage }
                onDrop={ ()=> {
                  setBucket(null, activeCapability);
                } }
                onDropRight={ (item)=> {
                  setBucket(1,item);
                } }
                onDropLeft={ (item)=> {
                  setBucket(0,item);
                } }
                totalElements={ totalElements }
              /> : null
          }
            
          {
            buckets
              .map((bucket, idx) => (
                <BucketGridItem
                  item
                  key={ `bucket--${bucket.id}` }
                  disabled={ false }
                  contents={
                    capabilities
                      ?.filter(({ bucket }) => bucket === idx) ?? null
                  }
                  onDrop={ () => {
                    setBucket(idx, activeCapability);
                  } }
                  onDelete={ (item)=> {
                    setBucket(null, item);
                  } }
                  setActiveCategory={ setActiveCapability }
                  title= { bucket.label }
                  order={ idx === 1 ? "9999" : "0" }
                  purpose={ setLevelCapabilityBucket(parseInt(bucket.id)) }
                />
              ))
          }
        
        </StyledKnowledgeWrapper>
       
      </DndProvider>
    </Stack>
  );

  function setBucket(
    bucket: number | null,
    // category: AssignedCategory
    targetId?: string
  ) {
    setCapabilities((prev) => {
      if(prev){
        prev.find(({ id }) => (id === targetId))!.bucket = bucket;
        return [ ...prev ];
      }
      else {
        return null;
      }
     
    });
  }
}

function DraggableCategory({
  ...props
}: ChipProps) {
  const [, drag] = useDrag(() => ({
    collect: (monitor) => ({ isDragging: Boolean(monitor.isDragging()) }),
    type:"capabilities"
  }));

  return (
    <Chip
      ref={ drag }
      { ...props }
    />
  );
}

function BucketGridItem({
  contents,
  onDelete,
  onDrop,
  setActiveCategory,
  title,
  purpose,
  ...props
}: BucketGridItemProps) {
  const [{ isOver }, drop] = useDrop({
    accept: "capabilities",
    collect: (monitor) => ({
      isOver: Boolean(monitor.isOver())
    }),
    drop: onDrop
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t }= useTranslation();
  const [bucketOpened, setBucketOpened]=useState(false);


  return (
    <>
      <StyledBucketGridItem
        $active={ isOver }
        alignItems="center"
        ref={ drop }
        onClick={ ()=> {
          if(isMobile){
            setBucketOpened(true);
          }
        } }
        { ...props }
      >
        <Stack gap={ 1 } alignItems={ "center" }>
          {
            purpose ? 
              <Icon 
                color={ theme.customColors.accentVariantB }
                icon={ purpose === CAPABILITY_TYPE.TRAINING ? "allenamento" : "approfondimento" } 
                size={ 30 } /> : 
              null
          }
       
          <Typography
            fontSize={ isMobile ? theme.spacing(1.5) : theme.spacing(3) }
            fontWeight={ 700 }
            lineHeight={ "normal" }
            textAlign={ "center" }
            sx={ { 
              display: "-webkit-box",
              maxWidth:"100%",
              overflow: "hidden",
              overflowWrap:"break-word",
              padding:0,
              textAlign:"center",
              textOverflow: "ellipsis",
              wordBreak:"break-word",

              "-webkit-line-clamp": "2",
              "user-select": "none"
            } }
          >
            { title }
          </Typography>
        </Stack>
        <Grid 
          className="scrollable"
          alignItems={ "center" }
          display={ "flex" }
          flexDirection={ "column" }

          gap={ 4 }
          sx={ {
            height:"calc(100% - 97px)",
            overflowY:"auto",
            paddingTop: isMobile ? 0 :  "2rem" 
          } }
        >
          {
            isMobile ? 

              <Typography
                alignItems="center"
                fontSize={ isMobile ? theme.spacing(1.5) : theme.spacing(1.75) }
                lineHeight={ "normal" }
                sx={ { "user-select": "none" } }
              >
                { t("element", { count: contents?.length ?? 0 }) }
              </Typography> : null
          }
          {
            !isMobile ? 
              contents?.map((category) => (
                <DraggableCategory
                  key={ `category-pill--${category.id}` }
                  onMouseDown={ () => setActiveCategory(category.id) }
                  onMouseUp={ () => {
                    setActiveCategory("");} }
                  { ...category }
                  onClickIcon={ ()=>{ 
                    if(onDelete && category.id){
                      onDelete(category.id);
                    }
               
                  } }
                  active={ true }
                  label={ category.name }
                  disabled={ false }
                  sx={ {
                    fontWeight:700
                  } }
                />
              )) : null
          } 
        </Grid>
        
      </StyledBucketGridItem>
      <StyledSwipeableDrawer
        id={ `area-drawer-${purpose}` }
        anchor="bottom"
        onOpen={ () => {} }
        onClose={ () => setBucketOpened(false) }
        open={ bucketOpened }
        variant={ "temporary" }
      >
        <StyledBucketGridItem>
          <Stack gap={ 1 } 
            alignItems={ "flex-start" }
          >
            {
              purpose ? 
                <Icon 
                  color={ theme.customColors.accentVariantB }
                  icon={ purpose === CAPABILITY_TYPE.TRAINING ? "allenamento" : "approfondimento" } 
                  size={ 30 } /> : 
                null
            }
       
            <Typography
              fontSize={ theme.spacing(3) }
              fontWeight={ 700 }
              lineHeight={ "normal" }
              textAlign={ "center" }
              sx={ { 
                display: "-webkit-box",
                maxWidth:"100%",
                overflow: "hidden",
                overflowWrap:"break-word",
                padding:0,
                textAlign:"center",
                textOverflow: "ellipsis",
                wordBreak:"break-word",

                "-webkit-line-clamp": "2",
                "user-select": "none"
              } }
            >
              { title }
            </Typography>
          </Stack>

          <Grid 
            className="scrollable"
            alignItems={ "flex-start" }
            display={ "flex" }
            flexWrap={ "wrap" }
            flexDirection={ "row" }
            overflow={ "auto" }
            columnGap={ 3 }
            rowGap={ 4 }
            sx={ {
              height:"200px",
              paddingTop:"2rem"
            } }
          >
            {
         
              contents?.map((category) => (
                <DraggableCategory
                  key={ `category-pill--${category.id}` }
                  onMouseDown={ () => setActiveCategory(category.id) }
                  onMouseUp={ () => {
                    setActiveCategory("");} }
                  { ...category }
                  onClickIcon={ ()=>{ 
                    if(onDelete && category.id){
                      onDelete(category.id);
                    }
               
                  } }
                  
                  active={ true }
                  label={ category.name }
                  disabled={ false }
                  sx={ {
                    fontWeight:700,
                    height:"123px"
                  } }
                />
              )) 
            } 
          </Grid>
        </StyledBucketGridItem>
        
      </StyledSwipeableDrawer>
    </>
   
  );
}
function BucketStartGridItem({
  hasNextPage,
  fetchNextPage,
  contents,
  onDropLeft,
  onDropRight,
  onDrop,
  setActiveCategory
}: BucketGridItemProps) {
  const [, drop] = useDrop({
    accept: "category",
    collect: (monitor) => ({
      isOver: Boolean(monitor.isOver())
    }),
    drop: onDrop
  });

  const theme = useTheme();
  const { t }=useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [contentsOrdered,setContentsOrdered]= useState(contents);
  const firstContent = contentsOrdered?.[0];
  const [count, setCount]=useState(0);



  useEffect(()=> {
    setContentsOrdered(contents);
    if( contents && contents.length && count === contents.length){
      setCount(prev => prev - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contents]);



  useEffect(()=> {
    if(contentsOrdered?.length && contents?.length){
      const newContents = reorderContents(contents, count);
      setContentsOrdered(newContents);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ 
    count,
    contents
  ]);

  return (
    <StyledCapabilityCardsHide
      ref={ drop }            
    >
      <AnimatedButton
        sx={ {
          bottom:0,
          cursor:"pointer",
          height:"fit-content",
          left:0,
          margin:"auto auto auto 0",
          position:"absolute",
          top:0,
          zIndex:2
        } }
        onClick={ ()=> {
          if(onDropLeft && firstContent && firstContent.id){
            onDropLeft(firstContent.id);
           
          }
        } }
      >
        <Icon icon="Vector-3" size={ 30 }/>
      </AnimatedButton>
      <Box 
        alignItems={ "center" }
        display={ "flex" }
        flexDirection={ "column" }
        height={ "100%" }
        justifyContent={ "flex-end" }
        position={ "relative" }
        zIndex={ 1 }
      >
        {
          contentsOrdered && contentsOrdered.length > 0 ?  
            contentsOrdered?.map((category, index) => (
              <StyledCapabilityCard 
                key={ `capability-${category.id}` }
                zIndex={ contentsOrdered?.length -  index }
                sx={ {
                  transform: `scale(${
                    (index === 0 ? "1.2" : index === 1 ?  "1.1" : "1"
                    )}) translateY(-${(index === 0 ? "37" : index === 1 ?  "20" : "0")}px)`,
                  transition:"transform .3s linear"
                } }
              >
                <DraggableCategory
                  key={ `category-pill--${category.id}` }
                  label={ category.name }
                  onMouseDown={ () =>{ 
                    setActiveCategory(category.id);
          
                  } }
                  onMouseUp={ () => setActiveCategory("") }
                  sx={ {
                    textAlign: "center",
                    width: (isMobile ? "90%" : undefined)
                  } }
                  { ...category }
                  $cardVersion={ true }
                  isFirst={ index === 0 }
                />
                
              
              </StyledCapabilityCard >
            ))    
            : 
            <StyledCapabilityCard>
              <CapabilitiesDecoratedBox 
                active={ true } 
                cover=""
                description={ t("no_capabilities") }
                isDisabled={ false }
              />
            </StyledCapabilityCard>
           
        }

        <Box 
          alignItems={ "center" }
          display={ "flex" }
          flexDirection={ "column" }
          gap={ isMobile ? 1 : 2 }
          fontSize={ isMobile ? theme.spacing(1.5) : theme.spacing(1.75) }
        >
          {
            !isMobile ? 

              <AnimatedButton
                onClick={ () => {
                  if(contentsOrdered){
                    setCount((prev) => {
                      if(prev > 0){
                        return prev - 1;
                      }
                      else {
                        return prev;
                      }
               
                    }); 
                  }
             
                } }
                sx={ {
                  cursor:count > 0 ? "pointer" : "not-allowed",
                  opacity:count > 0 ? 1 : 0.3,
                  
                  ".icon":{
                    transform:"rotate(180deg)"
                  }
                } }
          
              >
                <Icon 
                  icon="dropdown" 
                  size={ 16 }
                />
              </AnimatedButton> : 

              null
          } 
        
         
          {  `${ contentsOrdered?.length ? count + 1 : 0 } /  ${contentsOrdered?.length ?? 0}`  }

          {
            !isMobile ? 
              <AnimatedButton 
                onClick={ () => {
                  if(contentsOrdered){
                    if(((count + 1) === (contentsOrdered.length - 2 )) && hasNextPage && fetchNextPage){
                      fetchNextPage();
                      setTimeout(()=> {
                        setCount((prev) => {
                          if( prev + 1   < contentsOrdered.length ){
                            return prev + 1;
                          }
                          else {
                            return prev;
                          }
                   
                        });
                      },100);
                    }
                    else {
                      setCount((prev) => {
                        if( prev + 1   < contentsOrdered.length ){
                          return prev + 1;
                        }
                        else {
                          return prev;
                        }
                 
                      });
                    }
                 
                  }
          
                } }
                sx={ {
                  cursor: contentsOrdered && count + 1 < contentsOrdered.length   ? "pointer" : "not-allowed",
                  opacity: contentsOrdered && count + 1 < contentsOrdered.length  ? 1 : 0.3

                } }
              >
        
                <Icon 
                  icon="dropdown" 
                  size={ 16 }
                />
              </AnimatedButton> : 
              null
          }
         
        </Box>
      
      </Box>
     
      <AnimatedButton
        sx={ {
          bottom:0,
          cursor:"pointer",
          height:"fit-content",
          margin:"auto auto auto 0",
          position:"absolute",
          right:0,
          top:0,
          zIndex:2
        } }
        onClick={ ()=> {
          if(onDropRight && firstContent && firstContent.id){
            onDropRight(firstContent.id);
          }
        } }
      ><Icon icon="Vector-4" size={ 30 }/>
      </AnimatedButton>
    </StyledCapabilityCardsHide> 
    
  );
  function reorderContents(data:AssignedCapability[], index:number){

    return data.slice(index).concat(data.slice(0, index));
    
  }
}

