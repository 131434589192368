import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";



export const IndustriesBox = styled(Box)(({ theme })=> ({
  columnGap:theme.spacing(1),
  display:"flex",
  flexDirection:"row",
  flexWrap:"wrap",
  maxHeight:"600px",
  overflowX:"hidden",
  paddingBottom:"106px",
  rowGap:theme.spacing(4),
  width:"100%",


  [`${theme.breakpoints.down("sm")}`]:{
    rowGap:theme.spacing(1)
  }
}));

export const StyledIndustryBoxItem = styled(Box)<{bg?:string, isActive:boolean, 
    isProfile?: boolean}>(({ theme, bg, isActive, isProfile })=> ({
      backgroundImage:`${theme.linearGradients.gradientCAlternative01},url(${bg})`,
      backgroundRepeat:"no-repeat",
      backgroundSize:"cover",
      border: isActive ? "1px solid" : "",
      borderColor:isActive ? isProfile ? 
        theme.customColors.borderSecondaryComplete : theme.customColors.accentVariantA : "transparent",
      borderRadius:theme.spacing(1),
      boxShadow:  `0px 0px 4px 0px ${isProfile ? 
        theme.customColors.borderSecondaryComplete : isActive ? theme.customColors.accentVariantA : "none"}`,
      cursor:"pointer",
      display:"flex",
      flexDirection:"column",
      height:"177px",
      justifyContent:"flex-end",
      padding:theme.spacing(2,5),
      width:`calc(33.33% - ${(8 * 2)/3}px)`,

      "p":{
    
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "2",
        display: "-webkit-box",
        fontSize:theme.spacing(2.5),
        fontWeight:700,
        maxWidth:"100%",
        overflow: "hidden",
        overflowWrap:"break-word",
        textOverflow: "ellipsis"
      },

      [`${theme.breakpoints.down("sm")}`]:{
        height:"79px",
        padding:theme.spacing(1,2),
        width:`calc(50% - ${8/2}px)`,

        "p":{
          fontSize:theme.spacing(1.5)
        }
      }
  
    }));
