import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CarouselBox = styled(Box)(() => ({
  width:"100%"
}));

export const HeroBox = styled(Box)(({ theme }) => ({
  marginTop: "2.5rem",
  width:"100%",
  [theme.breakpoints.down("sm")]: {
    marginTop: "2rem"
  }
}));
