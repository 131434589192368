import {
  Box,
  Grid,
  GridProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const NotificationBox = styled(Grid)<GridProps>(({ theme }) => ({
  background: theme.customColors.backgroundPrimaryLighter,
  display:"flex",
  flexDirection:"column",
  flexWrap:"nowrap",
  gap:0,
  maxHeight:"800px",
  maxWidth:"507px",
  minWidth:"507px",
  
  ".icon":{
    "path":{
      fill:theme.palette.primary?.main
    }
  },

  [theme.breakpoints.down("sm")]:{
    height:"100%",
    maxHeight:"unset",
    maxWidth:"unset",
    minWidth:"100%",
    width: "100%"
  }


})) as typeof Grid;

export const NotificationItem = styled(Grid)<GridProps>(({ theme }) => ({
  background: "rgba(219, 255, 154, 0.1)",
  borderBottom:`1px solid ${theme.customColors.border}`,
  display:"flex",
  flexWrap:"wrap",

  columnGap:theme.spacing(2),
  rowGap:theme.spacing(1),

  padding:"1rem 37px",
  position:"relative",

  "a":{
    color:`${theme.palette.primary?.main} !important`,
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign:"end",
    width:"100%"
  },

  "&:before":{
    background:theme.customColors.accentVariantA,
    borderRadius:"50%",
    content:"''",
    display:"flex",
    height:"6px",
    width:"6px",

    position:"absolute",

    left:"16px",
    top:"16px"
  },

  "&.displayed":{
    background: "transparent",

    "&:before":{
      content:"none"
    }

  }



})) as typeof Grid;

export const DescriptionTypography = styled(Box)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  fontSize: "0.875rem",
  fontWeight: 400,
  width:" calc(100% - 40px)",

  ".date":{
    fontSize: "0.75rem",
    marginBottom:"8px"
  },

  [theme.breakpoints.down("sm")]:{
  }
}));






