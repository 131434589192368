import {
  Stack,
  Typography,
  type StackProps,
  type TypographyProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

type ContentFormatProps =
  StackProps & {
    active?: boolean
    children: string
    image: string
    onClick?: () => void,
    isProfile?: boolean
  }

export const FormatsStack = styled(Stack)<
  StackProps & {
    $active?: boolean,
    image: string,
    isProfile: boolean
  }
>(({
  $active=false,
  image,
  isProfile,
  theme
}) => ({
  alignItems: "flex-start",
  background: `${theme.linearGradients.gradientC}, url(${image})`,
  backgroundSize: "cover",
  borderRadius: theme.spacing(1.5),
  boxShadow: isProfile ? `0px 0px 4px 0px ${ theme.customColors.borderSecondaryComplete }` : "none",
  cursor:"pointer",
  height: "112px",
  justifyContent: "flex-end",
  outline: $active ? `${theme.spacing(0.25)} solid 
    ${ isProfile ? theme.customColors.borderSecondaryComplete : theme.customColors.accentVariantA }` : undefined,
  padding: theme.spacing(2),
  width:"200px",

  [theme.breakpoints.down("sm")]: {
    borderRadius: theme.spacing(0.5),
    maxHeight: "77px"
  }
}));

export const FormatTypography = styled(Typography)<
  TypographyProps & { $clamp?: number }
  >(({ $clamp=1, theme }) => ({
    /* CLAMP TEXT TO X LINES */
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": $clamp.toString(),

    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontWeight:"700",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  }));

export function ContentFormat({
  active=false,
  isProfile=false,
  children,
  image,
  onClick,
  ...props
} : ContentFormatProps) {
  return (
    <FormatsStack
      $active={ active }
      image={ image }
      isProfile={ isProfile }
      onClick={ onClick }
      { ...props }
    >
      <FormatTypography $clamp={ 2 }>
        { children }
      </FormatTypography>
    </FormatsStack>
  );
}
