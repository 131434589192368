import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import { useFilePicker } from "use-file-picker";
// eslint-disable-next-line import/no-unresolved
import { FileContent } from "use-file-picker/types";
// eslint-disable-next-line import/no-unresolved
import { FileAmountLimitValidator, FileSizeValidator } from "use-file-picker/validators";
import { Icon } from "../Icon";

const ButtonUploadCst: React.FC<{
  id: string;
  text?: string;
  maxSize?: number;
  acceptedFileTypes?: string[],
  onChange?: (base64: string) => void,
  required?: boolean,
  onError?: (reason: string) => void
}> = (props) => {

  const [selectedFile, setSelectedFile] = useState<FileContent<ArrayBuffer>>();

  const theme = useTheme();
  const { t } = useTranslation();

  const { text: buttonLabel, acceptedFileTypes, onChange, onError, required, maxSize } = props;

  const { openFilePicker } = useFilePicker({
    accept: acceptedFileTypes,
    readAs: "ArrayBuffer",
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileSizeValidator({ maxFileSize: maxSize || (1024 * 1024) /* 1MB */ })
    ],
    onFilesSelected: ({ filesContent, errors }) => {

      if(errors && errors.length > 0) {
        const reason = errors[0].reason;
        setSelectedFile(undefined);
        if(onError) return onError(reason);
      }

      if(filesContent && filesContent.length > 0) {
        setSelectedFile(filesContent[0]);
        const base64 = arrayBufferToBase64(filesContent[0].content);
        if(onChange) onChange(base64);
      }
    }
  });

  return (
    <Button onClick={ openFilePicker } variant={ (selectedFile) ? "outlined" : "contained" } endIcon={ selectedFile ? (
      <Icon
        className="icon-upload"
        icon={ "Icons_upload" }
        width={ "20px" }
        height={ "20px" }
        color={ theme.customColors.systemSecondary02 }
      />
    ) : undefined } sx={ {
      "&:hover": {
        backgroundColor: selectedFile ? `${theme.customColors.systemSecondary02}0A` : theme.customColors.backgroundPrimaryCta,
        borderColor: selectedFile ? theme.customColors.systemSecondary02 : theme.customColors.backgroundPrimaryCta
      },
      borderColor: selectedFile ? theme.customColors.systemSecondary02 : theme.customColors.backgroundPrimaryCta
    } }>
      <Typography sx={ {
        color: selectedFile ? theme.customColors.systemSecondary02 : theme.palette.background?.paper,
        lineClamp: 1,
        overflow: "hidden",
        textOverflow: "ellipsis"
      } }>
        { (selectedFile ? selectedFile.name : "") || buttonLabel || t("upload_file") } { required ? "*" : "" }
      </Typography>
    </Button>
  );
};

const arrayBufferToBase64 = ( buffer: ArrayBuffer ) => {
  var binary = "";
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return btoa( binary );
};

export default ButtonUploadCst;
