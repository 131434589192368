import { Box, BoxProps, Modal, Stack, StackProps, styled } from "@mui/material";

export const StyledModalEcm = styled(Modal)<BoxProps >(({ theme }) => ({
  alignItems:"center",
  display:"flex",
  justifyContent:"center",

  ">.MuiStack-root":{
    alignItems:"center",
    background: theme.linearGradients.gradientB,
    borderRadius:theme.spacing(0.5),
    flexDirection:"column",
    gap:theme.spacing(3),
    height: "fit-content",
    maxHeight:"calc(100% - 32px)",
    overflowY:"scroll",
    padding: theme.spacing(3,5),
    width: 660,

    [theme.breakpoints.down("sm")]:{  
      bottom: 0,
      padding: theme.spacing(3,3),
      position: "fixed",
      width: "100%"
    }
  }
}));

export const ModalTopPart = styled(Box)<BoxProps >(({ theme }) => ({
  alignItems:"center",
  borderBottom:`1px solid ${theme.customColors.border}`,
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(1),
  justifyContent:"center",
  paddingBottom:theme.spacing(3),
  width:"100%",


  ".description":{
    fontSize:theme.spacing(2.5),
    textAlign:"center"
  },
  ".title":{
    fontSize:theme.spacing(4),
    fontWeight:700,
    textAlign:"center"
  }
}));

export const StyledOptionsSelected = styled(Box)<BoxProps >(({ theme }) => ({
  alignItems:"center",
  display:"flex",
  flexWrap:"wrap",
  gap:theme.spacing(1),
  justifyContent: "flex-start",
  width: "100%"
}));

export const StyledOptionChip = styled(Box)<BoxProps >(({ theme }) => ({
  alignItems:"center",
  background:theme.customColors.backgroundSecondary,
  borderRadius:theme.spacing(1),
  color:theme.customColors.textPrimaryAlternative,
  cursor:"pointer",
  display:"flex",
  gap:theme.spacing(2),
  padding:theme.spacing(1),
  position:"relative",

  ".delete-icon":{
    height:"fit-content",
    lineHeight:0
  }

}));
export const StyledError = styled(Box)<BoxProps >(({ theme }) => ({
  alignItems:"center",
  color:theme.customColors.textWarning,
  display:"flex",
  gap:theme.spacing(1),
  justifyContent:"center",
  width:"100%"
}));
export const StyledActions = styled(Stack)<StackProps >(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  justifyContent: "center"
}));

