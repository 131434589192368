import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { ButtonLink } from "../../components";

export const TermsCondidionsStack = styled(Stack)(({ theme }) => ({
  margin:" 0 auto",
  paddingTop: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
  }
}));


export const TextContent = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap: "32px",
  marginBottom: "10px",
  textAlign: "justify",

  "& .subtitle::marker": {
    fontSize: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize:"12px",
      lineHeight: "14px"
    }
  },

  "& .auth": {
    listStyleType: "lower-alpha"
  },

  "& .roman": {
    listStyleType: "lower-roman"
  },

  "& *": {
    fontSize: "20px",
    lineHeight: "21px",

    [theme.breakpoints.down("sm")]: {
      fontSize:"12px",
      lineHeight: "14px"
    }
  }

  // "& *": {
  //   fontSize: "20px",
  //   lineHeight: "21px"
  // }


}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
  justifyContent: "center",
  lineHeight: "30px",
  [theme.breakpoints.down("sm")]: {
    fontSize:"14px",
    lineHeight: "18px"
  }
 
}));


export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  gap:"32px",
  justifyContent: "center",
  lineHeight: "21px",
  textAlign: "justify",

  [theme.breakpoints.down("sm")]: {
    fontSize:"12px",
    lineHeight: "14px"
  }
}));

export const SubTitleTypography = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  gap:"24px",
  justifyContent: "center",
  lineHeight: "21px",
  [theme.breakpoints.down("sm")]: {
    fontSize:"12px",
    lineHeight: "14px"
  }
}));
