import { create } from "zustand";

type ZoomId = {
  readonly carouselId: string | null,
  readonly slideId: string | null,
}

export type ZoomState = {
  readonly zoomMap: Record<string, Record<string, boolean>>
}

type ZoomActions = {
  readonly activateMapItem: (props: ZoomId) => void
  readonly deactivateMapItem: (props: ZoomId) => void
  // readonly initializeMap: (props: ZoomId[]) => void
  readonly initializeMapCarousel: (props:
    Pick<ZoomId, "carouselId"> & {
      slideIds?: string[]
    }
  ) => void
  // readonly initializeMapSlide: (props: ZoomId) => void
  // readonly mapIsInitialized: () => void
  // readonly resetMap: () => void
  readonly resetMapCarousel: (props: Pick<ZoomId, "carouselId">) => void

  readonly cardIsActive: (props: ZoomId) => boolean
}

const initialZoomState: ZoomState = {
  zoomMap: {}
};

export const useZoomStore = create<ZoomState & ZoomActions>((set, get) => ({
  ...initialZoomState,
  activateMapItem: ({ carouselId, slideId }) => {
    const newZoomMap = get().zoomMap;
    if (Object.hasOwn(newZoomMap, `${carouselId}`)) {
      if (Object.hasOwn(newZoomMap[`${carouselId}`], `${slideId}`)) {
        // reset all items
        Object.keys(newZoomMap).forEach((carouselKey) => {
          Object.keys(newZoomMap[carouselKey]).forEach((slideKey) => {
            newZoomMap[carouselKey][slideKey] = false;
          });
        });
        // set target item
        newZoomMap[`${carouselId}`][`${slideId}`] = true;
        set({ zoomMap: newZoomMap });
      }
    }
  },
  cardIsActive: ({ carouselId, slideId }) => {
    // return Object.entries(get().zoomMap).some(([key, val]) => (
    //   key === `${carouselId}/${slideId}` && val === true
    // ));
    // if (Object.hasOwn(get().zoomMap, `${carouselId}/${slideId}`)) {
    //   return get().zoomMap[`${carouselId}/${slideId}`];
    // } else {
    //   return false;
    // }
    return Boolean((get().zoomMap[`${carouselId}`][`${slideId}`]));
  },
  deactivateMapItem: ({ carouselId, slideId }) => {
    const newZoomMap = get().zoomMap;
    if (Object.hasOwn(newZoomMap, `${carouselId}`)) {
      if (Object.hasOwn(newZoomMap[`${carouselId}`], `${slideId}`)) {
        // reset target item
        newZoomMap[`${carouselId}`][`${slideId}`] = false;
        set({ zoomMap: newZoomMap });
      }
    }
  },
  // initializeMap(ids) {
  //   const newMap = { ...initialZoomState.zoomMap };
  //   if(Object.entries(newMap).length < 1) {
  //     ids.reduce((acc, { carouselId, slideId }) => {
  //       acc[`${carouselId}`][`${slideId}`] = false;
  //       return acc;
  //     }, newMap);
  //   }
  // },
  initializeMapCarousel: ({ carouselId, slideIds }) => {
    const newZoomMap = get().zoomMap;
    newZoomMap[`${carouselId}`] = {};
    if (slideIds) {
      slideIds.forEach((slideId) => {
        newZoomMap[`${carouselId}`][`${slideId}`] = false;
      });
    }

    set({ zoomMap: newZoomMap });

  },
  // initializeMapSlide: ({ carouselId, slideId }) => {
  //   const newZoomMap = get().zoomMap;
  //   if(Object.hasOwn(newZoomMap, `${carouselId}`)) {
  //     if(!Object.hasOwn(newZoomMap[`${carouselId}`], `${slideId}`)) {
  //       newZoomMap[`${carouselId}`][`${slideId}`] = false;
  //       set({ zoomMap: newZoomMap });
  //     }
  //   }
  // },
  // mapIsInitialized: () => {
  //   return Object.entries(get().zoomMap).length > 0;
  // },
  // resetMap: () => {
  //   const newZoomMap = get().zoomMap;
  //   Object.keys(newZoomMap).forEach((carouselKey) => {
  //     Object.keys(newZoomMap[carouselKey]).forEach((slideKey) => {
  //       newZoomMap[carouselKey][slideKey] = false;
  //     });
  //   });
  //   set({ zoomMap: newZoomMap });
  // },
  resetMapCarousel: ({ carouselId }) => {
    const newZoomMap = get().zoomMap;
    if(Object.hasOwn(newZoomMap, `${carouselId}`)) {
      delete newZoomMap[`${carouselId}`];
    }
    set({ zoomMap: newZoomMap });
  }
}));
