import {
  Stack,
  Avatar,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { 
  useState, 
  ForwardedRef,
  forwardRef 
} from "react";
import { useTranslation } from "react-i18next";

import { SharedItemCard } from "./SharedItemCard.component";

import type { TopicCardProps } from "./TopicCardShare.types";

import {
  Icon,
  CtaCommunityOverlay,
  ModifyContentModal, 
  CommentsSection
} from "..";
import { DeleteContentModal } from "../../components/DeleteContentModal";
import { 
  ActionLink,
  BodyTypography, 
  OwnerNameTypography,  
  TopicCardContainerStack, 
  TopicCardTop, 
  TopicTypography 
} from "../../components/TopicCard/TopicCard.styles";
import { TopicCardSignature } from "../../components/TopicCardSignature";
import { POST_TYPE, ROLES } from "../../consts";
import { useAuthStore } from "../../stores";
import { stringAvatar, useGetCreatedLabel } from "../../utils/community";

export const TopicCardShare = forwardRef(
  function TopicShare(
    props:TopicCardProps, 
    ref:ForwardedRef<HTMLDivElement | null>) {
    const {
      body,
      changed,
      commentsNumber,
      created,
      id,
      learningObjectTypology,
      likes,
      ownerImage,
      ownerName,
      sharedLo,
      selectedTopic,
      selectedFilter,
      topicOwner
    } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isModifyOpen, setIsModifyOpen] = useState(false);
    const[showComments,setShowComments]=useState(false);

    const userData = useAuthStore(state => state.userData);

    const isModerator = userData?.roles.some(role => role === ROLES.BUSINESS_ADMIN || role === ROLES.PRODUCT_OWNER) ;
    const userId = useAuthStore(state => state.userData?.id_user);

    const isEditable =  (userId && topicOwner == userId.toString());
    const isModified = created !== changed;

    return (
      <TopicCardContainerStack
        ref={ ref }
        gap={  showComments ? theme.spacing(2) : 0 }
      >
        <Stack 
          sx={ { 
            position: "relative" 
          } } 
          gap={ "24px" }
        >
          <TopicCardTop
          >
            <Avatar
              { ...stringAvatar(ownerName) }
              src={ ownerImage }
              sx={ {
                height: isMobile ? "32px" : "56px",
                width: isMobile ? "32px" : "56px"
              } }
            />
            <Stack
              flexGrow={ 1 }
              gap={ 1 } 
            >
              <Stack 
                direction={ "row" } 
                justifyContent={ "space-between" } 
                alignItems={ "center" }
              >
                <OwnerNameTypography>{ ownerName }</OwnerNameTypography>
                <TopicTypography
                  sx={ {
                    fontWeight: 600
                  } }>
                  <Icon
                    icon={ "share_post" }
                    size={ isMobile ? 15 : 18 }
                    color={ theme.customColors.systemPrimary01Alternative01 }
                  />
                  { t("share") }
                </TopicTypography>
              </Stack>
              <Stack 
                direction={ "row" }
                justifyContent={ "space-between" } 
                alignItems={ "center" }
                flexWrap={ "wrap" }>
                <TopicCardSignature
                  created={ useGetCreatedLabel( created) }
                  isModified={ isModified }
                  size={ "medium" }
                />
                { 
                  isEditable  || isModerator ? 
                    <Stack 
                      direction="row"
                      alignItems={ "flex-end" }
                      gap={ 2 }
                    >
                      { isEditable ? 
                      
                        <ActionLink
                          onClick={ () => setIsModifyOpen(true) }
                        >
                          { t("modify") }
                        </ActionLink>  : 
                        null
                      }
                     
                      <ActionLink 
                        onClick={ () => setIsDeleteOpen(true) }
                      >
                        { t("delete") }
                      </ActionLink> 
                    </Stack>  : 
                    null
                }
              </Stack>
            </Stack>
          </TopicCardTop>
      
          <BodyTypography >
            { body }
          </BodyTypography>

          {
            sharedLo && learningObjectTypology ? 

              <SharedItemCard
                title={ sharedLo.title }
                shortDescription={ sharedLo.description }
                id={ Number(sharedLo.lo_id) }
                learningObjectTypology={ learningObjectTypology }
                coverPublicURL={ sharedLo.publicCoverUrl }
                topicTags={ sharedLo.topics }
              /> : 
              null
          }
    
        
          <CtaCommunityOverlay
            id={ id }
            comments={ commentsNumber }
            likes={ likes }
            commentsOpened={ false }
            commentsClickAction={ () => {
              setShowComments(!showComments);
            } }
            selectedFilter={ selectedFilter }
            selectedTopic={ selectedTopic }
          />

          {
            isDeleteOpen && sharedLo ? 

              <DeleteContentModal 
                closeDrawer={ ()=> {
                  setIsDeleteOpen(false);
                } }
                id={ id }
                isModerator={ Boolean(isModerator) }
                loId={ sharedLo.lo_id }
                onClose={ ()=> {
                  setIsDeleteOpen(false);
                } }
                open={ isDeleteOpen }
                postType={ POST_TYPE.SHARED }
                selectedTopic={ selectedTopic }
                selectedFilter={ selectedFilter }
              /> : null
          }
        
          {
            isModifyOpen && sharedLo? 
              <ModifyContentModal
                selectedFilter={ selectedFilter }
                selectedTopic={ selectedTopic }
                open={ isModifyOpen }
                onClose={ () => setIsModifyOpen(false) }
                id={ id }
                loId={ sharedLo.lo_id }
                postType={ POST_TYPE.SHARED }
                body={ body }
                closeDrawer={ () => setIsModifyOpen(false) }
              /> 
              : null

          }
    
        </Stack>
    
        <CommentsSection 
          topicOwnerId={ topicOwner }
          selectedFilter={ selectedFilter }
          selectedTopic={ selectedTopic }
          discussionId={ id } 
          className={ showComments ? "visible" : "" } /> 
    
      </TopicCardContainerStack>
    );

  });
