import {
  Box,
  BoxProps,
  Typography,
  styled,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type { BadgeGoalsProps } from "./BadgeGoals.types";

import { BadgeLink } from "../../components/BadgeLink";
import { useAuthStore } from "../../stores";
import { CertificateButtonContainer } from "../CertificateButton/CertificateButtonContainer.component";


const StyledBadgeBox = styled(Box)<
BoxProps & {
  $isOver:boolean,
  $small?:boolean,
  $disabled?:boolean
}>(({ $disabled, $isOver, $small, theme })=>
{
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const width =  !$small && !isMobile ? "120px" :"46px" ;
  const height = !$small && !isMobile ? "87px" : "40px" ;

  const widthOver = !$small  && !isMobile ? "57px" : "31px";
  const heightOver = !$small && !isMobile ? "57px" : "31px";

  return {
    bottom:$isOver ? "-10px" : undefined,
    position:$isOver ? "absolute" : "static",
    right:0,

    "a":{
      cursor:$disabled ? "not-allowed" : "pointer"

    },
    "img":{
      height: $isOver  ? heightOver : height,
      maxWidth: $isOver ? widthOver : width,
      width:"unset"
    }

  };
}

);

const StyledCertificateBox = styled(Box)<BoxProps & {
  $small?:boolean
}>(({  $small, theme })=>{
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const width =  !$small && !isMobile ? "120px" :"38px" ;
  const height = !$small && !isMobile ? "87px" : "38px" ;
  return {
    position:"static",

    "img":{
      height: height,
      maxWidth: width,
      width:"unset"
    }

  };});

export function BadgeGoals({
  badge,
  badgeDisabled,
  certificate,
  small,
  downloadCertificate,
  isDownloading
} : BadgeGoalsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const learnerId = useAuthStore(state => state.userData?.id_user) ?? "";

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={ isMobile ? "9px" : theme.spacing(2) }
      minHeight={ isMobile ? "103px" : "auto" }
    >

      {
        certificate ?



          <Box
            position={ "relative" }
          >
            <StyledCertificateBox
              $small={ small }
            >
              <CertificateButtonContainer
                iconURL={ certificate.iconURL }
                id={ certificate.id }
                learningObjectTitle={ certificate.learningObjectTitle }
                name={ certificate.name }
                small={ small }
                isDownloading={ isDownloading }
                templatePath={ certificate.templatePath }
                downloadHandler={ ()=> {
                  downloadCertificate &&
                  downloadCertificate({
                    certificateId: certificate.id,
                    learnerId: learnerId as number,
                    learningObjectId: certificate?.learningObjectId as number
                  });}
                }
              />
            </StyledCertificateBox>

            {
              badge ?
                <StyledBadgeBox
                  $small={ small }
                  $isOver={ true }
                  sx={ {
                    pointerEvents:"none"
                  }
                  }
                >
                  <img src={ badge?.iconURL } alt={ badge?.name }/>
                </StyledBadgeBox> :

                null
            }

          </Box> :

          <Box
            position={ "relative" }
          >

            <StyledBadgeBox
              $small={ small }
              $isOver={ false }
              $disabled={ badgeDisabled }
            >
              <BadgeLink
                iconURL={ badge?.iconURL || "" }
                id={ badge?.id || 0 }
                learningObjectTitle={ badge?.learningObjectTitle || "" }
                name={ badge?.name || "" }
                small={ small }
                templatePath={ null }
              />
            </StyledBadgeBox>

          </Box>


      }
      {
        certificate?.learningObjectTitle || badge?.learningObjectTitle ? (
          <Typography
            color={ theme.customColors.textPrimary }
            fontWeight={ 700 }
            textAlign="center"
            fontSize={ isMobile ? "0.75rem ": "1rem" }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                boxOrient: "vertical",
                display: "-webkit-box",
                lineHeight: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2"
              },
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            } }
          >
            { certificate?.learningObjectTitle || badge?.learningObjectTitle }
          </Typography>
        ) : null
      }
    </Box>
  );
}
