/* eslint-disable max-len */
import { Box, Chip, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { cardWidth } from "../../components/CarouselCard/CarouselCard.styles";

export const CardsBox = styled(Box)(() => ({
  alignItems:"center",
  display: "grid",
  gridGap: "1rem",
  gridRowGap: "0.2rem",
  gridTemplateColumns: `repeat(auto-fill, ${cardWidth})px`,
  justifyContent: "space-around",
  paddingBottom:"80px",
  width:"100%"
}));

export const TopicsStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "4px",
  gap: "16px",
  padding: "16px 24px 24px 16px"
}));

export const TopicChip = styled(Chip)<{selected?: boolean, chatbot?: boolean}>(({ theme, selected, chatbot }) => ({
  alignItems: "center",
  background:`alpha(${theme.customColors.backgroundPrimaryLighter}, 0.6)`,
  borderColor: chatbot ? theme.customColors.border : selected ? theme.customColors.textMandatory: theme.customColors.borderTag,
  borderRadius: "4px",
  color: chatbot ? theme.customColors.textInput : selected ? theme.customColors.textMandatory: theme.customColors.textPrimary,
  fontSize:  "16px",
  fontWeight: 700,
  lineHeight: "18px",
  padding: "7px 8px",
  textTransform: "uppercase",

  "span": {
    padding:"0"
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "14px",
    padding: "5px 8px"
  }

}));



