import {
  Grid,
  Stack,
  StackProps,
  Typography
} from "@mui/material";
import { common } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { ElementType } from "react";
import { Icon } from "../Icon";

export const DetailTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.accentVariantE,
  fontSize: "2rem",
  lineHeight: 1.1875,
  overflowWrap:"break-word",
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    lineHeight: "18px",
    width: "100%"
  }
}));

export const HomeCardStack = styled(Stack)<
  & StackProps
  & {
    component: ElementType, // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'
    $coverPublicURL: string
  }
>(({ $coverPublicURL, theme }) => ({
  background: `${theme.linearGradients.gradientDAlternative01},
    ${theme.linearGradients.gradientDAlternative03}, ${theme.linearGradients.gradientDAlternative05},
      url(${$coverPublicURL})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  borderRadius: "0.8rem",
  height: "auto",
  // justifyContent: "space-between",
  justifyContent:"center",
  maxWidth:"100%",
  // minHeight: "28.125rem",
  minHeight:"500px",
  padding: "4.75rem 0 1.5rem 3rem",
  position: "relative",
  width: "100%",

  "&:first-child":{
    marginTop:"2.5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop:"1rem"
    }
  },

  "& .MuiChip-root": {
    border: `2.925px solid ${theme.customColors.borderTag}`,
    borderRadius: "0.4875rem",

    "& span": {
      fontSize: "1.125rem",
      fontWeight: "700",
      letterSpacing: "1.95px",
      lineHeight: "0.9375rem"
    }
  },

  ">span":{
    right:theme.spacing(4)
  },

  [theme.breakpoints.down("sm")]: {
    background: `${theme.linearGradients.gradientDAlternative02},
      ${theme.linearGradients.gradientDAlternative04}, ${theme.linearGradients.gradientDAlternative05},
        url(${$coverPublicURL})`,
    backgroundPositionX: "center",
    backgroundPositionY: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 630px",
    padding: "14rem 0 1.125rem 0",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2rem"
    }
  }
}));

export const TypeTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.accentVariantE,
  fontSize: "1.25rem",
  fontWeight: "700",
  letterSpacing: "1.95px",
  lineHeight: 7 / 6,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    lineHeight: "18px"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  ".primary": {
    color: theme.palette.primary?.main
  },
  color: theme.customColors.accentVariantE,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    lineHeight: "30px"
  },
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "2",
  display: "-webkit-box",
  overflow: "hidden",
  overflowWrap:"break-word",
  textOverflow: "ellipsis",

  fontSize: "4rem",
  fontWeight: "700",
  lineHeight: 7 / 6,
  maxWidth:"80%",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`
}));

export const StatusBoxIcon = styled(Icon)(({ icon, theme }) => ({
  "&":
    {
      height:"30px",
      width:"30px",

      "path":
      { fill: icon == "completato" ? theme.customColors.systemPrimary01 : theme.customColors.systemSecondary05 },

      [theme.breakpoints.down("sm")]:{
        height:"19px",
        width:"19px"
      }
    }
}));

export const CardGrid = styled(Grid)(({ theme }) => ({
  "> *": {
    padding: theme.spacing(1.5),
    paddingBlock: theme.spacing(0.25)
  }
}));

export const CardOverlayStack = styled(Stack)<
  Omit<
    StackProps,
    "component"
  > & {
    component: ElementType
  }
>(({ theme }) => ({
  left: "854px",
  position: "relative",
  //right: theme.spacing(3),
  top: "50%",
  transform: "translateY(-50%)",
  width: "61px",
  zIndex: theme.zIndex.mobileStepper
}));
