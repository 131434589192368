import {
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type { Tool } from "../types";
import { getEnvironmentVariables } from "../utils/general";

type ToolLink = {
  id: number
  privateUrl: string
  publicUrl: string
}

const { basePath } = getEnvironmentVariables();

export function useToolsQuery({
  enabled = true,
  learningObjectId,
  pageNumber=0,
  pageSize=9
  // topics=[]
}: {
  enabled?: boolean
  learningObjectId: string
  pageNumber?: number
  pageSize?: number
  // topics: string[]
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const fetcher = fetcherFactory();

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/learningObjects/${learningObjectId}/tools`;
  const params = `pageNumber=${pageNumber}&pageSize=${pageSize}`;

  return useQuery<
    unknown,
    unknown,
    Tool[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && initiativeId
      && sessionToken
      && corporateId
      && initiativeId
      && learningObjectId
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: { tools: Tool[]}) => {
        return apiAdapter(data.tools);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, params]
  });
}

export function useToolsLinkQuery({
  enabled = true,
  learningObjectId,
  toolId
}: {
  enabled?: boolean
  learningObjectId: string
  toolId: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const fetcher = fetcherFactory();

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/learningObjects/${learningObjectId}/tools/${toolId}`;

  return useQuery<
    unknown,
    unknown,
    ToolLink,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && learningObjectId
      && toolId
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ToolLink) => {
        return apiAdapter([data])[0];
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri]
  });
}
