import { Box, styled } from "@mui/material";

export const StyledUpload = styled(Box)(({ theme }) => ({
  alignItems: "center",
  border:  `2px dashed ${theme.customColors.borderPrimaryCta}`,
  borderRadius: "2px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  height: "144px",
  justifyContent: "center",
  marginTop: "24px"
}));

export const UploadedFile = styled(Box)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "4px",
  boxShadow: `10px 10px 80px 0px ${theme.customColors.backgroundPrimaryLighter}66`,
  display: "flex",
  flexDirection: "row",
  gap: "4px",
  justifyContent: "space-between",
  marginTop: theme.spacing(1),
  padding: theme.spacing(1,2)
}));

export const UploadedFileInfo = styled(Box)(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minWidth: "340px"
}));
  

