import {
  TextFieldProps,
  TextField
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  forwardRef,
  Ref
} from "react";

const _StyledTextField = forwardRef((
  props: TextFieldProps,
  ref?: Ref<HTMLDivElement>
) => (
  <TextField { ...props } ref={ ref }/>
));
_StyledTextField.displayName = "_StyledTextField";

export const StyledTextField = styled(_StyledTextField)(({
  error,
  theme
}) => {
  const accentColor = error
    ? theme.palette.error
    : theme.customColors.textPrimary;

  return {
    "input, label": {
      fontSize: "1em",
      lineHeight:1.5
    },

    "input": {
      padding: theme.spacing(1,2)
    },

    "label": {
      top: "-8px",

      "&.MuiInputLabel-shrink": {
        top: 0
      }
    },

    ".MuiOutlinedInput-notchedOutline, .MuiInputLabel-root": {
      borderColor: `${accentColor} !important`,
      color: `${accentColor} !important`
    },

    [theme.breakpoints.down("sm")]: {
      ".MuiFormHelperText-sizeSmall": {
        lineHeight: "0.875rem",
        marginLeft: "0",
        marginTop: "8px"
      },
      ".MuiInputBase-adornedEnd": {
        borderRadius: "2px",
        minHeight: "40px",
        paddingRight: "8px"
      },
      ".MuiInputBase-sizeSmall": {
        borderRadius: "2px",
        minHeight: "40px"
      },
      "input, label": {
        fontSize: "0.875rem"
      },
      "label": {
        top: 0
      }
    }
  };
});

export const TextInput = forwardRef(
  function (
    props: TextFieldProps,
    ref?: Ref<HTMLDivElement>
  ) {
    const {
      helperText
    } = props;
    return (
      <StyledTextField
        inputRef={ ref }
        autoComplete="off"
        type="text"
        variant="outlined"
        { ...props }
        helperText={ props.error ? helperText : "" }
      />
    );
  }
);
TextInput.displayName = "TextInput";
