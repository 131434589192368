import { TabsContentProfile } from "../../components";
import { TAB_ITEMS_PROFILE, TAB_ITEMS_PROFILE_CASE, TAB_ITEMS_PROFILE_CONFIG } from "../../consts";
import type { TabItemsConfigProfile, TabItemsProfile } from "../../types";


export function filterTabTypes({
  onboarding,
  onboardingCapability,
  onboardingExecutiveJob,
  onboardingPersonalJob,
  tabItems
} : {
      onboarding:boolean,
      onboardingExecutiveJob:boolean,
      onboardingPersonalJob:boolean,
      onboardingCapability:boolean,
      tabItems:TabItemsConfigProfile
  }) {

  return tabItems.flatMap((tab)=> {
    if (tab === TAB_ITEMS_PROFILE.GOALS) {
      return [tab];
    }
    else if(onboarding 
      && (
        (tab === TAB_ITEMS_PROFILE.SECTOR) || 
      (
        tab === TAB_ITEMS_PROFILE.KNOWLEDGE && 
        (onboardingExecutiveJob || 
          onboardingCapability || 
          onboardingPersonalJob
        )
      ) ||
      (
        tab === TAB_ITEMS_PROFILE.PREFERENCES
      )
      ) ){
      return [tab];
    }
    else {
      return [];
    }
  }) ?? [];
}

export function getTabsProfile({
  onboarding,
  onboardingCapability,
  onboardingExecutiveJob,
  onboardingPersonalJob
} : {
      onboarding:boolean,
      onboardingExecutiveJob:boolean,
      onboardingPersonalJob:boolean,
      onboardingCapability:boolean,
  }){
  const isSimpleOnboarding = onboarding && !onboardingExecutiveJob && !onboardingCapability && !onboardingPersonalJob;
  const noOnBoarding = !onboarding;

  const tabsType = noOnBoarding ? 
    TAB_ITEMS_PROFILE_CASE.NO_ONBOARDING : 
    isSimpleOnboarding ? TAB_ITEMS_PROFILE_CASE.SIMPLE_ONBOARDING : 
      TAB_ITEMS_PROFILE_CASE.ALL_ONBOARDING as keyof typeof TAB_ITEMS_PROFILE_CASE;

  const tabItems = TAB_ITEMS_PROFILE_CONFIG[tabsType].tabItems;
  return tabItems.map((tab)=> (
    {
      components:([
        // eslint-disable-next-line react/jsx-key
        <TabsContentProfile 
          tabTypeProfile={ tab } />
      ]),
      label:getTabLabel({ tabType:tab }),
      type:tab
    })
  
  );
}

function getTabLabel({
  tabType
} : {
  tabType: TabItemsProfile
}) {
  switch (tabType){
  case TAB_ITEMS_PROFILE.GOALS:
    return "your_goals";
  case TAB_ITEMS_PROFILE.SECTOR:
    return "your_sector";
  case TAB_ITEMS_PROFILE.KNOWLEDGE:
    return "your_knowledge";
  case TAB_ITEMS_PROFILE.PREFERENCES:
    return "your_preferences";
  }
}
