import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CapabilityItemContainer } from "./SignupSummary.styles";
import { ButtonLink } from "../../components/Button";
import { Chip } from "../../components/Chip";
import { Icon } from "../../components/Icon";
import { PreferenceStack } from "../../components/PreferenceStack";
import { ModifyLink, StyledChipsBox } from "../../components/PreferenceStack/PreferenceStack.styles";
import { LEARNING_LEVEL } from "../../consts";
import type { DomainItem } from "../../types";



export function SignupSummaryKnowledge({
  description,
  domains,
  isProfilePage=false,
  linkModify,
  onboardingAddText,
  noItemsText,
  noItemsToShow,
  title
}:{ 
    description?:string
    domains:DomainItem[] | undefined
    isProfilePage?:boolean
    linkModify:string
    onboardingAddText?:string
    noItemsText:string
    noItemsToShow:boolean
    title:string
  }){
  const{ pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PreferenceStack
      title={ title }
      direction="row"
      rowGap={ isMobile ? 1 : isProfilePage ? 2 : 5 }
      padding={ isMobile ? 2 : 3 }
      width="100%"
      flexWrap={ "wrap" }
      position={ "relative" }

      sx={ {
        ".styled-chip": {
          ">div": {
            background: isProfilePage ?  "transparent" : undefined
          }
        },
        ">p": {
          maxWidth: "calc(100% - 80px)"
        },
        marginBottom: isProfilePage ?  isMobile ? "24px" : "56px"  : undefined
      } }
    >
      {
        !noItemsToShow ? 

          <>
          
            <ModifyLink
              sx={ {
                position: "absolute",
                right: isMobile && isProfilePage ? "0" : "24px"
              } }
              href={ linkModify }
              state={ {
                isSummary:pathname.includes("summary")
              } }
            >
              <Typography >
                { t("modify") }
              </Typography>
            </ModifyLink> 

            {
              description ? 
                <Typography
                  fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
                  fontWeight={ 400 }
                  lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  width={ "80%" }
                >
                  { description }
                </Typography> : 
                null
            }

            <Stack
              direction={ isMobile ? "column" : "row" }
              flexWrap="wrap"
              width={ "100%" }
              gap={ isMobile || isProfilePage ? 2 : 5 }
              marginTop={ isProfilePage ? "16px" : 0 } 
            >
      

              {
                domains?.some(item => item.learning_level === LEARNING_LEVEL.BASIC) ?

                  <CapabilityItemContainer
                  >
                    <Typography>{ t("basic") }</Typography>
                    <StyledChipsBox className="styled-chip">
                      {
                        domains.filter(
                          item => item.learning_level === LEARNING_LEVEL.BASIC)?.map((capability) => 
                          (
                            <Chip
                              isDruggable={ false }
                              active={ false }
                              isDisabled={ false }
                              key={ `contentFormat--${capability.id}` }
                              name={ capability.label }
                              label={ capability.label }
                            />
                
                          ))
                      }
                    </StyledChipsBox>
                  </CapabilityItemContainer> : 
                  null
              }
              {
                domains?.some(item => item.learning_level === LEARNING_LEVEL.INTERMEDIATE) ?

                  <CapabilityItemContainer
                  >
                    <Typography>{ t("intermediate") }
                    </Typography>
                    <StyledChipsBox className="styled-chip">
                      {
                        domains.filter(
                          item => item.learning_level === LEARNING_LEVEL.INTERMEDIATE)?.map((capability) => 
                          (
                            <Chip
                              isDruggable={ false }
                              active={ false }
                              isDisabled={ false }
                              key={ `contentFormat--${capability.id}` }
                              name={ capability.label }
                              label={ capability.label  }
                            />
                
                          ))
                      }
                    </StyledChipsBox>
                  </CapabilityItemContainer>: 
                  null
              }
              {
                domains?.some(item => item.learning_level === LEARNING_LEVEL.ADVANCED) ?

                  <CapabilityItemContainer
                  >
                    <Typography>{ t("advanced") }
                    </Typography>
                    <StyledChipsBox className="styled-chip">
                      {
                        domains.filter(
                          item => item.learning_level === LEARNING_LEVEL.ADVANCED)?.map((capability) => 
                          (
                            <Chip
                              isDruggable={ false }
                              active={ false }
                              isDisabled={ false }
                              key={ `contentFormat--${capability.id}` }
                              name={ capability.label }
                              label={ capability.label }
                            />
                
                          ))
                      }
                    </StyledChipsBox>
                  </CapabilityItemContainer> : 
                  null
              }

   
            </Stack>
          
          </> : 
          <>
            <Typography
              fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
              fontWeight={ 400 }
              lineHeight={ isMobile ? (21 / 16) : 1.05 }
              width={ "80%" }
            >
              { noItemsText }
            </Typography>

            {
              onboardingAddText ? 

                <ButtonLink href={ linkModify }
                  sx={ {
                    fontSize:theme.spacing(2.25),
                    fontWeight:500,
                    gap:theme.spacing(1.25),
                    justifyContent:"flex-start",
                    padding:0,
                    width:"100%"
                  } }
                > 
                  { onboardingAddText }
                  <Icon 
                    icon="arrow_right_horizontal" 
                    size={ 20 } 
                    color={ theme.customColors.systemPrimary02 }
                  />
                </ButtonLink> : 
                null
            }
          </>
    
      }
    

    </PreferenceStack>
  );
}
