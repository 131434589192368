/* eslint-disable max-len */
//import { Stack, Typography, useTheme } from "@mui/material";
//import { useTranslation } from "react-i18next";

import { 
  Avatar, 
  Box, 
  Grid, 
  Typography, 
  useMediaQuery, 
  useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { RevisionItem, UserData } from "types";
import { 
  ExercisesCardContainer, 
  FileActions, 
  FileActionsContainer, 
  FileActionsWrapper, 
  FileUpload,
  IconsContainer } from "./ExercisesCardTable.style";
import { ExercisesCardTableProps } from "./ExercisesCardTable.types";
import { formatBytes } from "./FormatBytes";
import { getLastUpdateDate } from "./GetLastUpdateUtil";
import { useGetProfileImageQuery } from "../../queries";
import { useDownloadExercise } from "../../queries/exercises";
import { useAuthStore } from "../../stores";
import { AddNewFileModal } from "../AddNewFileModal";
import { DeleteConfirmContentModal } from "../DeleteConfirmContentModal/DeleteConfirmContentModal.component";
import { Icon } from "../Icon";
import { ModalFileHistory } from "../ModalFileHistory";

export function ExercisesCardTable(
  {
    editionId,
    exercises,
    teachers
  }: ExercisesCardTableProps
) {

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false);
  const [currentExerciseId, setCurrentExerciseId] = useState<number | null>(null);
  const [addFileFromReplace, setAddFileFromReplace] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showConfirmModal, setShowConfirmModal] = useState<any>();
  const [currentExerciseIdToReview, setCurrentExerciseIdToReview] = useState<number | null>(null);

  const { mutate: mutateDownloadFile, data: file } = useDownloadExercise(editionId);

  useEffect(() => {
    if(file?.public_url) {
      var element = document.createElement("a");
      element.setAttribute("href", file?.public_url);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }, [file]);
  
  useEffect(() => {
    setCurrentExerciseIdToReview(null);
  }, [exercises]);

  //TODO DOWNLOAD LOGIC

  const handleClose = () => {
    setIsOpenModalHistory(false);
    setIsOpenModalUpload(false);
  };

  const userData = useAuthStore(state => state.userData);
  const { data: profileImage  } = useGetProfileImageQuery({});

  function getUserAvatar(userData: UserData | null) {
    return {
      initials: `${userData?.name?.replaceAll(" ", "")} ${userData?.surname}`,
      src:  profileImage || undefined
    };
  }

  const userAvatar = getUserAvatar(userData);

  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0].toUpperCase()}${name.split(" ")[1][0].toUpperCase()}`
    };
  }

  return (
    <Box>
      { !isMobile && <Box           
        sx={ { 
          borderBottom: `1px solid ${theme.customColors.border}`,
          padding: isMobile ? theme.spacing(2,1) : theme.spacing(2,3) 
        } }>
        <Grid
          sx={ {
            alignItems: "center",
            display: "flex"
          } }
          container 
          columnSpacing={ { md: 5, sm: 2, xs: 1 } } >
          <Grid item xs={ 3 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("from_teacher") }
            </Typography> 
          </Grid>
          <Grid item xs={ 3 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("sent") }
            </Typography>
          </Grid>
          <Grid item xs={ 3 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("review") }
            </Typography>
          </Grid>
          <Grid item xs={ 3 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("last_update") }
            </Typography>
          </Grid>
        </Grid>
      </Box> }
      { exercises?.map((exerciseRow, index) => {
        const lastUpdateDate = getLastUpdateDate(
          exerciseRow.revisions[0]?.assignment_last_update_date, 
          exerciseRow.exercise_last_update_date,
          exerciseRow.revisions[0]?.correction_last_update_date);
        const idUserUpdate = exerciseRow.revisions[0]?.updated_by;
        const updateUserImg = teachers.find(teacher => teacher.id === idUserUpdate) ?  teachers.find(teacher => teacher.id === idUserUpdate)?.image :
          userAvatar.src;
        const updateUserInitials = teachers?.find(teacher => teacher.id === idUserUpdate) ?  teachers.find(teacher => teacher.id === idUserUpdate)?.completeName :
          userAvatar.initials;


        return (
          <ExercisesCardContainer key={ `exercise_${index}` }>
            <Grid className="cards-grid" container columnSpacing={ { md: 5, sm: 2,  xs: 1 } }>
              <FileActionsContainer item xs={ 12 } sm={ 3 } md={ 3 }className="from-teacher">
                { isMobile && <Typography
                  paddingBottom={ "8px" }
                  width={ "100%" }
                  fontSize={ "0.875rem" } 
                  fontWeight={ "700" } 
                  lineHeight={ "1.3125rem" }
                  color={ theme.customColors.textPrimary }>
                  { t("from_teacher") }
                </Typography> }
                <Avatar
                  { ...stringAvatar(exerciseRow?.teacher_id.label) }
                  alt={ "teacher name" }
                  src={ teachers.find(teacher => teacher.id === exerciseRow.teacher_id.id)?.image }
                  sx={ {
                    fontSize: "0.875rem",
                    height: theme.spacing(3),
                    marginBottom: isMobile ? "8px" : "0",
                    marginRight: theme.spacing(1),
                    width: theme.spacing(3)
                  } }
                >
                </Avatar>
                { isMobile && <Typography className="teacher-name"
                  fontSize={ "0.75rem" } 
                  marginBottom={ "8px" }
                  fontWeight={ "400" } 
                  lineHeight={ "0.875rem" }
                  color={ theme.customColors.textPrimary }>
                  { exerciseRow?.teacher_id.label }
                </Typography> }
                <FileActionsWrapper>
                  { !isMobile && <Typography className="teacher-name"
                    fontSize={ "0.75rem" } 
                    fontWeight={ "400" } 
                    lineHeight={ "0.875rem" }
                    color={ theme.customColors.textPrimary }>
                    { exerciseRow?.teacher_id.label }
                  </Typography> }
                  <FileActions>
                    <Typography className="file-name"
                      fontSize={ isMobile ? "0.75rem" : "1rem" } 
                      fontWeight={ "500" } 
                      lineHeight={ "1.3125rem" }
                      color={ theme.customColors.textPrimaryCta }>
                      { exerciseRow?.exercise_id.label }
                    </Typography>
                    <Icon
                      className="icon-download icon"
                      icon={ "download" }
                      width={ isMobile ? "14px" : "24px" }
                      height={ isMobile ? "14px" : "24px" }
                      color={ theme.customColors.systemPrimary02 }

                      onClick={ ()=> mutateDownloadFile({ exerciseId: exerciseRow?.exercise_id.id, mediaId: exerciseRow?.exercise_id.id, type: "EXERCISE" }) }
                    />
                  </FileActions>
                  <Typography className="file-info"
                    fontSize={ "0.75rem" } 
                    fontWeight={ "400" } 
                    lineHeight={ "0.875rem" }
                    color={ theme.customColors.textPrimary }
                    display={ isMobile ? "none" : "block" }
                    visibility={ exerciseRow?.exercise_size ? "visible" : "hidden" }>
                    { formatBytes(Number(exerciseRow?.exercise_size)) }
                  </Typography> 
                </FileActionsWrapper>
              </FileActionsContainer>
        
              <FileActionsContainer item xs={ 12 } sm={ 3 } md={ 3 } className="from-learner">
                { isMobile && <Typography
                  paddingBottom={ "8px" }
                  paddingTop={ "20px" }
                  width={ "100%" }
                  fontSize={ "0.875rem" } 
                  fontWeight={ "700" } 
                  lineHeight={ "1.3125rem" }
                  color={ theme.customColors.textPrimary }>
                  { t("sent") }
                </Typography> }
                <FileActionsWrapper sx={ { marginTop: "auto" } }>
                  { !!exerciseRow?.revisions[0]?.assignment_id?.label && currentExerciseIdToReview !== exerciseRow.exercise_id.id  && <FileActions sx={ {
                    marginTop: "auto"
                  } }>
                    <Typography className="file-name"
                      fontSize={ isMobile ? "0.75rem" : "1rem" } 
                      fontWeight={ "500" } 
                      lineHeight={ "1.3125rem" }
                      color={ theme.customColors.textPrimaryCta }>
                      { exerciseRow?.revisions[0]?.assignment_id?.label }
                    </Typography>
                    <IconsContainer direction="row" gap={ theme.spacing(1) }>
                      <Icon
                        className="icon-download icon"
                        icon={ "download" }
                        width={ isMobile ? "14px" : "24px" }
                        height={ isMobile ? "14px" : "24px" }
                        color={ theme.customColors.systemPrimary02 }

                        onClick={ ()=> mutateDownloadFile({ exerciseId: exerciseRow?.exercise_id.id, mediaId: exerciseRow?.revisions[0]?.assignment_id?.id as number, type: "ASSIGNMENT" }) }
                      />
                      { exerciseRow?.revisions[0]?.outcome !== "DA_RIVEDERE" &&
                        <div className={ 
                          `  icon-wrap ${exerciseRow?.revisions[0]?.outcome === "CHIUSO" || 
                        exerciseRow?.revisions[0]?.outcome === "IN_REVISIONE" ? 
                            "disabled" : 
                            ""}` }
                        >
                          <Icon
                            className="icon-replace icon"
                            icon={ "Icons_Replace" }
                            width={ isMobile ? "14px" : "24px" }
                            height={ isMobile ? "14px" : "24px" }
                            color={ exerciseRow?.revisions[0]?.outcome === "CHIUSO" 
                            || exerciseRow?.revisions[0]?.outcome === "IN_REVISIONE" ? 
                              theme.customColors.systemDisabled : 
                              theme.customColors.systemPrimary02 }

                            onClick={ () => {
                              setAddFileFromReplace(true);
                              setIsOpenModalUpload(true);
                              setCurrentExerciseId(exerciseRow?.exercise_id.id);
                            } }
                          />
                        </div> }
                      { exerciseRow?.revisions[0]?.outcome === "DA_RIVEDERE" && <Icon
                        className="icon-plus icon"
                        icon={ "plus" }
                        width={ isMobile ? "14px" : "24px" }
                        height={ isMobile ? "14px" : "24px" }
                        color={ theme.customColors.systemPrimary02 }

                        onClick={ () => {
                          setCurrentExerciseIdToReview(exerciseRow.exercise_id.id);
                        } }
                      /> }
                      <div className={ `icon-wrap ${exerciseRow?.revisions[0]?.outcome === "CHIUSO" || exerciseRow?.revisions[0]?.outcome === "IN_REVISIONE" ? "disabled" : ""}` }>
                        <Icon
                          className="icons_trash icon"
                          icon={ "trash" }
                          width={ isMobile ? "14px" : "24px" }
                          height={ isMobile ? "14px" : "24px" }
                          color={ exerciseRow?.revisions[0]?.outcome === "CHIUSO" || exerciseRow?.revisions[0]?.outcome === "IN_REVISIONE" ? theme.customColors.systemDisabled : theme.customColors.systemPrimary02 }
                          onClick={ () => setShowConfirmModal(exerciseRow) }
                        />
                      </div>
                    </IconsContainer>
                  </FileActions> }
                  { (!exerciseRow?.revisions[0]?.assignment_id || currentExerciseIdToReview === exerciseRow.exercise_id.id ) && <FileUpload gap={ "8px" }               
                    onClick={ () => {
                      setAddFileFromReplace(false);
                      setIsOpenModalUpload(true);
                      setCurrentExerciseId(exerciseRow?.exercise_id.id);
                    } }>
                    <Icon
                      className="icon-upload icon"
                      icon={ "Icons_upload" }
                      width={ "24px" }
                      height={ "24px" }
                      color={ theme.customColors.systemPrimary02 }
                    />
                    <Typography className="file-info"
                      fontSize={ isMobile ? "0.75rem" : "1rem" } 
                      fontWeight={ "500" } 
                      lineHeight={ "0.875rem" }
                      color={ theme.customColors.textPrimaryCta }>
                      { t("upload_file") }
                    </Typography>
                  </FileUpload> }
                  { !!exerciseRow?.revisions[0]?.assignment_id?.label && currentExerciseIdToReview !== exerciseRow.exercise_id.id  && <Typography className="file-info" 
                    visibility={ exerciseRow?.revisions[0]?.assignment_size ? "visible" : "hidden" }
                    fontSize={ "0.75rem" } 
                    fontWeight={ "400" } 
                    lineHeight={ "0.875rem" }
                    color={ theme.customColors.textPrimary }>
                    { formatBytes(Number(exerciseRow?.revisions[0]?.assignment_size)) }
                  </Typography> }
                </FileActionsWrapper>
              </FileActionsContainer>

              <FileActionsContainer item xs={ 12 } sm={ 3 } md={ 3 } className="review">
                { isMobile && <Typography
                  width={ "100%" }
                  paddingBottom={ "8px" }
                  paddingTop={ "20px" }
                  fontSize={ "0.875rem" } 
                  fontWeight={ "700" } 
                  lineHeight={ "1.3125rem" }
                  color={ theme.customColors.textPrimary }>
                  { t("review") }
                </Typography> }
                { exerciseRow?.revisions[0]?.correction_id?.label ? <FileActionsWrapper sx={ { marginTop: "auto" } }>
                  <FileActions sx={ {
                    marginTop: "auto"
                  } }>
                    <Typography className="file-name"
                      fontSize={ isMobile ? "0.75rem" : "1rem" } 
                      fontWeight={ "500" } 
                      lineHeight={ "1.3125rem" }
                      color={ theme.customColors.textPrimaryCta }>
                      { exerciseRow?.revisions[0]?.correction_id?.label }
                    </Typography>
                    <Icon
                      className="icon-download icon"
                      icon={ "download" }
                      width={ isMobile ? "14px" : "24px" }
                      height={ isMobile ? "14px" : "24px" }
                      color={ theme.customColors.systemPrimary02 }

                      onClick={ ()=> mutateDownloadFile({ exerciseId: exerciseRow?.exercise_id.id, mediaId: exerciseRow?.revisions[0]?.correction_id?.id as number, type: "CORRECTION" }) }
                    />
                  </FileActions>
                  <Typography className="file-info"
                    fontSize={ "0.75rem" } 
                    fontWeight={ "400" } 
                    lineHeight={ "0.875rem" }
                    color={ theme.customColors.textPrimary }
                    visibility={ exerciseRow?.revisions[0]?.correction_size ? "visible" : "hidden" }>
                    { formatBytes(Number(exerciseRow?.revisions[0]?.correction_size)) }
                  </Typography>
                </FileActionsWrapper> : "-" }
              </FileActionsContainer>
        
              <FileActionsContainer item xs={ 12 } sm={ 3 } md={ 3 }className="last-update">
                { isMobile && 
                  <Typography
                    paddingBottom={ "8px" }
                    paddingTop={ "20px" }
                    width={ "100%" }
                    fontSize={ "0.875rem" } 
                    fontWeight={ "700" } 
                    lineHeight={ "1.3125rem" }
                    color={ theme.customColors.textPrimary }>
                    { t("last_update") }
                  </Typography> }
                { lastUpdateDate ? 
                  <Avatar
                    { ...stringAvatar(updateUserInitials ?? "") }
                    alt={ "last update" }
                    src={ updateUserImg }
                    sx={ {
                      fontSize: "0.875rem",
                      height: theme.spacing(3),
                      marginRight: theme.spacing(1),
                      width: theme.spacing(3)
                    } }
                  >
                  </Avatar> : null 
                }
                <Typography className="date"
                  fontSize={ isMobile ? "0.6rem" : "1rem" } 
                  fontWeight={ "500" } 
                  lineHeight={ "1.3125rem" }
                  color={ theme.customColors.textPrimary }>
                  { lastUpdateDate }
                </Typography>
                { !!exerciseRow.revisions.length && !!exerciseRow.revisions.some(rev => !!rev.assignment_id) && <Icon 
                  className={ "icons_impostazioni icon" }
                  icon={ "Icons_impostazioni" } 
                  width={ isMobile ? "4px" : "6px" } 
                  color={ theme.customColors.systemPrimary01 } 
                  onClick={ () => {
                    setIsOpenModalHistory(true);
                    setCurrentExerciseId(exerciseRow?.exercise_id.id);
                  } }/> }
              </FileActionsContainer>
            </Grid>
          </ExercisesCardContainer>
        ); 
      }) }
      {
        isOpenModalHistory ? (
          <ModalFileHistory
            teachers={ teachers }
            editionId={ editionId }
            revisions={ exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.revisions as RevisionItem[] }
            teacherName={ exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.teacher_id.label as string }
            teacherImg={ teachers.find(teacher => teacher.id === exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.teacher_id.id)?.image }
            exerciseName={ exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.exercise_id.label as string }
            exerciseId={ exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.exercise_id.id as number }
            anchorEl={ document.body }
            anchorOrigin={ {
              horizontal: "center",
              vertical: "center"
            } }
            transformOrigin={ {
              horizontal: "center",
              vertical: "top"
            } }
            closeDrawer={ handleClose }
            onClose={  handleClose }
            open={ isOpenModalHistory }
          />
        ) : null
      }
      {
        isOpenModalUpload ? (
          <AddNewFileModal
            isReplace={ addFileFromReplace }
            revisionId={ exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.revisions[0]?.revision_id as number }
            editionId={ editionId }
            exerciseId={ exercises?.find(exercise => exercise.exercise_id.id === currentExerciseId)?.exercise_id.id as number }
            anchorEl={ document.body }
            anchorOrigin={ {
              horizontal: "center",
              vertical: "center"
            } }
            transformOrigin={ {
              horizontal: "center",
              vertical: "top"
            } }
            closeDrawer={ handleClose }
            onClose={  handleClose }
            open={ isOpenModalUpload }
          />
        ) : null
      }
      <DeleteConfirmContentModal
        closeDrawer={ ()=> setShowConfirmModal(null) }
        deleteId={ editionId }
        open={ !!showConfirmModal }
        data={ showConfirmModal }
      />
    </Box>
  );
}
