import {
  useQuery,
  useMutation,
  useQueryClient,
  type QueryKey
} from "@tanstack/react-query";
import {
  ERRORS
} from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type {
  LearningObject,
  MetaData
} from "../types";
import { getEnvironmentVariables } from "../utils/general";

type RatingStatistic = {
  objectStatistics: {
    averageRating: number
    learningObjectId: number
    learningObjectType: string
    numberOfComments: number
    numberOfFavourites: number
    numberOfRatings: number
    shares: number
  }
  userRating: {
    isFavouriteForLearner: 0 | 1
    lastRated: Date
    learnerId: number
    rating: number
  }
}

type RatingStatisticResponse = {
  corporateId: number
  domainId: number
  learnerObjectList: [RatingStatistic]
  totalNumberOfElements: number
  totalNumberOfPages: number
}

const { basePath } = getEnvironmentVariables();

const emptyRating: RatingStatistic = {
  objectStatistics: {
    averageRating: 0,
    learningObjectId: NaN,
    learningObjectType: "",
    numberOfComments: 0,
    numberOfFavourites: 0,
    numberOfRatings: 0,
    shares: 0
  },
  userRating: {
    isFavouriteForLearner: 0,
    lastRated: new Date(),
    learnerId: 0,
    rating: 0
  }
};

export function useRatingMutation({
  learningObjectId,
  learningObjectType,
  invalidateKeys=[]
} : {
  learningObjectId: string,
  learningObjectType: string,
  invalidateKeys?: Array<number | string>
}) {
  const queryClient = useQueryClient();
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const learnerId = useAuthStore(state => state.userData?.id_user) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  
  // const uri = `/social-rating/learner/${learnerId}/learning-object/${learningObjectId}/rating`;
  const params = `corporate-id=${corporateId}&domain-id=${initiativeId}&learning-object-type=${learningObjectType}`;

  const uriGet = "/social-rating/rating-statistics";
  const paramsGet =
  // eslint-disable-next-line max-len
  `corporate-id=${corporateId}&domain-id=${initiativeId}&learnerId=${learnerId}&learning-object-id=${learningObjectId}&learning-object-type=${learningObjectType}`;

  
  return useMutation({
    mutationFn: ({ rating } : { rating: number }) => fetcher(
      `${basePath}${getUri(learningObjectId)}?${params}`,
      {
        body: JSON.stringify({ "rating": rating }),
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken,

          "content-type":"application/json"
        },
        method: "POST"
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onMutate: async ({ rating }: { rating: number }) => {
      await queryClient.cancelQueries({
        queryKey: [sessionToken, uriGet, paramsGet, ...invalidateKeys]
      });

      const previousRating = queryClient.getQueryData(
        [sessionToken, uriGet, paramsGet, ...invalidateKeys]
      );

      queryClient.setQueryData(
        [sessionToken, uriGet, paramsGet, ...invalidateKeys],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (old: any) => ({
          ...old,
          userRating: {
            ...old.userRating,
            rating
          }
        })
      );

      return { previousRating };
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [sessionToken, uriGet, paramsGet, ...invalidateKeys]
      });
    }
  });

  function getUri(learningObjectId: string) {
    return `/social-rating/learner/${learnerId}/learning-object/${learningObjectId}/rating`;
  }
}

export function useRatingQuery({
  enabled=true,
  initialData,
  learningObjectId,
  learningObjectTypology,
  queryKey=[]
} : {
  enabled?: boolean,
  initialData?: RatingStatistic,
  learningObjectId: number,
  learningObjectTypology: string,
  queryKey?: Array<number | string>
}) {
  // function getLearningObjectType(learningObjectTypology: Level) {
  //   switch (learningObjectTypology) {
  //   case LEVEL.COURSE:
  //   case LEVEL.PATH:
  //     return learningObjectTypology?.toLowerCase();
  //   default:
  //     return "activity";
  //   }
  // }

  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const learnerId = useAuthStore(state => state.userData?.id_user) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();

  const uri = "/social-rating/rating-statistics";
  const params =
    // eslint-disable-next-line max-len
    `corporate-id=${corporateId}&domain-id=${initiativeId}&learnerId=${learnerId}&learning-object-id=${learningObjectId}&learning-object-type=${learningObjectTypology}`;
  
  return useQuery<
    unknown,
    unknown,
    RatingStatistic,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && idToken
      && sessionToken
      && corporateId
      && initiativeId
      && learnerId
      && learningObjectId
      && learningObjectTypology
    ),
    initialData,
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => (
        res.json()
      ))
      .then((data: RatingStatisticResponse) => (
        data?.learnerObjectList[0] ?? emptyRating
      ))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, params, ...queryKey]
  });
}

export function useTopRatedQuery({
  enabled=true,
  queryKey=[],
  pageNumber=0,
  pageSize=9,
  topics=""
} : {
  enabled?: boolean
  pageNumber?: number
  pageSize?: number
  queryKey?: Array<number | string>
  topics?: string
}) {
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const fetcher = fetcherFactory();

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/topRatedLearningObjects`;
  const params = `pageNumber=${pageNumber}&pageSize=${pageSize}${topics}`;
  
  return useQuery<
    unknown,
    unknown,
    LearningObject[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && idToken
      && sessionToken
      && corporateId
      && initiativeId
    ) && !isSeedingSession,
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      })
      .then((res) => (
        res.json()
      ))
      .then((data: { topRatedLearningObjects: LearningObject[] & { metadata: MetaData } }) => (
        (data.topRatedLearningObjects.length > 0) ? apiAdapter(data.topRatedLearningObjects) : emptyRating
      ))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, params, isSeedingSession, ...queryKey]
  });
}
