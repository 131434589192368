import { useTranslation } from "react-i18next";
import { ResumeButton } from "./CalendarCarouselCard.style";
import type { DayCourse } from "./CalendarCarouselCard.types";
import { useButtonStatusMap } from "../../hooks";
import { useNavigate } from "../../services";

export function ActionButton({ course } : { course: DayCourse}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // the useButtonStatusMap hook is used in many different components
  // I don't have time to check them all so I can't change it now ;_;
  const buttonTextMap = useButtonStatusMap({
    enrollType: "enrollType" in course ? course.enrollType ?? undefined : undefined,
    isCard: true,
    learningObjectType: "learningObjectType" in course ? course.learningObjectType ?? undefined : undefined,
    learningObjectTypology: "learningObjectTypology" in course ? course.learningObjectTypology ?? undefined : undefined,
    percentageOfCompletion: "percentageOfCompletion" in course ? course.percentageOfCompletion ?? undefined : undefined
  });
  const buttonText = course.dayCourseType !== "empty"
    ? course.status
      ? buttonTextMap[course.status]?.label
      : null
    : null;

  return course.dayCourseType !== "empty" ? (
    <ResumeButton
      onClick={ () => {
        if (buttonText == "start") {
          navigate(`/esplora/dettaglio/${course.id}/${course.learningObjectTypology}`);
        } else {
          navigate(`/per-te/dettaglio/${course.id}/${course.learningObjectTypology}`);
        }
      } }>
      { buttonText ? t(buttonText) : t("start") }
    </ResumeButton>
  ) : null;
}

