import {
  Divider,
  Grid,
  Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  lastDayOfMonth,
  startOfWeek
} from "date-fns";
import it from "date-fns/locale/it";
import {
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarArrow,
  CalendarContainerStack,
  CourseStack,
  CourseTypography,
  CurrentDayOfWeekTypography,
  CurrentDayTypography,
  DotSpan,
  SelectedDayTypography,
  StyledPickersDay
} from "./Calendar.style";
import { switchLocaleImport } from "./LocaleLogic";
import {
  ButtonLink,
  Icon,
  Link,
  Spinner
} from "../../components";
import {
  CONTENT_TYPE,
  PAGE_NAME
} from "../../consts";
import {
  useContentQuery,
  useStructuresQuery
} from "../../queries";
import { useNavigate } from "../../services";
import {
  useAuthStore,
  useLanguageStore
} from "../../stores";
import { useNavbarStore } from "../../stores/navbarStore";
import type {
  CalendarDateObject,
  DayToHighlight,
  JavaBasicDate
} from "../../types";
import {
  calendarTimeAsDateTime,
  contentIsExpiring,
  hasPassedCurrentDate,
  useDayColor,
  withPickersDayProps
} from "../../utils/calendar";
import { CustomRegex } from "../../utils/regex";

const contentTypeLabels = {
  ASYNC: "Digital",
  BLENDED: "Blended",
  SMART_LEARNING_SLOT: "Smart Learning",
  SYNC: "Live"
};

export function Calendar() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const language = useLanguageStore(state => state.language)?.id;
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [widgetSelectedDate, setWidgetSelectedDate] = useState(new Date());
  const [widgetSelectedMonth, setWidgetSelectedMonth] = useState(parseInt(format(new Date(), "MM")));
  const [widgetSelectedYear, setWidgetSelectedYear] = useState(parseInt(format(new Date(), "yyyy")));
  const locale = useDateFnsLocale({ localeCode: language || "it" }); //it come default, ma appena arriva la response dal servizio languages, si aggiorna con il primary_language

  const widgetSelected = format(widgetSelectedDate, "yyyy/MM/dd");
  const widgetStartDate =
    format(new Date(`${widgetSelectedYear}/${widgetSelectedMonth}/01`), "yyyy/MM/dd" );
  const widgetEndDate = format(
    lastDayOfMonth(new Date(`${widgetSelectedYear}/${widgetSelectedMonth}/01`)),
    "yyyy/MM/dd"
  );
  const currentDay = new Date();

  const { data: pageStructureWidgetAgenda } = useStructuresQuery({
    pageName: PAGE_NAME.WIDGET_AGENDA
  });
  const agendaContentsPath = pageStructureWidgetAgenda?.relativePaths?.find(
    (relativePath) => (relativePath.serviceType === "widgetCalendar")
  );
  const {
    data: learnerAgendaDaysWidget
    // isInitialLoading: learnerAgendaIsLoading
  } = useContentQuery<{ daysToHighlight: DayToHighlight[]}>({
    path: agendaContentsPath?.apiPath,

    // additional params
    endDate: widgetEndDate?.replaceAll("/","-"),
    selectedDate: widgetSelected?.replaceAll("/","-"),
    startDate: widgetStartDate?.replaceAll("/","-")
  });

  const dateNumbersToHighlight = learnerAgendaDaysWidget?.daysToHighlight
    // .filter((dayToHighlight) => (
    //   smartConfiguration?.smartLearning === true
    //   || (dayToHighlight.learningObjects && dayToHighlight.learningObjects.length > 0)
    // ))
    // .map((dayToHighlight) => dayToHighlight.day) ?? [];
    .flatMap((dayToHighlight) => {
      const hasLearningObject = dayToHighlight.learningObjects
        ? dayToHighlight.learningObjects.length > 0
        : false;
      if (smartConfiguration?.smartLearning === true || hasLearningObject) {
        return [dayToHighlight.day];
      } else {
        return [];
      }
    }) ?? [];

  const learningObjects: CalendarDateObject[] =
    learnerAgendaDaysWidget?.daysToHighlight
      ?.flatMap((dayToHighlight) => (
        dayToHighlight.learningObjects
          ?.map((learningObject) => ({
            date: [widgetSelectedYear, widgetSelectedMonth, dayToHighlight.day] as JavaBasicDate,
            dateObjectType: "learningObject" as const,
            endTime: learningObject.endTime, //"3 : 0"
            id: learningObject.id,
            learningObject,
            learningObjectType: learningObject.learningObjectType,
            smartLearning: null,
            startTime: learningObject.startTime, //"2 : 0"
            title: learningObject.title
          } satisfies CalendarDateObject)
          ) ?? []
      )) ?? [];


  const smartLearnings: CalendarDateObject[] =
    (smartConfiguration?.smartLearning === true) ? (
      learnerAgendaDaysWidget?.daysToHighlight
        ?.flatMap((dayToHighlight) => (
          dayToHighlight?.smartLearningSlots
            ?.map((smartLearning) => ({
              date: [widgetSelectedYear, widgetSelectedMonth, dayToHighlight.day] as JavaBasicDate,
              dateObjectType: "smartLearning" as const,
              endTime: smartLearning.endTime, //"3 : 0"
              id: smartLearning.id,
              learningObject: null,
              learningObjectType: CONTENT_TYPE.SMART_LEARNING_SLOT,
              smartLearning,
              startTime: smartLearning.startTime, //"2 : 0"
              title: t("learning_time")
            } satisfies CalendarDateObject)
            ) ?? []
        )) ?? []
    ) : [];

  const dateObjects =
    learningObjects?.concat(smartLearnings)
      .sort((dateObject1, dateObject2) => {
        if (
          dateObject1.dateObjectType === "learningObject"
          && dateObject2.dateObjectType === "smartLearning"
        ) {
          return -1;
        } else if (
          dateObject1.dateObjectType === "smartLearning"
          && dateObject2.dateObjectType === "learningObject"
        ) {
          return 1;
        } else if (
          dateObject1.dateObjectType === "learningObject"
          && dateObject2.dateObjectType === "learningObject"
        ) {
          const courseAIsExpiring = contentIsExpiring(dateObject1.learningObject, widgetSelectedDate);
          const courseBIsExpiring = contentIsExpiring(dateObject2.learningObject, widgetSelectedDate);

          if (courseAIsExpiring && !courseBIsExpiring) {
            return -1;
          } else if (!courseAIsExpiring && courseBIsExpiring) {
            return 1;
          } else { // same value
            return 0;
          }
        } else if (
          dateObject1.dateObjectType === "smartLearning"
          && dateObject2.dateObjectType === "smartLearning"
        ) {
          if (dateObject1.smartLearning.startTime < dateObject2.smartLearning.startTime) {
            return -1;
          } else if (dateObject1.smartLearning.startTime > dateObject1.smartLearning.startTime) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });

  // this could be extracted directly from the api response, but that would mean redoing the mappings as well
  const selectedCalendarDateObjects = dateObjects?.filter((dateObject) =>
    isSameDay(widgetSelectedDate, new Date(dateObject.date.join("/") ?? ""))
  );

  return smartConfiguration?.widgetCalendar === true ? (
    <CalendarContainerStack>
      <Stack direction="row">
        {
          isMobile ? (
            <Stack>
              <CurrentDayOfWeekTypography>
                { /* { format(currentDay, "EEEE", { locale.default }) } */ }
                { format(currentDay, "EEEE", { locale }) }
                { /* { new Intl.DateTimeFormat(language.id, { weekday: "long" }).format(new Date()) } */ }
              </CurrentDayOfWeekTypography>
              <CurrentDayTypography>
                { /* { format(currentDay, "dd", { locale.default }) } */ }
                { format(currentDay, "dd", { locale }) }
                { /* { new Intl.DateTimeFormat(language.id, { day: "numeric" }).format(new Date()) } */ }
              </CurrentDayTypography>
            </Stack>
          ) : null
        }
        { /* <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ it.default }> */ }
        <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ locale }>
          <DateCalendar
            disableHighlightToday={ true }
            onChange={ (newDate) => {
              setWidgetSelectedDate(newDate ?? new Date());
            } }
            onMonthChange={ (month) => {
              setWidgetSelectedDate(month);
              setWidgetSelectedMonth(parseInt(format(month, "MM", { locale })));

              setWidgetSelectedYear(parseInt(format(month, "yyyy")));
            } }
            onYearChange={ (year) => {
              setWidgetSelectedDate(year);
              setWidgetSelectedYear(parseInt(format(year, "yyyy")));
            } }
            // showDaysOutsideCurrentMonth={ false }
            slots={ {
              day: SeededCalendarDay({ dateNumbersToHighlight, dateObjects }),
              leftArrowIcon:
                () => <CalendarArrow icon={ "arrow_left_glow" } iconGlow={ "Icons_arrow_left_glow" }/>,
              rightArrowIcon:
                () => <CalendarArrow icon={ "Icons_arrow-right_glow" } iconGlow={ "arrow_right_filled_glow" }/>
            } }
            value={ widgetSelectedDate }
          />
        </LocalizationProvider>
      </Stack>
      <Divider sx={ { borderColor: theme.customColors.border } }/>
      <SelectedDayTypography>
        {
          isSameDay(widgetSelectedDate, new Date())
            ? t("today")
            // : format(widgetSelectedDate, "EEEE dd MMMM", { locale.default })
            : format(widgetSelectedDate, "EEEE dd MMMM", { locale })
            // : new Intl.DateTimeFormat(
            //   language.id,
            //   { weekday: "long", day: "numeric", month: "long" }
            // ).format(widgetSelectedDate)
        }
      </SelectedDayTypography>
      <Stack direction="row" spacing={ 2 } useFlexGap flexWrap="wrap">
        <>
          {
            (selectedCalendarDateObjects?.length === 0) ? (
              <CourseStack direction="row">
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={ {
                    backgroundColor: theme.customColors.backgroundSecondary,
                    borderColor: theme.customColors.borderTag,
                    borderRadius: "13px",
                    width: "0.131875rem"
                  } }
                />
                <Stack sx={ { paddingLeft: "0.5rem" } }>
                  {
                    (learnerAgendaDaysWidget === undefined) ? (
                      <Spinner
                        height="2.3125rem"
                        padding={ `0 0 0 ${theme.spacing(1)}` }
                        size={ 25 }
                        width="100%"
                      />
                    ) : (
                      <CourseTypography
                        sx={ {
                          fontSize: "0.75rem",
                          fontWeight: "500",
                          lineHeight: "2.0625rem"
                        } }
                      >
                        { t("no_scheduled_event") }
                      </CourseTypography>
                    )
                  }
                </Stack>
              </CourseStack>
            ) : null
          }
          {
            selectedCalendarDateObjects?.map((dateObject) => {
              const endTime =
              calendarTimeAsDateTime({ date: dateObject.date, time: dateObject.endTime }).toISOString();
              const startTime =
              calendarTimeAsDateTime({ date: dateObject.date, time: dateObject.startTime }).toISOString();

              // const dateObjectTime = calendarTimeAsDateTime({ date: dateObject.date, time: dateObject.startTime });
              // const hasPassed = isAfter(new Date(), dateObjectTime);
              const hasPassed = hasPassedCurrentDate({ date: dateObject.date, time: dateObject.startTime });

              return (
                <CourseStack
                  key={ `course--${dateObject.id}` }
                  direction="row"
                  onClick={ () => {
                    (dateObject.dateObjectType === "learningObject")
                    // eslint-disable-next-line max-len
                      ? navigate(`/per-te/dettaglio/${dateObject.learningObject.id}/${dateObject.learningObject.learningObjectTypology}`)
                      : (dateObject.dateObjectType === "smartLearning") && !hasPassed
                        ? navigate(`/smart-learning?endTime=${endTime}&startTime=${startTime}`)
                        : undefined;
                  } }
                  sx={ {
                    cursor:
                      dateObject.dateObjectType === "smartLearning" && hasPassed
                        ? "default"
                        : undefined
                  } }
                >
                  <Divider
                    orientation="vertical"
                    sx={ {
                      backgroundColor: courseColor(dateObject),
                      borderColor: courseColor(dateObject),
                      borderRadius: "13px",
                      width: "0.131875rem"
                    } }
                    flexItem
                  />
                  {
                    dateObject.learningObjectType ? (
                      <Stack sx={ { paddingLeft: "0.5rem" } }>
                        <CourseTypography
                          sx={ { color: courseColor(dateObject) } }
                        >
                          { contentTypeLabels[dateObject.learningObjectType] }
                        </CourseTypography>
                        <CourseTypography
                          fontWeight={ 500 }
                        >
                          { dateObject.title }
                        </CourseTypography>
                        {
                          (
                            dateObject.learningObjectType === CONTENT_TYPE.SMART_LEARNING_SLOT
                          || dateObject.learningObjectType === CONTENT_TYPE.SYNC
                          )
                            ? (
                              <CourseTypography>
                                {
                                  courseTime(
                                    dateObject.startTime ?? "00:00",
                                    dateObject.endTime ?? "00:00"
                                  )
                                }
                              </CourseTypography>
                            ) : null
                        }
                      </Stack>
                    ) : null
                  }
                </CourseStack>
              );
            }
            )
          }
        </>
      </Stack>
      <Grid container direction="row-reverse">
        <Link href="/agenda"
          onClick={ ()=> {
            setNavbarItem("AGENDA");
          } }
        >
          <ButtonLink
            sx={ {
              fontSize: "1.125rem",
              fontWeight: "500",
              gap:"0.625rem",
              paddingRight: 0,
              [theme.breakpoints.down("sm")]:{
                fontSize: "0.875rem",
                lineHeight: "1.125rem",
                paddingBottom: "0",
                paddingTop: "16px"

              },

              ".icon path":{
                fill:theme.palette.primary?.main
              }
            } }
          >
            { t("go_to_agenda") }
            <Icon icon={ "arrow_right_horizontal" } size={ "20px" } />
          </ButtonLink>
        </Link>
      </Grid>
    </CalendarContainerStack>
  ) : null;

  function courseTime(startTime: string, endTime: string) {
    const splittedStartTime = CustomRegex.matchTimeOfDay(startTime);
    const newStartTime = `${splittedStartTime?.[1].padStart(2, "0")}:${splittedStartTime?.[2].padStart(2, "0")}`;
    const splittedEndTime = CustomRegex.matchTimeOfDay(endTime);
    const newEndTime = `${splittedEndTime?.[1].padStart(2, "0")}:${splittedEndTime?.[2].padStart(2, "0")}`;

    return `${newStartTime} - ${newEndTime}`;
  }

  function courseColor(dateObject: CalendarDateObject) {
    if (dateObject.dateObjectType === "learningObject") {
      if (
        dateObject.learningObject.isMandatory
        && (
          dateObject.learningObjectType == "ASYNC"
          || dateObject.learningObjectType == "BLENDED"
        )
        && isSameDay(
          new Date(dateObject.date.join("/")),
          new Date(dateObject.learningObject.expirationDate.join("/"))
        )
      ) {
        return theme.customColors.textWarning;
      } else {
        return theme.customColors.textMandatory;
      }
    } else if (dateObject.dateObjectType === "smartLearning") {
      return theme.customColors.systemSecondary04;
    }
  }
}

function CalendarDay({
  dateNumbersToHighlight,
  dateObjects,
  day,
  outsideCurrentMonth,
  selected,
  ...props
} : PickersDayProps<Date> & {
  dateObjects: CalendarDateObject[],
  dateNumbersToHighlight: number[]
}) {
  const language = useLanguageStore(state => state.language)?.id;

  const thisDayCourses = dateObjects?.filter((course) =>
    isSameDay(day, new Date(course?.date.join("/")))
  );
  const activityColor = useDayColor(thisDayCourses);
  const locale = useDateFnsLocale({ localeCode: language || "it" }); //it come default, ma appena arriva la response dal servizio languages, si aggiorna con il primary_language

  const currentDay = new Date();
  // const start = startOfWeek(currentDay, { locale.default });
  const start = startOfWeek(currentDay, { locale });
  // const end = endOfWeek(currentDay, { locale.default });
  const end = endOfWeek(currentDay, { locale });
  const dayIsBetween =
    isSameDay(day, new Date(start))
    || isSameDay(day, new Date(end))
    || (isAfter(day, start) && isBefore(day, end));

  return <StyledPickersDay
    disableMargin={ true }
    outsideCurrentMonth={ outsideCurrentMonth }
    day={ day }
    $activityColor={ activityColor }
    $inBetween={ dayIsBetween }
    $sameDay= { isSameDay(day, currentDay) }
    $selected={ selected ?? false }
    { ...props }
  >
    <span
      style={ {
        height: "1.5625rem",
        lineHeight: "1.5625rem",
        width: "1.5625rem"
      } }
    >
      { /* { format(day, "d", { locale.default }) } */ }
      { format(day, "d", { locale }) }
      { /* { new Intl.DateTimeFormat(language.id, { day: "numeric" }).format(new Date()) } */ }
    </span>
    {
      (
        dateNumbersToHighlight.includes(day.getDate())
        && !outsideCurrentMonth
        && !selected
      ) ? <DotSpan /> : null
    }
  </StyledPickersDay>;
}

/**
 * SeededCalendarDay is a "useless" wrapper, meant to appease typescript and the mui calendar prop types
 * @param CalendarDay props
 * @returns a CalendarDay component, typed with additional mui calendar props
 */
const SeededCalendarDay = (props: {
  dateObjects: CalendarDateObject[],
  dateNumbersToHighlight: number[]
}) => withPickersDayProps<{
  dateObjects: CalendarDateObject[],
  dateNumbersToHighlight: number[]
}>({
  component: CalendarDay,
  ...props
});

function useDateFnsLocale({ localeCode } : { localeCode: string}) {
  const [locale, setLocale] = useState<Locale>(it);

  useEffect(() => {
    (async () => {
      try {
        const newLocale = await switchLocaleImport(localeCode);
        setLocale(newLocale.default);
      } catch(err) {
        // eslint-disable-next-line no-console
        console.error("import date-fns locale:", err);
      }
    })();
  }, [localeCode]);

  return locale;
}

// function endOfWeek(date: Date) {
//   return new Date(
//     date.getFullYear(),
//     date.getMonth(),
//     date.getDate() + (7 - date.getDay()), // go to day 7
//     23,
//     59,
//     59,
//     999
//   );
// }

// function startOfWeek(date: Date) {
//   return new Date(
//     date.getFullYear(),
//     date.getMonth(),
//     date.getDate() - (date.getDay() - 1) // to day 1
//   );
// }
