
import {
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { BadgeProps } from "./Badge.types";

export function Badge({
  iconURL,
  small,
  loading
} : BadgeProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={ theme.spacing(2) }
      sx={ {
        "img":{
          height: (isMobile || small) ? "36px" : "80px",
          width: (isMobile || small) ? "36px" : "80px"
        },
        "opacity": loading ? "0.1" : "1",
        "transition": "0.2s opacity"
      } }
    >
      <Box>
        <img src={ iconURL } alt={ "" }/>
      </Box>

    </Box>
  );
}
