import {
  Box,
  Divider,
  InputAdornment,
  SelectChangeEvent,
  Stack,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  format,
  isAfter,
  isBefore,
  isEqual,
  lastDayOfMonth
} from "date-fns";
import it from "date-fns/locale/it";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AvailableHoursTypography,
  CalendarArrow,
  DescriptionTypography,
  FormLabelTypography,
  FormSubLabelTypography,
  OpenCalendarArrow,
  TitleTypography
} from "./CalendarCard.style";
import { SelectTime } from "./SmartLearningSelect.component";
import { ButtonText } from "../../components/Button";
import { Icon } from "../../components/Icon";
import { Spinner } from "../../components/Spinner";
import { useContentQuery } from "../../queries";
import {
  type SlotsResponseType,
  useSlotsQuery,
  useSlotsPutQuery
} from "../../queries/smartLearning";
import type { 
  DayToHighlight, 
  LearningObject, 
  MetaData
} from "../../types";

export function SmartLearningModalEditContent({
  end,
  onClose,
  selectedDate,
  slotId,
  start
}: {
    end: string
    onClose?: () => void
    selectedDate: Date
    slotId: number
    start: string
}) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selected, setSelected] = useState<Date>(selectedDate);
  const [isOpen, setIsOpen] = useState(false);

  const [fromTime, setFromTime] = useState<string>(start);
  const [toTime, setToTime] = useState<string>(end);
  const [startDate, setStartDate] = useState<string | undefined>(
    format(selected, `yyyy-MM-dd'T'${fromTime}:00.000xxx`)
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    format(selected, `yyyy-MM-dd'T'${toTime}:00.000xxx`)
  );
  const [conflictText, setConflictText] = useState(false);


  const { data } = useSlotsQuery({
    enabled: true,
    endDate: format(selected, "yyyy-MM-dd'T23:59:00.000Z"),
    month: format(selected, "MM"),
    startDate: format(selected, "yyyy-MM-dd'T00:00:00.000Z"),
    year: format(selected, "yyyy")
  });

  
  //calculate available hours
  let availableHours: number | undefined = undefined;
  if(data?.hours_available != undefined) {
    const splitStart = start.split(":");
    const startMinutes = Number(splitStart[0]) * 60 + Number(splitStart[1]);
    const splitEnd = end.split(":");
    const endMinutes = Number(splitEnd[0]) * 60 + Number(splitEnd[1]);
    const diff = endMinutes - startMinutes;
    availableHours = (diff + (data?.hours_available * 60)) / 60;
  }

  //learnerAgenda
  const learnerAgendaEndDate = format(lastDayOfMonth(selected), "yyyy-MM-dd");
  const learnerAgendaSelected = format(new Date(selected ?? ""), "yyyy-MM-dd");
  const learnerAgendaStartDate = format(selected, "yyyy-MM-01");
  const { 
    data: learnerAgendaData
  } = useContentQuery<{
    daysToHighlight: DayToHighlight[],
    learningObjects: LearningObject[],
    metadata: MetaData
  }>({
    path: "/learning-catalogue/{corporateId}/{initiativeId}/learnerAgenda?selectedDate={selectedDate}&startDate={startDate}&endDate={endDate}&pageNumber={pageNumber}&pageSize={pageSize}",
    queryKey: [learnerAgendaSelected, learnerAgendaStartDate, learnerAgendaEndDate],

    // additional params
    endDate: learnerAgendaEndDate,
    selectedDate: learnerAgendaSelected,
    startDate: learnerAgendaStartDate
  });

  const { 
    mutate: mutateSlots,
    isLoading,
    isError,
    isIdle
  } = useSlotsPutQuery();

  function startDateSelectOptions(
    data: SlotsResponseType): Array<{timeOption: string, 
    bookable:boolean, 
    disabled: boolean}> {
    return timeSelectOptions().map((timeOption) => {
      let disabled = false;
      let bookable = true;
      const timezone = format(selected, "xxx");
      const timeOptionDate = new Date(format(selected, `yyyy-MM-dd'T'${timeOption}:00.000${timezone}`));
      data.slots.forEach((slot) => {
        if(slot.id != slotId) {
          const slotStartDate = new Date(slot.start_date);
          const slotEndDate = new Date(slot.end_date);
          if(
            isEqual(timeOptionDate, slotStartDate) ||
          (isAfter(timeOptionDate, slotStartDate) && isBefore(timeOptionDate, slotEndDate))
          ) {
            disabled = true;
          }
        }
      });
      learnerAgendaData?.learningObjects.forEach((learningObject) => {
        if(learningObject.startTime != null && learningObject.endTime != null) {
          /* eslint-disable max-len */
          const startTimeFormatted = `${learningObject.startTime.split(":")[0].padStart(2, "0")}:${learningObject.startTime.split(":")[1].padStart(2, "0")}`;
          const endTimeFormatted = `${learningObject.endTime.split(":")[0].padStart(2, "0")}:${learningObject.endTime.split(":")[1].padStart(2, "0")}`;
          const learningObjectStartDate = new Date(format(selected, `yyyy-MM-dd'T'${startTimeFormatted}:00.000${timezone}`));
          const learningObjectEndDate = new Date(format(selected, `yyyy-MM-dd'T'${endTimeFormatted}:00.000${timezone}`));
          /* eslint-enable max-len */
          if(
            isEqual(timeOptionDate, learningObjectStartDate) ||
            (isAfter(timeOptionDate, learningObjectStartDate) && isBefore(timeOptionDate, learningObjectEndDate))
          ) {
            bookable = false;
          }
        }
      });
      if(isBefore(timeOptionDate, new Date())) {
        disabled = true;
      }
      return {
        bookable:bookable,
        disabled: disabled,
        timeOption: timeOption
      };
    });
  }

  function endDateSelectOptions(data: SlotsResponseType): Array<{
    timeOption: string, 
    bookable:boolean, 
    disabled: boolean}> {
    return timeSelectOptions().map((timeOption) => {
      let disabled = false;
      let bookable = true;

      const timezone = format(selected, "xxx");
      const timeOptionDate = new Date(format(selected, `yyyy-MM-dd'T'${timeOption}:00.000${timezone}`));
      const selectedStartDate = new Date(startDate ?? "");
      if(isEqual(timeOptionDate, selectedStartDate) || isBefore(timeOptionDate, selectedStartDate)) {
        disabled = true;
      }
      if(
        availableHours != undefined && 
        timeOptionDate.getTime() - selectedStartDate.getTime() > availableHours * 60 * 60 * 1000
      ) {
        disabled = true;
      }
      if(!disabled) {
        data.slots.forEach((slot) => {
          if(slot.id != slotId) {
            const slotStartDate = new Date(slot.start_date);
            if(
              isBefore(selectedStartDate, slotStartDate) &&
            isAfter(timeOptionDate, slotStartDate)
            ) {
              disabled = true;
            }
          }
        });

        learnerAgendaData?.learningObjects.forEach((learningObject) => {
          if(learningObject.startTime != null && learningObject.endTime != null) {
            /* eslint-disable max-len */
            const startTimeFormatted = `${learningObject.startTime.split(":")[0].padStart(2, "0")}:${learningObject.startTime.split(":")[1].padStart(2, "0")}`;
            const learningObjectStartDate = new Date(format(selected, `yyyy-MM-dd'T'${startTimeFormatted}:00.000${timezone}`));
            /* eslint-enable max-len */
            if(
              isBefore(selectedStartDate, learningObjectStartDate) &&
              isAfter(timeOptionDate, learningObjectStartDate)
            ) {
              bookable = false;
            }
          }
        });
      }
      if(isBefore(timeOptionDate, new Date())) {
        disabled = true;
      }
      return {
        bookable:bookable,
        disabled: disabled,
        timeOption: timeOption
      };
    });
  }

  const startOptions = data ? startDateSelectOptions(data) : [];
  const endOptions = data ? endDateSelectOptions(data) : [];
  const { t } = useTranslation();

  return (
    <>
      { isIdle ?
        <Stack
          gap={ "24px" }
          height={ "100%" }
          divider={ <Divider
            sx={ {
              background:theme.customColors.border,
              height:"2px",
              width: "auto"
            } }
          /> }>
          <Stack
            gap={ "24px" }
            sx={ {
              "& .icon": {
                alignSelf: "flex-end"
              }
            } }
          >
            <Icon
              icon={ "close" }
              size={ "19px" }
              cursor="pointer"
              color={ theme.palette.primary?.main }
              onClick={ ()=> {if(onClose){onClose()}} }
            />
            <Stack
              gap={ "8px" }
            >
              <TitleTypography>
                { t("edit_slot") }
              </TitleTypography>
              <DescriptionTypography>
                { t("complete_agenda") }
              </DescriptionTypography>
            </Stack>
          </Stack>
          <Stack
            gap={ "40.5px" }
          >
            <Stack
              gap={ "21.5px" }
            >
              <Stack
                direction={ "row" }
                gap={ "20px" }
                alignItems={ "center" }
              >
                <FormLabelTypography>{ "Data:" }</FormLabelTypography>
                <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ it }>
                  <DesktopDatePicker
                    className="datepicker_wrapper"
                    disabled={ availableHours != undefined && availableHours == 0 }
                    disableHighlightToday={ true }
                    open={ isOpen }
                    closeOnSelect={ true }
                    format={ "dd MMMM yyyy" }
                    defaultValue={ selected }
                    disablePast={ true }
                    onChange={ (newDate) => {
                      setConflictText(false);
                      setSelected(newDate ?? new Date());
                      setFromTime("0");
                      setStartDate(undefined);
                      setToTime("0");
                      setEndDate(undefined);
                    } }
                    onYearChange={ (newDate) => {
                      setConflictText(false);
                      setSelected(newDate ?? new Date());
                      setFromTime("0");
                      setStartDate(undefined);
                      setToTime("0");
                      setEndDate(undefined);
                    } }
                    onClose={ () => {
                      setIsOpen(false);
                    } }
                    onOpen={ () => {
                      setIsOpen(true);
                    } }
                    slots={ {
                      openPickerIcon: openPickerIconComponent,

                      leftArrowIcon: () => (
                        <CalendarArrow icon={ "arrow_left_glow" } iconGlow={ "Icons_arrow_left_glow" }/>
                      ),
                      rightArrowIcon: () => (
                        <CalendarArrow icon={ "Icons_arrow-right_glow" }  iconGlow={ "arrow_right_filled_glow" }/>
                      )
                    } }
                    slotProps={ {
                      openPickerIcon: {
                        isOpen: isOpen
                      },
                      popper: {
                        className: "datepicker_popper",
                        placement: "bottom-start",
                        sx: {
                          margin: "4px 0 !important",
                          width: isMobile ? "auto" : "343px",

                          "& .MuiPickersPopper-paper": {
                            backgroundColor: theme.customColors.backgroundSecondary,
                            borderRadius: "2px",
                            padding: "16px 14px"
                          },

                          ".MuiDateCalendar-root": {
                            margin: "0 0 0 auto",
                            maxHeight: "100%",

                            [theme.breakpoints.down("sm")]: {
                              margin: "0 auto",
                              width: "auto !important"
                            }
                          },

                          "& .MuiDateCalendar-root .MuiPickersCalendarHeader-labelContainer": {
                            "button": {
                              display: "none"
                            }
                          },
                          ".MuiPickersCalendarHeader-root": {
                            margin: "0",
                            marginBottom: "0.65rem",
                            marginLeft: "1rem",
                            padding: "0",
                            position: "relative"
                          },

                          ".MuiPickersCalendarHeader-labelContainer": {
                            display: "block",
                            margin:"0 25%",
                            position: "absolute",
                            textAlign: "center",
                            width: "50%"
                          },

                          ".MuiPickersCalendarHeader-label": {
                            color: theme.customColors.backgroundPrimary,
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "16.94px"
                          },

                          ".MuiPickersArrowSwitcher-root": {
                            //justifyContent: "space-between",
                            gap: "4rem",
                            justifyContent: "space-around",
                            width: "100%"
                          },

                          ".MuiDayCalendar-weekDayLabel": {
                            color: theme.customColors.textPrimaryCta,
                            fontSize: "12px",
                            fontWeight: "700",
                            height: "24.12px"
                          },

                          // ".MuiPickersDay-hiddenDaySpacingFiller": {
                          //   height: "1.5625rem",
                          //   width: "1.5625rem"
                          // },

                          ".MuiDayCalendar-slideTransition": {
                            // minHeight: "15rem"
                          },

                          ".MuiYearCalendar-root": {
                            height: "240px",
                            width: "100%"
                          },

                          ".MuiDayCalendar-monthContainer": {
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.4rem",
                            padding: theme.spacing(0.8, 0, 1.5, 0)
                          },

                          ".MuiDayCalendar-header": {
                            justifyContent: "space-around",
                            padding: theme.spacing(0, 1)
                          },

                          ".MuiDayCalendar-weekContainer": {
                            justifyContent: "space-around",
                            padding: theme.spacing(0, 1)
                          },

                          "& .MuiPickersDay-root": {
                            color: theme.customColors.backgroundPrimary,
                            fontSize: "12px",
                            fontWeight: "700",
                            height: "25px",
                            lineHeight: "24.12px",
                            width: "25px",

                            "&.Mui-disabled": {
                              color: "#00000033"
                            },
                            // "&.Mui-selected": {
                            //   backgroundColor: theme.customColors.backgroundPrimaryCta
                            // },
                            "&:focus": {
                              backgroundColor: theme.customColors.backgroundPrimaryCta
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: `${theme.customColors.backgroundPrimaryCta}50`
                            }
                          },

                          "& .MuiPickersYear-yearButton": {
                            color: theme.customColors.backgroundPrimary,
                            fontSize: "12px",
                            fontWeight: "700",
                            height: "25px",
                            lineHeight: "24.12px",

                            "&.Mui-disabled": {
                              color: "#00000033"
                            },
                            "&:focus": {
                              backgroundColor: theme.customColors.backgroundPrimaryCta
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: `${theme.customColors.backgroundPrimaryCta}50`
                            }
                          }
                        }
                      },
                      textField: {
                        className: "datepicker_textfield",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon
                                icon={ "Icons_scadenza-e-edizioni" }
                                size={ "17px" }
                                color={ theme.customColors.backgroundPrimaryLighter }
                              />
                            </InputAdornment>
                          )
                        },
                        sx: {
                          flexGrow: "1",

                          "& .MuiInputBase-root": {
                            backgroundColor: theme.customColors.backgroundSecondary,
                            borderRadius: "2px",
                            color: theme.customColors.textPrimaryAlternative,
                            fontSize: "14px",
                            fontWeight: "400",
                            height: "40px",
                            lineHeight: "18px",
                            paddingLeft: "16px",
                            paddingRight: "16px"
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none"
                          },

                          "& .MuiButtonBase-root": {
                            marginRight: "0 !important",
                            padding: "0 !important"
                          }
                        }
                      }
                    } }
                  />
                </LocalizationProvider>
              </Stack>
              <Stack
                gap={ "8px" }
              >
                <Stack
                  direction={ "row" }
                  gap={ "20px" }
                  alignItems={ "flex-end" }
                >
                  <FormLabelTypography sx={ { paddingBottom: "10px" } }>{ "Orario:" }</FormLabelTypography>
                  <Stack
                    direction={ "row" }
                    gap={ "7px" }
                    flexGrow={ "1" }
                  >
                    <Stack
                      gap={ "4px" }
                      flexGrow={ "1" }
                    >
                      <FormSubLabelTypography>{ "Dalle:" }</FormSubLabelTypography>
                      <SelectTime
                        value={ fromTime }
                        onChange={ (event: SelectChangeEvent) => {
                          setConflictText(false);
                          setFromTime(event.target.value);
                          const timezone = format(selected, "xxx");
                          setStartDate(format(selected, `yyyy-MM-dd'T'${event.target.value}:00.000${timezone}`));
                          setToTime("0");
                          setEndDate(undefined);
                        } }
                        options={ startOptions }
                        disabled={ availableHours != undefined && availableHours == 0 }
                      />
                    </Stack>
                    <Stack
                      gap={ "4px" }
                      flexGrow={ "1" }
                    >
                      <FormSubLabelTypography>{ "Alle:" }</FormSubLabelTypography>
                      <SelectTime
                        value={ toTime }
                        onChange={ (event: SelectChangeEvent) => {
                          setConflictText(false);
                          setToTime(event.target.value);
                          const timezone = format(selected, "xxx");
                          setEndDate(format(selected, `yyyy-MM-dd'T'${event.target.value}:00.000${timezone}`));
                        } }
                        options={ endOptions }
                        disabled={ availableHours != undefined && availableHours == 0 }
                      />
                    </Stack>
                  </Stack>
                </Stack>
                { availableHours != undefined ? 
                  availableHours > 0 ? (
                    <AvailableHoursTypography
                      sx={ { marginLeft: "71px" } }
                    >
                      { t("hours_available", { count: availableHours }) }
                    </AvailableHoursTypography>
                  ) : (
                    <Stack
                      direction={ "row" }
                      gap={ "10px" }
                      sx={ { marginLeft: "71px" } }
                    > 
                      <Box>
                        <Icon
                          icon={ "Icons_info" }
                          size={ "19px" }
                          color={ theme.customColors.textWarning }
                        />
                      </Box>
                      <AvailableHoursTypography
                        sx={ { color: theme.customColors.textWarning } }
                      >
                        { t("no_available_hours") }
                      </AvailableHoursTypography>
                    </Stack>
                  ) : (
                    <AvailableHoursTypography/>
                  )
                }

                {
                  conflictText ? 

                    <Stack
                      direction={ "row" }
                      gap={ "10px" }
                      sx={ { marginLeft: "71px" } }
                    > 
                      <Box>
                        <Icon
                          icon={ "Icons_info" }
                          size={ "19px" }
                          color={ theme.customColors.textWarning }
                        />
                      </Box>
                      <AvailableHoursTypography
                        sx={ { color: theme.customColors.textWarning } }
                      >
                        { t("reevaluate_available_hours") }
                      </AvailableHoursTypography>
                    </Stack> : null
                }
              </Stack>
            </Stack>
            <Stack
              direction={ "row" }
              gap={ "16px" }
              justifyContent={ "center" }
            >
              <ButtonText
                sx={ {
                  backgroundColor: theme.customColors.drawerWidgetBg,
                  border: "1px solid",
                  borderColor: theme.customColors.textPrimaryCta,
                  borderRadius: "2px",
                  color: theme.customColors.textPrimaryCta,
                  fontSize: "16px",
                  fontWeight: "700",
                  height: "40px",
                  lineHeight: "21px",
                  padding: "9.5px 16px",

                  "&.Mui-disabled": {
                    backgroundColor: theme.customColors.drawerWidgetBg,
                    borderColor: theme.customColors.border
                  }
                } }
                disabled={ startDate && endDate ? false : true }
                onClick={ ()=> {
                  setConflictText(false);
                  setFromTime("0");
                  setToTime("0");
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setSelected(new Date());
                } }
              >
                { t("delete_slot") }
              </ButtonText>
              <ButtonText
                sx={ {
                  borderRadius: "2px",
                  fontSize: "16px",
                  fontWeight: "700",
                  height: "40px",
                  lineHeight: "21px",
                  padding: "9.5px 16px"
                } }
                disabled={ startDate && endDate ? false : true }
                onClick={ ()=> {
                  const timeStartSelected = startOptions.find(item => item.timeOption === fromTime);
                  const timeEndSelected  = endOptions.find(item => item.timeOption === toTime);
                  if(endDate && startDate) {
                    if(
                      timeStartSelected?.bookable && timeEndSelected?.bookable
                    ){
                      mutateSlots({
                        endDate: convertToGMT(endDate),
                        slotId: slotId,
                        startDate: convertToGMT(startDate)
                      });
                    } else {
                      setConflictText(true);
                    }
                  
                  }
                } }
              >
                { t("apply") }
              </ButtonText>
            </Stack>
          </Stack>
        </Stack> : 
        <Stack
          gap={ "24px" }
          divider={ <Divider
            sx={ {
              background:theme.customColors.border,
              height:"2px",
              width: "auto"
            } }
          /> }>
          <Stack
            gap={ "24px" }
            sx={ {
              "& .icon": {
                alignSelf: "flex-end"
              }
            } }
          >
            <Icon
              icon={ "close" }
              size={ "19px" }
              cursor="pointer"
              color={ theme.palette.primary?.main }
              onClick={ ()=> {if(onClose){onClose()}} }
            />
            {
              isLoading ?
                <Stack
                  gap={ "8px" }
                >
                  <Spinner
                    size={ 200 }
                    thickness={ 3 }
                  />
                </Stack> : 
                isError ?
                  <Stack
                    gap={ "8px" }
                  >
                    <TitleTypography>
                      { t("error_message") }
                    </TitleTypography>
                    { /* <DescriptionTypography>
                      { "messaggio di errore" }
                    </DescriptionTypography> */ }
                  </Stack> : 
                  <Stack
                    gap={ "8px" }
                  >
                    <TitleTypography>
                      { t("slot_edited") }
                    </TitleTypography>
                  </Stack>
            }
          </Stack>
          
        </Stack>
      }
    </>
  );
  
  function timeSelectOptions() {
    return [
      "00:00","00:30","01:00","01:30","02:00","02:30",
      "03:00","03:30","04:00","04:30","05:00","05:30",
      "06:00","06:30","07:00","07:30","08:00","08:30",
      "09:00","09:30","10:00","10:30","11:00","11:30",
      "12:00","12:30","13:00","13:30","14:00","14:30",
      "15:00","15:30","16:00","16:30","17:00","17:30",
      "18:00","18:30","19:00","19:30","20:00","20:30",
      "21:00","21:30","22:00","22:30","23:00","23:30",
      "23:59"
    ];
  }
}

function convertToGMT(date: string) {
  const dateObj = new Date(date);
  return dateObj.toISOString();
}

const openPickerIconComponent = ({ isOpen }: {isOpen: boolean}) => (
  <OpenCalendarArrow
    isOpen={ isOpen }
  />
);
