import { BoxProps,Stack, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "../../components/Link";


export const StyledPreferenceStack = styled(Stack)(({ theme }) => ({
  backdropFilter:"blur(20px)",
  background: alpha(theme.customColors.backgroundPrimaryLighter,0.25),
  borderRadius: "8px",
  padding: "24px",
  
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px"
  }
  // gap: theme.spacing(1),
  // padding: theme.spacing(2)

  // [`> :not(${PreferenceTypography})`]: {
  //   padding: padding ?? theme.spacing(1)
  // }
}));

export const StyledGoalsButton = styled(Stack)(({ theme }) => ({
  alignItems:"center",
  border:`1px solid ${theme.customColors.accentVariantB}`,
  borderRadius:theme.spacing(1), 
  boxShadow:`0px 0px 8px 0px ${theme.customColors.accentVariantB}`,
  flexDirection:"row",
  gap:theme.spacing(1),
  justifyContent:"space-between",
  maxWidth:"100%",
  padding:theme.spacing(2),

  [`${theme.breakpoints.down("sm")}`]:{
    padding:theme.spacing(1, 2),
    width: "240px",

    "p":{
      fontSize: "1rem"
    }
  }
}));

export const StyledChipsBox = styled(Stack)(({ theme }) => ({
  flexDirection:"row",
  flexWrap:"wrap",
  gap:theme.spacing(1),

  ".MuiBox-root":{
    backdropFilter:"blur(20px)",
    minHeight:"unset",
    "span":{
      fontWeight:700
    }
  },

  [`${theme.breakpoints.down("sm")}`]:{
    flexDirection:"colums",
    gap:theme.spacing(1),

    ".MuiBox-root":{
   
      "span":{
        fontSize:theme.spacing(1.5)
    
      }
    }
  }

}));

export const ModifyLink = styled(Link)<BoxProps>(({ theme })=> ({
  textDecoration: "underline", 

  "p":{
    color:theme.customColors.systemPrimary02,
    fontSize: "1rem",
    fontWeight:700,
    lineHeight:  (19 / 8)
  },

  [`${theme.breakpoints.down("sm")}`]:{
    lineHeight: "0.875rem",

    "p":{
      fontSize: "0.75rem",
      lineHeight: (7 / 6)  
    }
   
  }
}));


