import {
  Modal as MuiModal,
  Stack
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import { Spinner } from "../../components/Spinner";


const StyledModal = styled(MuiModal)(({ theme }) => {

  return {
    zIndex:theme.zIndex.snackbar,

    ".MuiModal-backdrop":{
      display:"none"
    }

  };});

export function LoginSpinner({
  open
}: {
  open: boolean
}) {
  const theme = useTheme();

  return (
    <StyledModal
      aria-labelledby="modal"
      open={ open }
    >
      <Stack
        alignItems="center"
        gap={ 4 }
        sx={ { 
          background:theme.customColors.backgroundPrimary,
          height:"100%",
          justifyContent:"center",

          "&:focus-visible": {
            outline: "none"
          } } }
      >
        <Stack
          alignItems="center"
          gap={ 1 }
        >
          <Spinner size={ 40 }/>
        </Stack>
    
      </Stack>
    </StyledModal>
  );
}
