import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  InputBase,
  Stack,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CreateContentModalBox = styled(Stack)(({ theme }) => ({
  alignItems:" flex-end",
  background: theme.linearGradients.gradientB,
  borderRadius:"8px",
  flexDirection: "column",
  gap: "24px",
  maxHeight:"calc(100% - 32px)",
  overflowX: "hidden",
  overflowY: "auto",
  padding:"24px 40px",
  
  [theme.breakpoints.down("sm")]:{  
    padding:" 16px 20px"
  },

  "&:hover": {
    "&::-webkit-scrollbar-thumb":{
      backgroundColor: theme.customColors.backgroundSecondary
    }
  },
  "::-webkit-scrollbar": {
    width: "6px"
  },
  "::-webkit-scrollbar-thumb":{
    backgroundColor: "transparent",
    borderRadius: "8px"
  },

  "& .editor-container": {
    backgroundColor: theme.customColors.backgroundSecondary,
    borderRadius: "2px",
    color: "black",
    padding: "0 16px 16px"
  },

  "& .rdw-editor-toolbar": {
    border: "none",
    margin: "0px",
    padding: "16px 0px"
  },

  "& .rdw-option-wrapper": {
    border: "none"
  },

  "& .rdw-editor-main": {
    height: "200px",

    "&:hover": {
      "&::-webkit-scrollbar-thumb":{
        backgroundColor: theme.customColors.border
      }
    },
    "::-webkit-scrollbar": {
      width: "6px"
    },
    "::-webkit-scrollbar-thumb":{
      backgroundColor: "transparent",
      borderRadius: "8px"
    }
  },

  ".public-DraftStyleDefault-block": {
    margin: "0px"
  }

}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  width: "549px",

  [theme.breakpoints.down("sm")]:{  
    fontSize: "16px",
    lineHeight: "21px",
    width: "100%"
  }
}));

export const TextContent = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:"8px",
  textAlign: "center",

  [theme.breakpoints.down("sm")]:{  
    width: "100%"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "36px",
  textShadow:" 0px 0px 20px #000",
  width: "537px",

  [theme.breakpoints.down("sm")]:{  
    fontSize: "24px",
    lineHeight: "30px",
    width: "100%"
  }
}));

export const ForbiddenLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWarning,
  fontSize: "10px",
  fontWeight: "700"
}));

export const TitleInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: theme.customColors.backgroundSecondary,
    borderRadius:"2px",
    color: theme.customColors.textInput,
    gap:theme.spacing(1),
    padding: theme.spacing(1, 2),

    [theme.breakpoints.down("sm")]:{ 
      fontSize:"14px", 
      width: "100%"
    },

    "&::placeholder ":{
      color:theme.customColors.backgroundDisabled,
      fontFamily:"Inter",
      fontWeight:400,
      lineHeight: "21px",
      opacity:1,
      
      [theme.breakpoints.down("sm")]: {
        fontSize:"14px",
        lineHeight: "8px",
        width: "100%"
      }

    }
  },
  width: "100%"
}));

export const BodyInputBase = styled(TextField)<TextFieldProps & 
{ hasMinHeight?:boolean 
}>((
  { 
    hasMinHeight = true, 
    theme 
  }) => ({
  ".MuiInputBase-root":{
    border:0,
    borderRadius:"2px",
    height: hasMinHeight ? "200px" : undefined,
    maxHeight:!hasMinHeight ? "200px" : undefined,
    padding:0
  },
  
  "& .MuiInputBase-input": {
    backgroundColor: theme.customColors.backgroundSecondary,
    borderRadius:"2px",
    boxSizing:"border-box",
    color: theme.customColors.textInput,
    height: hasMinHeight ? "100% !important " : undefined,
    maxHeight: "100% !important",
    overflowY:"scroll !important",
    padding: theme.spacing(1, 2),
   
    [theme.breakpoints.down("sm")]:{ 
      fontSize:"14px", 
      width: "100%"
    },

    "&::placeholder ":{
      color:theme.customColors.backgroundDisabled,
      fontFamily:"Inter",
      FontSize:"14px",
      fontWeight:400,
      lineHeight: "21px",
      opacity:1,

      [theme.breakpoints.down("sm")]: {
        fontSize:"14px",
        width:"100%"
      }
    }
  },
  width: "100%"
}));

export const ContentBox = styled(Stack)(({ theme }) => ({
  display:"flex",

  [theme.breakpoints.down("sm")]:{ 
    width: "100%"
  }
}));

export const AttachmentsBox = styled(Stack)(({ theme }) => ({
  background:theme.linearGradients.gradientB,
  borderRadius:"4px",
  boxShadow: "50px 50px 80px 0px rgba(0, 0, 0, 0.40)",
  display:"flex",
  flexDirection:"row",
  gap: "24px",
  justifyContent:"space-between",
  marginBottom:"24px",
  marginTop: "16x",
  paddingRight:"16px",
  [theme.breakpoints.down("sm")]:{ 
    marginRight:theme.spacing(2) 
  }

}));

export const UploadAttachmentsBox = styled(Box)<
  BoxProps
  >(() => ({
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius:"4px 0px 0px 4px",
    height:"80px",
    position:"relative",
    width:"80px"
  }));

export const TagBox = styled(Stack)(() => ({
  alignItems:"center",
  background:"rgba(10, 13, 21, 0.60)",
  border: "1px solid #FFF",
  borderRadius:"4px",
  display:"flex",
  flexDirection:"row",
  gap:"8px",
  justifyContent:"space-between",
  padding: "8px",
  width: "auto" 
}));

export const InsertUrl = styled(Grid)<GridProps>(({ theme }) => ({
  background:theme.linearGradients.gradientB,
  borderRadius:"4px",
  boxShadow: "50px 50px 80px 0px rgba(0, 0, 0, 0.40)",
  flexDirection: "row",
  gap: "24px",
  padding:" 24px 40px",
  width:"auto"
}));

export const ResultsStack = styled(Stack)<{$isVisible?:boolean}>(({ theme, $isVisible=true }) => ({
  backgroundColor: theme.customColors.backgroundSecondary,
  borderRadius: $isVisible? "2px" :"10%",
  bottom: theme.spacing(-1),
  boxShadow:"2px 2px 16px rgba(0, 0, 0, 0.4)",
  gap: "8px",
  opacity:$isVisible ? 1 : 0,
  padding: "8px 16px",
  pointerEvents:$isVisible ? "auto" : "none",
  position: "absolute",
  transform: $isVisible ? "translateY(100%) scale(1)" : " translateY(100%) scale(0.5)",
  transition:  "all .2s linear" ,
  width: "88.35%",
  zIndex: $isVisible ?  theme.zIndex.mobileStepper : 0
}));

export const TagTypography = styled(Typography)<{$disabled: boolean}>(({ theme, $disabled }) => ({
  color: $disabled ? theme.customColors.textDisabled : theme.palette.common?.black,
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5"
}));

export const StyledError = styled(Box)(({ theme }) => ({
  alignItems:"center",
  alignSelf:"flex-start",
  justifyContent:"center",
  color:theme.customColors.systemSecondary03,
  display:"flex",
  gap:theme.spacing(2),
  maxWidth:"582px"
}));
