import { Box, Grid, Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeroBox = styled(Box)(() => ({
  margin:"0 auto",
  maxWidth: "100%"
}));

export const CalendarBox = styled(Grid)(({ theme }) => ({
  display:"flex",
  justifyContent:"flex-end",
  overflow:"hidden",
  padding:theme.spacing(5, 0 , 9),
  width:"100%",

  [theme.breakpoints.down("md")]: {
    justifyContent:"center"
  },

  [theme.breakpoints.down("sm")]: {
    justifyContent:"start",
    paddingBottom: "0",
    paddingTop: "32px"
  }
}));

export const CalendarCarouselBox = styled(Grid)(({ theme }) => ({
  display:"block",
  justifyContent:"flex-end",
  overflow:"hidden",
  padding:theme.spacing(5, 0 , 9, 2.5),
  width:"100%",

  "& .swiper-slide-next": {
    // "&:after": {
    content: "''",
    height: "100%",
    opacity: 0.4,
    pointerEvents: "none",
    position: "relative",
    top: 0,
    width: "100%"
    // }
  },

  "& .swiper-slide-prev": {
    // "&:after": {
    content: "''",
    height: "100%",
    opacity: 0.4,
    pointerEvents: "none",
    position: "relative",
    top: 0,
    width: "100%"
    // }
  },



  [theme.breakpoints.down("md")]: {
    justifyContent:"center"
  },

  [theme.breakpoints.down("sm")]: {
    padding:theme.spacing(0, 0 , 0, 0)
  },

  "& .swiper-pagination": {
    [theme.breakpoints.down("sm")]: {
      bottom: "1.75rem",
      position: "absolute"
    }
  },

  // ">div":{
  //   maxWidth:"550px"
  // },

  ".swiper":{
    margin:"0"
  }

}));

export const AgendaStack = styled(Stack)<
  StackProps & { src?: string }
  >(({ src, theme }) => {

    return {
      // eslint-disable-next-line max-len
      background: `${theme.linearGradients.gradientC}, url(${src})`,
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      backgroundRepeat:"no-repeat",
      backgroundSize:"cover",
      maxWidth: "100%"
    };
  });

