import { GridMain } from "../../components";

export function Collection() {
  return (
    <>
      <GridMain>
        <p>PERCORSO</p>
        <p>hero</p>
        <p>interaction</p>
      </GridMain>
    </>
  );
}
