import { SignupSummaryPreferences } from "../../components/SignupSummary";
import { 
  useGetLearnerGoalsQuery, 
  usePreferencesQuery } from "../../queries";
// import { useAuthStore } from "../../stores";

export function ContentPreferences(){
  const {
    data: preferences
  } = usePreferencesQuery({});
  const { data: userGoals } = useGetLearnerGoalsQuery();


  return (
    <SignupSummaryPreferences 
      isProfilePage={ true }
      formats={ preferences?.formats } 
      userGoals={ userGoals }
    />

  );
}
