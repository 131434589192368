import {
  StepConnector,
  Stepper,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  useEffect,
  useMemo
} from "react";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  useLocation
} from "react-router-dom";
import {
  OnBoardingStack,
  StyledStep
} from "./OnBoarding.styles";
import {
  Background,
  GuardedLink,
  Icon
} from "../../components";
import { useOnboardingSteps } from "../../hooks";
import {
  useGetLearnerGoalsQuery,
  usePreferencesQuery
} from "../../queries";

import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";
import { CustomRegex } from "../../utils/regex";

export function OnBoarding() {
  const{ pathname, state:routerState } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));


  const { data: preferences, isLoading: preferencesAreLoading } = usePreferencesQuery({});
  const { data: userGoals, isLoading: userGoalsIsLoading } = useGetLearnerGoalsQuery();

  const corporateInfo = useAuthStore(state => state.corporateInfo);

  const userInfo = useAuthStore(state => state.userInfo);
  const currentLocationStep = CustomRegex.matchDirectChild(pathname, "signup");
  const steps = useOnboardingSteps();

  const stepsLength = steps.length;

  const [stepNumber, previousLocation] = useMemo(() => {
    const currentLocation = CustomRegex.matchDirectChild(pathname, "signup");
    switch(currentLocation) {
    case "password":
      return [0, "/access/login"];
    case "profession": 
      return [1, ""];
    case "job":  
      return [1, "/signup/profession"];
    case "skills":
      return [1, "/signup/job" ];
    case "capabilities": 
      return [1,  corporateInfo?.onboarding_personal_job || corporateInfo?.onboarding_executive_job ? 
        "/signup/skills" : 
        "/signup/job"];
    case "preferences":
      return [stepsLength === 4 ? 2 : 1, corporateInfo?.onboarding_capability ? "/signup/capabilities" : 
        corporateInfo?.onboarding_personal_job || corporateInfo?.onboarding_executive_job ? "/signup/skills" : 
          "" ];
    case "summary":
      return [stepsLength === 4 ? 3 : 2, "/signup/preferences"];
    default:
      return [0, "/access/login"];
    }
  }, [
    corporateInfo?.onboarding_capability, 
    corporateInfo?.onboarding_executive_job, 
    corporateInfo?.onboarding_personal_job, 
    pathname, 
    stepsLength]);
  const { t } = useTranslation();



  useEffect(() => {
    if (preferencesAreLoading || userGoalsIsLoading) {
      return;
    } else if (location.pathname === "/signup") {
      if(Boolean(userInfo?.industry) && 
      Boolean(!userInfo?.profession ) && 
      (
        corporateInfo?.onboarding_executive_job ||
        corporateInfo?.onboarding_personal_job ||
        corporateInfo?.onboarding_capability 
      )
      ){
        navigate("/signup/profession",
          { state:{ ...routerState, 
            first:true,
            onBoardingPercentage:"0%"
          } }
        );
      }
      else if(Boolean(userInfo?.industry) && 
      Boolean(userInfo?.profession) && 
      Boolean(!userInfo?.job) && 
      (
        corporateInfo?.onboarding_executive_job || 
        corporateInfo?.onboarding_personal_job ||
        corporateInfo?.onboarding_capability 
      )
      ){
        navigate("/signup/job",
          { state:{ 
            ...routerState, 
            first:true,
            onBoardingPercentage:routerState?.onBoardingPercentage ?? "0%"
          } }
        );
      }
      else if(
        Boolean(userInfo?.industry) && 
        Boolean(userInfo?.profession) && 
        Boolean(userInfo?.job) && 
        ( corporateInfo?.onboarding_executive_job || 
          corporateInfo?.onboarding_personal_job) ){
        navigate("/signup/skills",
          { state:{ ...routerState, first:true,
            onBoardingPercentage:routerState?.onBoardingPercentage ?? "0%" } }
        );
      }
      else if(
        Boolean(userInfo?.industry) && 
        Boolean(userInfo?.profession) && 
        Boolean(userInfo?.job) &&  
        corporateInfo?.onboarding_capability 
      ){
        navigate("/signup/capabilities",
          { state:{ 
            ...routerState, 
            first:true ,
            onBoardingPercentage:routerState?.onBoardingPercentage ?? "0%" } }
        );
      }
      else if(
        !preferences || 
        !userGoals?.goal.plan
      ){
        navigate("/signup/preferences",
          { state:{ 
            ...routerState, 
            first:true,
            onBoardingPercentage:routerState?.onBoardingPercentage ?? "0%" } }
        );
      }
      else{
        navigate("/signup/summary",
          { state:{ 
            ...routerState, 
            first:true,
            onBoardingPercentage:routerState?.onBoardingPercentage ?? "0%"
          } }
        );
      }
      
    }
  }, [
    corporateInfo?.onboarding_capability, 
    corporateInfo?.onboarding_executive_job, 
    corporateInfo?.onboarding_personal_job, 
    routerState, 
    navigate, 
    preferences, 
    preferencesAreLoading, 
    userGoals?.goal.plan, 
    userGoalsIsLoading, 
    userInfo,
    userInfo?.industry, 
    userInfo?.job, 
    userInfo?.profession
  ]);

  return (
    <Background>
      <OnBoardingStack
        component="form"
        gap={  isMobile ? 2 : 3 }
        padding={ currentLocationStep === "skills" ?  theme.spacing(8,3) : 
          isMobile ? theme.spacing(2) : 
            isTablet ? theme.spacing(8,5) : 
              theme.spacing(8,15)  }
      >
        {
           
          <>
            {
              routerState?.first ? (
                <Stepper
                  connector={
                    <StepConnector
                      sx = { {
                        margin: theme.spacing(0, (isMobile ? 0.6 : 1))
                      } }
                    />
                  }
                  sx={ { width: "min(100%, 38rem)" } }
                >
                  {
                    steps.map((label, idx) => (
                      <StyledStep
                        active={ stepNumber === idx }
                        component="span"
                        key={ `step--${idx}` }
                      >
                        {
                          idx < stepNumber ? 

                            <Icon 
                              icon="Icons_modulo-completato" 
                              color={ theme.customColors.accentVariantB }
                              size={ 16 }
                            /> : null
                        }
                        { t(label) }
                      </StyledStep>
                    ))
                  }
                </Stepper>
              ) : null
            }
            {
             
              (  
                ( routerState?.first && !!previousLocation) ||
                (!routerState?.first)
              ) ? 
                <GuardedLink
                  alignSelf="flex-start"
                  color="info"
                  href={  routerState?.first ? previousLocation : "/profile" }
                  onClick={ ()=> {
                    return true;
                  } }
                  startIcon={
                    <Icon
                      color={ theme.customColors.systemPrimary01 }
                      icon="arrow_left"
                      size={ isMobile ? 12 : 20 }
                    />
                  }
                  variant="text"
                  state={ {
                    ...routerState
                  } }
                  sx={ {
                    fontSize: (isMobile ? "0.75rem" : "1.125rem"),
                    fontWeight: 400,
                    lineHeight: isMobile ? (11 / 6) : (11 / 9),
                    minWidth:"unset",
                    padding: 0
                  } }
                >
                  { t("back") }
                </GuardedLink> : 
                null
            }
 
            <Outlet />
          </>  
        }
      </OnBoardingStack>
    </Background>
  );
}
