import { styled } from "@mui/material/styles";
import { HTMLProps } from "react";

export const StyledCodeInput = styled("input")<
  HTMLProps<HTMLInputElement> & { boxSize?: string }
>(({ boxSize, theme }) => ({
  all: "unset", // reset input styles

  border: `${theme.spacing(0.2)} solid ${theme.customColors.borderTag}`,
  borderRadius: theme.spacing(0.4),
  fontSize: boxSize ? `calc(${boxSize} / 2)` : theme.spacing(3),
  textAlign: "center",

  height: boxSize ?? theme.spacing(6),
  width: boxSize ?? theme.spacing(6),

  "::placeholder": {
    color: theme.customColors.textPrimary

  },

  ":focus": {
    "::placeholder": {
      color: "transparent"
    }
  }
}));
