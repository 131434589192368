import { GridMain } from "../../components";
import { useAuthStore } from "../../stores";

export function Lesson() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  return (
    <GridMain>
      <>
        <p>ATTIVITÀ DIDATTICA</p>
        <p>hero</p>
        <p>interaction</p>
      </>
    </GridMain>
  );
}
