import { HeroBanner as HeroBannerComponent } from "./HeroBanner.component";
import type { HeroBannerProps } from "./HeroBanner.types";
import * as HeroBannerVariants from "./HeroBanner.variants";

export const DefaultHeroBanner = ({ ...props }:
  Partial<
  Extract<HeroBannerProps, { learningObjectTypology: "ACTIVITY"}>
> | (
  Pick<Extract<HeroBannerProps, { learningObjectTypology: "COURSE" | "PACKAGE"}>, "learningObjectTypology" | "percentageOfCompletion"> & 
  Partial<Omit<Extract<HeroBannerProps, { learningObjectTypology: "COURSE" | "PACKAGE"}>, "learningObjectTypology" | "percentageOfCompletion">>
)
) => (
  <HeroBannerComponent { ...{ ...HeroBannerVariants.Default, ...props } }/>
);

export const HeroBanner = HeroBannerComponent;
