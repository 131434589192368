import { Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { AgendaProps } from "./Agenda.types";
import { TypographyTitle  } from "../../styles/global_styles";

export function Agenda({
  steps,
  title,
  ...props
}: AgendaProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      display="flex"
      flexDirection="column"
      gap={ theme.spacing(3) }
      height="fit-content"
      sx={ {
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]:{
          gap: theme.spacing(2)
        }
      } }
      { ...props }
    >
      <TypographyTitle sx={ {
        paddingBottom: 0,
        textTransform: "capitalize"
      } }
      >
        { title } :
      </TypographyTitle>
      {
        steps.map((step, index)=> (
          <Stack
            alignItems="center"
            direction="row"
            gap={ theme.spacing(3) }
            height="fit-content"
            key={ `step-item-${index}` }
            sx={ {
              ".step-icon": {
                border: "1px solid",
                borderColor: theme.palette.primary?.main,
                borderRadius: "50%",
                boxShadow: `0px 0px 4px ${theme.palette.primary?.main}`,
                position: "relative",

                height: "20px",
                width: "20px",

                "&:before": {
                  background:theme.palette.primary?.main,
                  borderRadius: "50px",
                  boxShadow: `0px 0px 4px ${theme.palette.primary?.main}`,
                  content: "''",
                  margin: "auto",
                  position: "absolute",

                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,

                  height: "6px",
                  width: "6px"

                },

                "&.dashed": {
                  borderStyle:"dashed",

                  "&:before": {
                    content:"none"
                  }
                }
              },
              [theme.breakpoints.down("sm")]:{
                fontSize: "0.875rem",
                marginBottom: "5px"
              }
            } }>
            <div
              className={
                step.item ?.toLowerCase() === "pausa" ||  step.item?.toLowerCase() === "break"
                  ? "step-icon dashed"
                  : "step-icon"
              }
            />
            {
              step.item?.toLowerCase() === "break" ||
              step.item ?.toLowerCase() === "pausa"
                ? t("break") :
                step.item
            }
          </Stack>
        ))
      }
    </Grid>
  );
}
