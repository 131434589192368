import {
  Box,
  BoxProps
} from "@mui/material";

import { styled } from "@mui/material/styles";
export const cardHeight = 243; //px
const cardHeightSmall = 177; //px
export const cardWidth = 431; //px
export const cardWidthSmall = 315; //px

export const TitleSkeleton = styled(Box)<
  BoxProps
  >(({ theme }) => ({
    "@keyframes opacityAnimation" :{
      "0%": {
        opacity: 1
      },
      "50%": {
        opacity: 0
      }
    },
    animation:  "2s opacityAnimation linear infinite",
    background: `${theme.linearGradients.gradientDAlternative10}`,
    backgroundSize: "200% 100%",
    borderRadius:"8px",
    display: "flex",
    height: "41px",
    marginBottom: "30px",
    width: "33%"

  }));

export const CarouselSkeletonContainer = styled(Box)<
BoxProps & { $small?: boolean}
>(({ $small }) => {
  const heightCountainer = $small ? "auto" : "385px";
  return {
    ".container": {
      display: "flex",
      gap: "30px"
    },
    height: heightCountainer
  };
});

export const CardSkeleton = styled(Box)<
BoxProps & { $small?: boolean}
>(({ $small, theme }) => {

  const slideBoxHeight = $small ? cardHeightSmall : cardHeight;
  const slideBoxWidth = $small ? cardWidthSmall : cardWidth;
  return {
    ".category ": {
      animation:  "2s opacityAnimation linear infinite",
      background: `${theme.linearGradients.gradientDAlternative11}`,
      borderRadius:"8px",
      display: "inline-block",
      heigth: "24px"
    },
    ".title": {
      animation:  "2s opacityAnimation linear infinite",
      background: `${theme.linearGradients.gradientDAlternative11}`,
      borderRadius:"8px",
      display: "inline-block",
      heigth: "30px"
    },
    background: `${theme.linearGradients.gradientDAlternative10}`,
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "column",
    height: slideBoxHeight,
    justifyContent: "flex-end",
    padding:theme.spacing(0, 5,3),
    position: "relative",
    width: slideBoxWidth
    
  };
});
    


export const CardSkeletonCategory = styled(Box)(({ theme }) => ({
  animation:  "2s opacityAnimation linear infinite",
  background: `${theme.linearGradients.gradientDAlternative11}`,
  borderRadius:"8px",
  display: "inline-block",
  height: "25px",
  marginBottom: "20px"

}));
export const CardSkeletonTitle = styled(Box)(({ theme }) => ({
  animation:  "2s opacityAnimation linear infinite",
  background: `${theme.linearGradients.gradientDAlternative11}`,
  borderRadius:"8px",
  display: "inline-block",
  height: "30px"

}));
    


export const CardSkeletonTop = styled(Box)(({ theme }) => ({
  animation:  "2s opacityAnimation linear infinite",
  background: `${theme.linearGradients.gradientDAlternative11}`,
  borderRadius:"8px",
  height: "25px",
  position: "absolute",
  right: "20px",
  top: "20px",
  width: "50px"

}));

export const CardSkeletonBottom = styled(Box)(() => ({
  display: "flex",
  marginTop: "20px"

}));
export const CardSkeletonBottom1 = styled(Box)(({ theme }) => ({
  animation:  "2s opacityAnimation linear infinite",
  background: `${theme.linearGradients.gradientDAlternative11}`,
  borderRadius:"8px",
  display: "inline-block",
  height: "25px",
  marginRight: "10px",
  width: "50px"

}));
export const CardSkeletonBottom2 = styled(Box)(({ theme }) => ({
  animation:  "2s opacityAnimation linear infinite",
  background: `${theme.linearGradients.gradientDAlternative11}`,
  borderRadius:"8px",
  display: "inline-block",
  height: "25px",
  width: "100px"

}));
    
