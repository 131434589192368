/* eslint-disable max-len */
import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  format
} from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { 
  ExercisesCardContainer, 
  FileActions, 
  FileActionsContainer, 
  FileActionsWrapper, 
  IconsContainer, 
  RowsWrapper } from "./ExercisesCardTable.style";
import { ExerciseRevisionsCardTableProps } from "./ExercisesRevisionsCardTable.types";
import { formatBytes } from "./FormatBytes";
import { getLastUpdateDate } from "./GetLastUpdateUtil";
import { useGetProfileImageQuery } from "../../queries";
import { useDownloadExercise } from "../../queries/exercises";
import { useAuthStore } from "../../stores";
import type { RevisionItem, UserData } from "../../types";
import { Icon } from "../Icon";

export function ExercisesCardRevisionsTable(
  {
    revisions,
    exerciseName,
    editionId,
    exerciseId,
    teacherName,
    teachers,
    teacherImg
  }: ExerciseRevisionsCardTableProps
) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userData = useAuthStore(state => state.userData);
  const { data: profileImage  } = useGetProfileImageQuery({});

  const { mutate: mutateDownloadFile, data: file } = useDownloadExercise(editionId);

  useEffect(() => {
    if(file?.public_url) {
      var element = document.createElement("a");
      element.setAttribute("href", file?.public_url);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }, [file]);

  function getUserAvatar(userData: UserData | null) {
    return {
      initials: `${userData?.name?.replaceAll(" ", "")} ${userData?.surname}`,
      src:  profileImage || undefined
    };
  }

  const userAvatar = getUserAvatar(userData);

  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  }

  return revisions.length ? (
    <>
      <Box className="teacher-avatar" 
        sx={ {
          display: "flex",
          marginBlock: "32px"
        } }>
        <Avatar
          alt={ "teacher" }
          src={ teacherImg }
          sx={ {
            fontSize: "0.875rem",
            height: theme.spacing(3),
            marginRight: theme.spacing(1),
            width: theme.spacing(3)
          } }
        >
          { teacherName
            ?.split(" ")
            ?.slice(0, 2)
            ?.map((slice) => slice[0]) }
        </Avatar>
        <Typography
          fontSize={ "1rem" } 
          fontWeight={ "500" } 
          lineHeight={ "1.3125rem" }
          color={ theme.customColors.textPrimary }>
          { exerciseName }
        </Typography> 
      </Box>
      <Box           
        sx={ { 
          borderBottom: `1px solid ${theme.customColors.border}`,
          padding: theme.spacing(2,0)
        } }>
        <Grid
          sx={ {
            alignItems: "center",
            display: "flex"
          } }
          container 
          columnSpacing={ { md: 5, sm: 2, xs: 1 } } >
          <Grid item xs={ 4 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("sent") }
            </Typography>
          </Grid>
          <Grid item xs={ 4 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("review") }
            </Typography>
          </Grid>
          <Grid item xs={ 4 }>
            <Typography
              fontSize={ isMobile ? "0.75rem" : "1.25rem" } 
              fontWeight={ "500" } 
              lineHeight={ "1.3125rem" }
              color={ theme.customColors.textPrimary }>
              { t("last_update") }
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <RowsWrapper>
        { !!revisions.length && revisions?.map((revisionRow: RevisionItem, index: number) => {
          const lastUpdateDate = getLastUpdateDate(
            revisionRow?.assignment_last_update_date,
            null,
            revisionRow?.correction_last_update_date);
          const idUserUpdate = revisionRow?.updated_by;
          const updateUserImg = teachers.find(teacher => teacher.id === idUserUpdate) ?  teachers.find(teacher => teacher.id === idUserUpdate)?.image :
            userAvatar.src;
          const updateUserInitials = teachers?.find(teacher => teacher.id === idUserUpdate) ?  teachers.find(teacher => teacher.id === idUserUpdate)?.completeName :
            userAvatar.initials;

          return revisionRow?.assignment_id ? (
            <ExercisesCardContainer key={ `revision_${index}` } $isModal={ true }>
              <Grid className="cards-grid" container columnSpacing={ { md: 5, sm: 2,  xs: 1 } }>
                { !!revisionRow && !!revisionRow.revision_id && <FileActionsContainer item xs={ 4 } className="from-learner">
                  <FileActionsWrapper>
                    <Typography className="send-date"
                      fontSize={ "0.75rem" } 
                      fontWeight={ "400" } 
                      lineHeight={ "0.875rem" }
                      color={ theme.customColors.textPrimary }>
                      { format(new Date(revisionRow.assignment_last_update_date as string), "yyyy-MM-dd") }
                    </Typography>
                    <FileActions>
                      <Typography className="file-name"
                        fontSize={ isMobile ? "0.75rem" : "1rem" } 
                        fontWeight={ "500" } 
                        lineHeight={ "1.3125rem" }
                        color={ theme.customColors.textPrimaryCta }>
                        { revisionRow?.assignment_id?.label }
                      </Typography>
                      <IconsContainer direction="row" gap={ theme.spacing(1) }>
                        <Icon
                          className="icon-download icon"
                          icon={ "download" }
                          width={ isMobile ? "14px" : "24px" }
                          height={ isMobile ? "14px" : "24px" }
                          color={ theme.customColors.systemPrimary02 }

                          onClick={ ()=> mutateDownloadFile({ exerciseId: exerciseId, mediaId: revisionRow?.assignment_id?.id as number, type: "ASSIGNMENT" }) }
                        />
                      </IconsContainer>
                    </FileActions>
                    <Typography className="file-info" 
                      fontSize={ "0.75rem" } 
                      fontWeight={ "400" } 
                      lineHeight={ "0.875rem" }
                      color={ theme.customColors.textPrimary }
                      visibility={ revisionRow?.assignment_size ? "visible" : "hidden" }>
                      { formatBytes(Number(revisionRow?.assignment_size)) }
                    </Typography>
                  </FileActionsWrapper>
                </FileActionsContainer> }

                <FileActionsContainer item xs={ 4 } className="review">
                  { revisionRow?.correction_id?.label ? <FileActionsWrapper>
                    <Typography className="send-date"
                      fontSize={ "0.75rem" } 
                      fontWeight={ "400" } 
                      lineHeight={ "0.875rem" }
                      color={ theme.customColors.textPrimary }>
                      { format(new Date(revisionRow?.correction_last_update_date as string), "yyyy-MM-dd" ) }
                    </Typography>
                    <FileActions>
                      <Typography className="file-name"
                        fontSize={ isMobile ? "0.75rem" : "1rem" } 
                        fontWeight={ "500" } 
                        lineHeight={ "1.3125rem" }
                        color={ theme.customColors.textPrimaryCta }>
                        { revisionRow?.correction_id?.label }
                      </Typography>
                      <Icon
                        className="icon-download icon"
                        icon={ "download" }
                        width={ isMobile ? "14px" : "24px" }
                        height={ isMobile ? "14px" : "24px" }
                        color={ theme.customColors.systemPrimary02 }

                        onClick={ ()=> mutateDownloadFile({ exerciseId: exerciseId, mediaId: revisionRow?.correction_id?.id as number, type: "CORRECTION" }) }
                      />
                    </FileActions>
                    <Typography className="file-info"
                      fontSize={ "0.75rem" } 
                      fontWeight={ "400" } 
                      lineHeight={ "0.875rem" }
                      color={ theme.customColors.textPrimary }
                      visibility={ revisionRow?.correction_size ? "visible" : "hidden" }>
                      { formatBytes(Number(revisionRow?.correction_size)) }
                    </Typography>
                  </FileActionsWrapper> : "-" }
                </FileActionsContainer>
        
                <FileActionsContainer item xs={ 4 } className="last-update">
                  { lastUpdateDate ? <Avatar
                    { ...stringAvatar(updateUserInitials ?? "") }
                    alt={ "last update" }
                    src={ updateUserImg }
                    sx={ {
                      fontSize: "0.875rem",
                      height: theme.spacing(3),
                      marginRight: theme.spacing(1),
                      width: theme.spacing(3)
                    } }
                  >
                  </Avatar> : "-" }
                  <Typography className="date"
                    fontSize={ isMobile ? "0.6rem" : "1rem" } 
                    fontWeight={ "500" } 
                    lineHeight={ "1.3125rem" }
                    color={ theme.customColors.textPrimary }>
                    { lastUpdateDate }
                  </Typography>
                </FileActionsContainer>
              </Grid>
            </ExercisesCardContainer>
          ) : null; 
        }) }
      </RowsWrapper>
    </>
  ) : null;
}
