import { Stack, styled } from "@mui/material";

export const GoalSelectionGroup = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px !important",
  width: "100%"
}));

export const GoalButton = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.customColors.borderTag}`,
  borderRadius: "8px", 
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  maxHeight: "52px",
  padding: "16px",
  width: "100%",

  "&.selected": {
    border: `1px solid ${theme.customColors.borderSecondaryComplete}`,
    boxShadow: `0px 0px 8px 0px ${theme.customColors.borderSecondaryComplete}`
  },

  ".label-plan": {
    color: theme.customColors.textPrimary,
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "21px",
    textTransform: "capitalize"
  },

  ".label-hours": {
    color: theme.customColors.textPrimary,
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14px",

    [theme.breakpoints.down("sm")]: {
      lineHeight: "21px"
    }
  }
}));
