export const style = {
  boxShadow: 24,
  left: "50%",
  p: 4,
  position: "absolute" as "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "100vw",
    sm: "70vw"
  }
};
