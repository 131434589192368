import {
  Box,
  // BoxProps,
  Grid,
  GridProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const PopUpLoginBox = styled(Grid)<GridProps>(({ theme }) => ({
  alignItems:"flex-end",
  background: theme.linearGradients.gradientB,
  borderRadius:"4px",
  color:theme.customColors.textPrimary,
  display:"inline-flex",
  flexDirection: "column",
  gap:theme.spacing(3),

  ".icon":{
    cursor:"pointer",
    margin:"0 0 0 auto",
    transform: "rotate(90deg)",

    "path":{
      fill:theme.palette.primary?.main
    }
  },

  ".MuiSvgIcon-root ": {
    color:theme.palette.primary?.main,
    cursor:"pointer",
    margin: "0 0 0 auto"
  },

  [theme.breakpoints.down("sm")]:{
    gap:"0.5rem",
    padding: "16px 20px",

    ".icon":{
      margin:"0 0 0 auto"
    }
  }
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "center",
  width: "549px",
  [theme.breakpoints.down("sm")]:{
    fontSize: "0.75rem"
  }
}));


export const TextContent = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:"1rem",

  [theme.breakpoints.down("sm")]:{
    gap: "0.5rem"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "36px",
  textAlign: "center",
  textShadow:" 0px 0px 20px 0px #000",
  width: "550px",
  [theme.breakpoints.down("sm")]:{
    fontSize: "1rem"
  }
}));
