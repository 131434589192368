
import { Grid, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ConversationItem, TopicItem } from "types";
import {
  StyledChatBot,
  StyledChatBotInputGrid,
  StyledChatBotInput,
  StyledAvatar,
  StyledChatGrid,
  StyledTitle,
  StyledChatGridTexts
} from "./ChatBot.styles";
import avatarIcon from "../../assets/avatar.svg";
import { ButtonText } from "../../components/Button";
import { ChipChatBot } from "../../components/ChatBotChip";
import { Icon } from "../../components/Icon/Icon.component";
import { Spinner } from "../../components/Spinner";
import { useGetChatBotQuery, useGetProfileImageQuery } from "../../queries";


export function ChatBot({ onClose }:{onClose:() => void}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [conversation, setConversation] = useState<ConversationItem[]>([]);

  const [question, setQuestion] = useState<string>("");
  const [bestAnswers, setBestAnswers] = useState<TopicItem[]>([]);
  const [sendQuestion, setSendQuestion] = useState(false);
  const [enableButton, setEnableButton] = useState(true);
  const [topic, setTopic] = useState("");

  const chatBotChipsRef = useRef<HTMLDivElement | null>(null);
  const { data:chatBotRes, isSuccess, isFetching, fetchStatus, status } = useGetChatBotQuery({
    enabled: !!question && sendQuestion,
    question: question,
    topic: topic ? topic : ""
  });

  const { data: profileImage  } = useGetProfileImageQuery({});

  useEffect(()=> {
    if(chatBotRes){
      const questionObj = {
        content: question,
        role: "user"
      } as ConversationItem;

      const answerObj = {
        content: chatBotRes.answer.text,
        role: "assistant"
      } as ConversationItem;

      const bestAnswers = chatBotRes.sources;

      setBestAnswers(bestAnswers);

      setTopic(chatBotRes?.answer?.topic);

      setConversation([...conversation, questionObj, answerObj]);

      if(isSuccess){
        setSendQuestion(false);
        setQuestion("");
        setEnableButton(true);
        setTimeout(()=> {
          chatBotChipsRef.current?.scrollIntoView({ behavior: "smooth" });
        });
      }
    }
  },[chatBotRes, conversation, isSuccess, question]);

  return (
    <StyledChatBot>
      < StyledAvatar
        src={ avatarIcon }
      />

      <Grid
        alignItems={ "center" }
        display={ "flex" }
        gap={ 1 }
        justifyContent={ "space-between" }
        maxWidth={ "180px" }
        marginLeft={ "auto" }
        paddingRight={ "6px" }
      >
        <Grid
          paddingBottom={ "8px" }
        >
          <StyledTitle>
            { t("chatBot_title") }
          </StyledTitle>

          <StyledTitle>
            { t("chatBot_title_1") }
          </StyledTitle>
        </Grid>
        <ButtonText
          sx={ {
            borderRadius:"8px",
            height:"fit-content",
            minWidth:"unset",
            padding:1
          } }
          onClick={
            ()=>{
              onClose();}  }
        >
          <Icon
            icon={ "close" }
            size={ 19 }
            color={ theme.customColors.systemSecondary05 }
          />
        </ButtonText>
      </Grid>
      <StyledChatGrid>
        <StyledChatGridTexts>
          <ChipChatBot
            isFirst={ true }
            orientation="left"
            text={ t("chatBot_text") }
            image={ avatarIcon }
            date={ "ora" }
          />
          {
            conversation && conversation.length > 0 ?
              conversation.map((item, index) => {

                return  (
                  <>
                    <ChipChatBot
                      key={ `item-question-${index}` }
                      isFirst={ false }
                      orientation={ item.role === "user" ? "right" : "left" }
                      text={ item.content }
                      image={ item.role === "user" ? profileImage : avatarIcon }
                      date={ "ora" }
                      ref={ chatBotChipsRef }
                      topics={ bestAnswers }
                    />
                  </>
                );
              }) :  null
          }
          { isFetching && fetchStatus !== "idle" && <Spinner size={ 50 } /> }
          { status === "error" &&
            <Typography
              color={ theme.customColors.textPrimary }
              paddingBottom={ theme.spacing(1) }>
              { t("chatbot_error") }
            </Typography> }
        </StyledChatGridTexts>
        <StyledChatBotInputGrid>
          <StyledChatBotInput
            value={ question }
            placeholder={ t("chatBot_placeholder") }
            name={ "answer" }
            onChange={ (changeEvent) => {
              setQuestion(changeEvent.currentTarget.value);
              setSendQuestion(false);
            } }
            onKeyUp={ (keyboardEvent) => {
              if (keyboardEvent.code === "Enter") {
                sendQuestionText();
              }
              else {
                setSendQuestion(false);
              }
            } }
          />
          <ButtonText
            disabled={ !enableButton }
            sx={ {
              minWidth:"unset"
            } }
            onClick={ ()=> {
              sendQuestionText();
            } }
          >
            <Icon
              icon={ "arrow_right_horizontal" }
              width={ "20px" }
              height={ "16px" }
              color={ theme.customColors.systemSecondary05 }
            />
          </ButtonText>
        </StyledChatBotInputGrid>
      </StyledChatGrid>
    </StyledChatBot>

  );
  function sendQuestionText(){
    setSendQuestion(true);
    setEnableButton(false);

    setTimeout(()=> {
      chatBotChipsRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  }
}
