import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { SearchProps } from "./SearchInput.types";
import {
  Icon,
  TextInput
} from "../../components";

export const ArrowBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.primary?.main,
  borderRadius: "0.15rem",
  cursor: "pointer",
  display: "flex",
  padding: "0 .5rem",

  "path": {
    fill: theme.palette.common?.black
  }
}));

export const ResultsStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.customColors.backgroundSecondary,
  bottom: theme.spacing(-1),
  boxShadow:"2px 2px 16px rgba(0, 0, 0, 0.4);",
  position: "absolute",
  transform: "translateY(100%)",
  width: "100%",
  zIndex: theme.zIndex.mobileStepper
}));

export const ResultTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common?.black,
  cursor: "pointer",
  fontWeight: 700,
  lineHeight: 9 / 4,
  padding: theme.spacing(0, 2.5)
}));

export const SearchCancel = styled(Icon)(() => ({}));

export const StyledTextInput = styled(TextInput)<
  Omit<
    SearchProps,
    "onEnter" |
    "selected"
  >
>(({ theme, value }) => ({
  ".MuiInputBase-input": {

    color : theme.palette.text?.secondary,
    paddingBottom: "0.4rem",
    paddingTop: "0.4rem"
  },
  ".MuiInputBase-root": {
    backgroundColor: theme.customColors.backgroundSecondary,
    borderRadius:theme.spacing(0.5)
  },

  [`${SearchCancel}`]: {
    "path": {
      fill: (value && value.length > 0)
        ? theme.palette.primary?.main
        : undefined
    }
  }
}));
