import { Box, Typography, useTheme } from "@mui/material";

import { StyledOptionChip } from "./modalsEcomm.styles";
import { Icon } from "../../components/Icon";
import { ecmItemType } from "../../queries/ecm";

export function OptionSelectedBox({  
  option,
  setDeleteItem 
}:{
    option:ecmItemType,
    setDeleteItem:()=> void
}) {
  const theme = useTheme();


  
  return(
    <StyledOptionChip 
      sx={ { minHeight: "48px" } }>
      <Typography
        fontWeight={ 700 }
      >{ option.title }</Typography>
      <Box 
        className={ "delete-icon" }
        onClick={ ()=> { setDeleteItem() } }>
        <Icon 
          color={ theme.customColors.systemPrimary02 }
          icon="close" 
          size={ 19 }/>
      </Box>
    </StyledOptionChip>
  );
}


