export const form_skillBook_add = {
  "fields": [
    {
      "label": "Inserisci titolo",
      "name": "title",
      "required": true,
      "type": "text"
    },
    {
      "label": "Durata (minuti)",
      "name": "duration",
      "required": true,
      "type": "text"
    },
    {
      "label": "Modalità di formazione",
      "name": "trainingType",
      "required": false,
      "type": "select"
    },
    {
      "label": "Descrizione",
      "name": "description",
      "required": false,
      "type": "text"
    },
    {
      "label": "Data inizio corso",
      "name": "startDate",
      "required": true,
      "type": "date"
    },
    {
      "label": "Data completamento corso",
      "name": "endDate",
      "required": true,
      "type": "date"
    },
    {
      "label": "Link certificato",
      "name": "link",
      "required": false,
      "type": "text"
    },
    {
      "label": "Data inizio validità del certificato",
      "name": "validationStartDate",
      "required": false,
      "type": "date"
    },
    {
      "label": "Data fine validità del certificato",
      "name": "validationEndDate",
      "required": false,
      "type": "date"
    },
    {
      "label": "Codice",
      "name": "code",
      "required": false,
      "type": "text"
    },
    {
      "label": "Note aggiuntive",
      "maxValue": 500,
      "name": "notes",
      "required": false,
      "type": "text"
    },
    {
      "label": "Carica certificato",
      "name": "file",
      "required": false,
      "type": "file"
    },
    {
      "name": "area_domain",
      "type": "groups",
      "fields": [
        {
          "label": "Area di conoscenza",
          "name": "knowledge_area",
          "type": "select"
        },
        {
          "label": "Dominio di conoscenza",
          "name": "knowledge_domain",
          "dependentOn": "knowledge_area",
          "type": "select"
        }
      ],
      "label": "Knowledge Area & Domain"
    },
    {
      "label": "Capacità",
      "name": "capability",
      "required": false,
      "type": "multi-select"
    },
    {
      "label": "ID Fonte",
      "name": "sourceId",
      "required": false,
      "type": "text"
    },
    {
      "label": "Fonte",
      "name": "source",
      "required": false,
      "type": "text"
    }
  ]
};
