import {
  Box,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  CardGrid,
  CardBodyGrid,
  TitleTypography
} from "./CardTabs.styles";
import type { CardTabsProps } from "./CardTabs.types";
import { CardCategories } from "../CardCategories";
import { CardSignature } from "../CardSignature";
import { CardType } from "../CardType";

export function CardTabs({
  categories,
  duration,
  expirationDate,
  isTest,
  isSurvey,
  learningObjectType,
  learningObjectTypology,
  overTitle,
  shortDescription,
  title,
  ...props
}: CardTabsProps) {

  const theme = useTheme();
  const { t }= useTranslation();
  return (
    <CardGrid container { ...props }>
      <CardBodyGrid item container>
        <CardType
          isSurvey={ Boolean(isSurvey) }
          isTest={ Boolean(isTest) }
          learningObjectTypology={ learningObjectTypology }
          learningObjectType={ learningObjectType }
          sx={ {
            [theme.breakpoints.down("sm")]:{
              "p":{
                fontSize:"1rem",
                fontWeight: "700",
                lineHeight: "21px",
                textTransform: "uppercase"
              }
            }
          } }
        />
        <TitleTypography variant="h3">
          { title }
        </TitleTypography>
        {
          overTitle ? (
            <Box
              alignItems={ "center" }
              display={ "flex" }
              gap={ 0.5 }
            >
              { t("care_of") }
              <Typography sx={ {
                textTransform:"capitalize",
                [theme.breakpoints.down("sm")]:{
                  fontSize:"0.875rem"
                }
              } }>
                { overTitle }
              </Typography>
            </Box>

          ) : null
        }
        {
          categories && categories.length > 0 ?
            <CardCategories categories={ categories }/> : null
        }
        {
          duration || expirationDate ?
            <CardSignature
              duration={ duration }
              expirationDate={ expirationDate }
            /> : null
        }

        <Typography
          lineHeight={ 1.3125 }
          textAlign="start"
          fontSize={ "20px" }
          sx={ {
            [theme.breakpoints.down("sm")]:{
              fontSize:"0.875rem"
            }
          } }
        >
          { shortDescription }
        </Typography>
      </CardBodyGrid>
    </CardGrid>
  );
}
