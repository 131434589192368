export class CustomRegex {
  /**
   * matchPathLeaf
   * @param path a valid sub-route
   * @param parent a parent segment
   * @returns the segment next to the parent in the url
   */
  static matchDirectChild(path: string, parent: string) {
    return path.match(new RegExp(`${parent}/(\\w+)`))?.[1];
  }

  /**
   * matchPathLeaf
   * @param path a valid sub-route
   * @returns the last segment of the url, trimming backslash on the end
   */
  static matchPathLeaf(path: string) {
    return path.match(/([^/]*)\/{0,1}$/)?.[1];
  }
  
  /**
   * matchSubROute
   * @param path a complete url
   * @returns the internal route of the website, exluding the domain
   */
  static matchSubRoute(path: string) {
    return path.match(/https{0,1}:\/\/\w+:{0,1}\d+\/(.*)/)?.[1];
  }

  /**
   * matchSubROute
   * @param path a complete url
   * @returns the internal route of the website, exluding the domain
   */
  static matchTimeOfDay(time: string) {
    return Array.from(time.match(/(\d{1,2})\s*:\s*(\d{1,2})/) ?? ["00", "00"]);
  }

  /**
   * matchEmail
   * @param email a string to match
   * @returns a boolean indicating if the input matches as email
   */
  static matchEmail(email: string) { // testEmail
    return Boolean(email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/g));
  }

  /**
   * matchNumber
   * @param text 
   * @returns a boolean indicating if the input string contains a number
   */
  static matchNumber(text: string) {
    return text.match(/\d+/g)?.join("");
  }
  
  /**
   * replaces query param empty array with standard param notation
   * ex. /my-path?q1=[]&q2=[] -> /my-path?q1={q1}&q2={q2}
   * @param path a uri path with query params in tha shape ..&param=[]
   * @returns 
   */
  static replaceArrayQueryParams(path: string) {
    return path.replace(/[?&](\w+)=\[\]/g, "&$1={$1}");
  }

  /**
   * tests if a string satisfies all password constraints
   * Minimum length 8 characters
   * Contains at least 1 number
   * Contains at least 1 special character
   * Contains at least 1 uppercase letter
   * Contains at least 1 lowercase letter
   * @param password 
   * @returns a boolean indicating if the input string satisfies all password constraints
   */
  static testPassword(password: string) {
    const passwordRegex =
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]).{8,}$/);
    return passwordRegex.test(password);
  }

  /**
   * tests if a string satisfies a minimum length
   * @param text 
   * @returns a boolean indicating if the input string satisfies a minimum length
   */
  static testLength(text: string, length: number) {
    const passwordRegex = new RegExp(`.{${length},}`);
    return passwordRegex.test(text);
  }

  /**
   * tests if a string contains a number
   * @param text 
   * @returns a boolean indicating if the input string contains a number
   */
  static testNumber(text: string) {
    const passwordRegex = new RegExp(/\d/);
    return passwordRegex.test(text);
  }

  /**
   * tests if a string contains a special character
   * @param text 
   * @returns a boolean indicating if the input string contains a special character
   */
  static testSpecialCharacter(text: string) {
    const passwordRegex = new RegExp(/(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=])/);
    return passwordRegex.test(text);
  }

  /**
   * tests if a string contains an uppercase character
   * @param text 
   * @returns a boolean indicating if the input string contains an uppercase character
   */
  static testUppercase(text: string) {
    const passwordRegex = new RegExp(/[A-Z]/);
    return passwordRegex.test(text);
  }

  /**
   * tests if a string contains an lowercase character
   * @param text 
   * @returns a boolean indicating if the input string contains an lowercase character
   */
  static testLowercase(text: string) {
    const passwordRegex = new RegExp(/[a-z]/);
    return passwordRegex.test(text);
  }

}
