import {
  Divider,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  NotificationBox,
  DescriptionTypography,
  NotificationItem
} from "./Notification.styles";
import { NotificationProps } from "./Notification.types";
import {
  NAVBAR_ITEMS,
  NOTIFICATION_TYPE
} from "../../consts";
import { formatDate } from "../../services";
import { useNavbarStore } from "../../stores";
import { Icon } from "../Icon";
import { Link } from "../Link";

export function Notification({
  contents,
  onClick=() => {},
  onClickLink
}: NotificationProps) {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  return (
    <NotificationBox
      container
      gap={ 3 }
    >
      <Typography
        fontWeight= "500" 
        fontSize= "16px" 
        lineHeight= "21px" 
        sx={ {
          color: theme.customColors.textPrimary,
          fontWeight: "500",
          padding: theme.spacing(3, 2.62, 3),
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            fontSize:"14px",
            fontWeight: 700,
            lineHeight:"18px"
          }
        } }
      >
        { t("notify_text") }
      </Typography>
      {
        contents && contents?.length > 0 ? (
          contents.map((content, index) => {
            const notificationType = detectNotificationType(content.eventName);
            return (
              <NotificationItem
                className={ content.isRead !== "N" ? "displayed" : "" }
                key={ `notificationItem-${index}` }
                container
                sx={ {
                  cursor:"pointer",

                  ".icon path": {
                    fill: notificationType.color
                  }
                } }
                onClick={ () => { onClick(content)} }
              >
                <Icon
                  icon={ notificationType.icon }
                  height={ "20px" }
                  width={ "20px" }
                />
                <DescriptionTypography>
                  <div className={ "date" }>
                    { formatDate(content.createdDate || "", i18n.language) }
                  </div>
                  <div className={ "description" }>
                    { content.description }
                  </div>
                </DescriptionTypography>
                <Link
                  href={ notificationType.link }
                  target={ "_self" }
                  rel="noreferrer"
                  onClick={ () => {
                    setNavbarItem(notificationType.navBarItem);
                    onClickLink();
                  } }
                >
                  { t(notificationType.linkText) }
                </Link>
              </NotificationItem>
            );
          })
        ) : (
          <Typography
            sx={ { padding: theme.spacing(3, 2.62, 3) } }
          >
            { t("empty_notify") }
          </Typography>
        )
      }
      <Divider
        sx={ {
          padding: "1rem 0",
          width: "100%"
        } }
      />
    </NotificationBox>
  );

  function detectNotificationType(type: string) {
    switch (type) {
    case NOTIFICATION_TYPE.REMINDER:
      return {
        color: theme.customColors.systemSecondary03,
        icon: "Icons_obbligatorio",
        link: "/esplora",
        linkText: "go_to_explore",
        navBarItem: NAVBAR_ITEMS.EXPLORE
      };
    case NOTIFICATION_TYPE.SUGGEST:
      return {
        color: theme.customColors.accentVariantB,
        icon: "Icons_invito",
        link: "/esplora",
        linkText: "go_to_explore",
        navBarItem: NAVBAR_ITEMS.EXPLORE
      };
    case NOTIFICATION_TYPE.BADGE:
      return {
        color: theme.customColors.accentVariantB,
        icon: "Icons_modulo-completato",
        link: "/profile",
        linkText: "go_profile",
        navBarItem: NAVBAR_ITEMS.NONE
      };
    case NOTIFICATION_TYPE.ENROLL:
      return {
        color: theme.customColors.accentVariantB,
        icon: "Icons_invito",
        link: "/esplora",
        linkText: "go_to_explore",
        navBarItem: NAVBAR_ITEMS.EXPLORE
      };
    case NOTIFICATION_TYPE.LEARN_REM:
      return {
        color: theme.customColors.systemSecondary03,
        icon: "Icons_scadenza-e-edizioni",
        link: "/esplora",
        linkText: "go_to_explore",
        navBarItem: NAVBAR_ITEMS.EXPLORE
      };
    case NOTIFICATION_TYPE.RESET:
      return {
        color: theme.customColors.systemSecondary03,
        icon: "Icons_obbligatorio",
        link: "/profile",
        linkText: "go_profile",
        navBarItem: NAVBAR_ITEMS.NONE
      };
    case NOTIFICATION_TYPE.WELCOME:
      return {
        color: theme.customColors.accentVariantB,
        icon: "Icons_modulo-completato",
        link: "/profile",
        linkText: "go_profile",
        navBarItem: NAVBAR_ITEMS.NONE
      };
    case NOTIFICATION_TYPE.WELCOME_NP:
      return {
        color: theme.customColors.accentVariantB,
        icon: "Icons_modulo-completato",
        link: "/profile",
        linkText: "go_profile",
        navBarItem: NAVBAR_ITEMS.NONE
      };
    default:
      return {
        color: theme.customColors.systemSecondary03,
        icon: "Icons_obbligatorio",
        link: "/esplora",
        linkText: "go_to_explore",
        navBarItem: NAVBAR_ITEMS.EXPLORE
      };
    }
  }
}
