import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PlayerLoaderBox = styled(Box)<
  BoxProps & { $image?: string }
>(({ theme, $image }) => ({
  backgroundImage: $image
    ? `${theme.linearGradients.gradientC}, url(${$image})`
    : undefined,
  backgroundPosition:"center",
  backgroundRepeat:"no-repeat",
  backgroundSize:"cover"
}));
