import {
  Box,
  Typography
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonText } from "../../components";
import { NAVBAR_ITEMS } from "../../consts";
import { useNavbarStore } from "../../stores";

  
  
const StyledNotFound = styled(Box)(({ theme }) => {
  return {
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    gap:theme.spacing(3),
    justifyContent:"center",
    width:"100%",

    "p": {
      color:theme.customColors.textPrimary,
      fontSize:theme.spacing(5),
      fontWeight:700,
      maxWidth:"60%",
      textAlign:"center"
    }
  };});
  
export function NotFoundComponent() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  
  return (
    <StyledNotFound
    >
      <Typography>{ t("not_found_lo") }</Typography> 
      <ButtonText onClick={ () =>{ 
        navigate("/esplora");
        setNavbarItem(NAVBAR_ITEMS.EXPLORE);
      } }>{ t("go_to_explore") }</ButtonText>
    </StyledNotFound>
  );
}
