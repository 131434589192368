import { NAVBAR_ITEMS } from "../consts";
import type { NavbarItem } from "../types";

export function persistNavItem (item: string){
  if (Object.keys(NAVBAR_ITEMS).includes(item)) {
    localStorage.setItem("navbarItem", item);
  }
}

export function getNavItem() {
  const itemStored = localStorage.getItem("navbarItem");
  if (Object.keys(NAVBAR_ITEMS).includes(itemStored ?? "")) {
    return itemStored as NavbarItem;
  } else {
    return null;
  }
}
