import {
  Box,
  BoxProps,
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { HTMLProps } from "react";

export const CardGrid = styled(Grid)(() => ({
  flexDirection:"column",

  ".card-cta":{
    gap:"1rem",
    paddingTop:"1rem",

    ">div":{
      maxWidth:"300px",
      width:"100%"

    }
  },
  ".card-signature":{
    maxWidth: "calc(100% - 172px)",
    "p":{
      fontSize:"0.75rem"
    }
  }
}));

export const DescriptionTypography = styled(Typography)(() => ({
  fontSize:"0.75rem",
  lineHeight: 1.3125,
  textAlign: "start"

}));

export const ImageBox = styled(Box)<
  BoxProps & HTMLProps<HTMLImageElement>
>(() => ({
// export const ImageBox = styled("img")(() => ({
  aspectRatio: "9 / 7",
  borderRadius:"8px",
  boxShadow:"box-shadow: 16px 16px 22px rgba(0, 0, 0, 0.4)",
  objectFit: "cover",

  padding: 0,
  paddingBlock: 0,

  height:"117px",
  width: "100%"

}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.3125,
  [theme.breakpoints.down("sm")]: {
    fontSize:"1rem"
  }
}));

export const CardTop = styled(Grid)(() => ({
  display:"flex",
  gap:"16px",
  padding:"0.5rem 0"
}));


