import { BigCarouselCard as BigCarouselCardComponent } from "./BigCarouselCard.component";
import type { BigCarouselCardProps } from "./BigCarouselCard.types";
import * as BigCarouselCardVariants from "./BigCarouselCard.variants";

export const DefaultHeroCard = ({
  ...props
} : Partial<BigCarouselCardProps>
) => (
  <BigCarouselCardComponent simple={ false } { ...{ ...BigCarouselCardVariants.Default , ...props } }/>
);

export const BookmarkHeroCard = ({
  ...props
} : Partial<BigCarouselCardProps>
) => (
  <BigCarouselCardComponent simple={ false } { ...{ ...BigCarouselCardVariants.Bookmark, ...props } }/>
);

export const BigCarouselCard = BigCarouselCardComponent;
