
import {
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";



export const StyledFilterBox = styled(Box)(({ theme }) => ({
  alignItems:"center",
  color:theme.customColors.textPrimary,
  display:"flex",
  justifyContent:"flex-end",

  ".filters": {
    "&.selected": {
      ".icon": {
        "path":{
          fill:theme.palette.primary?.main
        }
      }
    },

    ".icon": {
      cursor:"pointer",
      marginBottom:"8px",

      height:"17px",
      width:"17px"
    }

  },

  ".MuiFormControl-root  ": {
    visibility:"hidden",

    height: "0",
    width:"0"
  },

  "button": {
    fontWeight:"400",
    margin:"0 0 0 7px",
    minHeight:"unset",
    minWidth:"unset",
    opacity:1,
    padding:"8px 0",

    ".icon": {
      height:"17px",
      width:"17px"
    },

    "&.Mui-selected ": {
      ".icon": {
        "path": {
          fill:theme.palette.primary?.main
        },

        "&.square": {
          background: "rgba(244, 62, 99, 0.55)"
        }
      }
    }
  },

  ".MuiTabs-indicator":{
    display:"none"
  },

  ".MuiTabs-root": {
    alignItems:"flex-end"
  },

  [theme.breakpoints.down("sm")]:{
    margin:"0 20px"
  }

}));
