import {
  Avatar,
  Button,
  Stack,
  StackProps,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  CommentInputBase,
  ForbiddenLabelTypography,
  StyledCommentsSection
} from "./CommentsSection.style";
import {
  ButtonText,
  Comment,
  Icon
} from "../../components";
import { useGetProfileImageQuery } from "../../queries";
import {
  useCommentPostQuery,
  useInfiniteCommentQuery
} from "../../queries/community";
import { useAuthStore } from "../../stores/authStore";

export function CommentsSection({

  discussionId,
  selectedFilter,
  selectedTopic,
  topicOwnerId,
  ...props
} : {
  discussionId: string
  selectedTopic:string | null
  selectedFilter:string  | null
  topicOwnerId:string
} & StackProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [inputContent, setInputContent] = useState<string>("");
  const [showCommentForbiddenlabel, setShowCommentForbiddenlabel] = useState(false);
  const userData = useAuthStore(state => state.userData);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: results,
    fetchNextPage,
    hasNextPage,
    // isFetchingNextPage,
    // isFetching,
    isLoading
    // refetch: refetchComments
  } = useInfiniteCommentQuery({ discussionId: discussionId });

  const {
    mutate: mutateComment,
    isLoading: commentIsLoading,
    isIdle: commentIsIdle,
    isError: commentIsError,
    error: commentError
  } = useCommentPostQuery({ 
    body: inputContent, 
    discussionId: discussionId,
    selectedFilter:selectedFilter,
    selectedTopic:selectedTopic
  });

  //managing comment creation + fobidden word
  useEffect(() => {
    if(!commentIsIdle && !commentIsLoading) {
      if(commentIsError) {
        const errorObject = JSON.parse(JSON.parse(commentError as string).errors.FATAL[0].message);
        if(errorObject.message == "Forbidden word has been detected.") {
          setShowCommentForbiddenlabel(true);
        }
      } else {
        setInputContent("");
      }
    }
  }, [commentError, commentIsError, commentIsIdle, commentIsLoading]);

  const { data: profileImage  } = useGetProfileImageQuery({});
  
  return (
    <StyledCommentsSection { ...props }>
      { showCommentForbiddenlabel && 
        <ForbiddenLabelTypography
          key={ "forbidden-label-comment" }
          paddingLeft={ "56px" }
        >
          { t("forbidden_label_comment") }
        </ForbiddenLabelTypography>
      }
      <Stack
        direction={ "row" }
        gap={ isMobile ? "8px" : "16px" }
      >
        <Avatar
          alt={ `${userData?.name?.replaceAll(" ", "")} ${userData?.surname}` }
          { ...stringAvatar( `${userData?.name?.replaceAll(" ", "")} ${userData?.surname}`) }
          src={ profileImage }
        />
    
        <CommentInputBase
          className={ props.className }
          value={ inputContent }
          name={ `comment_pre_send_${discussionId}` }
          onChange={ (changeEvent) => {
            setShowCommentForbiddenlabel(false);
            setInputContent(changeEvent.target.value);
          } }
          onKeyUp={ (keyboardEvent) => {
            if (keyboardEvent.code === "Enter") {
              mutateComment();
            }
          } }
          placeholder={ t("enter_comment") }
          id={ `comment_pre_send_${discussionId}` }
          inputProps={ { "aria-label": t("enter_comment") } }
        />
        <ButtonText
          onClick={ () => {
            mutateComment();
          } }
          sx={ {
            borderRadius:"4px",
            minWidth:"42px"
          } }
          aria-label={ t("send") }
          disabled={ inputContent.length == 0 }
        >
          <Icon
            icon={ "arrow_right_horizontal" }
            width={ "20px" }
            height={ "16px" }
            color={ theme.customColors.systemSecondary05 }
          />
        </ButtonText>
      </Stack>
      <Stack 
        className={ props.className }
        paddingTop={ isMobile ? "24px" : "40px" }
      >
        { !isLoading && results &&
          results?.pages?.map((page) => (
            page?.data.map((comment) =>  
            
            {
              const ownerUserId = comment.owner_id;
              const ownerUser = page?.enrichedInformation?.users?.find(user => user.owner_id === ownerUserId);

              return <Comment 
                topicOwnerId={ topicOwnerId }
                selectedFilter={ selectedFilter }
                selectedTopic={ selectedTopic }
                changed={ comment.changed }
                key={ `comment-${comment.comment_id}` }
                id = { comment.comment_id }
                replies={ comment.reply }
                image={ ownerUser?.profileImageUrl || "" }
                authorName={ `${ownerUser?.name} ${ownerUser?.surname}` || "" }
                commentBody={ comment.body }
                created={ comment.created }
                commentOwner={ comment.owner_id }
                isReply={ false }
                discussionId={ discussionId }
              />;
            }
              
            )
          ))
        }
      </Stack>
      { hasNextPage &&
        <Button
          onClick={ () => fetchNextPage() }
          sx={ {
            gap:"0.5rem",
            margin:"0 auto",
            width:"fit-content"
          } }
        >
          { t("load_more_comments") }
          <Icon 
            color={ theme.customColors.systemPrimary02 }
            icon="dropdown" 
            size={ 20 } 
          />
        </Button> 
      }
    </StyledCommentsSection>
  );
  
  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  }
}
