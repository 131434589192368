import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroupsProps } from "./ButtonGroups.types";
import ButtonCst from "../ButtonCst";

export const ButtonGroups: React.FC<ButtonGroupsProps> = ({ onClickCancel, onClickConfirm }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={ {
        display: isMobile ? "block" : "flex",
        mt: "10px",
        flex: "1 1",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: 1,
        pt: 2
      } }
    >
      <ButtonCst
        id="cancel"
        variant={ "outlined" }
        size={ "medium" }
        sx={ {
          minWidth: "150px",
          mb: "10px",
          [theme.breakpoints.down("lg")]: {
            minWidth: "100px"
          },
          [theme.breakpoints.down("sm")]: {
            minWidth: "100%"
          }
        } }
        onClick={ onClickCancel }
      >
        { t("annul") }
      </ButtonCst>
      <ButtonCst
        id={ "confirm" }
        variant={ "contained" }
        size={ "medium" }
        sx={ {
          minWidth: "150px",
          mb: "10px",
          [theme.breakpoints.down("lg")]: {
            minWidth: "100px"
          },
          [theme.breakpoints.down("sm")]: {
            minWidth: "100%"
          }
        } }
        onClick={ onClickConfirm }
      >
        { t("confirm") }
      </ButtonCst>
    </Box>
  );
};
