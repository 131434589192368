import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OutputMessageProps } from "./type";
import ButtonCst from "../ButtonCst";

const OutputMessage: React.FC<OutputMessageProps> = ({ success, closeModal }) => {
  const { t } = useTranslation();

  return (
    <Box sx={ {
      display: "block"
    } }>
      <Typography id="confirmation-modal-title" variant="h6">
        { success ? t("success") : t("failed") }
      </Typography>
      <ButtonCst
        id={ "button" }
        variant="contained"
        color="primary"
        sx={ { mt: 4, width: "100%" } }
        onClick={ closeModal }
      >
        { t("close") }
      </ButtonCst>
    </Box>
  );
};

export default OutputMessage;
