import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Select } from "../../components/Select/Select.component";
import { Icon } from "../Icon";
export function ActionSelect({
  id,
  date,
  selectAction,
  start,
  end
}: {
  id: number
  date: Date
  selectAction: (
    action: string, 
    date: Date, 
    slotId: number,
    start: string,
    end: string
  ) => void
  start: string
  end: string
}) {

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack 
      direction={ "row" }
      sx={ {
        "& svg.icon": {
          cursor: "pointer"
        }
      } }
    >
      <Select
        options={ [
          {
            children: "Modifica",
            value: "modify"
          },
          {
            children: "Elimina",
            value: "remove"
          }
        ] }
        open={ isOpen }
        onClose={ () => setIsOpen(false) }
        onOpen={ () => setIsOpen(true) }
        onChange={ (changeEvent) => {
          selectAction(
            changeEvent.target.value as string,
            date,
            id,
            start,
            end
          );
        } }
        // onClick={ () => setIsOpen(!open) }
        value={ "" }
        className="action-select"
        sx={ {
          height: "0px",
          zIndex: "-1"
        } }
        MenuProps={ {
          className:"action-menu",
          sx: {
            "& .MuiPaper-root": {
              translate: "-48px -2px"
            }
          }
        } }
      />
      <Icon
        icon={ "Icons_impostazioni" }
        size={ "17px" }
        color={ theme.customColors.systemPrimary01 }
        onClick={ ()=> setIsOpen(true) }
      />
    </Stack>
  );
}

