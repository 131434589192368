import { CarouselCard as CarouselCardComponent } from "./CarouselCard.component";
import type { CarouselCardProps } from "./CarouselCard.types";
import * as CarouselCardVariants from "./CarouselCard.variants";

export const DefaultCard = ({ ...props }: 
  (
    Partial<
      Extract<CarouselCardProps, {
        contentType: "DEFAULT" | "HERO",
        learningObjectTypology: "ACTIVITY"
      }>
    > | (
      Pick<Extract<CarouselCardProps, {
        contentType: "DEFAULT" | "HERO",
        learningObjectTypology: "COURSE" | "PACKAGE"}
      >, "learningObjectTypology" | "percentageOfCompletion"> & 
      Partial<Omit<Extract<CarouselCardProps, {
        contentType: "DEFAULT" | "HERO",
        learningObjectTypology: "COURSE" | "PACKAGE"
      }>, "learningObjectTypology" | "percentageOfCompletion">>
    )
  ) | (
    Partial<
    Extract<CarouselCardProps, {
      contentType: "ZOOM" | "ZOOM_RELATED",
      learningObjectTypology: "ACTIVITY"
    }>
  > | (
    Pick<Extract<CarouselCardProps, {
      contentType: "ZOOM" | "ZOOM_RELATED",
      learningObjectTypology: "COURSE" | "PACKAGE"}
    >, "learningObjectTypology" | "percentageOfCompletion"> & 
    Partial<Omit<Extract<CarouselCardProps, {
      contentType: "ZOOM" | "ZOOM_RELATED",
      learningObjectTypology: "COURSE" | "PACKAGE"
    }>, "learningObjectTypology" | "percentageOfCompletion">>
  )
)) => (
  <CarouselCardComponent { ...{ ...CarouselCardVariants.Default, ...props } }/>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DefaultZoomCard = ({ ...props }: any) => (
  <CarouselCardComponent { ...{ ...CarouselCardVariants.DefaultZoom, ...props } }/>
);


export type { CarouselCardProps, CarouselCardBaseProps } from "./CarouselCard.types";

export const CarouselCard = CarouselCardComponent;
