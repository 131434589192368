import {
  Stack, 
  StackProps, 
  Typography,
  TypographyProps
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";

export const TopicCardSignatureStack = styled(Stack)<StackProps>(({ theme }) => ({
  alignItems:"center",
  display:"flex",
  flexDirection:"row",
  gap:theme.spacing(2)
}));


export const InfoTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: "400",
  lineHeight: "21px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "14px"
  }
}));
