/* eslint-disable max-len */
import { Grid } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledGrid,
  StyledDate,
  StyledText,
  StyledAvatar,
  StyledChip
} from "./ChatBot.Chip.styles";
import { ChatBotChipProps } from "./ChatBot.Chip.types";
import { LEVEL } from "../../consts";
import { TopicChip } from "../../routes/Community/Community.styles";
import { useAuthStore } from "../../stores";

export const ChipChatBot = forwardRef(function ChatBotChip(props:ChatBotChipProps, ref: ForwardedRef<HTMLDivElement>) {
  const { text, image,isFirst, orientation, date, topics } = props;
  const { t } = useTranslation();
  const userData = useAuthStore(state => state.userData);

  function goToDetail(id: string, activityType: string, activityOl: string) {
    switch (activityOl) {
    case "corso" :
      return window.location.href = `${window.location.origin}/esplora/dettaglio/${id}/${LEVEL.COURSE}`;
    case "percorso" :
      return window.location.href = `${window.location.origin}/esplora/dettaglio/${id}/${LEVEL.PATH}`;
    default:
      return window.location.href = `${window.location.origin}/esplora/dettaglio/${id}/${activityType}`;
    }
  }

  return (
    <StyledGrid ref={ ref } sx={ { width: "80%", alignSelf: orientation === "right" ? "flex-end" : "flex-start" } }>
      <StyledText
        orientation={ orientation }
      >
        { text }
        { orientation === "left" && topics ?
          topics?.map((item, index) => {
            return (
              <TopicChip
                sx={ {
                  cursor: "pointer",
                  display: "flex",
                  height: "auto",
                  justifyContent: "flex-start",
                  marginBlock: "8px",
                  width: "80%"
                } }
                chatbot={ true }
                variant="outlined"
                key={ `item-topic-${index}` }
                label={
                  <StyledChip>
                    <span>{ item?.tipologia_ol === "attivita" ? "attività" : item?.tipologia_ol }: </span>
                    <div>
                      { item?.titolo }
                    </div>
                  </StyledChip>
                }
                onClick={ () =>  goToDetail(item?.id_lo, item?.tipologia_attivita, item?.tipologia_ol) }/>
            );
          }) : null }
      </StyledText>

      {
        isFirst ?
          <StyledText
            orientation={ orientation }
          >
            { t("help_text") }
          </StyledText> : null
      }
      <Grid
        alignItems={ "center" }
        display={ "flex" }
        gap={ 1 }
        flexDirection={ orientation === "left" ? "row" : "row-reverse" }
      >
        <StyledAvatar
          src={ image }
          { ...stringAvatar( `${userData?.name?.replaceAll(" ", "")} ${userData?.surname}`) }
          sx={ {
            fontSize:"12px"
          } }
        />
        <StyledDate>{ date }</StyledDate>
      </Grid>
    </StyledGrid>
  );
  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  }
});

