import { useTranslation } from "react-i18next";
import { TabsContentProps } from "./TabsContent.types";
import {
  CardCourseTab,
  CardTabs,
  Description
} from "..";

export function TabsContentDetailWatch({
  currentLO,
  isActivityStandAlone=false,
  parentRoot,
  parentCourse
} :
  & Pick<
    TabsContentProps,
    | "isActivityStandAlone"
    | "parentRoot"
    | "parentCourse"
  >
  & Required<
    Pick<
      TabsContentProps,
      | "currentLO"
    >
  >
  & {
    isDetailsPage: false
  }
) {
  const { t } = useTranslation();

  const teachers = parentCourse?.teachers?.map((item) => item.completeName);

  return (
    <>
      <Description
        badge={ currentLO.badge }
        certificate={ currentLO.certificate }
        categories={ currentLO.topicTags }
        date={ currentLO.expirationDate }
        duration={ currentLO?.duration }
        isStandAlone={ isActivityStandAlone }
        learningObjectType={ currentLO.learningObjectType }
        learningObjectTypology={ currentLO.learningObjectTypology }
        percentageOfCompletion={ currentLO.percentageOfCompletion }
        shortDescription={ currentLO.longDescription }
        title={ currentLO.title }
      />
      {
        parentCourse ? (
          <CardTabs
            categories={ parentCourse.topicTags }
            duration={ parentCourse.duration }
            expirationDate={ parentCourse.expirationDate }
            learningObjectType={ parentCourse.learningObjectType }
            learningObjectTypology={ parentCourse.learningObjectTypology }
            overTitle={ teachers?.toString() }
            percentageOfCompletion={ parentCourse.percentageOfCompletion }
            shortDescription={ parentCourse.shortDescription }
            title={ parentCourse.title }
          />
        ) : null
      }
      {
        parentRoot ? (
          <>
            <span>{ t("module_included") }</span>
            <CardCourseTab
              categories={ parentRoot.topicTags }
              coverPublicURL={ parentRoot.coverPublicURL?.toString() }
              date={ parentRoot.expirationDate }
              duration={ parentRoot.duration }
              id={ parentRoot.id }
              learningObjectType={ parentRoot.learningObjectType }
              learningObjectTypology={ parentRoot.learningObjectTypology }
              shortDescription={ parentRoot.shortDescription }
              title={ parentRoot.title }
            />
          </>
        ) : null
      }
    </>
  );
}
