import { InputBase, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ContentInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: theme.customColors.backgroundSecondary,
    borderRadius:"2px",
    color: theme.customColors.systemSecondary05,
    padding: theme.spacing(1, 2),
    gap:theme.spacing(1),

    "&::placeholder ":{
      color:theme.customColors.backgroundDisabled,
      fontFamily:"Inter",
      fontWeight:400,
      lineHeight: "21px",
      opacity:1,
      [theme.breakpoints.down("sm")]: {
        fontSize:"14px",
        lineHeight: "8px",
        width:"158px"
      }

    }
  },
  width: "100%"
}));

export const ContentBox = styled(Stack)(({ theme }) => ({
  background:theme.linearGradients.gradientB,
  borderRadius:"4px",
  cursor:"pointer",
  display:"flex",
  gap: "16px",
  maxWidth:"894px",
  padding: theme.spacing(3),
  width:"100%",
  

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3, 2)
  }
}));
