import {
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
import type {
  Language,
  Translations
} from "../types";
import { getEnvironmentVariables } from "../utils/general";

const { basePath, corporateLanguage } = getEnvironmentVariables();

type LanguagesResponse = {
  id: number | null
  enable_multilanguage: boolean
  primary_language: Language
  secondary_languages: Language[]
}

export function useGetLanguages({
  enabled = true,
  queryKey = [],
  organizationId,
  initiativeId,
  showMainLayout
}: {
  enabled?: boolean
  queryKey?: string[]
  organizationId?: number
  initiativeId?: number | null
  showMainLayout: boolean
}) {
  const fetcher = fetcherFactory();
  const corporateId = organizationId ?? corporateLanguage;

  const uri = `${basePath}/usermgmt/corporates/${corporateId}${initiativeId ? `/initiatives/${initiativeId}/languages` : "/languages"}`;

  return useQuery<
    unknown,
    unknown,
    {
      enable_multilanguage: boolean,
      primary_language: Language,
      languages: Language[]
    },
    QueryKey
  >({
    enabled: enabled && Boolean(corporateId) && !showMainLayout || (showMainLayout && Boolean(initiativeId)),
    queryFn: () => fetcher(
      uri,
      {
        headers: {
          "accept": "application/json"
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: LanguagesResponse) => {
        const languages =
          (data.primary_language ? [data.primary_language] : [])
            .concat(data.secondary_languages);

        return {
          enable_multilanguage: data.enable_multilanguage ?? false,
          primary_language: data.primary_language,
          languages
        };
      }),
    queryKey: [uri, ...queryKey],
    refetchOnMount: false
  });
}

export function useGetTranslations({
  enabled = true,
  queryKey = [],
  language,
  setLanguage
}: {
  enabled?: boolean
  queryKey?: string[]
  language?: Language | null
  setLanguage: (language: Language, languageCookie?: Language) => void
}) {
  const { i18n } = useTranslation();
  const fetcher = fetcherFactory();
  const userData = useAuthStore(state => state.userData);
  const corporateId = userData?.organization_id ?? corporateLanguage;
  const initiativeId = userData?.initiative_id ?? "";

  const uri = `${basePath}/smartconfigurator/labelTranslations/WEB/${corporateId}`;
  const params = `initiativeId=${initiativeId}${language?.id ? "&langCode=" + language?.id : ""}`;
  const htmlTag = document.querySelector("html");

  return useQuery<
    unknown,
    unknown,
    Translations,
    QueryKey
  >({
    enabled: enabled && Boolean(corporateId) && Boolean(language?.id),
    onSuccess: (data) => {
      if (language && data.translation[ language?.id ]){
        i18n.addResourceBundle(
          language?.id,
          "translation",
          { ...data.translation[language?.id] }
        );
        i18n.changeLanguage(language?.id);
        document.cookie = `activeLangCode=${language?.id}`;
        setLanguage(language);
        htmlTag?.setAttribute("lang", language?.id);
      }
    },
    queryFn: () => fetcher(
      `${uri}?${params}`,
      {
        headers: {
          "accept": "application/json"
        }
      })
      .then((res) => {
        return res.json();
      }),
    queryKey: [uri, params, ...queryKey],
    refetchOnMount: true
  });
}


