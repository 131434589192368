import { Box, SwipeableDrawer, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
const boxHeight= 112;
const boxWidth=200;
const boxZoomHeight=201;
const boxZoomWidth=260;
const boxMobHeight=81;

export const AreasBox = styled(Box)(({ theme })=> ({
  cursor:"pointer",
  display:"flex",
  flexDirection:"row",
  flexWrap:"wrap",
  gap:theme.spacing(2),
  maxWidth:"968px"
}));


export const AreaBoxContainer = styled(Box)(({ theme })=> ({
  position:"relative",
  width:"fit-content",

  "&:hover":{
    overflow:"visible",

    [`${AreaItem}`]:{
      "p":{
        opacity:0
      }
    },
     
    [`${ AreaZoomItem}`]:{
      opacity:1,
      transform:"scale(1)"
    }
  },

  [`${theme.breakpoints.down("sm")}`]:{
    height:`${boxMobHeight}px`,
    width:"calc(50% - 8px)",

    "&:hover":{
      overflow:"hidden",

      [`${AreaItem}`]:{
        "p":{
          opacity:1
        }
      },
     
      [`${AreaZoomItem}`]:{
        opacity:0
      }
    }
  }
}));

export const AreaItem = styled(Box)(({ theme })=> ({
  backdropFilter:"blur(20px)",
  backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.1),
  borderRadius:theme.spacing(1),
  display:"flex",
  flexDirection:"column",
  height:`${boxHeight}px`,
  justifyContent:"flex-end",
  padding:theme.spacing(2,3),
  width:`${boxWidth}px`,

  "p":{
    overflowWrap:"break-word"
  },

  [`${theme.breakpoints.down("sm")}`]:{
    height:"100%",
    justifyContent:"center",
    padding:theme.spacing(1,1,0.5,2),
    width:"100%"
  }

}));

export const AreaZoomItem = styled(Box)(({ theme })=> ({
  backdropFilter:"blur(40px)",
  backgroundColor:alpha(theme.customColors.backgroundDisabledAlternative,0.8),
  border:`2px solid ${theme.customColors.accentVariantB}`,
  borderRadius:theme.spacing(1),
  boxShadow:`0px 0px 4px 0px ${theme.customColors.accentVariantB}`,
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(1),
  height:`${boxZoomHeight}px`,
  justifyContent:"flex-end",

  left:`-${(boxZoomWidth - boxWidth)/2}px`,
  opacity:0,
  padding:theme.spacing(2,3),
  position:"absolute",
  top:`-${(boxZoomHeight - boxHeight)/2}px`,
  transform:"scale(0.7)",
  transition:"all .3s linear",
  width:`${boxZoomWidth}px`,
  zIndex:4,

  "p":{
    overflowWrap:"break-word"
  }
}));


export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: "transparent",
    borderLeft:`2px solid ${theme.customColors.accentVariantB}`,
    borderRight:`2px solid ${theme.customColors.accentVariantB}`,
    borderTop:`2px solid ${theme.customColors.accentVariantB}`,
    borderTopLeftRadius:theme.spacing(1),
    borderTopRight:`2px solid ${theme.customColors.accentVariantB}`,
    borderTopRightRadius:theme.spacing(1),
    bottom:0,
    boxShadow:`0px 0px 4px 0px ${theme.customColors.accentVariantB}`,
    boxSizing: "border-box",
    height: "70%"
  },

  [`${AreaBoxContainer}`]:{
    backdropFilter:"blur(40px)",
    backgroundColor:alpha(theme.customColors.backgroundDisabledAlternative, 0.1),
    borderRadius:0,
    display:"flex",
    flexDirection:"column",
    height:"100%",
    justifyContent:"center",
    padding:theme.spacing(3,2.5,5),
    width:"100%"
  }
}));
