import { styled } from "@mui/material/styles";
import { Swiper, SwiperProps } from "swiper/react";


export const StyledSwiper = styled(Swiper)<
  SwiperProps
>(({ theme }) => ({
  maxWidth: "100%",

  margin: "54px auto",
  padding: "0 36px",

  ".swiper-button-next, .swiper-button-prev": {
    background: theme.palette.background?.default,
    marginTop: 0,
    paddingX: "16px",
    top: 0,

    height: "100%",
    width: "36px",

    "&.swiper-button-disabled":{
      opacity:1,

      "&:after":{
        opacity:.35
      }
    },

    "&:after": {
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      content:"''",
      height: "19px",
      width: "15px"
    }
  },

  /* eslint-disable max-len */
  ".swiper-button-next": {
    justifyContent: "flex-end",
    right: 0,

    "&:after": {
      backgroundImage:" url(\"data:image/svg+xml,%3Csvg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9435 6.48797L4.20715 0.753189C3.73573 0.43348 2.15094 0.293607 1.44882 1.09288C0.646401 2.02204 0.816916 3.43075 1.82997 4.14011L3.86612 5.55882L7.83812 8.34629C8.40985 8.74592 8.69069 9.36536 8.70072 9.9848C8.70072 10.6142 8.40985 11.2337 7.83812 11.6333L1.76979 15.8794C0.796855 16.5588 0.62634 17.9276 1.39867 18.8268L1.44882 18.8867C2.14091 19.686 3.61537 19.5961 4.20715 19.2264L15.0298 11.6833C16.1432 10.904 16.2034 9.3154 15.2204 8.44619L14.749 7.9966' stroke='white' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A\");"
    }
  },

  ".swiper-button-prev": {
    justifyContent: "flex-start",
    left: 0,

    "&:after": {
      backgroundImage:"url(\"data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.76883 13.5002L11.9027 19.2298C12.3421 19.5492 13.8193 19.6889 14.4737 18.8904C15.2217 17.9621 15.0627 16.5546 14.1185 15.8459L12.2206 14.4285L8.51825 11.6436C7.98534 11.2443 7.72357 10.6254 7.71422 10.0066C7.71422 9.3777 7.98534 8.75883 8.51825 8.35956L14.1745 4.11727C15.0814 3.43851 15.2404 2.071 14.5205 1.17263L14.4737 1.11274C13.8286 0.314193 12.4543 0.40403 11.9027 0.773358L1.82419 8.30965C0.78642 9.08823 0.730324 10.6753 1.64655 11.5438L2.08597 11.9929' stroke='white' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E \");"
    }
  },

  ".swiper-slide": {
    alignItems:"center",
    display: "flex" ,
    maxWidth: "fit-content",

    "&:first-child > div": {
      paddingLeft: 0
    }
  },

  ".swiper-wrapper": {
    margin: "0 auto",
    maxWidth: "100vw",
    width: "fit-content"
  },

  [theme.breakpoints.down("sm")]: {
    gap: "8px",
    marginBottom: "36px",
    marginTop: "26px",
    maxWidth:undefined,
    padding:"0",

    ".MuiGrid-container": {
      "> div": {
        height: "20px",
        width: "20px"
      },
      gap: "8px"
    },

    ".swiper-button-next, .swiper-button-prev": {
      display: "none"
    }
  }

}));
