import {
  useMutation,
  useQuery,
  // useQueryClient
  type QueryKey
} from "@tanstack/react-query";
import type { Format } from "./general";
import { ERRORS } from "../consts";
import { fetcherFactory } from "../services/fetcher";
import {
  useAuthStore,
  useCoverStore
} from "../stores";
import type {
  CoverActions,
  CoverState
} from "../stores/coversStore";
import type { TimeSlot } from "../types";
import { getEnvironmentVariables } from "../utils/general";


type Preferences = {
  formats: Format[]
  hour_preferences: TimeSlot[]
}

type PutPreferences = {
  formats: number[]
  hour_preferences: TimeSlot[]
}

const { basePath } = getEnvironmentVariables();

/* THIS GOES IN REACT ROUTER LOADERS */
export const getPreferencesQuery = (
  refreshSession: () => Promise<void>,
  {
    enabled,
    idToken,
    queryKey,
    sessionToken,
    uri="/usermgmt/users/self/preferences"
  }: {
    idToken:string,
    enabled: boolean
    queryKey: unknown[]
    sessionToken: string
    uri?: string
  },
  covers: CoverState & CoverActions
) => {
  const fetcher = fetcherFactory();
  return {
    enabled: Boolean(
      enabled 
      && idToken
      && sessionToken
    ),
    queryFn:
      () => fetcher(
        `${basePath}${uri}`,
        {
          headers: {
            "Authorization": idToken,
            "x-ada-session-token": sessionToken
          }
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data: Preferences) => {
          return {
            formats: data.formats.map((format) => ({ ...format, cover: covers.get() })),
            hour_preferences: data.hour_preferences
          };
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            refreshSession();
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        }),
    queryKey: [sessionToken, uri, idToken, ...queryKey]
  };
};

/* THIS GOES IN REACT COMPONENTS */
export const usePreferencesQuery = ({
  enabled=true,
  queryKey=[]
} : {
  enabled?: boolean
  queryKey?: unknown[]
}) => {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";

  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const covers = useCoverStore();
  const uri = "/usermgmt/users/self/preferences";
  const fetcher = fetcherFactory();
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);


  return useQuery<
    unknown,
    unknown,
    Preferences | undefined,
    QueryKey
  >( {
    enabled: Boolean(enabled && sessionToken),
    queryFn:() => fetcher(
      `${basePath}${uri}`,
      { 
        headers:  
        {
          "Authorization": idToken,
          "x-ada-session-token": sessionToken
        } }
    ).then((res) => {
      return res.json();
    })
      .then((data: Preferences) => {
        return {
          formats: data.formats.map((format) => ({ ...format, cover: covers.get() })),
          hour_preferences: data.hour_preferences
        };
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, idToken, ...queryKey]
  });
};

/* THIS GOES IN REACT COMPONENTS */
export const usePreferencesMutation = () => {
  // const queryClient = useQueryClient();
  const authToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken());
  const sessionToken = useAuthStore(state => state.sessionToken);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();

  const uri = "/usermgmt/users/self/preferences";

  return useMutation({
    mutationFn:
      (payload: PutPreferences) => fetcher(
        `${basePath}${uri}`,
        {
          
          body: JSON.stringify(payload),
          headers: {
            "Authorization": authToken ?? "",
            "Content-Type": "application/json",
            "x-ada-session-token": sessionToken ?? ""
          },
          method: "PUT"
        }
      )
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        })
    // onSuccess: (data) => {
    //   queryClient.setQueryData([sessionToken, uri, ...queryKey], data);
    // }
  });
};
