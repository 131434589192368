/* eslint-disable max-len */

import { Box, Link,Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  DescriptionTypography,
  CookieStack,TableCookie, TextContent, TitleTypography
} from "./Cookie.styles";
import { BoxCorners, GridMain } from "../../components";
import { useAuthStore } from "../../stores";

export function CookiePage() {
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  
  const hjSessionUser = "hjSessionUser_{site_id}";
  const hjSession = "_hjSession_{ site_id }";
  const hjIncludedInSessionSample = "_hjIncludedInSessionSample_{ site_id }";
  
  return (
    <>
      <GridMain
        sx={ {
          margin: theme.spacing(10, 0),
          position:"relative",
          [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(3.5, 0),

            ".box-el": {
              transform: "scale(0.4)"
            }
          }
        } }
    
      >
        <BoxCorners $filled={ true } $cornersPosition={ "topRight" } />
        <BoxCorners $filled={ true } $cornersPosition={ "bottomLeft" } />
        <CookieStack >
          <Typography
            margin={ "0 auto" }
            //width={ "786px" }
            sx={ {
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "30px",
              marginBottom: "80px",
              maxWidth: "1125px",
              textAlign: "center",
              top:"120px",
              [theme.breakpoints.down("sm")]: {
                fontSize:"16px",
                lineHeight: "21px"
              }
            } }
          >
            { "COOKIE POLICY" } 
          </Typography>
          <TextContent>
            <TitleTypography >
              { "SEZIONE 1 – INFORMAZIONI GENERALI" }
            </TitleTypography>
            <DescriptionTypography
              sx={ {
                flexDirection: "column",
                gap:"0px",
                justifyContent: "initial"
    
              } }
            >
              <span>
                { "Digit’Ed S.p.A. (P.I. 07490560633) a socio unico, soggetta a direzione e coordinamento di Digit’Ed Holding S.p.A., in persona del legale rappresentante pro tempore, con sede legale in Via San Vigilio 1, 20142 Milano, indirizzo di posta elettronica: " }
                <Link color= { theme.customColors.textPrimaryCta }
                  href="email:privacy@digited.it"
                  target="_blank"
                >
                  privacy@digited.it 
                </Link> ,
                { " agendo in qualità di Titolare del trattamento, tratta i Suoi dati, utilizzando cookie e altre tecnologie di tracciamento analoghe, durante la sua navigazione sul presente sito." }
              </span> 
              { "La presente Cookie Policy ha lo scopo di integrare la Privacy Policy  della presente piattaforma, descrivendo quali cookie e strumenti di tracciamento analoghi siano utilizzati sulla piattaforma, le relative finalità e le azioni che può intraprendere per gestire le preferenze da Lei espresse. Sulla piattaforma vengono utilizzate tecnologie per consentire una maggiore fruibilità ed user experience all’Utente che naviga sul sito, mettendo a disposizione alcune funzionalità aggiuntive. Tali tecnologie includono ad esempio cookie, pixel e script." } <br></br>
              { "I cookie sono pacchetti di informazioni inviate da un web server al browser Internet e memorizzati in automatico sul Suo personal computer o sul Suo dispositivo portatile (di seguito anche “dispositivo”), contenenti dati, come le impostazioni personalizzate della pagina (ad es. lingua, risoluzione schermo) e informazioni di log-in. Questi file di dati vengono generati dal web server collegato con il browser e di conseguenza inviati al Suo dispositivo" } <br></br>   
              { "Ulteriori informazioni su come vengano trattati i Suoi dati personali, ivi inclusi i dati personali raccolti per il tramite dei cookie, su come può esercitare i diritti per proteggere i Suoi dati personali e le informazioni necessarie per contattare il Responsabile della protezione dei dati personali (RPD) di Digit’Ed sono indicate nella Privacy Policy  della piattaforma." }      
            </DescriptionTypography>
            <TitleTypography>
              { "SEZIONE 2 – TIPOLOGIE DI COOKIE" }
            </TitleTypography>
            <DescriptionTypography
              sx={ {
                flexDirection: "column",
                gap:"0px",
                justifyContent: "initial"
    
              } }
            >
              { "In generale i cookie possono essere classificati come segue:" }
              <ul>
                <li><b>cookie “di sessione”</b>, vengono cancellati immediatamente alla chiusura del browser di navigazione;
                </li>
                <li>
                  <b>cookie “persistenti”</b>, rimangono all’interno del browser per un determinato periodo di tempo;
                </li>
                <li>
                  <b>cookie “di prima parte”</b>, generati e gestiti direttamente dal soggetto gestore del sito web sul quale l’Utente sta navigando;
                </li>
                <li>
                  <b>cookie di “terze parti”</b>, generati e gestiti da soggetti diversi dal gestore del sito web sul quale l’Utente sta navigando.
                </li>
              </ul>
              { "Quanto alla funzionalità del cookie, si distingue tra: " }
              <ol>
                <li> <b>Cookie Tecnici</b>, che permettono il funzionamento del sito web e la navigazione all’Utente; senza di essi, l’Utente potrebbe non essere in grado di accedere correttamente alle pagine e utilizzare alcuni dei servizi offerti dal sito. Per questa tipologia di cookie non è richiesto il consenso dell’Utente.</li>
                <li> <b>Cookie di performance o analitici</b>, che vengono utilizzati per valutare le prestazioni del sito web attraverso l’analisi statistica del traffico degli Utenti. Per l’utilizzo di questo tipo di cookie è di regola necessario il consenso dell’interessato.</li>
                <li> <b>Cookie di profilazione</b>, i quali permettono di monitorare i comportamenti degli Utenti in modo da poter inviare loro materiale pubblicitario in linea con le preferenze manifestate durante la navigazione. Anche per questo tipo di cookie è necessario il consenso esplicito dell’interessato.</li>
              </ol>
            </DescriptionTypography>
            <TitleTypography>
              { "SEZIONE 3 – COOKIE UTILIZZATI NEL NOSTRO SITO" }
            </TitleTypography>   
            <DescriptionTypography>
              { "Di seguito si riporta l’elenco dei cookie e altri strumenti di tracciamento utilizzati sulla piattaforma: " }
            </DescriptionTypography>
            <Box sx={ {
              overflowX: "auto"
            } }>
              <TableCookie>
                <thead>
                  <tr>
                    <th>Nome cookie</th>
                    <th>Tipologia</th>
                    <th>Finalità</th>
                    <th>Durata</th>
                    <th>Prima o terza parte</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CognitoIdentityServiceProvider.5g<br></br>d4d7qbi3059nudhpp44kuf9e.Last<br></br>AuthUser</td>
                    <td>Cookie Tecnico</td>
                    <td>Login su cognito</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>CognitoIdentityServiceProvider.5g<br></br>d4d7qbi3059nudhpp44kuf9e.exter<br></br>nal.id.po1.accessToken</td>
                    <td>Cookie Tecnico</td>
                    <td>Login su cognito</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>CognitoIdentityServiceProvider.5g<br></br>d4d7qbi3059nudhpp44kuf9e.exter<br></br>nal.id.po1.clockDrift</td>
                    <td>Cookie Tecnico</td>
                    <td>Login su cognito</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>CognitoIdentityServiceProvider.5g<br></br>d4d7qbi3059nudhpp44kuf9e.exter<br></br>nal.id.po1.idToken</td>
                    <td>Cookie Tecnico</td>
                    <td>Login su cognito</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>CognitoIdentityServiceProvider.5g<br></br>d4d7qbi3059nudhpp44kuf9e.exter<br></br>nal.id.po1.refreshToken</td>
                    <td>Cookie Tecnico</td>
                    <td>Login su cognito</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>corporate_domain</td>
                    <td>Cookie Tecnico</td>
                    <td>Dominio applicazione learner di provenienza</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Prima parte</td>
                  </tr>
                  <tr>
                    <td>digitedUser</td>
                    <td>Cookie Tecnico</td>
                    <td>Token utilizzato come header nei servizi BE</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Prima parte</td>
                  </tr>
                  <tr>
                    <td>initiativeUrl</td>
                    <td>Cookie Tecnico</td>
                    <td>Dominio iniziativa learner di provenienza</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Prima parte</td>
                  </tr>
                  <tr>
                    <td>sessionId</td>
                    <td>Cookie Tecnico</td>
                    <td>SessionID dell’Utente loggato utilizzato come header nei servizi BE</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Prima parte</td>
                  </tr>
                  <tr>
                    <td>digitedUser</td>
                    <td>Cookie Tecnico</td>
                    <td>Controllo accessi alle risorse S3/Cloudfront</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Prima parte</td>
                  </tr>
                  <tr>
                    <td>ga_CYMTCX6T1T</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_uetvid</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_sfid_6c72</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_bc_uuid</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjSessionUser_249047</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>wwupcp</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_evga_1924</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>sw</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>Cookie Tecnico</td>
                    <td>Funzionamento player Brightcove</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>{ hjSessionUser }</td>
                    <td>Cookie Tecnico</td>
                    <td>Mantiene l’ID Utente Hotjar per assicurare che i dati dalle visite successive al sito siano attribuiti allo stesso ID Utente.</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjid</td>
                    <td>Cookie Tecnico</td>
                    <td>Mantiene l’ID Utente Hotjar per assicurare che i dati dalle visite successive al sito siano attribuiti allo stesso ID Utente.</td>
                    <td>1 anno</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjFirstSeen</td>
                    <td>Cookie Tecnico</td>
                    <td>Identifica la prima sessione di un nuovo Utente. Utilizzato dai filtri di registrazione per identificare le nuove sessioni Utente</td>
                    <td>30 minuti</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjHasCachedUserAttributes</td>
                    <td>Cookie Tecnico</td>
                    <td>Identifica se il set di dati nell’elemento di archiviazione locale _hjUserAttributes sia aggiornato o meno.</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjUserAttributesHash</td>
                    <td>Cookie Tecnico</td>
                    <td>Identifica quando qualsiasi attributo dell’Utente è cambiato e ha bisogno di essere aggiornato.</td>
                    <td>2 minuti</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>{ hjSession }</td>
                    <td>Cookie Tecnico</td>
                    <td>Garantisce che le richieste successive nella finestra della sessione siano attribuite alla stessa sessione.</td>
                    <td>30 minuti</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjSessionTooLarge</td>
                    <td>Cookie Tecnico</td>
                    <td>Interrompe la raccolta dati da parte di Hotjar se una sessione diventa troppo grande. Viene determinato automaticamente da un segnale dal server se la dimensione della sessione supera il limite.</td>
                    <td>1 ora</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjSessionResumed</td>
                    <td>Cookie Tecnico</td>
                    <td>Viene impostato quando una sessione o una registrazione si ricollega ai server Hotjar dopo una interruzione della connessione</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjCookieTest</td>
                    <td>Cookie Tecnico</td>
                    <td>Controlla se il codice di tracciamento di Hotjar può utilizzare l’archiviazione locale. Se può, viene impostato un valore di 1. I dati memorizzati in _hjLocalStorageTest non hanno un tempo di scadenza, ma vengono eliminati quasi immediatamente dopo essere stati creati.</td>
                    <td>Inferiore a 100ms</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjLocalStorageTest</td>
                    <td>Cookie Tecnico</td>
                    <td>Controlla se il codice di tracciamento di Hotjar può utilizzare l’archiviazione locale. Se può, viene impostato un valore di 1. I dati memorizzati in _hjLocalStorageTest non hanno un tempo di scadenza, ma vengono eliminati quasi immediatamente dopo essere stati creati.</td>
                    <td>Inferiore a 100ms</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjSessionStorageTest</td>
                    <td>Cookie Tecnico</td>
                    <td>Controlla se il codice di tracciamento di Hotjar può utilizzare l’archiviazione della sessione. Se può, viene impostato un valore di 1. I dati memorizzati in _hjSessionStorageTest non hanno un tempo di scadenza, ma vengono eliminati quasi immediatamente dopo essere stati creati.</td>
                    <td>Inferiore a 100ms</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjIncludedInPageviewSample</td>
                    <td>Cookie Tecnico</td>
                    <td>Determina se un Utente è incluso nel campionamento dei dati definito dal limite giornaliero di sessioni della piattaforma.</td>
                    <td>2 minuti</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>{ hjIncludedInSessionSample }</td>
                    <td>Cookie Tecnico</td>
                    <td>Determina se un Utente è incluso nel campionamento dei dati definito dal limite giornaliero di sessioni della piattaforma.</td>
                    <td>2 minuti</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjAbsoluteSessionInProgress</td>
                    <td>Cookie Tecnico</td>
                    <td>Rileva la prima sessione di visualizzazione di pagina di un Utente.</td>
                    <td>30 minuti</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjTLDTest</td>
                    <td>Cookie Tecnico</td>
                    <td>Memorizza il cookie _hjTLDTest per diverse alternative di sottostringhe di URL fino a quando non fallisce. Consente di cercare di determinare il percorso del cookie più generico da utilizzare, invece dell’hostname della pagina. Ciò significa che i cookie possono essere condivisi tra sottodomini (se applicabile).</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjUserAttributes</td>
                    <td>Local Storage Item </td>
                    <td>Memorizza gli attributi dell’Utente inviati tramite l’API Hotjar Identify.</td>
                    <td>Nessuna durata settata</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>hjViewportId</td>
                    <td>Session storage item</td>
                    <td>Memorizza i dettagli del viewport dell’Utente, come le dimensioni e le misure.</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>hjActiveViewportIds</td>
                    <td>Local Storage Item</td>
                    <td>Memorizza gli ID delle viewport attive dell’Utente.</td>
                    <td>Memorizza un expirationTimestamp che viene utilizzato per convalidare le viewport attive all’inizializzazione dello script.</td>
                    <td>Terza parte</td>
                  </tr>
                  <tr>
                    <td>_hjRecordingEnabled</td>
                    <td>Session storage item</td>
                    <td>Imposta quando inizia una registrazione.Letto quando il modulo di registrazione viene inizializzato per vedere se l’Utente è già in una registrazione in una particolare sessione.</td>
                    <td>Per tutta la durata di sessione</td>
                    <td>Terza parte</td>
                  </tr>
                </tbody>

              </TableCookie>
            </Box>
        
            <TitleTypography>
              { "SEZIONE 4 – COME GESTIRE I COOKIE" }
            </TitleTypography>
            <DescriptionTypography>
              { "La maggior parte dei browser, di default, sono impostati per accettare automaticamente i cookie. In aggiunta, può disattivare la memorizzazione dei cookie in qualsiasi momento con effetto futuro, modificando le impostazioni del Suo browser in modo da non accettare i cookie, accettare unicamente determinate categorie di cookie oppure in modo tale da ricevere un avviso dal browser ogni volta che viene salvato un nuovo cookie. Ciò comporta eventualmente che, disabilitando o cancellando i cookie, la fruizione ottimale di alcune funzioni che il nostro sito offre sia preclusa. Le scelte da Lei compiute riguardano il browser sul quale ha impostato le Sue preferenze. Se utilizza più di un browser o dispositivo, potrebbe essere necessario modificare la configurazione predefinita anche su questi browser/dispositivi aggiuntivi, in modo da riflettere le proprie preferenze relative all’utilizzo dei cookie. In determinate circostanze, dopo gli aggiornamenti o se dovesse cancellare i cookie dal Suo browser, le impostazioni potrebbero essere ripristinate e potremmo doverle chiedere di esercitare nuovamente la Sua scelta riguardo ai cookie nella Sua prossima visita al nostro sito web. Per maggiori informazioni su come disattivare i cookie o modificare le proprie impostazioni, si rimanda ai link dei browser di riferimento. Può, anche, utilizzare l’opzione di “aiuto” presente all’interno del browser da Lei utilizzato: " }
              <ul>
                <li>
                  <Link 
                    color= { theme.customColors.textPrimaryCta }
                    href="https://support.mozilla.org/it/kb/Eliminare%20i%20cookie"
                    target="_blank"
                    padding={ "0px" }>
                    Firefox
                  </Link>
                </li>
                <li>
                  <Link
                    color= { theme.customColors.textPrimaryCta } 
                    href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                    target="_blank"
                    padding={ "0px" }>Microsoft Edge</Link>
                </li>
                <li>
                  <Link 
                    color= { theme.customColors.textPrimaryCta }
                    href="https://support.google.com/chrome/answer/95647?hl=it"
                    target="_blank"
                    padding={ "0px" }>Chrome </Link>
                </li>
                <li>
                  <Link 
                    color= { theme.customColors.textPrimaryCta }
                    href="https://help.opera.com/en/latest/security-and-privacy/#tracking"
                    target="_blank"
                    padding={ "0px" }>Opera</Link>
                </li>
                <li>
                  <Link
                    color= { theme.customColors.textPrimaryCta } 
                    href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"
                    target="_blank"
                    padding={ "0px" }>Safari </Link>
                </li>
              </ul>	
              { " Esistono anche dei prodotti software in grado di gestire i cookie per Lei. Se modificasse le impostazioni del browser o se utilizzasse determinati prodotti software che bloccano i cookie, rispetteremmo tali impostazioni e non inoltreremo tali tipologie di cookie che sono state modificati e/o bloccate. Qualora decidesse di abilitare i cookie sul Suo browser, Le verrà presentato sulla piattaforma un Cookie Banner attraverso il quale potrà esprimere le Sue preferenze sui cookie, potendo, ad esempio, attivare determinate tipologie di cookie. I cookie tecnici non richiedono il Suo consenso e saranno sempre attivi, se permette la configurazione dei cookie tramite le impostazioni del Suo browser. Per quanto riguarda le altre categorie di cookie, gli stessi saranno configurati unicamente se sarà fornito il Suo consenso." }
            </DescriptionTypography>         
            <Typography
              sx={ {
                display: "flex",
                flexDirection: "column",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                gap:"32px",
                justifyContent: "center",
                lineHeight: "21px",
                paddingTop: "24px",
                textAlign:"right",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "12px",
                  margin: theme.spacing(1, 0) }
              } }>
              { "Ultimo aggiornamento: dicembre 2023" }
            </Typography>
          </TextContent>
        </CookieStack>
      </GridMain>
    </>
  );
}
