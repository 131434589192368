import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HandleChangeProps, ModalEcmTypes, UserTypeBody } from "./ModalEcm.types";
import { 
  ModalTopPart, 
  StyledModalEcm, 
  StyledError, 
  StyledActions } from "./modalsEcomm.styles";
import { SponsorStep } from "./SponsorStep.component";
import { UserTypeStep } from "./UserTypeStep.component";
import { GuardedLink, Icon } from "../../components";
import { ECM_USER } from "../../consts";
import { ecmItemType, usePostEcmRegistration } from "../../queries/ecm";

export function ModalEcmComponent({
  enrollId,
  invalidateLearningObects,
  loId,
  open,
  onClose,
  sponsors
}:ModalEcmTypes){
 
  const { t }=useTranslation();
  const theme = useTheme();

  const loSponsors = sponsors?.map((sponsor, index)=> {
    return {
      id: index,
      profession_identifier:null,
      title:sponsor
    };
  }) ?? [] as ecmItemType[] | [];

  const defaultUserType = {
    disciplines:[],
    isSponsored:false,
    professions:[],
    sponsors: [],
    type:ECM_USER.EMPLOYEE
  };

  const [activeStep, setActiveStep]=useState<"userType" | "sponsor">("userType");
  const [buttonDisable, setButtonDisable]=useState(true);
  const [userTypeBody, setUserTypeBody]= useState<UserTypeBody>(defaultUserType);
  const [closeModal,setCloseModal]= useState(false);
  const [enoughDisiciplineError,setEnoughDisciplineError]=useState(false);


  
  const { mutate:postEcmRegistration, isSuccess:ecmRegistrationIsSuccess } = usePostEcmRegistration({
    disciplines:cleanBodyItems(userTypeBody?.disciplines ?? []),
    enrollId:enrollId,
    isProfessional:Boolean(userTypeBody?.type === ECM_USER.FREELANCE),
    isSponsored:Boolean(userTypeBody?.isSponsored),
    loId:loId,
    professions:cleanBodyItems(userTypeBody?.professions ?? []),
    sponsors:cleanBodyItems(userTypeBody?.sponsors ?? [], loId)
  });

  useEffect(()=> {
    if(ecmRegistrationIsSuccess){
      invalidateLearningObects();
      onClose();
    }
  },[
    ecmRegistrationIsSuccess, 
    invalidateLearningObects,
    onClose]);

  useEffect(() => {
    setButtonDisable(true);

    if(activeStep === "userType") {
      if( userTypeBody.professions.length && 
        userTypeBody.disciplines.length ) {
        setButtonDisable(false);
      }
    } else if(userTypeBody.isSponsored && userTypeBody?.sponsors?.length || !userTypeBody.isSponsored) {
      setButtonDisable(false);
    }
    else {
      setButtonDisable(true);
    }
  }, [activeStep, userTypeBody]);


  return (
    <StyledModalEcm 
      open={ open }
      onClose={ onClose }
    >
      <>
        <Stack 
          className="scrollable"
          display={ closeModal ? "flex " : "none" }
        >
          <ModalTopPart>
            <div className="title"> 
              { t("sure_exit") }</div>
            <div className="description"> 
              { t("delete_info") }</div>
          </ModalTopPart>
          <Box
            display={ "flex" }
            gap={ 2 }
          >
            <GuardedLink
              variant="outlined"
              href=""
              onClick={ () => {
                setCloseModal(false);
                return false;
              } }
            >
              { t("annul") }
            </GuardedLink> 
            <GuardedLink
              href="" 
              onClick={ () => {
                setCloseModal(false);
                onClose();
                return false;
              } }
            >
              { t("esc") }
            </GuardedLink>
          </Box>
        </Stack>
        <Stack 
          className="scrollable"
          display={ !closeModal ? "flex" : "none" }
        >
          <Stack 
            alignSelf={ "flex-end" }
            onClick={ ()=> setCloseModal(true) }
            sx={ {
              cursor:"pointer"
            } }
          >
            <Icon 
              color={ theme.customColors.systemPrimary02 }
              size={ 18 }
              icon="close"/>
          </Stack>
          <ModalTopPart>
            <div className="title"> 
              { t("registration_complete") }
            </div>
            <div className="description"> 
              { t("registration_description") }
            </div>
          </ModalTopPart>
          {
            enoughDisiciplineError ? 
              <StyledError> 
                <Icon 
                  color={ theme.customColors.textWarning }
                  size={ 16 }
                  icon="Icons_obbligatorio"/>
                { t("discipline_complete") }
              </StyledError> : null
          }
          {
            activeStep === "userType" ? 
              <UserTypeStep
                setUserType={ setUserTypeBody }
                userTypeBody={ userTypeBody }
              /> : 
              <SponsorStep
                setUserType={ setUserTypeBody }
                sponsors={ loSponsors }
                userTypeBody={ userTypeBody }
              />
          }
          { !(activeStep === "sponsor" && !userTypeBody.isSponsored) && <Typography 
            marginBottom={ theme.spacing(2) }
            marginTop={ theme.spacing(1) }
            width={ "100%" }
          >*{ t("required_fields") }</Typography> }
          <StyledActions>
            { activeStep === "sponsor" && <GuardedLink
              variant="outlined"
              onClick={ ()=> {
                setActiveStep("userType");
              } }
            >
              { t("back") }
            </GuardedLink> }
            <GuardedLink
              disabled={ buttonDisable }
              onClick={ ()=> {
                handleSubmit();
              } }
            >
              { t("continue") }
            </GuardedLink>
          </StyledActions>
        </Stack>
      </>
    </StyledModalEcm>

  );

  function handleSubmit(){
    setEnoughDisciplineError(false);
    if(
      activeStep === "sponsor"
    ){
      postEcmRegistration();
    }
    else {
      if(handleValidate(userTypeBody.professions, userTypeBody.disciplines)){
        setActiveStep("sponsor");
      }
      else {
        setEnoughDisciplineError(true);
      }
    }
    return false;
  }
  function handleValidate(professions:ecmItemType[] | [], disciplines:ecmItemType[] | []){    
    return professions.every(profession => {
      return disciplines?.some(discipline =>profession.id === discipline.profession_identifier );
    });
  }
}

export function handleSetSelectedValues(
  {
    options,
    selectedValues,
    setSelectedOptions,
    setSelectedValues
  }:HandleChangeProps
) {
  setSelectedOptions(selectedValues);
  setSelectedValues(
    options?.flatMap((option)=> {
      if(selectedValues?.some((item => item === option.id))){
        return {
          id:option.id,
          profession_identifier:option.profession_identifier,
          title:option.title
        };
      } else {
        return [];
      }
    }));
}
export function resetOptionSelected({
  itemToFilter,
  selectionTarget,
  setSelectionTarget
}:{
  itemToFilter:number,
  setSelectionTarget:Dispatch<SetStateAction<number[]| []>>
  selectionTarget:number[]| []
}) {
  setSelectionTarget(selectionTarget.filter(target => target !== itemToFilter));
}
export function setDisciplineSelectedOption(disciplineSelected:ecmItemType, professions?:ecmItemType[]){
  const labelProfession = professions?.find(
    profession => profession.id === disciplineSelected.profession_identifier)?.title;
  const label = labelProfession ? disciplineSelected.title + ` (${labelProfession})` : disciplineSelected.title;
  return {
    ...disciplineSelected,
    title:label
  };
  
}
export function setOptions(apiOptions:ecmItemType[], professions?:ecmItemType[]){
  return apiOptions.map(option => {
    const labelProfession = professions?.find(profession => profession.id === option.profession_identifier)?.title;
    const label = labelProfession ? option.title + ` (${labelProfession})` : option.title;
    return {
      children:label,
      value:option.id
    };
  });
}
export function deleteItemSelected({
  itemToFilter,
  selectionTarget,
  setSelectionTarget
}:{
  itemToFilter:number,
  setSelectionTarget:Dispatch<SetStateAction<ecmItemType[]| []>>
  selectionTarget:ecmItemType[]| []
}){
  setSelectionTarget(selectionTarget.filter(target => target.id !== itemToFilter));
}
export function StyledIconArrow({ isDisabled,isOpen, ...props } : { isOpen: boolean, isDisabled:boolean }) {
  const theme = useTheme();

  return <Box
    sx={ {
      alignSelf: "center",
      display: "flex",
      right: "16px",
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
    } }
    { ...props }
  >
    <Icon 
      icon={ "dropdown" } 
      width={ "20px" }
      color={ isDisabled ? theme.customColors.systemDisabled : theme.customColors.backgroundPrimaryCta }
    />
  </Box>;
}
export function cleanBodyItems(items:ecmItemType[] | [], loId?: number){
  return items?.map(item => {
    return {
      id: loId ? loId : item.id,
      title: item.title
    };
  }) ?? [];
}
