import { create } from "zustand";
import type { Language } from "../types";
import {
  getLanguage
} from "../utils/language";

type LanguageState = {
  readonly languageCookie?: Language
  readonly language?: Language
}

export type LanguageActions = {
  readonly setLanguage: (language: Language, languageCookie?: Language) => void
}

const initialLangaugeState: LanguageState = {
  language: undefined,
  languageCookie: getLanguage()
};

export const useLanguageStore = create<LanguageState & LanguageActions>((set) => ({
  ...initialLangaugeState,
  setLanguage: (language, languageCookie?: Language) => {
    set({ language });
    languageCookie && set({ languageCookie });
    const lang = document.querySelector("html");
    lang?.setAttribute("lang", language.id);
  }
}));
