import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useParams  } from "react-router-dom";
import { CommunityDetailsMainStack } from "./CommunityDetails.styles";
import { 
  GridMain,
  TopicCardDetails,
  WidgetDrawer
} from "../../components";
import { useAuthStore } from "../../stores";

export function CommunityDetails() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { t } = useTranslation();
  const { state: routerState } = useLocation();

  const { id } = useParams();
  
  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);
  const funcCommunity = useAuthStore(state => state.smartConfiguration?.funcCommunity);

  if(
    enableCommunityFlag === false
    || (enableCommunityFlag === true && funcCommunity === false)
  ) {
    return (
      <GridMain
        justifyContent={ "center" }
      >
        <Typography 
          padding={ "42px 80px" }
          textAlign={ "center" }
        >
          { t("no_community_available") }
        </Typography>
      </GridMain>
    );
  }

  return (
    <>
      <WidgetDrawer />

      <CommunityDetailsMainStack>
       
        { id &&
          <TopicCardDetails 
            id={ id } 
            viewComment={ routerState?.viewComment ?? false }
          />
        }

      </CommunityDetailsMainStack>
    
    </>

  );
}
  
