import { Button as ButtonComponent } from "./Button.component";
import { ButtonProps } from "./Button.types";
import {
  ButtonBookmark as ButtonBookmarkProps,
  ButtonText as ButtonTextProps,
  ButtonLink as ButtonLinkProps
} from "./Button.variants";

export const ButtonBookmark = ({ ...props }: Partial<ButtonProps>) => (
  <ButtonComponent { ...{ ...ButtonBookmarkProps, ...props } } />
);

export const ButtonText = ({ ...props }: Partial<ButtonProps>) => (
  <ButtonComponent { ...{ ...ButtonTextProps, ...props } } />
);

export const ButtonLink = ({ ...props }: Partial<ButtonProps>) => (
  <ButtonComponent { ...{ ...ButtonLinkProps, ...props } } />
);

export type { ButtonProps } from "./Button.types";
export const Button = ButtonComponent;
