import {
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GoalsModalSuccessProps } from "./GoalsModalSuccess.types";
import { ButtonText, Icon } from "..";

export function GoalsModalSuccess({
  closeDrawer,
  onClose,
  open,
  ...props
}: GoalsModalSuccessProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Popover
        className={ "popover-goals-success" }
        anchorEl={ "anchorEl" in props ? props.anchorEl : null }
        open={ open }
        onClose={ onClose }
        sx={ {
  
          ".MuiPopover-paper":{
            background: theme.linearGradients.gradientB,
            border: "none",
            borderRadius:"4px !important",
            boxShadow:`50px 50px 80px 0px ${theme.customColors.backgroundDisabled}`,
            height: "auto",
            maxHeight: "531px",
            width: "auto",
            overflow:"visible",
            padding: "24px 40px 40px",
            top: "200px !important",

            [theme.breakpoints.down("sm")]: {
              border: `1px solid ${theme.customColors.borderAccent} !important`,
              borderRadius:"8px 8px 0 0 !important",
              bottom: "60px",
              boxShadow: `0px 0px 4px 0px ${theme.customColors.borderAccent}`,
              left: "2px !important",
              margin: "0 auto",
              maxHeight: "100%",
              maxWidth: "calc(100% - 4px)",
              padding: "16px 20px",
              top: "unset !important",
              width: "calc(100% - 4px)"   
            }
          },

          ".MuiStack-root ": {
            gap: "0"
          },

          ".icon-close": {
            display:"flex !important",
            marginBottom: "24px",
            marginLeft: "auto",

            [theme.breakpoints.down("sm")]: {
              marginBottom: "8px"  
            }
          }
        } }
        { ...props }
      >
        <Icon
          className="icon-close"
          icon={ "close" }
          size={ "19px" }
          cursor="pointer"
          color={ theme.palette.primary?.main }
          onClick={ ()=> {
            if(closeDrawer){
              closeDrawer();
            }
          } }

        />
        <Stack alignItems={ isMobile ? "flex-start" : "center" }>
          <Typography
            sx={ {
              color: theme.customColors.textPrimary,
              fontSize: "32px",
              fontWeight: "700",
              lineHeight: "36px",
              marginBottom: "8px",
              textAlign: "center",

              [theme.breakpoints.down("sm")]: {
                fontSize: "24px",
                lineHeight: "30px",
                marginBottom: "16px",
                textAlign: "flex-start"
              }
            } }
            aria-labelledby="modal-modal-title"
          >
            {
              t("goals_success_title")
            }
          </Typography>
          <Typography
            sx={ {
              color: theme.customColors.textPrimary,
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "24px",
              textAlign: "center",

              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "21px",
                textAlign: "flex-start"
              }
            } }
            aria-describedby="modal-modal-description"
          >
            {
              t("goals_success_description")
            }
          </Typography>
          <ButtonText 
            sx={ {
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "24px",
              maxWidth: "128px",

              [theme.breakpoints.down("sm")]: {
                marginTop: "32px" 
              }
            } }
            onClick={ ()=> {
              if(closeDrawer){
                closeDrawer();
              }
            } }
          >
            {
              t("continue")
            }
          </ButtonText>
        </Stack>
      </Popover>
    </>
  );
}
