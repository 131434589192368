import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import {
  useEffect,
  useRef
} from "react";
import { useTranslation } from "react-i18next";
import {
  StyledCardRanking,
  StyledGridUsers,
  StyledUserItem
} from "./CardRanking.styles";
import { CardRankingProps } from "./CardRanking.types";
import {
  Icon,
  Spinner
} from "../../components";
import { TREND } from "../../consts";
import { useIntersectionObserver } from "../../hooks";
import { useLeaderboardQuery } from "../../queries/widgets";
import { useAuthStore } from "../../stores";
import type { LeaderboardItem } from "../../types";

const blankStudent = {
  profilePic: null,
  studentName: "-------"
} as const;

export function CardRanking({
  isUserPage=false
}: CardRankingProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const studentsContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    data: studentPages,
    hasNextPage: hasMoreStudents,
    fetchNextPage: fetchMoreStudents,
    isError: isErrorStudents,
    isFetchingNextPage: isFetchingMoreStudents,
    isInitialLoading: isLoadingStudents
  } = useLeaderboardQuery({
    pageSize: 10
  });

  const intersectionObserver = useIntersectionObserver({
    callback: () => { hasMoreStudents && !isFetchingMoreStudents && fetchMoreStudents() },
    container: studentsContainerRef
  });

  const students = studentPages?.pages
    .reduce((acc, page) => {
      return acc.concat(page?.leaderboards);
    }, [] as LeaderboardItem[]) ?? [];

  const filteredStudents =
    (smartConfiguration?.leaderboardAnonymous === true)
      ? students?.map((student) =>
        student?.isCaller ? student : Object?.assign(student, blankStudent)
      )
      : students;

  return smartConfiguration?.leaderboard ? (
    <StyledCardRanking $profilePage={ isUserPage } >
      <Stack
        alignItems="center"
        direction="row"
        gap={ isUserPage ? 1 : 0 }
      >
        <Typography
          fontWeight={ 700 }
          fontSize={ isUserPage ? theme.spacing(3) : undefined }
          sx={ {
            [theme.breakpoints.down("sm")]:{
              fontSize: theme.spacing(1.75)
            },
            marginRight: "10px"

          } }
        >
          { t("your_ranking") }
        </Typography>
        {
          smartConfiguration?.leaderboardAnonymous === true ? (
            <InfoTooltip
              arrow
              title={
                <Typography>
                  { t("anonymous_ranking") }
                </Typography>
              }
            >
              <Box>
                <Icon
                  icon="Icons_info"
                  size={ 20 }
                />
              </Box>
            </InfoTooltip>
          ) : null
        }
      </Stack>
      {
        (isUserPage && filteredStudents.length > 0) ? (
          <StyledUserItem
            container
            $isActive={ false }
            $isUserPage={ isUserPage }
            display="flex"
            sx={ { borderTop: "none" } }
          >
            <Grid
              item
              xs={ 2.5 }
              component={ Typography }
            >
              #
            </Grid>
            <Grid
              item
              xs={ 6 }
              component={ Typography }
              fontWeight={ 500 }
            >
              { t("members") }
            </Grid>
            <Grid
              item
              xs={ 3 }
              component={ Typography }
              fontWeight={ 500 }
            >
              { t("score") }
            </Grid>
          </StyledUserItem>
        ) : null
      }
      {
        (isLoadingStudents) ? (
          <Spinner />
        ) : null
      }
      {
        studentPages?.pages?.[0]?.callerRanking ? (
          <Student
            isUserPage={ isUserPage }
            observer={ intersectionObserver }
            observeStudent={ false }
            student={ studentPages.pages[0].callerRanking }
          />
        ) : null
      }
      {
        (filteredStudents.length > 0) ? (
          <StyledGridUsers
            className="scrollable"
            $profilePage={ isUserPage }
            ref={ studentsContainerRef }
          >
            {
              Boolean(intersectionObserver) && filteredStudents.map((user, idx)=> {
                return (
                  <Student
                    key={ `student--${user.studentEid}` }
                    isUserPage={ isUserPage }
                    observer={ intersectionObserver }
                    observeStudent={
                      (idx + 1) === (filteredStudents.length - 2)
                    }
                    student={ user }
                  />
                );

              })
            }
          </StyledGridUsers>
        ) : null
      }
      {
        (!isLoadingStudents && filteredStudents.length === 0) ? (
          <Typography>
            { t("ranking_no_content") }
          </Typography>
        ) : null
      }
      {
        (isErrorStudents) ? (
          <Typography>
            { t("ranking_error") }
          </Typography>
        ) : null
      }
    </StyledCardRanking>
  ) : null;
}

function Student({
  isUserPage,
  observer,
  observeStudent=false,
  student
  // ...props
} : {
  isUserPage: boolean
  observer: IntersectionObserver | null
  observeStudent?: boolean
  student: LeaderboardItem
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const selfRef = useRef<HTMLDivElement | null>(null);

  const abbreviatedName = student?.studentName
    ?.split(" ")
    ?.slice(0, 2)
    ?.map((slice) => slice[0]);

  const displayName = student?.studentName
    ?.split(" ")
    ?.map((split, idx) =>
      idx === 0 || split.length < 2
        ? split
        : `${split[0]}.`
    )
    .join(" ");

  useEffect(() => {
    observeStudent && selfRef.current && observer?.observe(selfRef.current);
  }, [observer, observeStudent]);

  return (
    <StyledUserItem
      key={ `ranking-item--${student?.studentEid}` }
      container
      ref={ selfRef }
      $isActive={ student?.isCaller }
      $isUserPage={ isUserPage }
      // { ...props }
    >
      <Grid
        item
        xs={ isUserPage ? 2.5 : 3 }
        alignItems="center"
        component={ Typography }
        display="flex"
        gap={ theme.spacing(1.75) }
        justifyContent="center"
        whiteSpace="nowrap"
        sx={ {
          ".icon":{
            "path":{
              fill:student?.trend === TREND.EQUALS ? "none !important"
                : undefined  ,
              stroke: student?.trend === TREND.EQUALS ? student?.isCaller ?
                `${theme.customColors.systemSecondary05} !important`
                : `${theme.customColors.systemPrimary01} !important` : undefined
            }
          }
        } }
      >
        <Icon
          color={
            student?.isCaller
              ? theme.customColors.systemSecondary05
              : (student?.trend === TREND.UP)
                ? theme.customColors.systemSecondary01
                :(student?.trend === TREND.DOWN)
                  ? theme.customColors.systemSecondary03
                  : theme.customColors.systemPrimary01
          }
          icon={ student?.trend === TREND.EQUALS ? "Icons_Equal" :"dropdown" }
          size={ 20 }
          style={ {

            transform:
              student?.trend === TREND.UP
                ? "rotate(180deg)"
                : undefined
          } }
        />
        { student?.ranking ?? "--" }
      </Grid>
      <Grid
        item
        xs={ isUserPage ? 6 : 5.5 }
        alignItems="center"
        display="flex"
        gap={ theme.spacing(0.5) }
        justifyContent="flex-start"
        maxWidth="50%"
        width="100%"
      >
        {
          (smartConfiguration?.leaderboardAnonymous === false || student?.isCaller) ? (
            <Avatar
              alt={ student?.studentName }
              // sizes=theme.spacing(3)
              src={ student?.profilePic }
              sx={ {
                fontSize: theme.spacing(1.5),
                height: theme.spacing(3),
                width: theme.spacing(3)
              } }
            >
              { abbreviatedName }
            </Avatar>
          ) : (
            <Icon
              icon="Icons_Profilo"
              size={ 20 }
            />
          )
        }
        <Divider
          orientation="vertical"
          sx={ {
            color: "transparent",
            width: "0.3rem"
          } }
        />
        <Typography
          sx={ {
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          } }
        >
          { isUserPage ? student?.studentName : displayName }
        </Typography>
      </Grid>
      <Grid
        item
        xs={ 3 }
        component={ Typography }
        textAlign="right"
        whiteSpace="nowrap"
      >
        { student?.totalScore + ` ${t("score_text")}`  }
      </Grid>
    </StyledUserItem>
  );
}

const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip { ...props } classes={ { popper: className } } />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.customColors.textPrimary
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.linearGradients.gradientB,
    border: `1px solid ${theme.customColors.borderTag}`,
    borderRadius: theme.spacing(1),
    fontSize: theme.spacing(1.5),
    maxWidth: "240px",
    padding: theme.spacing(1),
    textAlign: "center"
  }
}));


