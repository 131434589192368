function format(value?: string | Object, format?: string, lng?: string): string {
  if (format === "date" && typeof lng === "string") {
    return formatDate(value as Date, lng);
  } else {
    return value?.toString() || "";
  }
}

/**
 * formatDate,
 * formats a date input according to a specified locale
 * @param date a date value in milliseconds, a date string or a Date instance 
 * @param lng a string with a valid language ISO code (TODO: better typing)
 * @returns a string with the localized value of the data
 */
export function formatDate(date: Date | number | string, lng: string): string {
  if (typeof date === "number" || typeof date === "string") {
    return new Intl.DateTimeFormat(lng).format(new Date(date));
  } else if (typeof date === "object" && date instanceof Date) {
    return new Intl.DateTimeFormat(lng).format(date);
  } else {
    throw new TypeError("date should be either a number, a string or a Date");
  }
}

export default format;
