import {
  Avatar,
  Button,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import {
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  ActionButton,
  AuthorTypography,
  CommentStack,
  CommentTypography,
  NameTypography
} from "./Comment.style";
import type { CommentProps } from "./Comment.types";
import {
  ButtonText,
  Icon,
  Modal,
  Spinner,
  TopicCardSignature
} from "../../components";
import {
  CommentInputBase,
  ForbiddenLabelTypography
} from "../../components/CommentsSection/CommentsSection.style";
import {
  useCommentDeleteQuery,
  useCommentPutQuery,
  useInfiniteReplyQuery,
  useReplyPostQuery
} from "../../queries/community";
import { useAuthStore } from "../../stores";
 
export function Comment({
  authorName,
  changed,
  commentBody,
  commentOwner,
  created,
  discussionId,
  id,
  image,
  isReply,
  parentCommentId,
  replies,
  selectedFilter, 
  selectedTopic,
  topicOwnerId

} : CommentProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const userId = useAuthStore(state => state.userData?.id_user);
 
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showReplyComment, setShowReplyComment] = useState<boolean>(false);
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<"reply" | "modify">("reply");
  const [inputContent, setInputContent] = useState<string>("");
  const [showReplies, setShowReplies] = useState<boolean>(false);
  const [showForbiddenlabel, setShowForbiddenlabel] = useState(false);

  const isModified = created !== changed;
 
  const {
    data: results,
    fetchNextPage,
    hasNextPage,
    // isFetchingNextPage,
    // isFetching,
    isLoading
    // refetch: refetchReplies
  } = useInfiniteReplyQuery({ commentId: id, enabled: showReplies });
 
  const {
    mutate: mutateReply,
    isLoading: replyIsLoading,
    isIdle: replyIsIdle,
    isError: replyIsError,
    error: replyError
  } = useReplyPostQuery();
 
  const {
    mutate: mutateModifyComment,
    isLoading: modifyIsLoading,
    isIdle: modifyIsIdle,
    isError: modifyIsError,
    error: modifyError
  } = useCommentPutQuery();
 
  const {
    mutate: mutateDeleteComment,
    isLoading: deleteIsLoading,
    isIdle: deleteIsIdle,
    isError: deleteIsError
  } = useCommentDeleteQuery({ 
    commentId: id, 
    discussionId: discussionId, 
    parentCommentId: parentCommentId,
    selectedFilter:selectedFilter,
    selectedTopic:selectedTopic
  });
 
  //managing fobidden word for reply + showing replies + hide input
  useEffect(() => {
    if(!replyIsIdle && !replyIsLoading) {
      if(replyIsError) {
        const errorObject = JSON.parse(JSON.parse(replyError as string).errors.FATAL[0].message);
        if(errorObject.message == "Forbidden word has been detected.") {
          setShowForbiddenlabel(true);
        }
        setShowReplyComment(false);
      } else {
        setShowReplyComment(false);
        setShowInput(false);
        setShowReplies(true);
      }
    }
  },[replyError, replyIsError, replyIsIdle, replyIsLoading]);
 
  //managing comment/reply deletion
  useEffect(() => {
    if(!deleteIsIdle && !deleteIsLoading) {
      if(deleteIsError) {
        console.log("comment deletion error");
      }
      setShowInput(false);
    }
  },[deleteIsError, deleteIsIdle, deleteIsLoading]);
 
  //managing fobidden word for modify
  useEffect(() => {
    if(!modifyIsIdle && !modifyIsLoading) {
      if(modifyIsError) {
        const errorObject = JSON.parse(JSON.parse(modifyError as string).errors.FATAL[0].message);
        if(errorObject.message == "Forbidden word has been detected.") {
          setShowForbiddenlabel(true);
        }
      } else {
        setShowInput(false);
      }
    }
   
  },[modifyError, modifyIsError, modifyIsIdle, modifyIsLoading]);
 
  return (
    <Stack direction="row" gap={ "16px" }>
      <Stack direction="row" gap={ "16px" } 
        sx={ { 
          paddingTop: "16px", width: "100%" ,   
          
          [theme.breakpoints.down("sm")]:{
            gap: "8px"
          }
        } }>
        <Stack gap={ "16px" }>
          <Avatar alt={ authorName }src={ image } >
            {
              authorName.length > 1
                ? `${authorName.split(" ")?.[0]?.[0]}${authorName.split(" ")?.[1]?.[0]}`
                : authorName.length > 0
                  ? authorName.split(" ")?.[0]?.[0]
                  : ""
            }
          </Avatar>
          { !isReply && Number(replies) > 0 &&
            <Divider
              orientation="vertical"
              flexItem
              sx={ {
                borderColor: theme.customColors.border,
                flexGrow: "1",
                margin: "0 20px"
              } } />
          }
        </Stack>
        <Stack sx={ { flexGrow: "1" } }>
          <CommentStack gap={ "8px" }>
            <Stack gap={ "4px" }>
              <Stack direction={ "row" } gap={ "8px" } alignItems={ "center" }>
                <NameTypography>{ authorName }</NameTypography>
                { (commentOwner === topicOwnerId ) &&
                  <AuthorTypography>{ t("author") }</AuthorTypography>
                }
              </Stack>
              <CommentTypography>{ commentBody }</CommentTypography>
            </Stack>
            <TopicCardSignature
              created={ getcreatedLabel(created) }
              isModified={ isModified }
              size={ "small" }
            />
         
          </CommentStack>
          { showInput ?
            <Stack>
              { showForbiddenlabel &&
                <ForbiddenLabelTypography
                  key={ "forbidden-label-reply" }
                  sx={ {
                    position: "relative",
                    top: "8px"
                  } }
                >
                  { t("forbidden_label_comment") }
                </ForbiddenLabelTypography>
              }
              <Stack
                direction={ "row" }
                gap={ "16px" }
                paddingTop={ "8px" }
              >
                <CommentInputBase
                  defaultValue={ !showReplyComment ? commentBody : "" }
                  onChange={ (changeEvent) => {
                    setShowForbiddenlabel(false);
                    setInputContent(changeEvent.target.value);
                  } }
                  onKeyUp={ (keyboardEvent) => {
                    if (keyboardEvent.code === "Enter") {
                      if(selectedAction == "modify") {
                        mutateModifyComment({
                          body: inputContent,
                          commentId: id,
                          discussionId: discussionId,
                          parentCommentId: parentCommentId
                        });
                      } else {
                        mutateReply({ body: inputContent, commentId: id, discussionId: discussionId });
                      }
                    }
                  } }
                  placeholder={ 
                    selectedAction == "modify" ? 
                      t("enter_change") : 
                      t("enter_answer") 
                  }
                  endAdornment={
                    <Icon
                      icon={ "close" }
                      width={ "16px" }
                      height={ "16px" }
                      color={ theme.palette.primary?.main }
                      onClick={ () => {
                        setShowForbiddenlabel(false);
                        setShowInput(false);
                      } }
                      style={ {
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px"
                      } }
                    />
                  }
                />
                <ButtonText
                  onClick={ () => {
                    //setShowInput(false);
                    if(selectedAction == "modify") {
                      mutateModifyComment({
                        body: inputContent,
                        commentId: id,
                        discussionId: discussionId,
                        parentCommentId: parentCommentId
                      });
                    } else {
                      mutateReply({ body: inputContent, commentId: id, discussionId: discussionId });
                    }
                  } }
                  sx={ {
                    borderRadius:"4px",
                    minWidth:"42px"
                  } }
                  disabled={ inputContent.length == 0 }
                >
                  <Icon
                    icon={ "arrow_right_horizontal" }
                    width={ "20px" }
                    height={ "16px" }
                    color="black"
                  />
                </ButtonText>
              </Stack>
            </Stack> :
            showRemove ?
              <Stack direction={ "row" } gap={ "8px" }>
                <Typography>{ "Sei sicuro di voler eliminare questo commento?" }</Typography>
                <ActionButton onClick={ () => {
                  mutateDeleteComment();
                } }>
                  { t("delete") }
                </ActionButton>
                <ActionButton onClick={ () => setShowRemove(false) }>
                  { "Annulla" }
                </ActionButton>
              </Stack> :
              <Stack direction={ "row" } gap={ "8px" }>
                { !isReply &&
                  <ActionButton onClick={ () => {
                    setShowInput(true);
                    setShowReplyComment(true);
                    setInputContent("");
                    setSelectedAction("reply");
                  } }>
                    { t("reply") }
                  </ActionButton>
                }
                { userId && commentOwner == userId.toString() &&
                  <>
                    <ActionButton onClick={ () => {
                      setShowInput(true);
                      setInputContent(commentBody);
                      setSelectedAction("modify");
                    } }>
                      { t("modify") }
                    </ActionButton>
                    <ActionButton onClick={ () => {
                      setShowRemove(true);
                    } }>
                      { t("remove") }
                    </ActionButton>
                  </>
                }
              </Stack>
          }
          <Modal
            open={ showRemove && !deleteIsIdle && !deleteIsLoading && deleteIsError }
            onClose={ ()=> { setShowRemove(false) } }
            description={ "An error occurred while deleting comment" }
            title={ "Comment Deletion Error" }
          />
          { showReplies ?
            <Stack>
              { !isLoading && results &&
              results?.pages?.map((page) => (
                page?.data.map((comment) => {
 
                  const ownerUserId = comment.owner_id;
                  const ownerUser = page?.enrichedInformation?.users?.find(user => user.owner_id === ownerUserId);
             
                  return <Comment
                    selectedFilter={ null }
                    selectedTopic={ null }
                    key={ `comment-${comment.comment_id}` }
                    authorName={ `${ownerUser?.name} ${ownerUser?.surname}` || "" }
                    commentBody={ comment.body }
                    commentOwner={ comment.owner_id }
                    created={ comment.created }
                    id = { comment.comment_id }
                    image={ ownerUser?.profileImageUrl || "" }
                    isReply={ true }
                    replies={ comment.reply }
                    discussionId={ discussionId }
                    parentCommentId={ id }
                    changed={ comment.changed }
                    topicOwnerId={ topicOwnerId }

                  />;
                }
                )
              ))
              }
              { isLoading &&
                <Spinner
                  size={ 20 }
                  thickness={ 3 }
                  padding={ 0 }
                />
              }
              { hasNextPage &&
                <Button
                  onClick={ () => fetchNextPage() }
                  sx={ {
                    gap:"0.5rem",
                    margin:"0 auto",
                    width:"fit-content"
                  } }
                >
                  { t("load_more_replies") }
                  <Icon
                    color={ theme.customColors.systemPrimary02 }
                    icon="dropdown"
                    size={ 20 }
                  />
                </Button>
              }
            </Stack> :
            !isReply && Number(replies) > 0 &&
              <ActionButton onClick={ () => setShowReplies(true) }>
                { t("show_replies") }
              </ActionButton>
          }
        </Stack>
      </Stack>
    </Stack>
  );
 
  // duplicated in TopicCard Component
  function getcreatedLabel(created: string) {
    if(created) {
      const date = new Date(created);
      const now = new Date();
      const hours = Math.trunc(((now.getTime() - date.getTime()) / 1000 / 3600));
      if (hours < 1) return t("now");
      if (hours < 24) return `${t("hour", { count: hours })} ${t("ago")}`;
      if (hours < 48) return `${t("day", { count: 1 })} ${t("ago")}`;
      if (hours < 168) return `${t("day", { count: Math.floor(hours / 24) })} ${t("ago")}`;
      if (hours < 336) return `${t("week", { count: 1 })} ${t("ago")}`;
      else return format(date, "dd/MM/yyy");
    } else {
      return "undefined";
    }
  }
}
