import {
  Grid,
  GridProps,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ElementType,
  PropsWithChildren
} from "react";
import { MAIN_LATERAL_PADDING } from "../../consts";

export function GridMain ({
  children,
  component="main",
  ...props
}: PropsWithChildren<GridProps & { component?: ElementType}> ){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      component={ component }
      container
      alignSelf="center"
      flexGrow={ 1 }
      maxWidth={ isMobile ? "100%" : "1440px" }
      overflow="clip visible"
      padding={
        isMobile
          ? theme.spacing(0, 2.5, 5)
          : theme.spacing(
            0,
            (MAIN_LATERAL_PADDING / 8) // mui spacings are 8px wide
            , 10)
      }
      { ...props }
    >
      { children }
    </Grid>
  );
}
