import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardTabsLinkStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.6rem",
  cursor:"pointer",
  gap: theme.spacing(1),
  // maxWidth: "unset",
  padding: "1rem 1.5rem",
  width:"100%",

  "&.not-allowed":{
    cursor:"not-allowed"
  },

  [theme.breakpoints.down("sm")]:{
    padding: "0.5rem 1rem"
  }
}));
