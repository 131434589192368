import { useTheme, useMediaQuery, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BadgeGoals,
  GridBox,
  Icon,
  Link, Modal
} from "../../components";

import { useAchievementsQuery, useDownloadCertificate } from "../../queries";
import { useNavbarStore } from "../../stores";
import { getError } from "../../utils/error";
import { downloadBlobFile } from "../../utils/general";
import { CertificateInfo } from "../CertificateButton/CertificateButtonContainer.types";

export function ContentGoals (){

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const [certificatesInDownload, setCertificatesInDownload] = useState<number[] | null>(null);
  const [showModal, setShowModal] = useState(false);


  const {
    data: learnerAchievements
  } = useAchievementsQuery({});

  const {
    error: downloadCertificateError,
    isError: isDownloadCertificateError,
    mutateAsync: mutateDownloadCertificate } = useDownloadCertificate();

  useEffect(() => {
    isDownloadCertificateError && setShowModal(true);
  }, [isDownloadCertificateError]);

  const downloadCertificateHandler = async (certificateInfo: CertificateInfo)=>{
    setCertificatesInDownload(certificatesInDownload => {
      return certificatesInDownload ? [...certificatesInDownload, certificateInfo.certificateId]
        : [certificateInfo.certificateId];
    });

    try {
      const certificateResponseBlob = await mutateDownloadCertificate(certificateInfo);
      downloadBlobFile({
        blobFile: certificateResponseBlob,
        fileName: `issued_certificate_lo_${certificateInfo.learningObjectId}_learner_${certificateInfo.learnerId}.pdf`
      });
    }finally {
      setCertificatesInDownload(certificatesInDownload => {
        if (certificatesInDownload && certificatesInDownload.length > 0){
          const filteredIds = certificatesInDownload.filter(id => id !== certificateInfo.certificateId);
          if (filteredIds.length < 1){
            return null;
          }
        }
        return null;
      });
    }
  };

  return (

    <GridBox
      columns={ (!learnerAchievements || !(learnerAchievements.length > 0 )) ? 1 : isMobile ? 4 : isTablet ? 4  : 6 }
      gap={ 2 }
      sx={ {
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns:(learnerAchievements && learnerAchievements?.length > 0)
            ? "repeat(4, 1fr)"
            : "unset",
          justifyContent: "flex-start"
        }
      } }
    >
      {
        (!learnerAchievements || !(learnerAchievements.length > 0 )) ? (
          <>
            <Typography
              fontSize={ isMobile ? "1rem" : "1.5rem" }
              fontWeight={ 700 }
              lineHeight={ "1.25" }>
              { t("no_certification") }
            </Typography>
            <Typography
              fontSize={ isMobile ? "0.875rem" : "1.25rem" }
              lineHeight={ isMobile ? "1.3125" : "1.05" }
            >
              { t( "new_certifications") }
            </Typography>
            <Link
              display="flex"
              alignItems="center"
              href={ "/esplora" }
              gap={ "10px" }
              onClick={ ()=> {
                setNavbarItem("EXPLORE");
              } }
            >
              <Typography
                sx={ {
                  alignItems:"center",
                  fontSize: isMobile ? "12px" : theme.spacing(2.25),
                  fontWeight:500,
                  gap:theme.spacing(1),
                  justifyContent:"flex-start",
                  lineHeight:"1",

                  ".icon path":{
                    fill:theme.palette.primary?.main
                  }
                } }
              >
                { t("go_to_explore") }
              </Typography>
              <Icon
                icon={ "arrow_right_horizontal" }
                color={ theme.customColors?.systemPrimary02 }
                size={ 20 }/>
            </Link>
          </>
        ) : (
          learnerAchievements?.map((badge) => (
            <BadgeGoals
              key={ `badge-${badge.certificate?.id || badge.badge?.id}` }
              small={ false }
              badgeDisabled={ true }
              badge={ badge.badge }
              certificate={ badge.certificate }
              downloadCertificate={ downloadCertificateHandler }
              isDownloading={ !!( badge.certificate?.id &&
                certificatesInDownload &&
                certificatesInDownload.indexOf(badge.certificate?.id)>=0)
              }
            />
          ))
        )
      }
      <Modal
        open={ showModal && isDownloadCertificateError }
        onClose={ ()=> { setShowModal(false) } }
        description={ getError(downloadCertificateError)?.[0]?.message ?? "" }
        title={ getError(downloadCertificateError)?.[0]?.name ?? "" }
      />
    </GridBox>
  );
}
