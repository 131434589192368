import {
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type {
  // SmartConfiguration,
  SmartConfigurationGeneric
} from "../types";
import { getEnvironmentVariables } from "../utils/general";
  
const { basePath } = getEnvironmentVariables();

// function getSmartConfiguratorQuery(
//   refreshSession: () => Promise<void>,
//   {
//     accessToken,
//     enabled,
//     queryKey,
//     refetchOnMount,
//     sessionToken,
//     url
//   } : {
//     accessToken: string
//     enabled: boolean
//     queryKey: string[]
//     refetchOnMount: RefetchOnMount
//     sessionToken: string
//     url: string
//   }
// ) {
//   const fetcher = fetcherFactory();
  
//   return {
//     enabled,
//     queryFn: () => fetcher(
//       url,
//       {
//         headers: {
//           "Authorization": accessToken,
//           "x-ada-session-token": sessionToken
//         }
//       })
//       .then((res) => {
//         return res.json();
//       })
//       .then((data: SmartConfiguration | SmartConfigurationGeneric) => {
//         if (Array.isArray(data)) {
//           return apiAdapter(data);
//         } else if (
//           typeof data === "object"
//           && data !== null
//         ) {
//           return apiAdapter([data])[0];
//         }
//       })
//       .catch((error) => {
//         if (error === ERRORS.UNAUTHORIZED) {
//           refreshSession();
//           throw error; // rethrow so that react query doesn't complain about undefined return value
//         } else {
//           throw error;
//         }
//       }),
//     queryKey,
//     refetchOnMount
//   };
// }

// moved to auth store to interact with sign out redirect
// export function useSmartConfiguratorQuery({
//   enabled=true,
//   queryKey=[]
// } : {
//   enabled?: boolean
//   queryKey?: string[]
// }){
//   const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
//   const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
//   const userData = useAuthStore(state => state.userData);
//   const refreshSession = useAuthStore(state => state.refreshSession);
  
//   const corporateId = userData?.organization_id;
//   const initiativeId = userData?.initiative_id;
  
//   const queryUri = `${basePath}/smartconfigurator/configurations/${corporateId}/${initiativeId}`;
  
//   return useQuery<
//     unknown,
//     unknown,
//     SmartConfiguration,
//     QueryKey
//   >(
//     getSmartConfiguratorQuery(
//       refreshSession,
//       {
//         accessToken,
//         enabled: enabled && Boolean(corporateId && initiativeId),
//         queryKey: [queryUri, ...queryKey],
//         sessionToken,
//         url: queryUri
//       }
//     )
//   );
// }

export function useSmartConfiguratorGenericQuery({
  enabled=true,
  queryKey=[]
} : {
  enabled?: boolean
  queryKey?: string[]
}) {
  const fetcher = fetcherFactory();
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  
  const corporateId = userData?.organization_id;
  const initiativeId = userData?.initiative_id;
  
  const uri = `${basePath}/smartconfigurator/generic/${corporateId}`;
  const params = `initiativeId=${initiativeId}`;
  
  return useQuery<
    unknown,
    unknown,
    SmartConfigurationGeneric[],
    QueryKey
  >({
    enabled: enabled && Boolean(
      accessToken
      && corporateId
      && initiativeId
      && sessionToken
    ) && !isSeedingSession,
    queryFn: () => fetcher(
      `${uri}?${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: SmartConfigurationGeneric[]) => {
        if (Array.isArray(data)) {
          return apiAdapter(data);
        } else if (
          typeof data === "object"
          && data !== null
        ) {
          return apiAdapter([data])[0];
        } else {
          return null; // may be an issue for reactQuery (no undefined allowed)
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [uri, params, isSeedingSession, ...queryKey],
    refetchOnMount: false
  });
}
  
