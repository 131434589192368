import {
  format
} from "date-fns";
import { 
  useTranslation 
} from "react-i18next";

export function useGetCreatedLabel(created: string) {
  const { t } = useTranslation();
  if(created) {
    const date = new Date(created);
    const now = new Date();
    const hours = Math.trunc(((now.getTime() - date.getTime()) / 1000 / 3600));
    if (hours < 1) return t("now");
    if (hours < 24) return `${t("hour", { count: hours })} ${t("ago")}`;
    if (hours < 48) return `${t("day", { count: 1 })} ${t("ago")}`;
    if (hours < 168) return `${t("day", { count: Math.floor(hours / 24) })} ${t("ago")}`;
    if (hours < 336) return `${t("week", { count: 1 })} ${t("ago")}`;
    else return format(date, "dd/MM/yyy");
  } else {
    return "undefined";
  }
}

export function getReadingTime(text: string) {
  const words = text.split(" ").length;
  return words / 2;  //2 words per second
}

export  function secondsToMinuteslabel(duration: number) {
  if(duration < 60) {
    return `${Math.round(duration)} sec`;
  } else {
    return `${Math.round(duration / 60)} min`;
  }
    
}
export function stringAvatar(name: string) {
  return {
    children:
      name?.length > 1
        ? `${name.split(" ")?.[0]?.[0]}${name.split(" ")?.[1]?.[0]}`
        : name?.length > 0
          ? name.split(" ")?.[0]?.[0]
          : ""
  };
}
