import { Stack, Typography, Button, useTheme, Box } from "@mui/material";
import { t } from "i18next";
import {   useLocation, useNavigate, useParams } from "react-router-dom";
import { GridMain,  Icon, Spinner, CarouselCard } from "../../components";
import { StyledButtonLink, StyledIcon } from "../../components/Video/Video.styles";
import { STATUS_LO } from "../../consts";
import { useInfiniteRelatedLearningObjectQuery } from "../../queries";
import { CardsBox } from "../../routes/Category/Category.styles";
import { useAuthStore } from "../../stores";
import type { LearningObject, MetaData } from "../../types";
import { createEndDate } from "../../utils/general";

export function ActivitiesRelatedPage(){
  const { id: idString } = useParams<{
    id: string
  }>();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  
  const { 
    data: relatedLearningActivities,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading } = useInfiniteRelatedLearningObjectQuery<Record<
    string,
    LearningObject[]
  > & {
    
    metadata: MetaData
  }>({
    learningObjectId: idString?? "",
    pageSize:9,
    // eslint-disable-next-line max-len
    path: "/learning-catalogue/{corporateId}/{initiativeId}/learningObjects/{learningObjectId}/relatedLearningActivities?pageNumber={pageNumber}&pageSize={pageSize}"    
  });
 
  const { state: routerState } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  //const typeLearning = useType(routerState.typeLearning, routerState.typologyLearning);
  // const typeLearning = routerState.typeLearning;
  // const typologyLearning = routerState.typologyLearning;
  
  const responseKey = relatedLearningActivities
    ? (Object.keys(relatedLearningActivities?.pages?.[0]).find((key) => key !== "metadata") ?? "")
    : "";
  
  const relatedCards = (relatedLearningActivities && responseKey)
    ? (
      relatedLearningActivities?.pages?.reduce((acc, page) => {
        const contents = page[responseKey] ?? [];
        return acc.concat(contents);
      }, [] as LearningObject[])
    ) : [];
  
  return (
    <>
      <GridMain>
        <Stack
          maxWidth="calc(100vw - (100vw - 100%))"
          minWidth={ "100%" }
        >
          <Box>
            <StyledButtonLink
              // eslint-disable-next-line no-console
              onClick={ ()=> {navigate(-1)} }
              sx={ { 
                left: 0,
                margin: "1rem 0",
                opacity: 1,
                padding:0,
                pointerEvents: "unset",
                position: "relative",
                zIndex: 999
              } }
            >
              <StyledIcon
                icon={ "arrow_left" }
                height={ "20px" }
                width={ "16px" }
              />
              { t("explore_back") }
            </StyledButtonLink>
          </Box>
          <Typography
            variant={ "h4" }
            fontWeight={ "700" }
            padding={ "32px 0" }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.5rem",
                marginBottom: "16px",
                paddingLeft: "0",
                paddingRight: "0"
              }
            } }
          >
            { routerState?.title ?? "" }
          </Typography>
          <CardsBox 
            $isLoading={ isLoading }
            sx={ {
              gridTemplateColumns: "repeat(auto-fill, 310px)",
              [theme.breakpoints.down("sm")]:{
                gridTemplateColumns: "auto"
              }
            } }>
            {
              isLoading ? (
                <Spinner />
              ) : (
              //  relatedLearningActivities?.pages[0].relatedLearningActivities?.map((card) => (
                relatedCards.map((card) => (
                  <CarouselCard
                    key={ `carousel-category-detail--card--${card.id}` }
                    booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                    categories={ card.topicTags || [] }
                    coverPublicURL={ card.coverPublicURL }
                    disabled={ false }
                    duration={ card.duration }
                    ecmRegistration={ card.ecmRegistration }
                    ecm_specialization={ card.ecm_specialization }
                    editionNumber={ card?.editionNumber }
                    endDate={ createEndDate(card) }
                    enrollType={ card.enrollType }
                    expirationDate={ card.expirationDate }
                    id={ card.id }
                    isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                    isFavourite={ card.isFavourite }
                    isMandatory={ card.isMandatory }
                    isToj={ false }
                    learningObjectType={ card?.learningObjectType || "" }
                    learningObjectTypology={ card.learningObjectTypology  }
                    percentageOfCompletion={ card.percentageOfCompletion }
                    shortDescription={ card.shortDescription }
                    small={ true }
                    startDate={ createEndDate(card)  }
                    status={ card.status  || "N" }
                    title={ card.title }
                  />
                ))
              )
            }

          </CardsBox>
          {
            (hasNextPage && !isFetchingNextPage) ? (
              <Button
                disableRipple
                onClick={ () => fetchNextPage() }
                sx={ {
                  gap:"0.5rem",
                  margin:"0 auto",
                  width:"fit-content"
                } }
              >
                { t("load_more") }
                <Icon 
                  color={ theme.customColors.systemPrimary02 }
                  icon="dropdown" 
                  size={ 20 } 
                />
              </Button>
            ) : null
          }
          {
            isFetchingNextPage ? (
              <Spinner />
            ) : null
          }
        </Stack>
      </GridMain>
    </>
  );
}

