import {
  Typography,
  type TypographyProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { VideoCaptionStyles } from "./VideoCaption.styles";
import { CourseInfoTextProps } from "./VideoCaption.types";
import {
  useLevel,
  useType
} from "../../hooks";
import { forwardMuiStyle } from "../../utils/styles";
import { TitleTypography } from "../CardTabs/CardTabs.styles";

const StyledCardType = styled(Typography)<
  TypographyProps
  >(({ fontSize, lineHeight, theme }) => ({
    ".accent-text": {
      color: theme.customColors.accentVariantA
    },
    fontSize: forwardMuiStyle(fontSize, "1rem"),
    lineHeight: forwardMuiStyle(lineHeight, 1.3125),
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]:{
      fontSize:"0.75rem",
      fontWeight: "700",
      marginBottom: "4px",
      textTransform: "uppercase"
    }
  }));

export function VideoCaptionOverlay({
  courseDetails,
  learningObjectType,
  learningObjectTypology,
  moduleNumber,
  rootDetails,
  title
}: CourseInfoTextProps) {
  const { t }= useTranslation();
  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);
  const learningObjectTypologyType = useLevel(learningObjectTypology);
  const rootLevel =  useLevel(rootDetails?.learningObjectTypology || learningObjectTypology);
  const courseLevel =  useLevel(courseDetails?.learningObjectTypology || learningObjectTypology);
  const courseType = useType(
    courseDetails?.learningObjectType || learningObjectType,
    courseDetails?.learningObjectTypology || learningObjectTypology
  );
  const rootType = useType(
    rootDetails?.learningObjectType || learningObjectType,
    courseDetails?.learningObjectTypology || learningObjectTypology
  );
  const detectCaption = () => {
    if(rootDetails && courseDetails && ( rootDetails && rootDetails?.id !== courseDetails.id)){
      return {
        learningObjectTypology:rootLevel,
        text:` - ${t("course")} : ${courseDetails.title}`,
        typology:rootType
      };
    }
    else if (rootDetails && !courseDetails){
      return {
        learningObjectTypology:rootLevel,
        text:` : ${rootDetails.title}`,
        typology:rootType
      };
    }
    else if(courseDetails){
      return {
        learningObjectTypology:courseLevel,
        text:` : ${courseDetails.title}`,
        typology:courseType
      };
    }
    else {
      return  {
        learningObjectTypology:learningObjectTypologyType,
        text:"",
        typology:typeObjectLearning
      };
    }
  };

  return (
    <VideoCaptionStyles className={ "video-caption" }>
      <StyledCardType  >
        <span className="accent-text">
          { detectCaption().typology }
        </span> | { detectCaption().learningObjectTypology }
        {
          detectCaption().text
        }
      </StyledCardType>
      <TitleTypography variant="h1">
        { moduleNumber ? `${moduleNumber} - ` : null }  { title }
      </TitleTypography>
    </VideoCaptionStyles>
  );
}
