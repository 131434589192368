import {
  Box,
  CircularProgress,
  Typography
} from "@mui/material";
// import { SpinnerBox } from "./Spinner.styles";
import { SpinnerProps } from "./Spinner.types";

export function Spinner({
  alignItems="center",
  alignSelf="auto",
  height,
  justifyContent="center",
  justifySelf="auto",
  minHeight,
  msg,
  padding="40px",
  width,
  size=80,
  ...props
}: SpinnerProps) {
  return (
    <Box 
      display={ "flex" }
      alignItems={ alignItems }
      alignSelf={ alignSelf } 
      height={ height }
      justifyContent={ justifyContent }
      justifySelf={ justifySelf } 
      minHeight={ minHeight }
      padding={ padding }
      width={ width }
    >
      <CircularProgress size={ size } { ...props }/>
      { msg && (
        <Typography textTransform="capitalize">
          { msg }
        </Typography>
      )
      }
    </Box>
  );
}
