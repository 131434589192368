import type {
  UserAchievement,
  UserAchievementsRaw
} from "../types";

export function mapUserAchievements({
  hasBadges,
  hasCertificates,
  rawAchievements
} : {
  hasBadges: boolean
  hasCertificates: boolean
  rawAchievements: UserAchievementsRaw
}) {
  if (hasBadges && hasCertificates) {
    const groupedItems = rawAchievements?.certificates.map(certificate => {
      const relatedBadge = rawAchievements?.badges.find(badge =>
        badge.learningObjectId === certificate.learningObjectId
      );
      return {
        badge: { ...relatedBadge },
        certificate: { ...certificate },
        issuedOn: certificate.issuedOn
      } as UserAchievement;
    });

    const badgeAlone = rawAchievements?.badges.flatMap((badge ) => {
      const relatedCertificate = rawAchievements.certificates?.some((certicate) => 
        certicate.learningObjectId === badge.learningObjectId
      );
      if (!relatedCertificate) {
        return {
          badge: { ...badge },
          certificate: undefined,
          issuedOn: badge.issuedOn
        } as UserAchievement;
      } else {
        return [];
      }
    });
    return groupedItems?.concat(badgeAlone || []);

  } else if (hasBadges && !hasCertificates) {
    return rawAchievements?.badges.map(badge => {
      return {
        badge: { ...badge },
        certificate: undefined,
        issuedOn: badge.issuedOn
      } as UserAchievement;
    });
  } else if(!hasBadges && hasCertificates) {
    return rawAchievements?.certificates.map(certificate => {
      return {
        badge: undefined,
        certificate: { ...certificate },
        issuedOn: certificate.issuedOn
      } as UserAchievement;
    });
  } else {
    return [] as UserAchievement[];
  }
}

export function sortUserAchievements(
  array: UserAchievement[]
){
  if (array) {
    return array.sort((a, b) => {
      const firstElA = a.issuedOn; 
      const firstElB = b.issuedOn;
      const dateA = firstElA
        ? new Date(
          firstElA[0], firstElA[1],firstElA[2],
          firstElA[3],firstElA[4], firstElA[5]
        )
        : new Date("");
      const dateB = firstElB
        ? new Date(
          firstElB[0], firstElB[1],firstElB[2],
          firstElB[3],firstElB[4],firstElB[5]
        )
        : new Date("");

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  } else return [];
}
