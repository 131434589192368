import { useTranslation } from "react-i18next";
import { SignupSummarySector } from "../../components/SignupSummary";
import { useAuthStore } from "../../stores";

export function ContentSector (){
  const userInfo = useAuthStore(state => state.userInfo);

  const { t }=useTranslation();
  return (
    <>
      <SignupSummarySector
        job={ userInfo?.job }
        descriptionJobTab={ t("your_job_description") }
        profession={ userInfo?.profession }
        descriptionProfessionTab={ t("your_profession_description") }
        isProfilePage={ true }
      />
    </>
  );
}
