import {
  Radio as MuiRadio,
  RadioGroup,
  RadioGroupProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel
} from "@mui/material";

type RadioProps =
  RadioGroupProps & {
    label?: string,
    labelId: string,
    options: Omit<FormControlLabelProps, "control">[]
  }

export function Radio({
  defaultValue,
  label,
  labelId,
  name,
  options,
  ...props
}: RadioProps) {
  return (
    <FormControl fullWidth>
      {
        label ? (
          <FormLabel id={ labelId }>
            { label }
          </FormLabel>
        ) : null
      }
      <RadioGroup
        aria-labelledby={ labelId }
        defaultValue={ defaultValue }
        name={ name }
        { ...props }
      >
        {
          options?.map((option, idx) => (
            <FormControlLabel
              key={ idx }
              control={ <MuiRadio /> }
              label={ option.label }
              value={ option.value ?? option.label }
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  );
}
