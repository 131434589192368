import { styled } from "@mui/material/styles";

export const VisuallyHiddenInputStyle = styled("input")({
  bottom: 0,
  clipPath: "inset(50%)",
  left: 0,
  height: 1,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 1
});
