import React from "react";
import {
  Errors, PayloadProps, SkillBook
} from "../routes/Profile/AddSkillBook/AddSkillBook.types";

type SetErrors = (errors: Errors) => void;
type SetFormValues = (callback: (prevValues: { [key: string]: unknown }) => { [key: string]: unknown }) => void;

export const handleFileChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  name: string,
  maxFileSize: number,
  minFileSize: number,
  errors: Errors,
  setErrors: SetErrors,
  setFormValues: SetFormValues
) => {
  if (e.target.files) {
    try {
      let newErrors: Errors = { ...errors };
      const file = (e.target.files as FileList)[0];

      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) { //CHECK FILE TYPE
        newErrors["file"] = "file_format_error";
        setErrors(newErrors);
        return;
      } else if (file.size < minFileSize || file.size > maxFileSize) { //CHECK FILE SIZE
        newErrors["file"] = "file_size_error";
        setErrors(newErrors);
        return;
      } else {
        if ("file" in newErrors) {
          delete newErrors["file"];
        }
      }

      setErrors(newErrors);

      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: file,
        "file_name": file.name
      }));
    } catch (error) {
      console.log("Error: ", error);
    }
  }
};

export const onSubmit = (data: SkillBook, mutate: (data: FormData) => void) => {
  if (!data) return;

  let knowledgeStructure;
  let capability;

  if (data.area_domain) {
    knowledgeStructure = data.area_domain.map((area) => ({
      id: Number(area.knowledge_area.value),
      label: area.knowledge_area.label,
      domain: area.knowledge_domain
        ? [{
          id: Number(area.knowledge_domain.value),
          label: area.knowledge_domain.label
        }]
        : []
    }));
  }

  if (data.capability) {
    capability = data.capability.map((e) => ({
      id: Number(e.value),
      label: e.label
    }));
  }

  const payload: PayloadProps = {
    data: {
      uploadByAdmin: false,
      title: data.title,
      duration: data.duration,
      description: data.description,
      courseStartDate: data.startDate,
      courseEndDate: data.endDate,
      link: data.link,
      certificateStartValidityDate: data.validationStartDate,
      certificateEndValidityDate: data.validationEndDate,
      code: data.code,
      notes: data.notes,
      area: knowledgeStructure,
      trainingType: data.trainingType ? data.trainingType.value : "",
      capability: capability,
      sourceId: data.sourceId,
      source: data.source
    },
    certificate: data.file ? data.file : ""
  };

  mutate(payload as unknown as FormData);
};

