import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TabsContentProps } from "./TabsContent.types";
import {
  Agenda,
  CardTeacher,
  Carousel,
  TabsFilters
} from "../../components";
import {
  CAROUSEL_TYPE,
  LEVEL,
  STATUS_LO
} from "../../consts";
import {
  TypographyDescription,
  TypographyTitle
} from "../../styles/global_styles";
import type { StatusLO } from "../../types";

export function TabsContentDetail({
  contentAgenda=[],
  contentModules=[],
  currentLO,
  currentTeachers=[],
  filter,
  setFilter
} :
  & Pick<
    TabsContentProps,
    | "contentAgenda"
    | "contentModules"
    | "currentTeachers"
    | "filter"
    | "setFilter"
  >
  & Required<
    & Pick<
      TabsContentProps,
      | "currentLO"
    >
    & {
      isDetailsPage: true
    }
  >
) {
  const theme = useTheme();
  const { t } = useTranslation();

  const tabOptions = getTabOptions();

  return (
    <>
      <TypographyTitle>
        { t("what_to_expect") }
      </TypographyTitle>
      <TypographyDescription
        sx={ { paddingBottom: "40px" } }
      >
        { currentLO.longDescription }
      </TypographyDescription>
      {
        contentAgenda.length > 0 ? (
          <Agenda
            title={ t("agenda") }
            steps={ contentAgenda }
          />
        ) : null
      }
      {
        currentTeachers && currentTeachers?.length > 0 ? (
          <>
            <TypographyTitle>
              { t("teachers") }
            </TypographyTitle>
            <Box sx={ { marginBottom: theme.spacing(4) } }>
              <Carousel
                label={ "teachers-carousel" }
                carouselType={ CAROUSEL_TYPE.DEFAULT }
                hasPagination={ false }
              >
                {
                  currentTeachers?.map((item, id) => (
                    <CardTeacher
                      key={ id.toString() }
                      id={ item.id }
                      image={ item.image }
                      overTitle={ item.operationalField || "" }
                      title={ item.completeName || item?.toString() }
                    />
                  ))
                }
              </Carousel>
            </Box>
          </>
        ) : null
      }
      {
        (currentLO?.learningObjectTypology === LEVEL.PATH) ? (
          <TabsFilters<StatusLO>
            contents={ contentModules }
            filter={ filter }
            options={ tabOptions }
            setFilter={ setFilter }
            title={ t("modules") }
          />
        ) : null
      }
    </>
  );

  function getTabOptions() {
    return [
      {
        children: t("completed"),
        value: STATUS_LO.C
      },
      {
        children: t("in_progress"),
        value: STATUS_LO.P
      },
      {
        children: t("to_start"),
        value: STATUS_LO.E
      }
    ];
  }
}
