import {
  Grid, Typography
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import type { FiltersBarProps } from "./FiltersBar.types";
import { Icon } from "../../components";

const StyledFilters = styled(Grid)(({ theme }) => ({
  alignItems:"center",
  border:`1px solid ${theme.customColors.border}`,
  borderRadius:theme.spacing(1),
  boxShadow: theme.customColors.boxShadowFilter,
  display:"flex",
  justifyContent:"space-between",
  marginTop:theme.spacing(4),
  maxWidth:"100%",
  padding:theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    marginTop:theme.spacing(2),
    maxHeight: "56px",
    minHeight: "56px",
    padding: "16px 8px"
    
  }
}));

export function FiltersBar({
  filters,
  onClickHandler,
  ...props
}: FiltersBarProps) {
  const theme = useTheme();
  const { t }= useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedFilters = filters
    .filter((filter) => filter.selected)
    .map((filter) => t(filter.value))
    .join(", ");

  return (
    <StyledFilters { ...props }>
      <Typography
        fontWeight={ isMobile ? 500 : 700 }
        lineHeight={ isMobile ? "18px" : 1.5 }
        fontSize={ isMobile ? "0.875rem" : "1rem" }
      >
        { t("filter") }
      </Typography>
      <Grid
        display={ "flex" }
        alignItems={ "center" }
        gap={ theme.spacing(2) }
      >
        { isMobile? null :
          (selectedFilters) ? (
            <Typography color={ theme.customColors.accentVariantA }
              fontSize={ isMobile ? "0.75rem" : "1rem" }
              lineHeight={ isMobile ? "14px" : "1.5" }>
              { selectedFilters }
            </Typography>
          ) : null
        }
        {
          filters.map((filter, index) =>  {
            return (
              <Icon 
                style={ {
                  cursor:"pointer",
                  transition:"all .4s linear"
                } }
                key={ `filterIcon-${index}` }
                icon={ filter.icon }
                size={ filter.selected ? isMobile ? 27 : 25 : 20 }
                color={
                  filter.selected
                    ? theme.customColors.accentVariantA
                    : theme.customColors.systemPrimary01
                }
                onClick={ ()=> { onClickHandler(filter.value)} }
              />
            );
          })
        }
      </Grid>
    </StyledFilters>
  );
}
