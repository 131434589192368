import {
  Fragment,
  PropsWithChildren,
  useEffect
} from "react";
import { useLocation } from "react-router";

export function ScrollToTop({ children }: PropsWithChildren){
  const { pathname } = useLocation();

  useEffect(() => {
    // if the browser is able to handle scroll restoration
    // on its own steal control from it :D
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      { children }
    </Fragment>
  );
}

