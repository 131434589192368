import { 
  Dialog,
  Stack, 
  Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  DeleteContentModalBox
} from "./DeleteConfirmContentModal.styles";
import { DeleteConfirmContentModalProps } from "./DeleteConfirmContentModal.types";

import { 
  ButtonText
} from "..";
import { useDeleteAssignment } from "../../queries/exercises";


export function DeleteConfirmContentModal({
  closeDrawer,
  deleteId,
  onClose,
  open,
  data,
  ...props
}: DeleteConfirmContentModalProps) {
  const { t } = useTranslation();

  const { data: response, mutate: mutateDeleteAssignment } = useDeleteAssignment(Number(deleteId));
  const theme = useTheme();
  
  const [showSuccess, setShowSuccess]= useState(false);

  useEffect(() => {
    if (response?.ok) {
      setShowSuccess(true);
    }
  }, [response]);
  

  return (
    <>
      
      <Dialog
        maxWidth={ false }
        open={ open }
        onClose={ onClose }
        scroll={ "paper" }
        sx={ {
          ".MuiDialog-paper ": {
            background: theme.linearGradients.gradientB,
            borderRadius:"8px",
            maxHeight:"calc(100% - 32px)",
            padding: "10px"
          }
        } }
      >
     
        <DeleteContentModalBox
          container
          { ...props }
        >
          { showSuccess ? 
            <Stack gap={ "24px" }>
              <Typography
                sx={ {
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "center",
                  width: "537px"
                } }
              >{ t("exercises.delete.success") }
              </Typography>
              <Stack 
                direction={ "row" } 
                justifyContent={ "center" } 
                gap={ "24px" }>
                <ButtonText onClick={ () => { 
                  setShowSuccess(false);
                  closeDrawer();
                } }>
                  { t("close") }
                </ButtonText>
              </Stack>
            </Stack>
            :
            <Stack gap={ "24px" }>
              <Typography
                sx={ {
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "center",
                  width: "537px"
                } }
              >{ t("exercises.delete.confirm") }
              </Typography>
              <Stack 
                direction={ "row" } 
                justifyContent={ "center" } 
                gap={ "24px" }>
                <ButtonText onClick={ () => { 
                  closeDrawer();
                } }>
                  { t("no") }
                </ButtonText>
                <ButtonText 
                  onClick={ () => { 
                    mutateDeleteAssignment({ 
                      assignmentId: data?.revisions[0]?.assignment_id ? data?.revisions[0]?.assignment_id?.id : null, 
                      exerciseId: data?.exercise_id?.id, 
                      revisionId: data?.revisions[0].revision_id 
                    });
                  } }
                >
                  { t("yes") }
                </ButtonText>
              </Stack>
            </Stack>
          }
        </DeleteContentModalBox>
      </Dialog>
    </>
  );

}
