import {
  Stack,
  Typography,
  Button,
  Box
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";

export const TopicCardContainerStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  padding: theme.spacing(3),

  "& .MuiChip-root": {
    border: `2px solid ${theme.customColors.borderTag}`,
    borderRadius: "8px",

    "& span": {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "1px",
      lineHeight: "20px"
    }
  }
}));

export const OwnerNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "21px",

  [theme.breakpoints.down("sm")]:{  
    fontSize: "16px"
  }
}));

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "30px",
  width:"75%",

  [theme.breakpoints.down("sm")]:{  
    fontSize: "14px",
    lineHeight: "18px",
    paddingRight: "0",
    width:"100%"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "48px",
  fontWeight: "700",
  lineHeight: "56px",

  [theme.breakpoints.down("sm")]:{  
    fontSize: "24px",
    lineHeight: "30px",
    width:"100%"
  }
}));

export const BodyTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21px",


  [theme.breakpoints.down("sm")]:{  
    fontSize: "14px",
    paddingRight: "0px"
  }
}));

export const LinkTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimaryCta,
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "22px"
}));

export const LinkInfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textDisabledAlternative,
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px"
}));

export const ActionLink = styled(Button)(() => ({
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "22px",
  padding: "0px",
  textDecoration: "underline",
  textTransform: "none",

  "&:hover": {
    background: "none",
    textDecoration: "underline"
  }
}));

export const TopicTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: `${theme.customColors.textPrimaryCta}99`,
  borderRadius: "8px",
  color: theme.customColors.textTag,
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "21px",
  padding: "7.5px 8px",

  [theme.breakpoints.down("sm")]:{  
    alignItems:"center",
    borderRadius: "4px",
    display: "flex",
    fontSize: "12px",
    maxHeight: "24px"
  }
}));

export const TopicCardDetailsBox = styled(Box)<{cover: string}>(({ cover, theme }) => ({
  //background: `${theme.linearGradients.gradientDAlternative03}, url(${cover})`,
  //backgroundPositionX: "center",
  //backgroundPositionY: "0px",
  //backgroundRepeat:"no-repeat",
  //backgroundSize: "100% 800px",
  display: "flex",
  flexDirection:"column",
  paddingTop:"32px",
  position:"relative",

  [theme.breakpoints.down("sm")]:{  
    paddingTop: "24px"
  },

  "&::before": {
    content: "''",

    background: `${theme.linearGradients.gradientDAlternative08}, url(${cover})`,
    backgroundPosition: "center",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
    height: "800px",
    left: "0px",
    position: "absolute",
    top: "0px",
    width: "100%",

    [theme.breakpoints.down("sm")]:{  
      height: "668px"
    }
  },

  "main":{
    borderBottom:`1px solid ${theme.customColors.border}`,
    marginBottom:theme.spacing(5),
    paddingBottom:theme.spacing(5),

    [theme.breakpoints.down("sm")]:{  
      marginBottom:theme.spacing(3),
      paddingBottom:theme.spacing(1)
    }
  }
 
}));
