import { Stack, Typography, Box, BoxProps } from "@mui/material";
import { common } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { HTMLProps } from "react";
import bg from "../../assets/newsBg.png";

export const ContainerStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  padding: "2rem 1rem"
}));

export const NewsStack = styled(Stack)(({ theme }) => ({
  background: `url(${bg})`,
  backgroundColor:  "rgba(27, 27, 27, 0.72)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  borderRadius: "0.5rem",
  maxHeight: "12rem",
  minHeight: "3.5rem",
  overflowY: "auto",
  padding: "1rem",


  "&:hover": {
    "&::-webkit-scrollbar-thumb":{
      backgroundColor: theme.customColors.backgroundSecondary
    }
  },
  "::-webkit-scrollbar": {
    width: "6px"
  },
  "::-webkit-scrollbar-thumb":{
    backgroundColor: "transparent",
    borderRadius: "8px"
  },

  "& a:first-child div": {
    marginTop: "0"
  }
}));

export const ImageBox = styled(Box)<
  BoxProps & HTMLProps<HTMLImageElement>
>(() => ({
  aspectRatio: "9 / 7",
  borderRadius:"8px",
  boxShadow:"box-shadow: 16px 16px 22px rgba(0, 0, 0, 0.4);",
  objectFit: "cover",
  padding:  "6px 0 ",
  paddingBlock: " 0 !important",

  height: "2.5625rem",
  width: "2.5625rem"
}));

export const HeaderTypography = styled(Typography)(({ theme }) => ({
  ".primary": {
    color: theme.palette.primary?.main
  },
  color: theme.customColors.textDisabledAlternative,
  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: 7 / 6,
  overflow: "hidden",
  textOverflow: "ellipsis",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,
  whiteSpace: "nowrap"
  
  // [theme.breakpoints.down("sm")]: {
  //   margin: "auto 0",
  //   position: "absolute",
  //   top: "-1rem"
  // }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  ".primary": {
    color: theme.palette.primary?.main
  },
  color: theme.customColors.textPrimary,
  fontSize: "0.8rem",
  fontWeight: "500",
  lineHeight: 7 / 6,
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,

  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "2",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis"
  // [theme.breakpoints.down("sm")]: {
  //   margin: "auto 0",
  //   position: "absolute",
  //   top: "-1rem"
  // }
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
  ".primary": {
    color: theme.palette.primary?.main
  },
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: 7 / 6,
  marginBottom: "0.5rem",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`
}));

