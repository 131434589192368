import { Grid } from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import {
  CardTabsAttachment,
  CardTabsLink
} from "../../components";
import type { Tool, UsefulLink } from "../../types";

const GridColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "calc(50% - 32px)",

  [theme.breakpoints.down("sm")]: {
    width: "100%",

    "&:first-child": {
      marginBottom: "23px"
    }
  }
}));

export function CardLinks({
  cardTabsLinks=[],
  cardTabsTools=[],
  disabled
} : {
  cardTabsLinks?: UsefulLink[]
  cardTabsTools?: Tool[]
  disabled: boolean
}) {
  const theme = useTheme();

  return (
    <Grid
      columnGap={ "32px" }
      display={ "flex" }
      flexWrap={ "wrap" }
      justifyContent={ "space-between" }
      paddingBottom={ "2rem" }
      rowGap={ "8px" }
      width={ "1150px" }
      sx={ {
        [theme.breakpoints.down("sm")]: {
          marginTop: "8px",
          width: "100%"
        }
      } }
    >
      {
        (cardTabsTools.length > 0) ? (
          <GridColumn
            key={ "grid-Attachment" }>
            {
              cardTabsTools.map((card, inx: number) => {
                return (
                  <CardTabsAttachment
                    key={ `card-attachment-${inx.toString()}` }
                    disabled={ disabled }

                    dimension={ card.dimension }
                    id={ card.id }
                    name={ card.name }
                    shortDescription={ card.shortDescription }
                  />
                );
              })
            }
          </GridColumn>
        ) : null
      }
      {
        (cardTabsLinks?.length > 0) ? (
          <GridColumn key={ "grid-Links" }>
            {
              cardTabsLinks.map((card, inx: number) => {
                return (
                  <CardTabsLink
                    key={ `card-links-${inx.toString()}` }
                    disabled={ disabled }

                    description={ card.description }
                    url_label={ card.url_label }
                    url={ card.url }
                  />
                );
              })
            }
          </GridColumn>
        ) : null
      }
    </Grid>
  );
}
