export async function switchLocaleImport(langCode="it") {
  /**
     * switch to all locales supported by date-fns
     * 
     * because of static analysis limitations import()
     * can't contain variables or template strings
     */
  switch(langCode) {
  case "af": return await import("date-fns/locale/af");
  case "ar": return await import("date-fns/locale/ar");
  case "az": return await import("date-fns/locale/az");
  case "be": return await import("date-fns/locale/be");
  case "bg": return await import("date-fns/locale/bg");
  case "bn": return await import("date-fns/locale/bn");
  case "bs": return await import("date-fns/locale/bs");
  case "ca": return await import("date-fns/locale/ca");
  case "cs": return await import("date-fns/locale/cs");
  case "cy": return await import("date-fns/locale/cy");
  case "da": return await import("date-fns/locale/da");
  case "de": return await import("date-fns/locale/de");
  case "el": return await import("date-fns/locale/el");
  case "en": return await import("date-fns/locale/en-GB"); // week starts with monday
  case "eo": return await import("date-fns/locale/eo");
  case "es": return await import("date-fns/locale/es");
  case "et": return await import("date-fns/locale/et");
  case "eu": return await import("date-fns/locale/eu");
  case "fa": return await import("date-fns/locale/fa-IR");
  case "fi": return await import("date-fns/locale/fi");
  case "fr": return await import("date-fns/locale/fr");
  case "fy": return await import("date-fns/locale/fy");
  case "gd": return await import("date-fns/locale/gd");
  case "gl": return await import("date-fns/locale/gl");
  case "gu": return await import("date-fns/locale/gu");
  case "he": return await import("date-fns/locale/he");
  case "hi": return await import("date-fns/locale/hi");
  case "hr": return await import("date-fns/locale/hr");
  case "ht": return await import("date-fns/locale/ht");
  case "hu": return await import("date-fns/locale/hu");
  case "hy": return await import("date-fns/locale/hy");
  case "id": return await import("date-fns/locale/id");
  case "is": return await import("date-fns/locale/is");
  case "it": return await import("date-fns/locale/it");
  case "ja": return await import("date-fns/locale/ja");
  case "ka": return await import("date-fns/locale/ka");
  case "kk": return await import("date-fns/locale/kk");
  case "km": return await import("date-fns/locale/km");
  case "kn": return await import("date-fns/locale/kn");
  case "ko": return await import("date-fns/locale/ko");
  case "lb": return await import("date-fns/locale/lb");
  case "lt": return await import("date-fns/locale/lt");
  case "lv": return await import("date-fns/locale/lv");
  case "mk": return await import("date-fns/locale/mk");
  case "mn": return await import("date-fns/locale/mn");
  case "ms": return await import("date-fns/locale/ms");
  case "mt": return await import("date-fns/locale/mt");
  case "nb": return await import("date-fns/locale/nb");
  case "nl": return await import("date-fns/locale/nl");
  case "nn": return await import("date-fns/locale/nn");
  case "oc": return await import("date-fns/locale/oc");
  case "pl": return await import("date-fns/locale/pl");
  case "pt": return await import("date-fns/locale/pt");
  case "ro": return await import("date-fns/locale/ro");
  case "ru": return await import("date-fns/locale/ru");
  case "sk": return await import("date-fns/locale/sk");
  case "sl": return await import("date-fns/locale/sl");
  case "sq": return await import("date-fns/locale/sq");
  case "sr": return await import("date-fns/locale/sr");
  case "sv": return await import("date-fns/locale/sv");
  case "ta": return await import("date-fns/locale/ta");
  case "te": return await import("date-fns/locale/te");
  case "th": return await import("date-fns/locale/th");
  case "tr": return await import("date-fns/locale/tr");
  case "ug": return await import("date-fns/locale/ug");
  case "uk": return await import("date-fns/locale/uk");
  case "uz": return await import("date-fns/locale/uz");
  case "vi": return await import("date-fns/locale/vi");
  case "zh": return await import("date-fns/locale/zh-CN");
      
  default: return await import("date-fns/locale/it");
  }
}
