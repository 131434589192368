import { create } from "zustand";
import { NAVBAR_ITEMS } from "../consts";
import type { NavbarItem } from "../types";
import { 
  getNavItem, 
  persistNavItem 
} from "../utils/navbar";

export type NavbarState = {
  item: NavbarItem,
  splash: boolean
}

type NabvbarActions = {
  readonly setNavbarItem: (item: NavbarItem) => void,
  readonly setSplash: (arg: boolean) => void
}

const defaultNavbarState: NavbarState = {
  item: getNavItem() ?? NAVBAR_ITEMS.FOR_YOU,
  splash: false
};

export const useNavbarStore = create<NavbarState & NabvbarActions>((set) => {
  
  return {
    ...defaultNavbarState,
    setNavbarItem: (item: NavbarItem) => {
      set({ item });
      persistNavItem(item.toString());
    },
    setSplash: (paramSplash: boolean) => {
      set({ splash: paramSplash });
    }
  };
});
