import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { StyledPreferenceStack } from "./PreferenceStack.styles";
import type {
  PreferenceStackProps
} from "./PreferenceStack.types";

export function PreferenceStack({
  children,
  description,
  title,
  ...props
}: PreferenceStackProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledPreferenceStack { ...props }>
      {
        title ? (
          <Typography
            fontSize={ isMobile ? "1rem" : "1.5rem" }
            fontWeight={ 700 }
            lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
            width={ "100%" }
          >
            { title }
          </Typography>
        ) : null
      }
      {
        description ? (
          <Typography
            fontSize={ isMobile ? "0.875rem" : "1.25rem" }
            lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
            width={ "100%" }
          >
            { description }
          </Typography>
        ) : null
      }
      { children }
    </StyledPreferenceStack>
  );
}
