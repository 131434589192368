import { Checkbox as MuiCheckbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "../../components";

const StyledIcon = styled(Icon)(({ theme }) => ({
  /**
   * this styling revolves around the idea that the sizing of the checkbox
   * will be customized with "transform: scale(...)", the relative sizings
   * are taken from the CheckBoxOutlineBlankOutlinedIcon deaults
   */

  background: theme.palette.primary?.main,
  borderRadius: "0.1em",
  boxShadow: `0 0 0.75rem ${theme.palette.primary?.main}`,
  fontSize: "1.5rem",
  margin: "0.125em",
  padding: "5px",

  height: "0.75em",
  width: "0.75em",
  
  path: {
    fill: theme.palette.background?.paper
  }
}));

export function Checkbox({
  ...props
}) {
  return (
    <MuiCheckbox
      { ...props }
      checkedIcon={
        <StyledIcon
          className="MuiSvgIcon-root"
          icon={ "completato_mobile" }
        />
      }
    />
  );
}
