import {
  Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledCardGoals = styled(Grid)(({ theme }) => ({
  background:theme.linearGradients.gradientB,
  borderRadius:theme.spacing(1),
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(2),
  padding:theme.spacing(3,2)

}));

export const StyledGridBadges = styled(Grid)(({ theme }) => ({
  columnGap:theme.spacing(1.75),
  display:"flex",
  flexWrap:"wrap",
  rowGap:theme.spacing(2),

  ">div":{
    width:`calc(50% - ${theme.spacing(1.75)} )`
  }


}));

