import { FormControl, InputLabel, Select, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { SelectCstProps } from "./types";

const SelectCst: React.FC<SelectCstProps> = ({ label, placeholder, name, options, value, required, error, onChange }) => {
  const theme = useTheme();

  return (
    <FormControl
      fullWidth
      required={ required }
      sx={ {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: theme.customColors.borderTag
          }
        },
        "& .MuiFormLabel-root": {
          color: theme.customColors.borderTag
        },
        "& .MuiSvgIcon-root": {
          color: theme.customColors.textPrimary
        }
      } }
    >
      { label && <InputLabel>{ label }</InputLabel> }
      <Select
        label={ label }
        placeholder={ placeholder }
        name={ name }
        value={ value }
        error={ error }
        onChange={ onChange as (e: SelectChangeEvent<unknown>) => void }
      >
        { options.map((option, index) => (
          <MenuItem
            key={ index }
            sx={ { textTransform: "capitalize", whiteSpace: "normal" } }
            title={ option?.label }
            value={ option.value }
            data-label={ option.label }
          >
            <Typography
              sx={ {
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize"
              } }
            >
              { option?.label }
            </Typography>
          </MenuItem>)
        ) }
      </Select>
    </FormControl>
  );
};

export default SelectCst;
