/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar } from "@mui/material";
import { yellow } from "@mui/material/colors";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import { useState } from "react";
import { AUTH_MODE } from "../../consts";
import {
  deleteApplicationSession,
  deleteCognitoSession,
  deleteCognitoSessionSSO
} from "../../services/auth";
import { useAuthStore } from "../../stores";
import { getEnvironmentVariables } from "../../utils/general";

const {
  basePath: baseUrl,
  ssoBaseUrl: baseUrlSSO,
  ssoClientId: clientIdSSO
} = getEnvironmentVariables();

export function SessionRevoker() {
  const theme = useTheme();
  const authMode = useAuthStore(state => state.mode);
  const authUser = useAuthStore(state => state.user);
  const authorization = useAuthStore(state => state.session?.getAccessToken().getJwtToken());
  const refreshToken = useAuthStore(state => state.session?.getRefreshToken());
  const sessionToken = useAuthStore(state => state.sessionToken);

  const [show, setShow] = useState(true);

  const hasApplicationSession = !!sessionToken;
  const hasCognitoSession = authorization && authMode === AUTH_MODE.Cognito;
  const hasCognitoSessionSSO = authorization && authMode === AUTH_MODE.SSO;

  return (
    <AppBar
      sx={ {
        backgroundColor: theme.palette.common?.black,
        color: yellow.A400,
        zIndex: theme.zIndex.tooltip,

        " p": { margin: 0 }
      } }
    >
      <ShowButton onClick={ () => setShow((prev) => !prev) }>
        { show ? "hide" : "show" }
      </ShowButton>
      {
        show ? (
          <>
            { /* <p>
              { `application session: "${Boolean(sessionToken)}"` }
              { " | " }{  `cognito session: "${Boolean(authSession)}"` }
              { " | " }{ `mode: ${authMode}` }
             </p> */ }
            <DeleteButton
              // className={ hasApplicationSession ? "active" : "disabled" }
              onClick={ async() => {
                hasApplicationSession && authorization
              && await deleteApplicationSession({ authorization, baseUrl, sessionToken });
              } }>
              revoke application session
            </DeleteButton>
            <DeleteButton
              // className={ (hasCognitoSession || hasCognitoSessionSSO) ? "active" : "disabled" }
              onClick={ async() => {
                if (authMode === AUTH_MODE.Cognito) {
                  hasCognitoSession && authUser
                && await deleteCognitoSession({ cognitoUser: authUser });
                } else if (authMode === AUTH_MODE.SSO) {
                  hasCognitoSessionSSO && refreshToken
                && await deleteCognitoSessionSSO({
                  baseUrlSSO,
                  clientIdSSO,
                  refreshToken
                });
                }
              } }>
              revoke cognito session
            </DeleteButton>
            <DeleteButton
              // className={
              //   hasApplicationSession
              //   && (hasCognitoSession || hasCognitoSessionSSO)
              //     ? "active" : "disabled"
              // }
              onClick={ async() => {
                if (
                  authMode === AUTH_MODE.Cognito
                && authorization && sessionToken && authUser
                ) {
                  await deleteApplicationSession({
                    authorization,
                    baseUrl,
                    sessionToken
                  });
                  await deleteCognitoSession({ cognitoUser: authUser });
                } else if (
                  authMode === AUTH_MODE.SSO
                && authorization && refreshToken && sessionToken
                ) {
                  await deleteApplicationSession({
                    authorization,
                    baseUrl,
                    sessionToken
                  });
                  await deleteCognitoSessionSSO({
                    baseUrlSSO,
                    clientIdSSO,
                    refreshToken
                  });
                }
              } }>
              revoke application session and cognito session
            </DeleteButton>
          </>
        ) : null
      }
    </AppBar>
  );
}

const DeleteButton = styled("p")(({ theme }) => ({
  // "&.active": {
  backgroundColor: yellow.A400,
  color: theme.palette.common?.black,
  cursor: "pointer",
  // outlineColor: theme.palette.common?.black,
  // outlineOffset: "-1px",
  // outlineStyle: "solid"
  textDecoration: "underline"
  // }

  // "&.disabled": {
  //   color: yellow[100],
  //   textDecoration: "line-through"
  // }
}));

const ShowButton = styled("p")(({ theme }) => ({
  alignSelf: "end",
  backgroundColor: yellow.A400,
  color: theme.palette.common?.black,
  cursor: "pointer",
  width: "fit-content"
}));
