import { Card as CardComponent } from "./Card.component";
import { CardProps } from "./Card.types";
import {
  BlendedCard as BlendedCardProps,
  DigitaleCard as DigitaleCardProps,
  LiveClassroomCard as LiveEventCardProps,
  VirtualClassroomCard as VirtualEventCardProps
} from "./Card.variants";

export const BlendedCard = ({ ...props }:
  Partial<
  Extract<CardProps, { learningObjectTypology: "ACTIVITY"}>
> | (
  Pick<Extract<CardProps, { learningObjectTypology: 
    "COURSE" | "PACKAGE"}>, 
    "learningObjectTypology" | 
    "percentageOfCompletion"> & 
  Partial<Omit<Extract<CardProps, 
  { learningObjectTypology: "COURSE" | 
  "PACKAGE"}>, "learningObjectTypology" | "percentageOfCompletion">>
)) => (
  <CardComponent { ...{ ...BlendedCardProps, ...props } }/>
);

export const DigitaleCard = ({ ...props }:
  Partial<
  Extract<CardProps, { learningObjectTypology: "ACTIVITY"}>
> | (
  Pick<Extract<CardProps, { 
    earningObjectTypology: "COURSE" 
    | "PACKAGE"}>, "learningObjectTypology" 
    | "percentageOfCompletion"> & 
  Partial<Omit<Extract<CardProps, 
  { learningObjectTypology: "COURSE" | "PACKAGE"
}>, "learningObjectTypology" | "percentageOfCompletion">>
)) => (
  <CardComponent { ...{ ...DigitaleCardProps, ...props } }/>
);

export const LiveClassroomCard = ({ ...props }: 
  Partial<
  Extract<CardProps, { learningObjectTypology: "ACTIVITY"}>
> | (
  Pick<Extract<CardProps, { 
    learningObjectTypology: "COURSE" | "PACKAGE"
  }>, "learningObjectTypology" | "percentageOfCompletion"> & 
  Partial<Omit<Extract<CardProps, { 
    learningObjectTypology: "COURSE" | "PACKAGE"
  }>, "learningObjectTypology" | "percentageOfCompletion">>
)) => (
  <CardComponent { ...{ ...LiveEventCardProps, ...props } }/>
);

export const VirtualClassroomCard = ({ ...props }: 
  Partial<
  Extract<CardProps, { learningObjectTypology: "ACTIVITY"}>
> | (
  Pick<Extract<CardProps, { 
    learningObjectTypology: "COURSE" | "PACKAGE"
  }>, "learningObjectTypology" | "percentageOfCompletion"> & 
  Partial<Omit<Extract<CardProps, { 
    learningObjectTypology: "COURSE" | "PACKAGE"
  }>, "learningObjectTypology" | "percentageOfCompletion">>
)) => (
  <CardComponent { ...{ ...VirtualEventCardProps, ...props } }/>
);

export const Card = CardComponent;
export type { CardProps } from "./Card.types";
