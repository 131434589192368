import {
  StyledPlayerLoader, 
  VideoStack
} from "./CommunityVideo.styles";
import { CommunityVideoProps } from "./CommunityVideo.types";
import { CommunityVideoCaptionOverlay } from "./CommunityVideoCaptionOverlay.component";
import { GridMain } from "../../components";

export function CommunityVideo({
  height,
  id,
  title,
  videoId,
  ...props
}: CommunityVideoProps) {
  return (
    <VideoStack
      height={ height }
      width={ "100%" }
      { ...props }
    >
      <StyledPlayerLoader
        canPlayBack={ true }
        courseId={ null }
        tentativeId={ null }
        parentId={ null }
        id={ id }
        position="relative"
        shortDescription={ "short desc" }
        title={ title }
        videoId={ videoId }
      >
        {
          <GridMain
            position={ "absolute" }
            margin={ "0 auto" }
            //minHeight={ "750px" }
            sx={ {
              inset:0
            } }
          >
            <CommunityVideoCaptionOverlay
              title={ title }
            />
          </GridMain>
        }
      </StyledPlayerLoader>
    </VideoStack>
  );
}
