import { Stack , Box, BoxProps, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CapabilityItemContainer = styled(Box)<BoxProps>(({ theme })=> ({
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(2),
  maxWidth:"calc(50% - 8px)",

  ".styled-chip": {
    ">div": {
      [`${theme.breakpoints.down("sm")}`]:{
        maxWidth:"113px",
        width: "113px"
      }
    }
  },

  "p":{
    fontSize:theme.spacing(2.5),
    fontWeight:700 
  },

  [`${theme.breakpoints.down("sm")}`]:{
    gap:theme.spacing(1),

    "p":{
      fontSize: "1rem"
    }
  }
}));
export const YourPlanContainer = styled(Stack)(({ theme })=> ({
  backdropFilter:"blur(20px)",
  background: `${theme.customColors.backgroundPrimaryLighter}40`,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  padding: "24px 103px 32px 24px",
  width: "100%",

  [`${theme.breakpoints.down("lg")}`]: {
    padding: "24px 16px"
  }
}));

export const ProfileImage = styled(Box)(({ theme })=> ({
  backgroundSize: "cover",
  borderRadius: "8px",
  boxShadow: `4.267px 4.267px 34.133px 0px ${theme.customColors.backgroundPrimaryLighter}40`,
  display: "flex",
  height: "100%",
  maxHeight: "235px",
  maxWidth: "344px",
  minHeight: "235px",
  width: "100%",

  [`${theme.breakpoints.down("lg")}`]: {
    maxWidth: "100%"
  }
}));

export const ProfileBox = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  marginBottom: "24px",

  [`${theme.breakpoints.down("lg")}`]: {
    flexDirection: "column"
  },

  ".profile-name": {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "30px",
    marginTop: "auto",
    padding: "16px"
  }
}));

export const PlanDescription = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),
  width: "100%",

  [`${theme.breakpoints.down("lg")}`]: {
    padding: "0"
  }
}));

export const UserInfoStack = styled(Box)<BoxProps>(({ theme })=> ({
  display: "flex",
  flexDirection:"column",
  position:"relative",
  width: "50%",

  ".top-stack":{
    alignItems:"center",
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(2),
    justifyContent:"space-between",

    "a":{
      position:"static"
    }
    
  },

  ".MuiBox-root":{
    backdropFilter:"blur(20px)",
    minHeight:"unset",
    "span":{
      fontSize:theme.spacing(2.5),
      fontWeight:700
    }
  },

  [`${theme.breakpoints.down("sm")}`]:{
    gap: theme.spacing(2),
    width: "100%"
  }
}));

export const YourGoals = styled(Box)(({ theme })=> ({
  display: "flex",
  gap: "40px",

  [`${theme.breakpoints.down("lg")}`]: {
    gap: "20px"
  },

  [`${theme.breakpoints.down("sm")}`]: {
    flexDirection: "column"
  }
}));

export const PreferencesContainer = styled(Stack)<
  StackProps & {isProfile?: boolean}>(({ theme, isProfile }) => {
    return {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      maxWidth: isProfile ? "650px" : "unset",
      minWidth: isProfile ? "650px" : "unset",

      [`${theme.breakpoints.down("sm")}`]:{
        maxWidth: "100%",
        minWidth: "100%",
        width:"100%",

        ">div": {
          width: "100%"
        }
      },

      ".favourites-carousel": {
        ".swiper-wrapper": {
          alignItems:"center",
          display: "flex",
          minHeight: "116px"
        },
    
        ".swiper-slide": {
          marginRight: "8px !important",
          maxHeight: "77px",

          ">div": {
            border:  `${theme.spacing(0.25)} solid ${ isProfile ? 
              theme.customColors.borderSecondaryComplete : theme.customColors.accentVariantA }`,
            borderRadius: "8px",
            boxShadow: `0px 0px 4px 0px ${ isProfile ? 
              theme.customColors.borderSecondaryComplete : theme.customColors.accentVariantA }`,
            maxHeight: "calc(112px - 32px)",
            maxWidth: "103px",
            minWidth: "103px",
            outline: "none",
            
            [theme.breakpoints.down("sm")]: {
              maxHeight: "calc(77px - 32px)"
            }
          }
        },

        ".swiper-pagination": {
          marginTop: "0px"
        }
      }
    };
  });
  


