import { useEffect } from "react";
import { useAuthStore } from "../../stores";

export function Refresh() {
  const refreshSession = useAuthStore(state => state.refreshSession);

  useEffect(() => {
    refreshSession();
    const intervalId = setInterval(() => {
      refreshSession();
    }, 5 * 60 * 1000); // 5min
    return clearInterval(intervalId);
  });

  return <></>;
}
