import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const YourGoalCard = styled(Stack)(({ theme })=> ({
  backdropFilter: "blur(20px)",
  backgroundColor: `${theme.customColors.backgroundSecondary}1A`,
  borderRadius: "8px",
  maxWidth: "315px",
  minWidth: "315px",
  padding: "24px 16px",

  [theme.breakpoints.down("max")]: {
    minWidth: "100%",
    width: "100%"
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    minWidth: "100%",
    width: "100%"
  }
}));

export const FavouriteFormats = styled(Stack)(({ theme })=> ({
  backdropFilter: "blur(20px)",
  backgroundColor: `${theme.customColors.backgroundSecondary}1A`,
  borderRadius: "8px",
  maxHeight: "473px",
  minHeight: "473px",
  minWidth: "675px",
  padding: "24px",
  width: "calc(100% - 315px - 16px)",

  "&:only-child":{
    width:"100%"
  },

  [theme.breakpoints.down("max")]: {
    minHeight: "auto",
    minWidth: "100%",
    width: "100%"
  },

  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
    padding: "0",
    width: "100%"
  },

  ".favourites-carousel": {
    marginTop: "16px",
    padding: "0 8px",

    [theme.breakpoints.down("sm")]: {
      padding: "0"
    },

    ".swiper-wrapper": {
      alignItems:"center",
      display: "flex",
      minHeight: "116px"
    },

    ".swiper-slide": {
      marginRight: "8px !important",
      maxHeight: "112px",
      minWidth: "200px",

      [theme.breakpoints.down("sm")]: {
        marginRight: "10px !important",
        maxHeight: "77px",
        minWidth: "132px",

        "&:first-of-type": {
          paddingLeft: "4px"
        }
      },

      ">div": {
        maxHeight: "calc(112px - 32px)",

        [theme.breakpoints.down("sm")]: {
          maxHeight: "calc(77px - 32px)",
          maxWidth: "103px",
          minWidth: "103px"
        }
      }
    },

    ".swiper-pagination": {
      marginTop: "16px"
    }
  }
}));

export const CardsContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  //flexWrap: "wrap",
  gap: "16px",
  justifyContent: "space-between",
  marginTop: "32px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%"
  },

  [theme.breakpoints.down("max")]: {
    flexWrap: "wrap"
  }
}));
  
