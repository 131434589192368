import {
  Stack,
  type StackProps
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import type { ElementType } from "react";

export const AccessStack = styled(Stack)<
  StackProps & { src?: string }
>(({ src, theme }) => ({
  alignItems: "center",
  // eslint-disable-next-line max-len
  background: `${theme.linearGradients.gradientDAlternative06}, 
    ${theme.linearGradients.gradientDAlternative03}, url(${src})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  flexGrow: 1,

  "> *": {
    marginTop: theme.spacing(10),

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4)
    }
  }
}));


export const LoginStack = styled(Stack)<
  StackProps & {
        /*
      there's something wrong with the type implementation of
      OverridableComponent and Stack when extending the default Stack export
    */
    component?: ElementType
  }
>(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.background?.default && alpha(theme.palette.background?.default, 0.8),
  borderRadius: theme.spacing(1),
  boxShadow: "25px 25px 33px rgba(0, 0, 0, 0.25)",
  gap: theme.spacing(4),
  marginLeft:theme.spacing(2.5),
  marginRight:theme.spacing(2.5),
  maxWidth: "900px",
  width: "90%",

  padding: theme.spacing(5, 3, 4),

  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(2),
    marginTop: "16px",
    maxWidth:"unset",
    width:"calc(100% - 40px)",

    padding: theme.spacing(5, 2, 4, 2)

  }
}));
