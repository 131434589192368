import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Typography
} from "@mui/material";

import { styled } from "@mui/material/styles";

export const CardTeacherBox = styled(Grid)<GridProps>(({ theme }) => ({
  borderRadius:"8px",
  cursor:"pointer",
  padding: "0",
  position: "relative",

  height: "200px",
  minWidth:"320px",
  width:"100%",

  [theme.breakpoints.down("sm")]:{
    height: "91px",
    maxWidth:"160px",
    minWidth:"160px"
  }

})) as typeof Grid; // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: 1.21875,
  textTransform: "capitalize",

  [theme.breakpoints.down("sm")]:{
    fontSize: "0.75rem"
  }
}));

export const ImageBox = styled(Box)<
  BoxProps & {image:string}
  >(({ image,theme }) => ({
    background: image ? undefined : theme.customColors.backgroundSecondary,
    borderRadius:"8px",
    display: "flex",

    height: "100%",
    width:"100%",

    "&::after": {
      background: theme.linearGradients.gradientCAlternative01,
      borderRadius:"8px",
      content: "''",
      position: "absolute",

      bottom: 0,
      left:0,
      right: 0,
      top:0,

      height:"100%",
      width:"100%"
    },
    img: {
      borderRadius:"8px",
      objectFit: "cover",
      objectPosition: "center center",
      position: "relative",

      height:"100%",
      width:"100%"

    }

  }));

export const TextContent = styled(Box)(() => ({
  position:"absolute",

  bottom:"1rem",
  left:"1rem",
  right:"1rem"

}));
export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: 1.21875,
  marginBottom:"8px",
  textTransform: "capitalize",

  [theme.breakpoints.down("sm")]:{
    fontSize: "0.875rem",
    marginBottom:"0"
  }

}));





