/* eslint-disable sort-keys */
/* eslint-disable max-len */
import {
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  // useLoaderData,
  useNavigate,
  useParams
} from "react-router-dom";

import {
  GridMain,
  HeroBanner,
  Modal,
  ModalEcmComponent,
  ScormTojModal,
  Spinner,
  Tabs
} from "../../components";
import {
  STATUS_LO,
  LEVEL,
  TAB_ITEMS_CONFIG
} from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import {
  useEditionsQuery,
  useLearningObjectQuery,
  useRelatedLearningObjectQuery,
  useToolsQuery
} from "../../queries";
import { useSharedPosts } from "../../queries/community";
import { useGetExercisesList } from "../../queries/exercises";
import { useAuthStore } from "../../stores";
import type {
  Course,
  JavaBasicDate,
  LearningActivity,
  Level,
  Root,
  StatusLO
} from "../../types";
import { hasPassedExpiration } from "../../utils/datetime";
import { getError } from "../../utils/error";
import {
  filterTabTypes,
  getExploreTabProps
} from "../../utils/explore";

export function ExploreDetail() {
  const isNotFoundCode = "core-005";
  const { t } = useTranslation();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { pathname, state: routerState } = useLocation();
  const { id: idString, learningObjectTypology } = useParams<{
    id: string
    learningObjectTypology: Level
  }>();
  const id = idString ? parseInt(idString) : NaN;
  const [statusFilter, setStatusFilter] = useState<StatusLO | null>(null);
  const [openModalEcm,setOpenModalEcm]=useState(false);
  const [openModalToj,setOpenModalToj]=useState(false);
  const [
    placeholderLO,
    setPlaceholderLO
  ] = useState<Course | LearningActivity | Root | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);
  const { navigation, setNavigation } = useContext(UserNavigationContext);
  const isLearningActivity = (
    learningObjectTypology !== LEVEL.COURSE && learningObjectTypology !== LEVEL.PATH
  );

  useEffect(() => {
    const isExpired = hasPassedExpiration(learningObject?.expirationDate);

    if (navigation?.includes(location.pathname) || (isLearningActivity && isExpired)) return;
    const currentNavigation = navigation;
    currentNavigation?.push(location.pathname);
    
    setNavigation && setNavigation(currentNavigation || []);
  }, [location.pathname]);

  const isCourse = (
    learningObjectTypology === LEVEL.COURSE
  );

  const courseId = routerState?.courseId
    ? routerState.courseId as number
    : learningObjectTypology === LEVEL.COURSE
      ? id
      : undefined;

  const rootId = routerState?.rootId
    ? routerState.rootId as number
    : learningObjectTypology === LEVEL.PATH && id
      ? id
      : undefined;
  const loRootId = routerState?.loRootId
    ? routerState.loRootId as number
    : undefined;
  

  const {
    data: learningObject,
    error: learningObjectError,
    isFetching:learningObjectIsFetching,
    isError: learningObjectIsError,
    isSuccess: learningObjectIsSuccess,
    refetch:refetchLo
  } = useLearningObjectQuery({
    courseId: courseId,
    enabled: Boolean(!isNaN(id) && learningObjectTypology),
    id: id ?? "",
    learningObjectTypology: learningObjectTypology,
    placeholderData: placeholderLO,
    rootId: (isLearningActivity || isCourse ) ?  rootId ?? loRootId : null,
    status: statusFilter
  });

  const {
    data: parentCourse,
    refetch:refetchParentCourse
  } = useLearningObjectQuery({
    enabled: Boolean(courseId),
    id: routerState?.courseId ?? "",
    learningObjectTypology: LEVEL.COURSE,
    rootId: (isLearningActivity || isCourse ) ?  rootId ?? loRootId : null
  });
  // const {
  //   data: parentRoot
  // } = useLearningObjectQuery({
  //   enabled: Boolean(rootId),
  //   id: state?.rootId ?? "",
  //   learningObjectTypology: LEVEL.PATH,
  //   onlyLearningActivity: true
  // });

  const { data: editions } = useEditionsQuery({ 
    classroomId: id?.toString() ?? ""
  });
  const { data: related } = useRelatedLearningObjectQuery({
    learningObjectId: id?.toString() ?? ""
  });
  const { data: tools } = useToolsQuery({
    learningObjectId: learningObject?.id?.toString() ?? ""
  });

  const communityFlag = enableCommunityFlag === true && smartConfiguration?.funcCommunity === true && smartConfiguration?.communityShareContent;

  const { data: sharedPosts } = useSharedPosts({ enabled: communityFlag, loId: id });

  const activities =
    // (parentRoot && "learningActivities" in parentRoot) ? parentRoot.learningActivities : 
    (parentCourse && "learningActivities" in parentCourse) ? parentCourse.learningActivities :
      (learningObject && "learningActivities" in learningObject) ? learningObject.learningActivities :
        [];

  const editionBooked = editions?.find(edition => edition.status === "E") || undefined;
  const hasEdition =  learningObject?.status === "R" && editions?.some(edition => 
    edition.status !== "R" 
  ) || undefined;
  
  const { data: exercises } = useGetExercisesList({ editionId: editionBooked?.id });

  /*const exercises = !exercisesApi ? [
    {
      teacher_id: {
        id: 1,
        label: "Mario Insegnante"
      },
      exercise_id: {
        id: 1,
        label: "ES_1"
      },
      exercise_size: 1.1,
      exercise_last_update_date: "2024-01-01T14:38:39.272Z",
      revisions: [
        {
          revision_id: 1,
          assignment_id: {
            id: 1,
            label: "Svolgimento Es_1"
          },
          assignment_size: 1.2,
          assignment_last_update_date: "2024-01-02T14:38:39.272Z",
          correction_id: {
            id: 1,
            label: "Revisione_1"
          },
          correction_size: 1.3,
          correction_last_update_date: "2024-01-03T14:38:39.272Z",
          outcome: ""
        }
      ]
    },
    {
      teacher_id: {
        id: 2,
        label: "Giovanna Insegnante"
      },
      exercise_id: {
        id: 2,
        label: "ES_2"
      },
      exercise_size: 2.1,
      exercise_last_update_date: null,
      revisions: [
      ]
    }
  ]  : exercisesApi;*/

  const filteredTabTypes =
    learningObject?.learningObjectTypology ? (
      filterTabTypes({
        activities,
        editions,
        exercises,
        learningObject,
        related,
        sharedPosts,
        tabItems: TAB_ITEMS_CONFIG?.[learningObject?.learningObjectTypology].tabItems,
        tools
      })
    ) : [];

  const contentTabs = filteredTabTypes?.flatMap((tabType) => (
    learningObject ? (
      getExploreTabProps({
        activities,
        courseId,
        editions,
        exercises,
        filter: statusFilter,
        isDetailPage: true,
        learningObject,
        related,
        rootId: rootId ?? loRootId,
        setFilter: setStatusFilter,
        setOpenModalEcm:setOpenModalEcm,
        sharedPosts,
        t,
        tabType,
        tools
      })) : []
  ));
  
  const isNotFound = getError(learningObjectError)?.[0]?.cause === isNotFoundCode; 
  useEffect(()=> {
    if (learningObjectError && !isNotFound){
      setShowModal(true);
    }
    if(isNotFound){
      navigate("/not-found");
    }
  }, [isNotFound, learningObjectError, navigate]);

  useEffect(() => {
    if (learningObjectIsSuccess) {
      setPlaceholderLO(learningObject);
    }
  }, [learningObject, learningObjectIsSuccess]);
  const isToj = learningObject?.learningObjectTypology === LEVEL.DA_FINAL_BALANCE || 
  learningObject?.learningObjectTypology === LEVEL.DA_GOALS || 
  learningObject?.learningObjectTypology === LEVEL.DA_MATERIAL || 
  learningObject?.learningObjectTypology === LEVEL.DA_MEETING;
  
  window.addEventListener("message", (e) => {
    if (e.data == "closeModal" && isToj) {
      setOpenModalToj(false);
      refetchLo();

      if(courseId){
        refetchParentCourse();
      }

    }
  });

  const endDate = learningObject?.ecmEndDate ? 
    [ learningObject?.ecmEndDate?.[0], 
      learningObject?.ecmEndDate?.[1],
      learningObject?.ecmEndDate?.[2]
    ]  as JavaBasicDate : 
 
    learningObject?.editionsTojDetails?.[0] ? 
        [
          learningObject.editionsTojDetails[0].endTime?.[0], 
          learningObject.editionsTojDetails[0].endTime?.[1],
          learningObject.editionsTojDetails[0].endTime?.[2]
        ]  as JavaBasicDate
      : undefined ;

  const startDate = learningObject?.ecmStartDate ?  [
    learningObject?.ecmStartDate?.[0], 
    learningObject?.ecmStartDate?.[1],
    learningObject?.ecmStartDate?.[2]
  ] as JavaBasicDate :   
    learningObject?.editionsTojDetails?.[0] ? 
        [
          learningObject.editionsTojDetails[0].startTime?.[0], 
          learningObject.editionsTojDetails[0].startTime?.[1],
          learningObject.editionsTojDetails[0].startTime?.[2]
        ]   as JavaBasicDate
      : undefined;

  useEffect(() => {
    if (learningObjectIsFetching) return;
    refetchLo();
  }, [pathname]);
  

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
  
    const handleVisibilityChange = () => {
      if (!document.hidden && 
      (  learningObjectTypology !== LEVEL.DA_PHYSICAL_CLASS &&
        learningObjectTypology !== LEVEL.DA_VIRTUAL_CLASS && 
        learningObjectTypology !== LEVEL.DA_MEETING
      )
      ){
        refetchLo();
        if(courseId && routerState?.courseId){
          refetchParentCourse();
        }
      }
      
      
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  return (
    <>
      {
        (learningObjectIsFetching ) ? (
          <Spinner/>
        ) : 
          (
            isNotFound || !learningObject ? (
              <p>error occurred</p>
            ) : (
              <>
                <HeroBanner
                  badge={ learningObject.badge }
                  booked={
                    (learningObject.status === STATUS_LO.E)
                    || (learningObject.status === STATUS_LO.P)
                  }
                  categories={ learningObject.topicTags || [] }
                  certificate={ learningObject.certificate }
                  coverVideoPublicUrl={ learningObject?.coverVideoPublicURL }
                  courseId={ courseId }
                  coverPublicURL={ learningObject.coverPublicURL?.toString() || "" }
                  duration={ learningObject.duration }
                  ecmType={ learningObject.ecmType }
                  editionEndSubscriptionDate={ editionBooked?.endTime }
                  editionNumber={ editions?.length }
                  editionStartSubscriptionDate={ editionBooked?.startTime }
                  editionVirtualLink={ editionBooked?.link }
                  expirationDate={ learningObject?.expirationDate }
                  endDate={ endDate }
                  enrollType={ learningObject.enrollType }
                  id={ learningObject?.id }
                  ecmRegistration={ learningObject.ecmRegistration }
                  ecm_specialization={ learningObject.ecm_specialization }
                  isFavourite={ learningObject?.isFavourite }
                  isMandatory={ learningObject.isMandatory }
                  isSurvey={ "isSurvey" in learningObject ? learningObject.isSurvey : false }
                  isTest={  "isTest" in learningObject ? learningObject.isTest : false }
                  isToj={ learningObject?.isToj }
                  learningObjectType={ learningObject.learningObjectType }
                  learningObjectTypology={
                    learningObject.learningObjectTypology
                    || learningObjectTypology
                  }
                  meetingDetails={ learningObject?.meetingDetails }
                  percentageOfCompletion={ learningObject.percentageOfCompletion }
                  rating={ learningObject.averageRatingScore ?? 0 }
                  rootId={ rootId ?? loRootId }
                  setOpenModalEcm={ setOpenModalEcm }
                  setOpenTojModal={ setOpenModalToj }
                  shortDescription={ learningObject.shortDescription }
                  startDate={ startDate }
                  status={  hasEdition ? "N" : learningObject?.status }
                  title={ learningObject.title }
                />
                <GridMain padding={ 0 }>
                  <Tabs
                    contents={ contentTabs }
                    titleInset={ true }
                  />
                </GridMain>
              </>
            )
          )
      }

      { 
        learningObject && learningObject.enrollId && openModalEcm ? 
          <ModalEcmComponent
            courseId={ courseId }
            open={ openModalEcm }
            invalidateLearningObects={ ()=> {
              refetchLo();
              if(courseId){
                refetchParentCourse();
              }
              
            } }
            loId={ id }
            enrollId={ learningObject?.enrollId }
            onClose={ ()=> setOpenModalEcm(false) }
            rootId={ rootId }
            sponsors={ learningObject?.sponsors }
          /> : null
      }
      {
        openModalToj && learningObject ? 

          <ScormTojModal
            activityLevel={ learningObject?.learningObjectTypology  }
            idString={ learningObject.id }
            open={ openModalToj }
            courseId={ courseId?.toString() ?? null }
            rootId={ rootId?.toString() ?? null }
          /> : 
          null
      }
      <Modal 
        description={ getError(learningObjectError)?.[0]?.message ?? "" }
        open={ learningObjectIsError && showModal }
        title={ getError(learningObjectError)?.[0]?.name ?? "" }
        onClose={ ()=> { 
          setShowModal(false);
          navigate(-1);
        } }
      />
    </>
  );
}
