import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";

export const CertificateButton = styled(LoadingButton)(({ theme }) => ({

  ".MuiLoadingButton-loadingIndicator .MuiCircularProgress-root": {
    [theme.breakpoints.up("md")]: {
      "width": "32px !important", /* Imposta la larghezza desiderata */
      "height": "32px !important" /* Imposta l'altezza desiderata */
    }
  },

  "&:hover": {
    background: "none"
  }
}));
