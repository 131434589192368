

import { BoxCornersStyled } from "./BoxCorners.styles";
import type { BoxCornersProps } from "./BoxCorners.types";

export function BoxCorners({
  $filled=false,
  $cornersPosition,
  $isSignup,
  ...props
}: BoxCornersProps) {
  return (
    <BoxCornersStyled
      $isSignup={ $isSignup }
      $filled={ $filled }
      $cornersPosition={ $cornersPosition }
      { ...props }
    >
      <div className="box-el"/>
    </BoxCornersStyled>

  );
}
