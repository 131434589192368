import {
  ChangeEvent,
  KeyboardEvent,
  useRef
} from "react";
import { StyledCodeInput } from "./CodeInput.styles";
import type { CodeInputProps } from "./CodeInput.types";

export function CodeInput({
  boxSize,
  onChange,
  ...props
} : CodeInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <StyledCodeInput
      boxSize={ boxSize }
      onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
        changeEvent.target.value = changeEvent.target.value.split("").slice(-1).pop() || "";
        onChange && onChange(changeEvent);
        if (changeEvent.target.value !== "") {
          inputRef.current?.nextElementSibling &&
          (inputRef.current.nextElementSibling as HTMLElement).focus();
        }
        return changeEvent.target.value;
      } }
      onKeyDown={ (keyboardEvent: KeyboardEvent<HTMLInputElement>) => {
        if (keyboardEvent.key === "Backspace") {
          if (inputRef.current?.nextElementSibling || !inputRef.current?.value) {
            inputRef.current?.previousElementSibling &&
            (inputRef.current.previousElementSibling as HTMLElement)?.focus();
          }
        // } else if (isNaN(parseInt(keyboardEvent.key)) || parseInt(keyboardEvent.key) < 0) {
        //   keyboardEvent.preventDefault();
        } else if (keyboardEvent.key === " ") {
          keyboardEvent.preventDefault();
        }
      } }
      placeholder="-"
      ref={ inputRef }
      { ...props }
    />
  );
}
