import {
  useQuery,
  useMutation,
  useQueryClient,
  type QueryKey
} from "@tanstack/react-query";
import { CertificateInfo } from "../components/CertificateButton/CertificateButtonContainer.types";
import { ERRORS } from "../consts";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
// import type { NotificationResponse } from "../types";
import { getEnvironmentVariables } from "../utils/general";

const { basePath } = getEnvironmentVariables();

export function getEditProfileImageQuery(
  refreshSession: () => Promise<void>,
  {
    enabled,
    idToken,
    queryKey,
    sessionToken,
    uri
  } : {
    enabled: boolean
    idToken: string
    queryKey: string[]
    sessionToken: string
    uri: string
  })
{
  const fetcher = fetcherFactory();
  return {
    enabled:enabled,
    // initialData,
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      }
    )
      .then((res) => {
        return res.text();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          refreshSession();
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: queryKey,
    staleTime: 2 * 60 * 1000 // 2min
  };
}

export function useGetProfileImageQuery({
  enabled=true
  // queryKey=[]
} : {
  enabled?: boolean
  // queryKey?: string[]
}) {
  const fetcher = fetcherFactory();
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  const uri = `/mediamanager/userprofile/${userId}/image`;

  return useQuery<
    unknown,
    unknown,
    string,
    QueryKey
  >(
    {
      enabled:Boolean(enabled
        && userId
        && idToken
        && sessionToken
      ) && !isSeedingSession,
      // initialData,
      queryFn: () => fetcher(
        `${basePath}${uri}`,
        {
          headers:{
            "authorization": idToken,
            "x-ada-session-token": sessionToken
          }
        }
      )
        .then((res) => {
          return res.text();
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        }),
      queryKey: [uri, isSeedingSession],
      staleTime: 2 * 60 * 1000 // 2min
    });
}

export function useMutationProfileImageQuery() {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const fetcher = fetcherFactory();
  const queryClient = useQueryClient();

  const apiPath = `/mediamanager/userprofile/image?userId=${userId}`;
  const uriToInvalidate = `/mediamanager/userprofile/${userId}/image`;

  return useMutation({
    mutationFn: (formdata:FormData) => {
      return fetcher(
        `${basePath}${apiPath}`,
        {
          body: formdata,
          headers:{
            "authorization": idToken,
            "x-ada-session-token": sessionToken

          },
          method: "POST"
        }
      )
        .then((res) => {
          return res.text();
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
            throw error;
          }
        });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [uriToInvalidate, isSeedingSession] });
    }
  });
}

export function useCvFormativeQuery({
  enabled=true,
  studentId
}: {
  enabled?: boolean
  studentId: string
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  return useQuery<
    unknown,
    unknown,
    string,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && sessionToken
      && corporateId
      && initiativeId
      && studentId
    ),
    queryFn: () => fetcher(
      `${basePath}/enrollment/cvformative?studentId=${studentId}`,
      {
        body: null,
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      }
    )
      .then((res) => {
        return res.text();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: ["useCvFormativeQuery", studentId]
  });
}

export function useDownloadCertificate() {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();

  return useMutation({
    mutationFn: (certificateInfo: CertificateInfo) => fetcher(
      `${basePath}/certificates/issued-certificate/${certificateInfo.certificateId}/download?objectId=${certificateInfo.learningObjectId}&learnerId=${certificateInfo.learnerId}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "GET"
      }).then( (res) => {
      return res.blob();
    })
      .catch(async (error) => {
        const errorRes = await error;
        throw errorRes;
      })
  });
}
