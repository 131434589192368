import {
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CardsBox } from "./Category.styles";
import {
  Button,
  ButtonLink,
  CardFilter,
  CarouselCard,
  FiltersCarousel,
  GridMain,
  Icon,
  Link,
  Spinner
} from "../../components";
import {
  STATUS_LO,
  PAGE_NAME
} from "../../consts";
import {
  useInfiniteContentQuery,
  useStructuresQuery,
  useTopicsQuery
} from "../../queries";
import { useAuthStore, useNavbarStore } from "../../stores";
import type {
  LearningObject,
  MetaData
} from "../../types";
import { createEndDate, createStartDate } from "../../utils/general";

export function Category() {
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const navbarItem = useNavbarStore(state => state.item);
  const theme = useTheme();
  const { category } = useParams();
  const { state:routerState } = useLocation();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { data: pageStructure } = useStructuresQuery({
    pageName: navbarItem === "AGENDA" ? PAGE_NAME.AGENDA_OVERVIEW : PAGE_NAME.EXPLORE_OVERVIEW
  });
  const { data: allTopics } = useTopicsQuery({});
  const [selectedTopics, setSelectedTopics] = useState<number[]>([]);
  const topicsQueryString = selectedTopics.reduce(
    /* the uri arrives with ..topics= */
    (currentTopicsQuery, currentTopic, currentTopicIndex) => {
      if (currentTopicIndex == 0 && currentTopic) {
        return currentTopicsQuery + currentTopic;
      } else {
        return currentTopicsQuery + "&topics=" + currentTopic;
      }
    },
    ""
  );
  const relativePath = pageStructure?.relativePaths.find((relativePath) => (
    relativePath.title === decodeURI(category ?? "")
  ));
  const { t } = useTranslation();
  const {
    data: carouselData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading
  } = useInfiniteContentQuery<
    Record<
      string,
      LearningObject[]
    > & {
      metadata: MetaData
    }
  >({
    enabled: Boolean(relativePath),
    pageSize: 12,
    path: relativePath?.apiPath ?? "",
    //additional params
    topics: topicsQueryString
  });


  const responseKey = carouselData
    ? (Object.keys(carouselData?.pages?.[0]).find((key) => key !== "metadata") ?? "")
    : "";
  const carouselCards = (carouselData && responseKey)
    ? (
      carouselData?.pages?.reduce((acc, page) => {
        const contents = page[responseKey] ?? [];
        return acc.concat(contents);
      }, [] as LearningObject[])
    ) : [];
  const title = routerState?.titleCards ? routerState.titleCards :  
    (relativePath?.serviceType && relativePath?.serviceType !== "visCarCategory") ? 
      t(relativePath?.serviceType) : relativePath?.title;
  return (
    <GridMain>
      <Stack
        maxWidth="calc(100vw - (100vw - 100%))"
        minWidth={ "100%" }
        sx={ {
          ".button-back": {
            color: theme.customColors.textPrimary,
            fontSize: "1.141rem",
            fontWeight: "400",
            gap: "9px",
            justifyContent: "flex-start",
            padding: "32px 16px 37px",
            textAlign: "start",

            [theme.breakpoints.down("sm")]: {
              paddingLeft: "0"
            },

            "&: hover":{
              background: "transparent"
            },

            ".icon":{
              height: "18px",
              width: "13px"
            }
          }
        } }
      >
        <Link href="/esplora" onClick={ () => setNavbarItem("EXPLORE") }>
          <ButtonLink
            disableRipple
            className={ "button-back" }
          >
            <Icon
              className={ "icon" }
              color= { theme.customColors.systemPrimary01 }
              icon={ "arrow_left" }
              width={ "20px" }
              height={ "20px" }/>
            { t("back_to_explore") }
          </ButtonLink>
        </Link>
        <Typography
          variant={ "h4" }
          fontWeight={ "700" }
          padding={ "32px 42px 0" }
          sx={ {
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.5rem",
              marginBottom: "16px",
              paddingLeft: "0",
              paddingRight: "0"
            }
          } }
        >
          { title }
        </Typography>
        <FiltersCarousel
          label={ "filters-carousel" }
          hasPagination={ false }
        >
          {
            allTopics?.map((topic) => (
              topic.learning_object_associated > 0 ? (
                <CardFilter
                  key={ `topic--${topic.id}` }
                  coverPublicURL={ topic.url.public_url }
                  onClickhandler={ () => {
                    const currentSelectedCategories = [...selectedTopics];
                    if (selectedTopics.includes(topic.id)) {
                      currentSelectedCategories.splice(
                        currentSelectedCategories.indexOf(topic.id),
                        1
                      );
                    } else {
                      currentSelectedCategories.push(topic.id);
                      currentSelectedCategories.sort();
                    }
                    setSelectedTopics(currentSelectedCategories);
                  } }
                  selected={ selectedTopics.includes(topic.id) }
                  title={ topic.name }
                /> 
              ) : null
            ))
          }
        </FiltersCarousel>

        <CardsBox $isLoading={ isLoading } >
          {
            isLoading && !!relativePath ? (
              <Spinner />
            ) : (
              carouselCards?.map((card) => (
                <CarouselCard
                  key={ `carousel-category-detail--card--${card.id}` }
                  booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                  categories={ card.topicTags || [] }
                  coverPublicURL={ card.coverPublicURL }
                  coverVideoPublicURL={ card.videoPublicURL }
                  disabled={ false }
                  duration={ card.duration }
                  editionNumber={ card?.editionNumber }
                  ecmRegistration={ card.ecmRegistration }
                  ecm_specialization={ card.ecm_specialization }
                  endDate={ createEndDate(card) }
                  enrollType={ card.enrollType }
                  expirationDate={ card.expirationDate }
                  id={ card.id }
                  isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                  isFavourite={ card.isFavourite }
                  isMandatory={ card.isMandatory }
                  isToj={ card.isToj }
                  learningObjectType={ card?.learningObjectType || "" }
                  learningObjectTypology={ card.learningObjectTypology  }
                  percentageOfCompletion={ card.percentageOfCompletion }
                  shortDescription={ card.shortDescription }
                  startDate={ createStartDate(card)  }
                  status={ card.status  || "N" }
                  title={ card.title }
                />
              ))
            )
          }

        </CardsBox>
        {
          (hasNextPage && !isFetchingNextPage) ? (
            <Button
              disabled={ !hasNextPage }
              disableRipple
              onClick={ () => fetchNextPage() }
              sx={ {
                gap:"0.5rem",
                margin:"0 auto",
                width:"fit-content"
              } }
            >
              { t("load_more") }
              <Icon 
                color={ theme.customColors.systemPrimary02 }
                icon="dropdown" 
                size={ 20 } 
              />
            </Button>
          ) : null
        }
        {
          isFetchingNextPage ? (
            <Spinner />
          ) : null
        }
      </Stack>
    </GridMain>
  );
}
