import {
  Box,
  Grid,
  GridProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CreateModalSuccessBox = styled(Grid)<GridProps>(({ theme }) => ({
  alignItems:" flex-end",
  borderRadius:"4px",
  flexDirection: "column",
  gap: "24px",
  padding:" 24px 40px",
  [theme.breakpoints.down("sm")]:{
    padding:" 16px 20px",
    width: "375px"
  }
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "21px",
  width:"549px",
  

  [theme.breakpoints.down("sm")]:{
    fontSize: "16px",
    lineHeight: "21px",
    width:"331px"
  }
}));

export const TextContent = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:"8px",

  [theme.breakpoints.down("sm")]:{
    width:"331px"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "36px",
  textAlign: "center",
  width:"537px",
  
  [theme.breakpoints.down("sm")]:{
    fontSize: "24px",
    lineHeight: "30px",
    width:"331px"
  }
}));
