
import { ButtonProps } from "./Button.types";
import { Icon } from "../Icon";

export const ButtonText: ButtonProps = {
  children: "text1",
  variant: "contained"
};

export const ButtonBookmark: ButtonProps = {
  $icon: true,
  children: <Icon icon={ "Icons_salva-preferito_glow" } width={ "20px" } height={ "20px" }/>,
  color: "primary",
  variant: "outlined"
};

export const ButtonLink: ButtonProps = {
  $icon: true,
  children: ["text", <Icon icon={ "left" } size={ 20 } key={ "icons" }/>]  ,
  color: "primary",
  variant: "text"
};
