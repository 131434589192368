import {
  Avatar,
  Grid,
  InputBase,
  Typography,
  alpha,
  styled } from "@mui/material";
import chatBoxbg from "../../assets/Subtract.png";


export const StyledChatBot = styled(Grid)(({ theme }) => ({
  backgroundImage:`url(${chatBoxbg})..`,
  // eslint-disable-next-line max-len
  background: `radial-gradient(34px at 20.93% 16px, #0000 112%, ${alpha(theme.customColors.backgroundPrimary,1)} 101%) 60% 110% / calc(5.5* 100%) 100% no-repeat`,
  backgroundRepeat:"no-repeat",
  backgroundSize:"cover",
  borderRadius: theme.spacing(1),


  padding:theme.spacing(1, 1),
  position:"relative",


  height:"100%",
  width:"100%",

  "&:before":{
    content:"''",
    background:alpha(theme.customColors.backgroundSecondary,0.2),
    position:"absolute"

  }

}));
export const StyledChatGrid = styled(Grid)(({ theme }) => ({
  height:"calc(100% - 50px)",

  background: alpha(theme.customColors.backgroundSecondary, 0.2),
  borderRadius:"4px",
  display:"flex",
  flexDirection:"column",
  justifyContent:"flex-end",
  padding: theme.spacing(0, 2, 2)

}));
export const StyledChatGridTexts = styled(Grid)(({ theme }) => ({
  height:"calc(100%)",
  overflowY:"scroll",
  paddingTop:theme.spacing(2),

  display: "flex",
  flexDirection:"column",

  marginRight: "-6px",

  "&:hover": {
    "::-webkit-scrollbar-thumb": {
      background: alpha(theme.customColors.systemPrimary01,0.2)
    }
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },

  "::-webkit-scrollbar": {
    width: "6px"
  },

  "::-webkit-scrollbar-thumb": {
    "backgroundColor": "transparent",
    borderRadius: "8px"
  }
}));
export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textTag,
  fontSize: "0.875rem",
  fontWeight: 700,
  maxWidth: "140px",

  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "1",
  display: "-webkit-box",
  overflow: "hidden",
  overflowWrap:"break-word",
  textOverflow: "ellipsis"

}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border:`1px solid ${theme.customColors.systemSecondary01}`,
  borderRadius:"60px",
  boxShadow:`0px 0px 4px 0px ${theme.customColors.systemSecondary01}`,
  position:"absolute",

  height:"64px",
  width:"64px",

  left:" 35px",
  top: "-16px"

}));

export const StyledChatBotInputGrid = styled(Grid)(({ theme }) => ({
  borderTop:`.5px solid ${theme.customColors.borderTag}`,
  display:"flex",
  gap:theme.spacing(1),
  justifyContent:"space-between",
  paddingTop:theme.spacing(2),

  "button":{
    padding:theme.spacing(1.2, 1.4)
  }

}));

export const StyledChatBotInput = styled(InputBase)(({ theme }) => ({

  background: theme.customColors.backgroundSecondary,
  borderRadius:theme.spacing(1),

  fontSize:theme.spacing(1.5),
  fontWeight:400,
  padding:theme.spacing(1.5, 2),
  width:"calc(100% - 44px)",

  "input":{
    color:theme.customColors.textInput,
    padding:0,

    "&::placeholder":{
      color:theme.customColors.textDisabled,
      opacity:1
    }
  }

}));
