import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BackgroundBar,
  ForegroundBar,
  ProgressTypography
} from "./Progress.styles";
import type { ProgressProps } from "./Progress.types";
import { CustomRegex } from "../../utils/regex";

export function Progress({ percentageOfCompletion, ...props } : ProgressProps) {
  const { t } = useTranslation();

  const theme = useTheme();
  const progressAmount = CustomRegex.matchNumber(percentageOfCompletion ) ?? "0";

  return (
    <Stack
      className="progress-component"
      data-testid="percentageOfCompletion"
      gap={ "4px" }
      maxWidth={ "250px" }
      { ...props }
    >
      <ProgressTypography textAlign="start" sx={ { color: props.color || theme.customColors.textPrimary } } >
        { t("percentageOfCompletion") }: <span className="bold">
          { progressAmount }%
        </span>
      </ProgressTypography>
      <BackgroundBar data-testid="percentageOfCompletion-bg">
        <ForegroundBar
          data-testid="percentageOfCompletion-fg"
          $progressAmount={ progressAmount }
        />
      </BackgroundBar>
    </Stack>
  );
}
