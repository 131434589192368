import { 
  Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import {
  CreateModalSuccessBox,
  DescriptionTypography,
  TextContent,
  TitleTypography
} from "./CreateModalSuccess.styles";
import { CreateModalSuccessProps } from "./CreateModalSuccess.types";
import { ButtonText, Icon } from "../../components";

export function CreateModalSuccess({
  onClose,
  ...props
}: CreateModalSuccessProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (

    <CreateModalSuccessBox
      container
      { ...props }
    >
      <Icon
        icon={ "close" }
        size={ "19px" }
        cursor="pointer"
        color={ theme.palette.primary?.main }
        transform= "rotate(-90deg)"
        onClick={ ()=> {
          if(onClose){
            onClose();
          }
        }
        }
      />
      <TextContent>
        <TitleTypography>
          {  props.title }
        </TitleTypography>
        <DescriptionTypography>
          { t("give_voice") }
        </DescriptionTypography>
        <Stack
          flexDirection="row"
          gap={ theme.spacing(1) }
          justifyContent={ "center" }
          marginTop={ "16px" }
        >
          <ButtonText
            sx={ {
              borderRadius:"2px",
              fontWeight:700,
              maxWidth:"120px",
              padding:theme.spacing(1,2)
            } }
            onClick={ ()=> {
              if(onClose){
                onClose();
              }
           
            } }
          >
            { t("continue") }
          </ButtonText>
        </Stack>
      </TextContent>
    </CreateModalSuccessBox>

  );
}
