import { CardTabs as CardTabsComponent } from "./CardTabs.component";
import { CardTabsProps } from "./CardTabs.types";
import {
  BlendedCourseCard as BlendedCourseCardProps
} from "./CardTabs.variants";

export const BlendedCardTab = ({ ...props }: CardTabsProps) => (
  <CardTabsComponent { ...{ ...BlendedCourseCardProps, ...props } }/>
);

export const CardTabs = CardTabsComponent;
