import { Stack, StackProps, Typography } from "@mui/material";
import { common } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { ElementType } from "react";

export const DetailTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.accentVariantE,
  fontSize: "2rem",
  lineHeight: 1.1875,
  width:"70% !important",

  [theme.breakpoints.down("sm")]: {
    fontSize:"0.875rem",
    lineHeight: "18px",

    display: "-webkit-box !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "4"
  }
}));

export const BigCarouselCardStack = styled(Stack)<
  StackProps &
  { $coverPublicURL:string } &

  {
    component: ElementType // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'
  }
>(({ $coverPublicURL, theme }) => ({
  "& > *:not(button)" : {
    width: "50%"
  },
  /*
    "&:focus-within": {
      border: `0.125rem solid ${accent}`
    },
  */
  background: `${theme.linearGradients.gradientCAlternative03}, url(${$coverPublicURL})`,
  backgroundPosition:"center",
  backgroundRepeat:"no-repeat",
  backgroundSize: "cover",
  borderRadius:"12px",
  boxSizing:"border-box",
  height:"100%",
  justifyContent: "flex-end",
  maxWidth: "100%",
  minHeight: "450px",
  padding: "4.6875rem 0 4.688rem 5.875rem",

  [theme.breakpoints.down("sm")]: {
    background:`${theme.linearGradients.gradientD}, url(${$coverPublicURL})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius:"8px",
    minHeight: "366px",
    padding: "17px 17px 8px",

    "& > *:not(button)" : {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "85%"
    },
    ".card-signature .icon": {
      height:"20px",
      width:"20px"
    }
  },

  ".card-signature .icon": {
    height:"24px",
    width:"24px"
  },

  "a > p":{
    color: theme.customColors.accentVariantE
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.accentVariantE,
  fontSize: "3rem",
  fontWeight:"700",
  lineHeight: 7 / 6,
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,

  [theme.breakpoints.down("sm")]: {
    fontSize:"1.5rem",
    lineHeight: "1.25"
  }
}));
