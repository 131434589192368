import type { BadgeLinkProps } from "./BadgeLink.types";
import {
  Badge,
  Link
} from "../../components";

export function BadgeLink({
  iconURL,
  small,
  templatePath
} : BadgeLinkProps) {
  return (
    <Link
      download={
        Boolean(templatePath)
      }
      target={ templatePath ? "_blank" : "_self" }
      href={ templatePath ? templatePath : "/profile" }
    >
      <Badge
        iconURL={ iconURL }
        small={ small }
      />
    </Link>
  );
}
