import SwiperCore, { A11y, Mousewheel, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { StyledSwiper } from "./FiltersCarousel.styles";
import { FiltersCarouselProps } from "./FiltersCarousel.types";
/* eslint-disable import/no-unresolved */
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/pagination";
import "swiper/css/navigation";
/* eslint-enable import/no-unresolved */

SwiperCore.use([A11y, Mousewheel, Pagination, Navigation]);

export function FiltersCarousel({
  children,
  hasPagination=false,
  label,
  ...props
}: FiltersCarouselProps) {

  const cards =
    (!Array.isArray(children) && children != undefined) // not null or undefined
      ? [children]
      : children;

  return (
    <StyledSwiper
      key={ label }
      a11y={ { enabled: true } }
      // centeredSlides={ true }
      direction={ "horizontal" }
      mousewheel={ true }
      navigation={ true }
      pagination={
        hasPagination ? {
          clickable: true
          // dynamicBullets: true
        } : false
      }
      slidesPerView={ "auto"  }
      spaceBetween={ 0 }
      { ...props }
    >
      {
        cards?.map((card, idx) => (
          <SwiperSlide key={ `carousel-${label}--card-${idx}` }>
            { card }
          </SwiperSlide>
        ))
      }
    </StyledSwiper>
  );
}
