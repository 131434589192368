import {
  type QueryKey,
  useQuery,
  useMutation,
  useQueryClient
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
import {
  getEnvironmentVariables,
  replacePathParams
} from "../utils/general";


type SlotResponse = {
  end_date: string
  id: number
  start_date: string
}

export type SlotsResponseType = {
  slots: Array<SlotResponse>
  hours_available: number
}

const { basePath } = getEnvironmentVariables();

export function useSlotsQuery({
  enabled=true,
  month,
  year,
  startDate,
  endDate
}: {
  enabled?: boolean
  month: string
  year: string
  startDate: string
  endDate: string
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const authToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const uri = `${basePath}/usermgmt/learner/smart-learning/slots`;
  const params = `start_date=${startDate}&end_date=${endDate}&month=${month}&year=${year}`;

  return useQuery<
    unknown,
    unknown,
    SlotsResponseType,
    QueryKey
  >({
    enabled: Boolean(enabled 
      && authToken
      && sessionToken
    ),
    queryFn: () => fetcher(
      `${uri}?${params}`,
      {
        body: null,
        headers:{
          "authorization": authToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "GET"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    // queryFn: () => { //fetcher goes here
    //   return {
    //     "hours_available": 1,
    //     "slots": [
    //       {
    //         "end_date": "2023-10-13T08:30:00.000Z",
    //         "id": 0,
    //         "start_date": "2023-10-13T07:30:00.000Z"
    //       },
    //       {
    //         "end_date": "2023-10-13T11:00:00.000Z",
    //         "id": 1,
    //         "start_date": "2023-10-13T10:00:00.000Z"
    //       }
    //     ]
    //   };
    // },
    queryKey: ["useSlotsQuery", startDate, endDate]
  });
}

export function useSlotsPostQuery() {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const authToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  
  const queryClient = useQueryClient();

  const userData = useAuthStore(state => state.userData);
  const pathParams = {
    corporateId: userData?.organization_id?.toString() ?? "",
    initiativeId: userData?.initiative_id?.toString() ?? ""
  };
  const apiPathToInvalidate = replacePathParams(
    "/learning-catalogue/{corporateId}/{initiativeId}/learnerAgenda", 
    pathParams
  );

  return useMutation({
    mutationFn: ({
      startDate,
      endDate
    } : {
      startDate: string
      endDate: string
    }) => fetcher(
      `${basePath}/usermgmt/learner/smart-learning/slots`,
      {
        body: JSON.stringify(
          {
            "end_date": endDate,
            "start_date": startDate
          }
        ),
        headers:{
          "authorization": authToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    // mutationFn: ({
    //   entityId
    // }: {
    //   entityId: number
    // }) => {  //fetcher goes here
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve(`{"message": "User: ${userId} liked entity: ${entityId}."}`);
    //     }, 300);
    //   });
    // },
    onSuccess: (data, { startDate, endDate }) => {
      const getStartDate = `${startDate.split("T")[0]}T00:00:00.000Z`;
      const getEndDate = `${endDate.split("T")[0]}T23:59:00.000Z`;
      queryClient.invalidateQueries({ queryKey: ["useSlotsQuery", getStartDate, getEndDate] });
      queryClient.invalidateQueries(
        {
          predicate: (query) => {
            return (query.queryKey[0] as String).startsWith(apiPathToInvalidate);
          }
        }
      );
    }
  });
}

export function useSlotsPutQuery() {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const authToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const queryClient = useQueryClient();

  const userData = useAuthStore(state => state.userData);
  const pathParams = {
    corporateId: userData?.organization_id?.toString() ?? "",
    initiativeId: userData?.initiative_id?.toString() ?? ""
  };
  const apiPathToInvalidate = replacePathParams(
    "/learning-catalogue/{corporateId}/{initiativeId}/learnerAgenda", 
    pathParams
  );

  return useMutation({
    mutationFn: ({
      slotId,
      startDate,
      endDate
    } : {
      slotId: number
      startDate: string
      endDate: string
    }) => fetcher(
      `${basePath}/usermgmt/learner/smart-learning/slots/${slotId}`,
      {
        body: JSON.stringify(
          {
            "end_date": endDate,
            "start_date": startDate
          }
        ),
        headers:{
          "authorization": authToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "PUT"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    // mutationFn: ({
    //   entityId
    // }: {
    //   entityId: number
    // }) => {  //fetcher goes here
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve(`{"message": "User: ${userId} liked entity: ${entityId}."}`);
    //     }, 300);
    //   });
    // },
    onSuccess: (data, { startDate, endDate }) => {
      const getStartDate = `${startDate.split("T")[0]}T00:00:00.000Z`;
      const getEndDate = `${endDate.split("T")[0]}T23:59:00.000Z`;
      queryClient.invalidateQueries({ queryKey: ["useSlotsQuery", getStartDate, getEndDate] });
      queryClient.invalidateQueries(
        {
          predicate: (query) => {
            return (query.queryKey[0] as String).startsWith(apiPathToInvalidate);
          }
        }
      );
    }
  });
}

export function useSlotsDeleteQuery() {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const authToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const queryClient = useQueryClient();

  const userData = useAuthStore(state => state.userData);
  const pathParams = {
    corporateId: userData?.organization_id?.toString() ?? "",
    initiativeId: userData?.initiative_id?.toString() ?? ""
  };
  const apiPathToInvalidate = replacePathParams(
    "/learning-catalogue/{corporateId}/{initiativeId}/learnerAgenda", 
    pathParams
  );

  return useMutation({
    mutationFn: ({
      slotId
    } : {
      slotId: number
    }) => fetcher(
      `${basePath}/usermgmt/learner/smart-learning/slots/${slotId}`,
      {
        body: null,
        headers:{
          "authorization": authToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "DELETE"
      }

    )
      .then((res) => {
        let body = res.text();
        return body;
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    // mutationFn: ({
    //   entityId
    // }: {
    //   entityId: number
    // }) => {  //fetcher goes here
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve(`{"message": "User: ${userId} liked entity: ${entityId}."}`);
    //     }, 300);
    //   });
    // },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useSlotsQuery"] });
      queryClient.invalidateQueries(
        {
          predicate: (query) => {
            return (query.queryKey[0] as String).startsWith(apiPathToInvalidate);
          }
        }
      );
    }
  });
}
