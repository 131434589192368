import { CardCourseTab as CardComponent } from "./CardCourseTab.component";
import type { CardCourseTabProps } from "./CardCourseTab.types";
import * as CardCourseTabVariants from "./CardCourseTab.variants";

export const CardCourseTabs = ({ ...props }:
  Partial<
  Extract<CardCourseTabProps, { learningObjectTypology: "ACTIVITY"}>
> | (
  Pick<Extract<CardCourseTabProps, { learningObjectTypology: "COURSE" | "PACKAGE"}>, "learningObjectTypology" | "percentageOfCompletion"> & 
  Partial<Omit<Extract<CardCourseTabProps, { learningObjectTypology: "COURSE" | "PACKAGE"}>, "learningObjectTypology" | "percentageOfCompletion">>
)) => (
  <CardComponent { ...{ ...CardCourseTabVariants.Default, ...props } }/>
);

export const CardCourseTab = CardComponent;

