import {
  Box,
  Grid,
  GridProps
} from "@mui/material";

import { styled } from "@mui/material/styles";

export const CardProgressBox = styled(Grid)<GridProps & { $profilePage:boolean } >(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxHeight: "50rem",
  padding: theme.spacing(3),

  [`${theme.breakpoints.down("sm")}`]: {
    padding: theme.spacing(2)
  }

})) ;

export const CardProgressItemsBox = styled(Grid)<GridProps>(({ theme }) => ({
  height:"220px",
  width:"210px",

  alignItems:"center",
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(3),
  justifyContent:"center",
  margin:"auto",
  position:"relative"

}));

export const StyledPercentageItem = styled(Box)<{
  $left:string,
  $right:string,
}>(({
  $left,
  $right
}) => {
  return {
    left:$left,
    position:"absolute",
    right:$right,

    display:"flex",
    flexDirection:"column",
    zIndex:1,

    ".progress-left":{
      transform:"scaleX(-1);"
    },

    ".centered":{
      transform:"translateX(-14px)"
    }

  };});

export const StyledTitleSummary = styled(Grid)<GridProps>(({ theme }) => ({
  alignItems:"center",
  color:theme.customColors.textPrimary,
  display:"flex",
  flexDirection:"column",
  fontSize:theme.spacing(3.5),
  fontWeight:700,
  justifyContent:"flex-end",

  height:"80%",

  "span":{
    fontSize:theme.spacing(1.6),
    fontWeight:400,
    paddingBottom: "10px"
  }
})) ;

export const StyledTooltip = styled(Box)<{
  $bottom:string,
  $left:string,
  $right:string,
  $top:string,
  $visible:boolean

}>(({
  $bottom,
  $left,
  $right,
  $top,
  $visible,
  theme
}) => {
  return {

    bottom:$bottom,
    left:$left,
    position:"absolute",
    right:$right,
    top:$top,

    height:"fit-content",
    maxWidth:"fit-content",
    minWidth:"100px",

    opacity:$visible ? 1 : 0,

    pointerEvents:"none",

    zIndex:2,

    ".tooltip-wrap":{

      background: theme.linearGradients.gradientB,
      border:`1px solid ${theme.customColors.textPrimary}`,
      borderRadius: theme.spacing(1),
      display:"flex",
      flexDirection:"column",
      fontSize:theme.spacing(1.5),

      padding:theme.spacing(1),
      textAlign:"center",

      position:"relative",

      "&:before":{

        // eslint-disable-next-line max-len
        backgroundImage:"url(\"data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.93934 6.23223L1.20711 0.5H14.7929L9.06066 6.23223C8.47487 6.81802 7.52513 6.81802 6.93934 6.23223Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A\")",
        backgroundRepeat:"no-repeat",
        content:"''",
        margin:"0 auto",
        position:"absolute",

        bottom:"-8px",
        left:0,
        right:0,

        height:"8px",
        width:"16px"

      }
    }



  };});
