import {
  Stack,
  type BoxProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const InlineModal = styled(Stack)<
  & BoxProps
  & { $open: boolean }
>(({ $open }) => ({
  display: $open ? undefined : "none",
  height: "100%",
  width: "100%"
}));


export const StyledIframe = styled("iframe")(() => ({
  border: "none",
  height: "100%",
  inset: 0,
  width: "100%"
}));
