import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ButtonProps } from "./Button.types";


const StyledButton = styled(MuiButton)<ButtonProps>(({ theme, variant }) => ({
  alignItems: "center",
  borderRadius: theme.spacing(1),/*(variant === "contained" || variant === "outlined") ? "2px" : "4px",*/
  color: (variant === "contained")
    ? theme.palette.background?.paper
    : undefined,
  display: "flex",
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: 1.5,
  minWidth: "128px",
  padding: theme.spacing(1, 2),
  textTransform: "inherit",
  "&.MuiButton-outlinedPrimary": {
    "&:hover": {
      backgroundColor: theme.customColors.textPrimaryCta,
      color: theme.palette.background?.paper,
      "path":{
        "fill": theme.palette.background?.paper
      }
    }
  },

  "&.Mui-disabled": {
    background:theme.customColors.backgroundDisabled,
    color:theme.customColors.textDisabledAlternative,
    cursor:"not-allowed",
    pointerEvents:"auto"
  }
}));

export function Button({ ...props }: ButtonProps) {
  return (
    <StyledButton { ...props } />
  );
}
