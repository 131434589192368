import { styled } from "@mui/material/styles";
import { DetailedHTMLProps, ImgHTMLAttributes } from "react";

export type LogoProps =
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    height: string
  };

export const LogoWrapper = styled("div")<
  Pick<LogoProps, "height">
>(
  ({ height, theme }) => ({
    "> img": {
      maxHeight: height,
      objectFit: "contain"
    },
    alignItems: "center",
    display: "flex",
    margin: theme.spacing(0, 1, 0, 0)
  })
);

export function Logo({ height, ...props }: LogoProps) {
  return (
    <LogoWrapper height={ height }>
      { /* eslint-disable-next-line jsx-a11y/alt-text */ }
      <img { ...props } />
    </LogoWrapper>
  );
}
