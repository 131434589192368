import {
  Grid,
  useTheme
} from "@mui/material";
import {
  Fragment,
  useEffect,
  useRef
} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  // useLocation,
  useParams
} from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { LEVEL } from "../../consts";
import { useStartOrResumeQuery } from "../../queries";
import { useAuthStore } from "../../stores";
import type {
  LearningActivity,
  Level,
  UserData
} from "../../types";
import {
  addEscapeString,
  getEnvironmentVariables
} from "../../utils/general";

const {
  scormJs,
  scormTracker,
  xapiTracker
} = getEnvironmentVariables();
export function Scorm({ courseId, rootId }: { courseId: string | null, rootId: string | null }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    id: idString,
    learningObjectTypology: activityLevel
  } = useParams<{ id: string, learningObjectTypology: Level}>();
  const location = useLocation();
  const regex = new RegExp("(^| )activeLangCode=([^;]+)");
  const match = document.cookie.match(regex);
  const language = match && match[2];
  const calculatedCourseId = location.search.split("courseId=")[1]?.split("&")[0];
  const calculatedRootId = location.search.split("rootId=")[1];  
  
  
  // const { search } = useLocation();
  // const query = new URLSearchParams(search);

  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken);
  const userData = useAuthStore(state => state.userData);
  const flagScript = useRef(false);

  const activityId = idString ? parseInt(idString) : NaN;
  // const courseId = query.get("courseId");
  // const rootId = query.get("rootId");
  // const language = document.documentElement.lang;
  

  const {
    data: learningObject,
    isLoading: startOrResumeIsLoading,
    fetchStatus
  } = useStartOrResumeQuery({
    learningObjectId: activityId,
    learningObjectTypology: activityLevel as Level,
    parentId: rootId !== "null"
      ? rootId?.toString()
      : courseId !== "null"
        ? courseId?.toString()
        : activityId.toString()
  });
  
  const idPath = rootId !== "null"
    ? rootId?.toString()
    : courseId !== "null"
      ? courseId?.toString()
      : activityId.toString();
  const typologyPath = rootId !== "null"
    ? LEVEL.PATH
    : courseId !== "null"
      ? LEVEL.COURSE
      : activityLevel;
  const detailsPath = `esplora/dettaglio/${idPath}/${typologyPath}`;

  useEffect(() => {
    if (
      accessToken
      && fetchStatus === "idle"
      && learningObject 
      && scormJs
      && sessionToken
      && !startOrResumeIsLoading
      && userData
    ) {
      if (!flagScript.current) {
        const libraryScript = (callback: () => void) => {
          const libraryScript = document.createElement("script");
          libraryScript.src = `${scormJs}?hash=${sessionToken}`;
          libraryScript.type = "text/javascript";
          libraryScript.setAttribute("data-react-helmet", "true");
          libraryScript.onload = () => callback();
          document.body.appendChild(libraryScript);
        };
        const libraryInit = () => {
          const libraryInit = document.createElement("script");
          libraryInit.type = "text/javascript";
          libraryInit.text = getLibraryInitText({
            accessToken,
            courseId,
            detailsPath,
            learningObject,
            rootId,
            sessionToken,
            userData,
            calculatedCourseId,
            calculatedRootId,
            language
          });
          document.body.appendChild(libraryInit);
        };
        libraryScript(libraryInit);
      }
      flagScript.current;
    }

  }, [
    accessToken, courseId, detailsPath, fetchStatus, learningObject,
    rootId, sessionToken, startOrResumeIsLoading, userData, calculatedCourseId, calculatedRootId
  ]);
  const isToj = activityLevel === LEVEL.DA_FINAL_BALANCE || 
  activityLevel === LEVEL.DA_GOALS || 
  activityLevel === LEVEL.DA_MATERIAL || 
  activityLevel === LEVEL.DA_MEETING;

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{ t("app_name") }</title>
        <style type="text/css">
          { `body { background: ${ !isToj ? 
            theme.palette.background?.paper : 
            theme.linearGradients.gradientB}  ` }
        </style>
      </Helmet>
      <Grid
        display={ "flex" }
        alignItems={ "center" }
        justifyContent={ "center" }
        width={ "100%" }
        height={ "calc(100% - 72px)" }
        position={ "absolute" }
        bottom={ 0 }
        left={ 0 }
      >
        <Spinner 
          alignSelf="center" 
          padding={ 4 } 
          size={ 80 } 
          thickness={ 3 } 
        />
      </Grid>
    </Fragment>
  );
}

function getLibraryInitText({
  accessToken,
  courseId,
  detailsPath,
  learningObject,
  rootId,
  sessionToken,
  userData,
  calculatedCourseId,
  calculatedRootId,
  language
} : {
  accessToken: string
  courseId: string | null
  detailsPath: string
  learningObject: LearningActivity
  rootId: string | null
  sessionToken: string
  userData: UserData,
  calculatedCourseId?: string,
  calculatedRootId?: string,
  language: string | null
}) {
  return `
var libInput = {
langCode: "${language}",
activityId: ${learningObject?.id},    
corporateId: ${userData?.organization_id},
courseId: ${courseId || calculatedCourseId},
domain: "${window.location.origin}/${detailsPath}",
iframeSrc: "${learningObject?.link}",
initiativeId: ${userData?.initiative_id},
learnerId: ${userData?.id_user},
learnerName: "${addEscapeString(userData?.name)} ${addEscapeString(userData?.surname)}",
LO_type: "${learningObject?.learningObjectTypology}",
objectDescription: "${addEscapeString(learningObject.shortDescription)}",
objectName: "${addEscapeString(learningObject.title)}",
pathId: ${rootId || calculatedRootId},
sessionId: "${sessionToken}",
tokenJwt: "${accessToken}",
tojEditionId:${learningObject?.tojEditionIds?.[0]},
userEmail: "${userData?.email}",
userName: "${userData?.external_id}",
surveyId:${learningObject.surveyId},
tentativeId: ${learningObject?.tentativeId},
trackOnClick:${learningObject?.trackOnClick},
VITE_SCORM_TRACKER: "${scormTracker}",
VITE_XAPI_TRACKER: "${xapiTracker}"
};

function startCourse() {
  var trackerDataInput = new TrackerDataInput(libInput);
  trackerDataInput.start();
}

startCourse();
`;
}
