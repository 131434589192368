import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { BoxCornersProps } from "./BoxCorners.types";

const thinnestBorder = `${1 / 16}rem`;

export const BoxCornersStyled = styled(Grid)<
  BoxCornersProps
>(({ $filled, $cornersPosition, $isSignup, theme }) => ({
  pointerEvents: "none",
  position: "absolute",
  transform: getCornerRotation({ $cornersPosition }),

  bottom:  $cornersPosition === "bottomRight" && $isSignup ? "-18px" : 
    ($cornersPosition === "bottomLeft" || $cornersPosition === "bottomRight") ? 0 : undefined,
  left: $cornersPosition === "topLeft" && $isSignup ? "-20px" : 
    ($cornersPosition === "topLeft" || $cornersPosition === "bottomLeft") ? "42px" : undefined,
  right: $cornersPosition === "bottomRight" && $isSignup ? "-20px" : 
    ($cornersPosition === "topRight" || $cornersPosition === "bottomRight") ? "42px" : undefined,
  top:  $cornersPosition === "topLeft" && $isSignup ? "-18px" : 
    ($cornersPosition === "topLeft" || $cornersPosition === "topRight") ? 0 : undefined,

  height: "3.625rem",
  width: "4.5rem",


  ".box-el":{
    borderLeft: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : undefined,
    borderTop: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : undefined,
    position: "relative",

    height: "100%",
    width: "100%",

    "&:after, &:before": {
      background: $filled ? theme.palette.primary?.main : "transparent",
      border: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : undefined,
      content: "''",
      position: "absolute"
    },

    "&:after": {
      borderBottom: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : 0,
      borderLeft: 0,
      borderRight: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : 0,
      borderTop: 0,
      right: 0,
      top: 0,

      height: "1.5rem",
      width: $filled ? "100%" : "calc(100% - 1.875rem)"
    },

    "&:before": {
      borderBottom: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : 0,
      borderLeft: 0,
      borderRight: !$filled ? `${thinnestBorder} solid ${theme.palette.primary?.main}` : 0,
      borderTop: 0,
      bottom: 0,

      height: $filled ? "100%" : "calc(100% - 1.5rem)",
      width: "1.875rem"
    }
  },

  [theme.breakpoints.down("sm")]: {
    left: $cornersPosition === "topLeft" && $isSignup ? "-20px" : 
      ($cornersPosition === "topLeft" || $cornersPosition === "bottomLeft") ? "20px" : undefined,
    right: $cornersPosition === "bottomRight" && $isSignup ? "-20px" : 
      ($cornersPosition === "topRight" || $cornersPosition === "bottomRight") ? "20px" : undefined,
    transform: ($cornersPosition === "topLeft" ? "" : `${getCornerRotation({ $cornersPosition })} scale(0.5)`)
  }
}));

function getCornerRotation({ $cornersPosition }: Pick<BoxCornersProps, "$cornersPosition">) {
  switch($cornersPosition) {
  case "topRight":
    return "rotateY(180deg)";
  case "bottomRight":
    return "rotate(180deg)";
  case "bottomLeft":
    return "rotateX(180deg)";
  default:
    return undefined;
  }
}
