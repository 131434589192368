import { VideoCaptionStyles , TitleTypography } from "./CommunityVideo.styles";
  
export function CommunityVideoCaptionOverlay({
  title
}: {
    title: string
}) {
  
  return (
    <VideoCaptionStyles className={ "video-caption" }>
      <TitleTypography variant="h3">
        { title }
      </TitleTypography>
    </VideoCaptionStyles>
  );
}
  
