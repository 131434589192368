import { HeroCard  as HeroCardComponent } from "./HeroCardSearch.component";
import type { HeroCardSearchProps } from "./HeroCardSearch.types";
import * as HeroCardSearchVariants from "./HeroCardSearch.variants";

export const DefaultHeroSearch = ({ ...props }: Partial<HeroCardSearchProps>) => (
  <HeroCardComponent { ...{ ...HeroCardSearchVariants.DefaultSearch, ...props } }/>
);


export const HeroCardSearch = HeroCardComponent;
