
import React from "react";

interface UserNavigationContextInterface {
  navigation?: string[];
  setNavigation?: (item: string[]) => void;
}

const defaultValue: UserNavigationContextInterface = {
  navigation: undefined,
  setNavigation: undefined
};
export const UserNavigationContext = React.createContext(defaultValue);

export const useUserNavigation = () => {
  return React.useContext(UserNavigationContext);
};

export const useUserNavigationProvider = (): UserNavigationContextInterface => {
  const [navigation, setNavigation] = React.useState<string[]>([]);

  return { navigation, setNavigation };
};
