import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { cardWidth } from "../../components/CarouselCard/CarouselCard.styles";

export const CardsBox = styled(Box)<{$isLoading:boolean}>(({ theme, $isLoading }) => ({
  alignItems:"center",
  display: $isLoading ? "flex":"grid",
  gridGap: "1rem",
  gridRowGap: "2.5rem",
  gridTemplateColumns: `repeat(auto-fill, ${cardWidth}px)`,
  justifyContent: "space-around",
  paddingBottom:"80px",
  width:"100%",

  [theme.breakpoints.down("sm")]:{
    gridTemplateColumns: "repeat(1, 1fr)"
  }
}));

