import { create } from "zustand";

type CalendarSelectedDate = {
  selectedDate: Date | null,
  slideTo: number
}

type CalendarSelectedDateActions = {
  readonly setCalendarSelectedDate: (props: CalendarSelectedDate) => void
}

const initialCalendarSelectedDate: CalendarSelectedDate = {
  selectedDate: new Date(),
  slideTo: 0
};

export const useCalendarSelectedDateStore = create<CalendarSelectedDate & CalendarSelectedDateActions>((set) => ({
  ...initialCalendarSelectedDate,
  setCalendarSelectedDate: ({ selectedDate, slideTo }: CalendarSelectedDate) => set({ selectedDate, slideTo })
}));
