import {

  Box,
  BoxProps,
  Grid,
  GridProps,
  SwipeableDrawer
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const AnimatedButton = styled(Box)<
  BoxProps 
>(() => ({
  
  "&:hover":{
    transform:"scale(1.1)",

    "&:active":{
      transform:"scale(0.9)"
    }
  },
  transition:"all .2s linear"
}));
export const StyledKnowledgeWrapper = styled(Grid)<
  GridProps 
>(({ theme }) => ({
  background:alpha(theme.customColors.backgroundPrimaryLighter,0.1),
  borderRadius:theme.spacing(1),
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  maxHeight:"100%",
  maxWidth:"1050px",
  padding:theme.spacing(4,3),
  position:"relative",
  width:"100%",

  [`${theme.breakpoints.down("md")}`]:{
    flexWrap:"wrap",
    gap:theme.spacing(2),
    padding:theme.spacing(2,3)
  }
}));

export const StyledBucketGridItem = styled(Grid)<
  GridProps & { $active?: boolean }
>(({
  $active=false,
  theme
}) => ({
  background: alpha(theme.customColors.backgroundSecondary, 0.1),
  border:`1px solid ${theme.customColors.borderTag}`,
  borderRadius: theme.spacing(1),
  height:"100%",
  maxWidth:"300px",
  opacity: $active ? 0.8 : 1,
  padding: theme.spacing(4, 3, 2),
  width:"calc(33% - ((16px * 2) / 3))",

  [`${theme.breakpoints.down("md")}`]:{
    cursor:"pointer",
    height:"120px",
    padding: theme.spacing(1,0.2),
    width:"120px"
  }
}));

export const StyledCapabilityCard = styled(Grid)<
  GridProps & { $active?: boolean }
>(({
  theme
}) => ({
  borderRadius:theme.spacing(1),
  height:"270px",
  inset:0,
  margin:"auto",
  position:"absolute",
  width:"fit-content",

  [`${theme.breakpoints.down("md")}`]:{
    height:"213px"
  }
}));


export const StyledCapabilityCardsHide = styled(Box)(({ theme })=> ({
  height:"100%",
  order:1,
  position:"relative",
  width:"calc(33% - ((16px * 2) / 3))",

  [`${theme.breakpoints.down("md")}`]:{
    height:"350px",
    order:0,
    width:"100%"
  }

}));
  
export const PersonalSkillsItemsBox = styled(Box)(({ theme })=> ({
  display:"flex",
  flexDirection:"row",
  flexWrap:"wrap",
  gap:theme.spacing(2)
}));

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: "transparent",
    borderLeft:`2px solid ${theme.customColors.accentVariantB}`,
    borderRight:`2px solid ${theme.customColors.accentVariantB}`,
    borderTop:`2px solid ${theme.customColors.accentVariantB}`,
    borderTopLeftRadius:theme.spacing(1),
    borderTopRight:`2px solid ${theme.customColors.accentVariantB}`,
    borderTopRightRadius:theme.spacing(1),
    bottom:0,
    boxShadow:`0px 0px 4px 0px ${theme.customColors.accentVariantB}`,
    boxSizing: "border-box",
    height: "70%"
  },

  [`${StyledBucketGridItem}`]:{
    backdropFilter:"blur(40px)",
    backgroundColor:alpha(theme.customColors.backgroundDisabledAlternative, 0.1),
    border:0,
    borderRadius:0,
    cursor:"auto",
    display:"flex",
    flexDirection:"column",
    height:"100%",
    justifyContent:"center",
    maxWidth:"unset",
    padding:theme.spacing(3,2.5,5),
    width:"100%"
  }
}));

