import {
  useInfiniteQuery,
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type { MetaData } from "../types";
import {
  getEnvironmentVariables,
  replacePathParams
} from "../utils/general";

// cr. https://stackoverflow.com/questions/61431397/how-to-define-typescript-type-as-a-dictionary-of-strings-but-with-one-numeric-i
// type VerifyGenericApiContentResponse<T> =
//   & { [K in keyof T]: K extends "metadata" ? unknown : Record<string, unknown>[] }
//   & { metadata?: MetaData }
// const asGenericApiContentResponse = <T extends VerifyGenericApiContentResponse<T>>(t: T) => t;

type ContentResponse =
  & { [items: string]: Record<string, unknown>[] }
  & { metadata?: MetaData }

const { basePath } = getEnvironmentVariables();

export function getContentsQuery(
  refreshSession: () => Promise<void>,
  {
    enabled,
    idToken,
    queryKey,
    sessionToken,
    uri
  }:{
    enabled: boolean,
    idToken: string,
    queryKey: string[],
    sessionToken: string,
    uri: string
  })
{
  const fetcher = fetcherFactory();
  return {
    enabled,
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: ContentResponse) => {
        return Object.entries(data ?? {}).reduce((acc, [label, value]) => {
          if (label !== "metadata") {
            acc[label] = apiAdapter(value as Record<string, unknown>[]);
          } else {
            acc[label] = value ? (value as MetaData) : { topic_ids:[], totalElements: 0, totalPages: 0 };
          }
          return acc;
        }, {} as ContentResponse);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          refreshSession();
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: queryKey
  };
}
  
export function useContentQuery<T>({
  enabled=true,
  pageNumber=0,
  pageSize=9,
  path,
  queryKey=[],
  ...otherParams
} : {
    enabled?: boolean
    pageNumber?: number
    pageSize?: number
    path: string | undefined
    queryKey?: string[]
  } & Record<string, unknown>) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  const pathParams = {
    corporateId: userData?.organization_id?.toString() ?? "",
    initiativeId: userData?.initiative_id?.toString() ?? "",
    pageNumber: pageNumber?.toString(),
    pageSize: pageSize?.toString(),
    ...otherParams
  };
  const apiPath = replacePathParams(path ?? "", pathParams);
  
  const fullQueryKey = [
    // apiPath.split("?")[0], apiPath.split("?")[1], `${pageSize}`, `${pageNumber}`, ...(queryKey ?? [])
    apiPath, isSeedingSession, ...queryKey
  ];
  
  return useQuery<
      unknown,
      unknown,
      T,
      QueryKey
    >(
      {
        enabled:Boolean(
          enabled 
          && path
          && idToken
          && sessionToken
          && userData?.initiative_id
          && userData?.organization_id
        ) && !isSeedingSession,
        queryFn: () => fetcher(
          `${basePath}${apiPath}`,
          {
            headers:{
              "authorization": idToken,
              "x-ada-session-token": sessionToken
            }
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data: ContentResponse) => {
            return Object.entries(data ?? {}).reduce((acc, [label, value]) => {
              if (label !== "metadata") {
                acc[label] = apiAdapter(value as Record<string, unknown>[]);
              } else {
                acc[label] = value ? (value as MetaData) : { topic_ids:[], totalElements: 0, totalPages: 0 };
              }
              return acc;
            }, {} as ContentResponse);
          })
          .catch((error) => {
            if (error === ERRORS.UNAUTHORIZED) {
              setUnauthorized(true);
              throw error; // rethrow so that react query doesn't complain about undefined return value
            } else {
              throw error;
            }
          }),
        queryKey: fullQueryKey
      }
    );
}

export function useInfiniteContentQuery<T extends { metadata: MetaData }>({
  enabled=true,
  pageSize=9,
  path,
  queryKey=[],
  ...otherParams
} : {
  enabled?: boolean
  pageSize?: number
  path: string
  queryKey?: Array<number | string>
} & Record<string, unknown>) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);


  const apiPath = getBaseApiPath();

  return useInfiniteQuery<
    unknown,
    unknown,
    T,
    QueryKey
  >({
    enabled:Boolean(enabled && path) && !isSeedingSession,
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as T)?.metadata as MetaData)?.totalPages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      `${basePath}${getApiPath({ pageNumber: pageParam?.nextPage ?? 0 })}`,
      {
        headers: {
          "authorization": idToken, // add to query key
          "x-ada-session-token": sessionToken // add to query key
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ContentResponse) => {
        return Object.entries(data ?? {}).reduce((acc, [label, value]) => {
          if (label !== "metadata") {
            acc[label] = apiAdapter(value as Record<string, unknown>[]);
          } else {
            acc[label] = value ? (value as MetaData) : { topic_ids:[],totalElements: 0, totalPages: 0 };
          }
          return acc;
        }, {} as ContentResponse);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [apiPath, isSeedingSession, ...queryKey]
  });

  function getBaseApiPath() {
    const pathParams = {
      corporateId: userData?.organization_id.toString() ?? "",
      initiativeId: userData?.initiative_id?.toString() ?? "",
      pageSize: pageSize?.toString(),
      ...otherParams
    };
    return replacePathParams(path ?? "", pathParams);
  }

  function getApiPath({ pageNumber=0 }) {
    if (!userData) return "";
    const pathParams = {
      corporateId: userData?.organization_id.toString() ?? "",
      initiativeId: userData?.initiative_id?.toString() ?? "",
      pageNumber: pageNumber?.toString() ?? 0,
      pageSize: pageSize?.toString(),
      ...otherParams
    };
    return replacePathParams(path ?? "", pathParams);
  }
}
