import {
  Avatar,
  Box,
  Grid,
  Typography,
  styled } from "@mui/material";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom:theme.spacing(2)
}));

export const StyledChip = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "12px",

  "span": {
    fontSize: "10px"
  },

  "div": {
    whiteSpace: "normal"
  }
}));

export const StyledText = styled(Grid)<{orientation:"right"| "left"}>(({ orientation, theme } ) => ({
  background:theme.customColors.backgroundSecondary,
  borderRadius: orientation === "left" ? "12px 12px 12px 0px" : "12px 12px 0px 12px" ,
  color:theme.customColors.textInput,
  padding:theme.spacing(1),

  fontSize:theme.spacing(1.5),
  lineHeight:"normal",
  marginBottom:"8px"

}));

export const StyledDate = styled(Typography)(({ theme }) => ({
  color:theme.customColors.textPrimary,
  display: "none",
  fontWeight: 300
}));

export const StyledAvatar = styled(Avatar)(() => ({
  borderRadius:"60px",
  height:"24px",
  width:"24px"
}));

