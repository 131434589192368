import {
  Box,
  Checkbox, Divider,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ChangeEvent,
  Fragment,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { FormErrors } from "types";
import IntroVideo from "../../assets/OL_login_spalsh.mp4";
import {
  Link,
  ButtonText,
  TextInput,
  PasswordInput,
  Spinner
  // PopUpLogin
} from "../../components";
import { AUTH_MODE, SELF_SIGNUP_TYPE } from "../../consts";
import { UserMgmtResponse } from "../../services/fetchUserMgmt";
import { useAuthStore } from "../../stores";
import { getCookieByName } from "../../utils/general";


export function Login() {
  const theme = useTheme();
  const signIn = useAuthStore(state => state.signIn);
  const isLoading = useAuthStore(state => state.isLoading);
  const ssoEndpoint = useAuthStore(state => state.ssoEndpoint);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { state: routerState } = useLocation();
  const authCognitoIsAllowed = useAuthStore(state =>
    state.allowedAuthentications.includes(AUTH_MODE.Cognito)
  );
  const authSSOIsAllowed = useAuthStore(state =>
    state.allowedAuthentications.includes(AUTH_MODE.SSO)
  );
  const storedUser = localStorage.getItem("storedUser");
  const [username, setUsername] = useState(storedUser ?? "");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const [remember, setRemember] = useState(Boolean(storedUser));
  const isAuthenticated = useAuthStore(state => Boolean(state.session));
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  const loadingSpinnerCookie = getCookieByName("loadingSpinner");
  const setSplash = useAuthStore(state => state.setSplash);

  const navigate = useNavigate();

  //SMART TV
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");
  if(uuid){
    localStorage.setItem(
      "uuid_qrcode",
      uuid
    );
  }else{
    localStorage.removeItem("uuid_qrcode");
  }

  
  const userMgmtData = useLoaderData() as UserMgmtResponse;
  if (!isLoading && !isAuthenticated && !loadingSpinnerCookie) {
    setSpinnerLoginVisible(false);
  }

  function handleRegister() {
    navigate("/register");
  }

  function handleValidate() {
    /* TODO: use something like yup or zod for validation */

    const passwordIsValid = Boolean(password.length > 0);
    const usernameIsValid = Boolean(username.length > 0);

    setErrors({ password: !passwordIsValid, username: !usernameIsValid });
    return { passwordIsValid, usernameIsValid };
  }

  function handleSubmit() {
    const {
      passwordIsValid,
      usernameIsValid
    } = handleValidate();

    if (!passwordIsValid || !usernameIsValid) return;

    const payload = {
      password: password,
      username: username
    };
    signIn(userMgmtData.id, payload.username, payload.password, remember);
  }

  const [showSplash, setShowSplash] = useState(!!userMgmtData.splash);

  useEffect(() => {
    if (isLoading) return;
    if (!userMgmtData?.splash) return;
    setShowSplash(true);
    setTimeout(() => {
      setShowSplash(false);
      setSplash(true);
    }, 4500);
  }, []);


  if (!!userMgmtData?.splash && !!showSplash && !isLoading) {
    return <Box sx={ {
      background: "#000",
      inset: "0 0 0 0",
      position: "fixed",
      zIndex: 9999
    } }>
      <video style={ {
        height: "100%",
        width: "100%"
      } } src={ IntroVideo } autoPlay={ true } muted={ true } />
    </Box>;
  }

  return (
    <Stack
      alignItems="center"
      gap={ isMobile ? 3 : 4 }
      width="430px"
      sx={ {
        [theme.breakpoints.down("sm")]: {
          width: "100%"
        }
      } }
    >
      { isLoading || (!loadingSpinnerCookie && isAuthenticated) ? (
        <Spinner />
      ) : (
        <Fragment>
          { /* <PopUpLogin/> */ }
          <Stack
            justifyContent={ isMobile ? "flex-start" : "center" }
            gap={ 1 }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                width: "100%"
              }
            } }
          >
            <Typography
              color="primary"
              fontSize={ isMobile ? "1.25rem" : "2rem" }
              fontWeight={ 700 }
              lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
              paddingTop={ isMobile ? "16px" : "0" }
              textAlign={ isMobile ? "left" : "center" }
            >
              { t("access_digited") }
            </Typography>
            {
              authCognitoIsAllowed ? (
                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                  textAlign={ isMobile ? "left" : "center" }
                >
                  { t("enter_credentials") }
                </Typography>
              ) : null
            }
          </Stack>
          {
            authCognitoIsAllowed ? (
              <>
                <Stack
                  alignItems="center"
                  gap={ isMobile ? 3 : 4 }
                  width="100%"
                  onKeyDown={ (e) => {

                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }

                  } }
                >
                  <TextInput
                    error={ errors?.username }
                    helperText={ t("no_valid_username") }
                    id="login-id"
                    label={ t("username") }
                    onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
                      setUsername(changeEvent.target.value);
                    } }
                    size={ isMobile ? "small" : "medium" }
                    value={ username }
                    sx={ { width: "100%" } }
                  />
                  <PasswordInput
                    error={ errors?.password }
                    helperText={ t("no_valid_password") }
                    id="login-password"
                    label={ t("password") }
                    onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
                      setPassword(changeEvent.target.value);
                    } }
                    size={ isMobile ? "small" : "medium" }
                    value={ password }
                    sx={ {
                      width: "100%",

                      "& .MuiInputLabel-root": {
                        fontSize: "14px"
                      }
                    } }
                  />
                </Stack>
                <Stack
                  alignItems="center"
                  gap={ 1 }
                  direction="row"
                  justifyContent="space-between"
                  width={ "100%" }
                  marginBottom={ 1 }
                >
                  <FormControlLabel
                    sx={ {
                      marginLeft: 0
                    } }
                    control={
                      <Checkbox
                        inputProps={ { "aria-label": "remember credentials" } }
                        // inputRef={ rememberRef }
                        //size={ isMobile ? "small" : "medium" }
                        onChange={ (changeEvent) => {
                          setRemember(changeEvent.target.checked);
                        } }
                        checked={ remember }
                        sx={ {
                          color: theme.customColors.borderTag,

                          padding: "0 8px 0 0",

                          ".MuiSvgIcon-root": {
                            transform: "scale(1.2)"
                          },

                          [theme.breakpoints.down("sm")]: {
                            "svg": {
                              height: "16px",
                              width: "auto"
                            }
                          }
                        } }
                      />
                    }
                    label={
                      <Typography
                        fontSize={ isMobile ? "0.875rem" : "1rem" }
                        lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                        textTransform="capitalize"
                      >
                        { t("remember_me") }
                      </Typography>
                    }
                  />
                  <Link
                    href="/access/password/recovery"
                    state={ {
                      ...routerState,
                      recover: true
                    } }
                  >
                    <Typography
                      fontSize={ isMobile ? "0.75rem" : "1.125rem" }
                      lineHeight={ isMobile ? "0.875rem" : (21 / 20) }
                      textTransform="capitalize"
                      sx={ {
                        textDecoration: "underline"
                      } }
                    >
                      { t("recover_pw") }
                    </Typography>
                  </Link>
                </Stack>
              </>
            ) : null
          }
          <Stack
            alignItems={ "center" }
            direction={ "column" }
            divider={
              <Divider sx={ {
                "&::after": {
                  width: isMobile ? "50%" : "153px"
                },
                "&::before": {
                  width: isMobile ? "50%" : "153px"
                },

                "span": {
                  alignItems: "center",
                  display: "flex",
                  fontSize: theme.spacing(1.5),
                  height: "14px",
                  justifyContent: "center",
                  padding: theme.spacing(0, 3),
                  width: "24.187%"
                },

                borderColor: theme.customColors.border,
                justifyContent: "center",
                width: isMobile ? "100%" : "430px"
              } }
              >
                { t("or") }
              </Divider>
            }
            gap={ 3 }
            justifyContent="center"
            paddingBottom={ isMobile && !authSSOIsAllowed ? "16px" : "0" }
            width="100%"
          >
            {
              authCognitoIsAllowed ? (
                <ButtonText
                  onClick={ (e) => {
                    e.preventDefault();
                    handleSubmit();
                  } }
                  type="submit"
                  sx={ {
                    gap: "8px",
                    minWidth: !authSSOIsAllowed && isMobile ? "128px" : "156px",
                    [theme.breakpoints.down("sm")]: {
                      lineHeight: "1.3125rem"
                    }
                  } }
                >
                  { t("login") }
                </ButtonText>
              ) : null
            }
            {
              authSSOIsAllowed ? (
                <Link
                  // href={ ssoEndpoint.href }
                  href={ ssoEndpoint?.href.replace("%2B", "+") }
                >
                  <ButtonText
                    sx={ {
                      gap: "8px",
                      minWidth: isMobile ? "100%" : "156px",
                      textTransform: "inherit",
                      [theme.breakpoints.down("sm")]: {
                        lineHeight: "1.3125rem"
                      }
                    } }
                  >
                    { t("sso") }
                  </ButtonText>
                </Link>
              ) : null
            }

            { (userMgmtData.self_signup === SELF_SIGNUP_TYPE.LEARNER ||
                userMgmtData.self_signup === SELF_SIGNUP_TYPE.MANAGER || userMgmtData.self_signup_enabled) &&
                  <ButtonText
                    onClick={ ()=> {
                      handleRegister();
                    } }
                    sx={ {
                      gap: "8px",
                      minWidth: "156px",
                      textTransform:"inherit",
                      [theme.breakpoints.down("sm")]: {
                        lineHeight: "1.3125rem"
                      }
                    } }
                  >
                    { t("register") }
                  </ButtonText>
            }

          </Stack>
        </Fragment>
      ) }
    </Stack>

  );
}
