import TabPanel from "@mui/lab/TabPanel";
import {
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";


export const StyledTab = styled(Box)(({ theme }) => ({
  width:"100%",

  "&.hidden": {
    display:"none"
  },

  "&.is-visible": {
    display:"block"
  },

  "button ":{
    fontSize:"1rem"
  },
  [theme.breakpoints.down("sm")]: {
    "button":{
      fontSize: "0.875rem"
    }
  }
}));

export const StyledTabBox = styled(Box)(({ theme }) => ({
  borderBottom: `1.34px solid ${theme.customColors.border} !important`,
  borderColor: theme.customColors.borderTag,
  color:theme.customColors.textPrimary,

  paddingTop:"12px",
  position:"relative",
  zIndex: theme.zIndex.appBar,

  "button": {
    color:theme.customColors.textPrimary,
    fontWeight:"400",
    margin:"0 12px",
    minHeight:"unset",
    minWidth:"unset",
    opacity:1,
    padding:"8px 0",

    "&.Mui-selected": {
      color:theme.customColors.textPrimary,
      fontWeight:"500"
    },
    "&:first-child": {
      marginLeft:"0"
    }

  },

  ".MuiTabs-indicator":{
    background:theme.customColors.systemPrimary01,
    borderRadius:"4px",
    boxShadow: `0px 0px 4px ${theme.customColors.backgroundSecondary}`,
    height:"2.5px",
    maxWidth:"calc(100% - 32px)"
  },

  ".MuiTabs-root": {
    alignItems:"flex-end"
  }

}));

export const StyledTabContent = styled(TabPanel)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  maxWidth:"100%",
  padding: "40px 42px 0",

  [theme.breakpoints.down("sm")]:{
    padding: "24px 20px 0"
  }
}));

