
import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {  
  useEffect,
  useState 
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { 
  AreasBox, 
  AreaBoxContainer, 
  AreaItem, 
  AreaZoomItem, 
  StyledSwipeableDrawer 
} from "./Profile.Job.styles";
import {
  GuardedLink,
  Modal,
  SelectInput,
  Spinner
} from "../../components";
import { Button } from "../../components/Button/Button.component";
import { StyledBottomBar } from "../../components/OnBoarding/OnBoarding.styles";
import { Progress } from "../../components/Progress/Progress.component";

import { useUserJobsAreasQuery,  
  type OnBoardingItemType, 
  useUserJobsQuery, 
  useUpdateUserInfo } from "../../queries/onboarding";
import { useAuthStore } from "../../stores";
import { getError } from "../../utils/error";

export function ProfileJob() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const{  state:routerState } = useLocation();
  const userInfo = useAuthStore(state => state.userInfo);
  const userProfession = routerState?.profession?.name ?? 
  userInfo?.profession?.label ?? "label profession not found";
  const userJob = routerState?.profession?.name ? null : userInfo?.job?.id;
  const [jobSearched, setJobSearched] = useState<string>(userInfo?.job?.label ?? "");
  const [jobSelected, setJobSelected] = useState<Pick<OnBoardingItemType, "id" | "name"> | null>(null);
 
  const [areaOpened, setAreaOpened]=useState<{id:number, name:string, description:string} | null>(null);
  const [isRedirect, setIsRedirect]=useState(false);
  const [erroModalOpen,setErrorModalOpen]=useState(false);

  const { t }= useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const refetchUserInfo = useAuthStore(state => state.refetchUserInfo);
  const navigate = useNavigate();

  const pageSize = 6;

  const professionState = { 
    id:routerState?.profession?.id,
    name:routerState?.profession?.name 
  } as Pick<OnBoardingItemType, "id" | "name"> ;

  const { 
    error:updateUserInfoError,
    mutate:updateUserInfo, 
    isSuccess:updateUserInfoIsSuccess, 
    isError:updateUserInfoIsError 
  } = useUpdateUserInfo({
    job:jobSelected?.id ?? userInfo?.job?.id ?? null,
    profession:professionState?.id ?? userInfo?.profession?.id
  });

  const {
    data:jobs,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useUserJobsQuery({ 
    pageSize:pageSize,
    professionId:professionState?.id ?? userInfo?.profession?.id ?? undefined,
    professionName: jobSearched
  });

  const jobsApi = jobs?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as OnBoardingItemType[]);



  const {
    data:jobAreas,
    error:jobAreasError,
    fetchNextPage:getNextAreas,
    hasNextPage:hasOthersAreas,
    isError:jobAreasIsError,
    isFetchingNextPage:isFetchingNextAreas
  } = useUserJobsAreasQuery({ 
    jobId:jobSelected?.id ?? userJob ?? null,
    pageSize:6
  });

  const ApiAreas = jobAreas?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as OnBoardingItemType[]);



  useEffect(()=> {
    if(updateUserInfoIsSuccess){
      refetchUserInfo();
      setIsRedirect(true);
      setTimeout(()=> {
        setIsRedirect(false);
        navigate("/signup", { 
          state:{ 
            ...routerState,
            onBoardingPercentage:"40%"
          } 
        });
      },2000);
   
    }
    if((jobAreasError && jobAreasIsError) || (updateUserInfoError || updateUserInfoIsError) ){
      setErrorModalOpen(true);
    }
  },[
    routerState, 
    navigate, 
    refetchUserInfo, 
    updateUserInfo, 
    updateUserInfoIsSuccess, 
    jobAreasError, 
    jobAreasIsError, 
    updateUserInfoError, 
    updateUserInfoIsError
  ]);


  return (
    <Stack
      gap={ isMobile ? 2 : 6 }
      height="100%"
      minHeight={ "800px" }
      width={ "100%" }
    >
      <Stack
        gap={ isMobile ? 1 :  2 }
        width={ "100%" }
      >
        <Typography
          color="primary"
          fontSize={ isMobile ? "1.25rem" :  "2rem" }
          fontWeight={ 700 }
          lineHeight={ 19 / 16 }
          textAlign={ "start" }
        >
          { t("your_role") }
        </Typography>
        <Typography
          fontSize={ isMobile ? "0.875rem" : "1.5rem" }
          lineHeight={ "1.05" }
          textAlign={ "start" }
        >
          { t("professional_suppl_chain").replace("{Job}", userProfession) }
        </Typography>
      </Stack>

      <SelectInput
        type="dropdown"
        fullWidth={ isMobile }
        callBack={ () => { hasNextPage && !isFetchingNextPage && fetchNextPage() } }
        setValue={ setJobSearched }
        value={ jobSelected?.name ?? jobSearched }
        setValueSelected={ setJobSelected }
        resultsApi={ jobsApi }
        onChange={ (target)=> {
          setJobSearched(target.currentTarget.value);
          setJobSelected(null);
        } }
        onBlur={ ()=> {
          setJobSearched(userInfo?.job?.label ?? "");
        } }
        onMouseDown={ ()=> {
          setJobSearched("");
          setJobSelected(null);

        } }
        placeholder={ t("job_search") }
      />
      
      {
        jobAreas && ApiAreas?.length ? 
          
          <AreasBoxComponent/>
  
          : 
          null
      }
  
      
      <StyledBottomBar>
        <Progress percentageOfCompletion={ jobSelected || userJob ? "40%" : routerState?.onBoardingPercentage }/>
      
        <GuardedLink
          disabled={ !jobSelected && !jobSearched } // TODO: check allowed values from set
          href={ "" }
          onClick={ () => { 
            if(professionState && (jobSelected || userInfo?.job?.label) && userInfo && userInfo.industry.id){
              updateUserInfo();
              return false;
            }
            return false;
           
          } }
          state= { {
            ...routerState,
            job:{ ...jobSelected },
            onBoardingPercentage:"40%"
          } }
        >
          {
            isRedirect ? 
              <Spinner size={ 20 } padding={ 0 }/> : 

              t("continue") 
          }
          
        </GuardedLink>

      </StyledBottomBar>

      <StyledSwipeableDrawer
        id={ `area-drawer-${areaOpened?.id}` }
        anchor="bottom"
        onOpen={ () => {} }
        onClose={ () => setAreaOpened(null) }
        open={ Boolean(areaOpened) }
        variant={ "temporary" }
      >
        <AreaBoxContainer>
          <Typography 
            fontSize={ theme.spacing(3) }
            fontWeight={ 700 }
          >
            { areaOpened?.name }
          </Typography>
          <Typography >{ areaOpened?.description }
          </Typography>
        </AreaBoxContainer>
      </StyledSwipeableDrawer>

      <Modal 
        open={ erroModalOpen }
        description={ getError(jobAreasError)?.[0]?.message ?? "" }
        title={ getError(jobAreasError)?.[0]?.name ?? "" }
        onClose={ ()=> {setErrorModalOpen(false)} }
      />
    </Stack>
  );

  function AreasBoxComponent (){

    return (
      <AreasBox>
        <Typography
          width={ "100%" }
          color={ "primary" }
          fontSize={ isMobile ? 
            theme.spacing(1.5) : 
            theme.spacing(2.5) }

        >{ t("discover_areas") }
        </Typography>
        { 
          ApiAreas?.map((job, index)=> (
            <AreaBoxContainer
              key={ `job-area-${index}-${job.id}` }
              onClick={ ()=> {
                if(isMobile){
                  setAreaOpened({
                    description:job.description,
                    id:job.id,
                    name:job.name
                  });
                }
           
              } }
            >
              < AreaItem >

                <Typography
                  fontWeight={ 700 }
                  fontSize={ isMobile ? theme.spacing(1.5) : theme.spacing(2) }
                >
                  { job.name }
                </Typography>
              </ AreaItem>

              {
                !isMobile ?  
                  < AreaZoomItem>
                    <Typography 
                      fontWeight={ 700 }
                      fontSize={  theme.spacing(2) }
                    >
                      { job.name }
                    </Typography>
                    <Typography 
                      fontSize={ theme.spacing(1.5) }
                    >{ job.description }
                    </Typography>
                  </AreaZoomItem> : 
                  null
              }
           
            </AreaBoxContainer>

          )) }
        {
          isFetchingNextAreas ? 
            <Spinner alignSelf="center" padding={ 2 } size={ 20 } thickness={ 2 } /> : 
            null
        }
        {
          hasOthersAreas && !isFetchingNextAreas? 
            <Button onClick={ ()=> {
              getNextAreas();
            } }>
              { t("load_more") }
            </Button> : null
        }

      </AreasBox>
    );
  }
}
