import { CardRanking as CardRankingComponent } from "./CardRanking.component";
import { CardRankingProps } from "./CardRanking.types";
import {
  CardRankingDefault as CardRankingProp
} from "./CardRanking.variants";

export const CardRankingDefault = ({ ...props }: Partial<CardRankingProps>) => (
  <CardRankingComponent { ...{ ...CardRankingProp, ...props } }/>
);

export const CardRanking = CardRankingComponent;

