import { QueryKey, useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {  ERRORS } from "../consts";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";

import { getEnvironmentVariables } from "../utils/general";

export type ecmItemType = {
    id:number,
    profession_identifier:number | null,
    title:string
}
export type ecmItemPostType = {
  id:number,
  title:string
}

export type ecmItemApiType = {
  id:number,
  name:string,
  profession:{
    id:number,
    title:number
  }
}

export type ResponseType = {
      metadata: {
        total_elements: number
        total_pages: number,
      },
      output: ecmItemApiType[],
}
export type ResponseTypeAdapted = {
  metadata: {
    total_elements: number
    total_pages: number,
  },
  output: ecmItemType[],
}

const { basePath } = getEnvironmentVariables();

export function useEcmProfessionsQuery({
  enabled=true,
  pageSize,
  queryKey=[]
} : {
  enabled?: boolean
  pageSize:number,
  queryKey?: string[]
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  
  const fetcher = fetcherFactory();
  
  const uri = "/learning-catalogue/ecm/professions";


  return useInfiniteQuery<
    unknown,
    unknown,
    ResponseTypeAdapted,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      `${basePath}${uri}?page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data:ResponseType) => {
        return { 
          metadata:data.metadata,
          output:data.output.map(item => {
            return{
              ...item,
              id:item.id,
              title:item.name
            };
          })
        };
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, ...queryKey]
  });
}
export function useEcmDisciplineQuery({
  enabled=true,
  pageSize,
  profession,
  queryKey=[]
} : {
  enabled?: boolean
  pageSize:number
  profession:number[]
  queryKey?: string[]
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  
  const fetcher = fetcherFactory();
  
  const uri = "/learning-catalogue/ecm/professions/disciplines";
  const params = profession.map(profession => `profession_ids=${profession}`).toString().replaceAll(",","&");



  return useInfiniteQuery<
    unknown,
    unknown,
    ResponseTypeAdapted,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && profession.length
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
    ),
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as ResponseType).metadata)?.total_pages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      `${basePath}${uri}?page=${pageParam?.nextPage ?? 0}&page_size=${pageSize}&${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data:ResponseType) => {
        return { 
          metadata:data.metadata,
          output:data.output.map(item => {
            return{
              ...item,
              id:item.id,
              profession_identifier:item.profession.id,
              title:item.name
            };
          }) as ecmItemType[]
        };
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, params, ...queryKey]
  });
}

export function usePostEcmRegistration({
  disciplines,  
  enrollId,
  isProfessional,
  isSponsored,
  loId,
  professions,
  sponsors
}: {
disciplines:ecmItemPostType[] | []
enrollId:number,
isSponsored:boolean,
isProfessional:boolean,
loId:number,
professions:ecmItemPostType[] | [],
sponsors: ecmItemPostType[] | []

}) {

  const fetcher = fetcherFactory();
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";

  const body = {
    corporateId: corporateId,
    disciplines: disciplines,
    enrollId: enrollId,
    isProfessional:isProfessional,
    isSponsored: isSponsored,
    LOid: loId,
    professions: professions,
    sponsors:sponsors,
    userId: userId
  };

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}/ecmRegistration`,
      {
        body: JSON.stringify(
          body
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        if( res && res?.bodyUsed){
          return res.json();
        }
        else {
          return res;
        }
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else {
          throw error;
        }
      }),
    onSuccess: () => {

    }
  });
}

