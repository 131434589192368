import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  CardTop,
  CardGrid,
  DescriptionTypography,
  ImageBox,
  TitleTypography
} from "./CardModuleMobile.styles";
import {
  CardCtaModule,
  // CardModuleProps,
  CardSignature,
  Icon,
  StatusBox
} from "../../components";
import {
  useAccent, useIconStatus, useType
  // useIconStatus
} from "../../hooks";
// import { hasPassedExpiration } from "../../utils/datetime";
import { OutletContext } from "../../routes";
import { formatDate } from "../../services";
import { hasPassedExpiration } from "../../utils/datetime";
import type { Module } from "../../utils/explore";
export function CardModuleMobile({
  badge,
  certificate,
  coverPublicURL,
  courseId=undefined,
  disabled,
  duration,
  enrollType,
  id,
  isMandatory,
  expirationDate,
  isStandAlone,
  isSurvey,
  isTest,
  learningActivityNumber,
  learningObjectType,
  learningObjectTypology,
  madeAttempts,
  maxAttempts,
  minimumScore,
  percentageOfCompletion,
  rootId=undefined,
  shortDescription,
  status,
  title,
  toolNumber,
  ...props
}: Module) {
  const { t, i18n } = useTranslation();
  const { sectionUrl } = useOutletContext<OutletContext>();
  const isExpired = hasPassedExpiration(expirationDate);
  const accentColor = useAccent({ isExpired, isMandatory, status });
  const iconStatus = useIconStatus({ status });
  const messageStatusBox = (isExpired && expirationDate && status !== "C" && isMandatory )
    ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
    : undefined;
  const theme = useTheme();
  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);
  const navigate = useNavigate();
  const isTestCompleted = (isTest || isSurvey) && status === "C";


  return (
    <CardGrid container { ...props }>
     
      <span className={ "type" }>
        {
          isTest || isSurvey ? 
            <Icon 
              color={ theme.customColors.accentVariantA }
              icon={ isTest ? "test" : "survey" } 
              size={ 20 }/> : null
        }
        { isTest ? t("test") : isSurvey ? t("survey") :  typeObjectLearning }
      </span>
      <TitleTypography >
        { title }
      </TitleTypography>
      <CardTop>
        <Grid item
          position={ "relative" }
          height={ "117px" }
          width={ "156px" }
          sx={ {
            filter: "drop-shadow(25px 25px 80px rgba(21, 21, 21, 0.4))"
          } }
        >
          { status === "C"  || isMandatory  ?
            <StatusBox
              message={ messageStatusBox }
              accent={ accentColor }
              icon={ iconStatus }
            /> : null
          }
          <ImageBox
            alt="card cover"
            component="img"
            src={ coverPublicURL }
            onClick={ ()=> {
              if(!disabled){
                navigate(`/${sectionUrl}/dettaglio/${id}/${learningObjectTypology}`, {
                  state:{
                    courseId:courseId,
                    loRootId:rootId
                  }
                });
              }
             
            } }
            
          />
        </Grid>
        <CardSignature
          learningActivityNumber={ learningActivityNumber }
          duration={ duration }
          direction="column"
          justifyContent={ "flex-end" }
          gap={ 1 }
          resources={ toolNumber }
          xs={ 1 }
          isSurvey={ isSurvey }
          isTest={ isTest }
          minimumScore={ minimumScore ?? undefined }
          temptative={ isTest && maxAttempts ? maxAttempts - (madeAttempts ?? 0) : undefined }
          learningObjectTypology={ learningObjectTypology }
        />
      </CardTop>
      <DescriptionTypography>
        { shortDescription }
      </DescriptionTypography>
      <CardCtaModule
        badge={ badge }
        certificate={ certificate }
        className={ "card-cta" }
        courseId={ courseId }
        disabled={ disabled || isTestCompleted }
        disableFavourites={ isTest || isSurvey }
        enrollType={ enrollType }
        ecm_specialization={ false }
        ecmRegistration={ false }
        isSurvey={ isSurvey }
        isTest={ isTest }
        id={ id }
        isStandAlone={ isStandAlone }
        isCard={ true }
        learningObjectType={ learningObjectType }
        learningObjectTypology={ learningObjectTypology }
        madeAttempts={ madeAttempts ?? undefined }
        percentageOfCompletion={ percentageOfCompletion }
        rootId={ rootId }
        status={ status }
      />
    </CardGrid>
  );
}
