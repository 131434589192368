import { CardTeacher as CardTeacherComponent } from "./CardTeacher.component";
import { CardTeacherProps } from "./CardTeacher.types";
import { Default as DefaultCardTeacherProps } from "./CardTeacher.variants";

export const DefaultCardTeacher = ({ ...props }: Partial<CardTeacherProps>) => (
  <CardTeacherComponent { ...{ ...DefaultCardTeacherProps, ...props } }/>
);

export type { CardTeacherProps };
export const CardTeacher = CardTeacherComponent;

