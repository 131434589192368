import {
  Box,
  BoxProps,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import { Icon } from "../../components";

type StatusBoxProps =
  & Omit<
    BoxProps,
    "children"
  >
  & BoxProps
  & {
    accent: string,
    message?: string,
    inHeroBanner?: boolean,
    icon: string,
  }

export const StyledStatusBox = styled(Box)<{
  $accent: string
  $message?: string,
  $inHeroBanner?: boolean
} & BoxProps>(({
  $accent,
  $message,
  $inHeroBanner,
  theme
}) => ({
  alignItems: "center",
  backgroundColor: $accent,
  borderRadius: "0 0.25rem 0.25rem 0",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  color: theme.palette.common?.black,
  display: "flex",
  gap: "1rem",
  height: "2.5rem",
  justifyContent: "center",
  left: "-0.5rem",
  padding: theme.spacing(1),
  pointerEvents:"none",
  position: "absolute",
  top: $inHeroBanner ? "5.375rem" : "1.375rem",
  width: $message ? "fit-content" : "2.5rem",

  [theme.breakpoints.down("sm")]:{
    gap: "0.5rem",
    height: "unset",
    left: "-0.2rem",
    minHeight: "unset",
    minWidth: "unset",
    padding: "5px 8px",
    width: $message ? "fit-content" : "unset"
  }
}));

export function StatusBox({
  icon,
  message,
  inHeroBanner,
  ...props
} : StatusBoxProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledStatusBox
      $accent={ props.accent }
      $message={ message }
      $inHeroBanner={ inHeroBanner }
      { ...props }
    >
      <Icon
        color={ theme.customColors.systemSecondary05 }
        icon={ icon }
        size={ isMobile ? "16px" : "20px" }
      />
      {
        (message) ? (
          <Typography
            component="span"
            fontSize={ isMobile ? "1rem" : "0.75rem" }
            fontWeight="500"
            color={ theme.customColors.textPrimaryAlternative }
          >
            { message }
          </Typography>
        ): null
      }
    </StyledStatusBox>
  );}
