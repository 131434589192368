import { useMutation, useQuery } from "@tanstack/react-query";
import { PayloadProps } from "../routes/Profile/AddSkillBook/AddSkillBook.types";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
import { getEnvironmentVariables } from "../utils/general";

const { basePath } = getEnvironmentVariables();

interface UseOptionsQueryParams {
  enabled?: boolean;
  queryKey?: string[];
  onSuccess?: (data: { output: [] }) => void;
  onError?: (error: string) => void;
  onSettled?: (data: { output: [] }, error: string) => void;
  knowledgeAreaId?: string | number;
}

export function useKnowledgeAreasOptionsQuery({
  enabled = true,
  onSuccess,
  onError
}: UseOptionsQueryParams) {

  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateInfo!.id}/knowledge-areas`;

  return useQuery(
    [uri],
    () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      }
    ).then((res) => {
      return res.json();
    }),
    {
      enabled: Boolean(enabled && sessionToken && idToken) && !isSeedingSession,
      onSuccess,
      onError
    }
  );
}

export function useKnowledgeDomainsOptionsQuery({
  knowledgeAreaId,
  enabled = true,
  onSuccess,
  onError
}: UseOptionsQueryParams) {

  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateInfo!.id}/knowledge-areas/${knowledgeAreaId}/domains`;

  return useQuery(
    [uri],
    () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      }
    ).then((res) => {
      return res.json();
    })
      .catch((error) => {
        onError;
        throw error;
      }),
    {
      enabled: Boolean(enabled && sessionToken && idToken) && !isSeedingSession,
      onSuccess
    }
  );
}

export function useCapabilitiesOptionsQuery({
  enabled = true,
  onSuccess,
  onError
}: UseOptionsQueryParams) {

  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const fetcher = fetcherFactory();

  const uri = `/skills/${corporateInfo!.id}/capabilities`;

  return useQuery(
    [uri],
    () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      }
    ).then((res) => {
      return res.json();
    })
      .catch((error) => {
        onError;
        throw error;
      }),
    {
      enabled: Boolean(enabled && sessionToken && idToken) && !isSeedingSession,
      onSuccess,
      onError
    });
}

export function useCourseTypesOptionsQuery({
  enabled = true,
  onSuccess,
  onError
}: UseOptionsQueryParams) {

  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const fetcher = fetcherFactory();

  const uri = "/certificates/external/course-type";

  return useQuery(
    [uri],
    () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      }
    ).then((res) => {
      return res.json();
    })
      .catch((error) => {
        console.log("error", error);
        onError;
        throw error;
      }),
    {
      enabled: Boolean(enabled && sessionToken && idToken) && !isSeedingSession,
      onSuccess,
      onError
    });
}

export function usePostCertificate({
  onSuccess,
  onError
}: UseOptionsQueryParams) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const uri = "/certificates/external/certificate-request/upload";

  return useMutation(
    async (data: PayloadProps) => {
      console.log("Invio dati a:", `${ basePath }${ uri }`);
      console.log("Dati inviati:", data);

      const formData = new FormData();
      formData.append(
        "certificate",
        data.certificate ? data.certificate : ""
      );
      formData.append("data", JSON.stringify(data.data));

      console.log("formData", formData);
      try {
        const response = await fetcher(
          `${ basePath }${ uri }`,
          {
            method: "POST",
            headers: {
              "authorization": idToken,
              "x-ada-session-token": sessionToken
            },
            body: formData
          }
        );
        return response;
      } catch (error) {
        console.error("Errore durante l'invio:", error);
        throw error;
      }
    },
    {
      onSuccess: (data) => {
        console.log("Successo dalla mutazione:", data);
        if (onSuccess) onSuccess(data as unknown as  { output: [] });
      },
      onError: (error) => {
        console.error("Errore dalla mutazione:", error);
        if (onError) onError(error as string);
      }
    }
  );
}
