
import {
  IconButton,
  InputAdornment,
  TextFieldProps
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Icon, TextInput } from "../../components";

export function PasswordInput({ ...props }: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  return (
    <TextInput
      type={ showPassword ? "text" : "password" }
      InputProps={ {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={ `toggle ${props.label} visibility` }
              // onClick={ () => setShowPassword((prev) => !prev) }
              onMouseDown={ () => setShowPassword(true) }
              onMouseUp={ () => setShowPassword(false) }
              onTouchStart={ () => setShowPassword(true) }
              onTouchEnd={ () => setShowPassword(false) }
            >
              {
                showPassword
                  ? <Icon icon={ "Icons_visible" } color={ theme.customColors.systemPrimary02 } size={ 20 }/>
                  :<Icon icon={ "Icons_not-visible" } color={ theme.customColors.systemPrimary02 } size={ 20 }/>
              }
            </IconButton>
          </InputAdornment>
        )
      } }
      { ...props }
    />
  );
}
