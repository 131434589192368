import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { SignupSummaryCapabilities } from "./SignupSummaryCapabilities.component";
import { SignupSummaryPlan } from "./SignupSummaryPlan.component";
import {
  GuardedLink,
  SignupSummaryKnowledge,
  SignupSummaryPreferences,
  SignupSummarySector,
  Spinner
} from "../../components";
import { StyledBottomBar } from "../../components/OnBoarding/OnBoarding.styles";
import { 
  StyledPreferenceStack 
} from "../../components/PreferenceStack/PreferenceStack.styles";

import { useGetLearnerGoalsQuery, useGetProfileImageQuery, usePreferencesQuery } from "../../queries";
import { useAuthStore } from "../../stores";



export function SignupSummary() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const postOnBoardingCompleted = useAuthStore(state => state.postOnBoardingCompleted);
  const navigate = useNavigate();
  const { data: preferences, isLoading: preferencesAreLoading } = usePreferencesQuery({});
  const { data: userGoals } = useGetLearnerGoalsQuery();

  const userInfo = useAuthStore(state => state.userInfo);
  const { data: profileImage } = useGetProfileImageQuery({});
  

  return (
    <Stack
      alignItems="center"
      gap={ isMobile ? 2 : 3 }
      maxWidth="1800px"
      width="min(80rem, 100%)"
      marginBottom={ "72px" }
      sx={ {

        [`${theme.breakpoints.down("sm")}`]:{

          [`${StyledPreferenceStack}`]:{
            padding:theme.spacing(2) 
          }
          
        }
      } }
    >
      {
        preferencesAreLoading ? (
          <Spinner />
        ) : (
          <>
            <Stack
              gap={ isMobile ? 1 : 2 }
            >
              <Typography
                color="primary"
                fontSize={ isMobile ? "1.25rem" :  "2rem" }
                fontWeight={ 700 }
                textAlign={ "start" }
                lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
              >
                { t("data_review") }
              </Typography>
              <Stack alignItems="center">
                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  textAlign={ "start" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                >
                  { t("profile_ready") }
                </Typography>
              </Stack>
            </Stack>

            
            <SignupSummaryPlan 
              profileImage={ profileImage ?? "" } 
              name={ userInfo?.name as string + " " +  userInfo?.surname as string }
            />
           
            {
              userInfo?.profession || userInfo?.job ? 

                <SignupSummarySector 
                  job={ userInfo.job }
                  profession={ userInfo.profession }
                /> : 
                null
            }

               
            {
              userInfo?.executive_domains && userInfo.executive_domains.length ? 

                <SignupSummaryKnowledge
                  title={ t("your_executive_knowledge") }
                  domains={ userInfo.executive_domains }
                  linkModify="/signup/skills"
                  noItemsText={ "" }
                  noItemsToShow={ false }
                /> : 

                null
            }
         
            {
              userInfo?.personal_domains && userInfo.personal_domains.length ? 
              
                <SignupSummaryKnowledge
                  title={ t("your_personal_knowledge") }
                  domains={ userInfo.personal_domains }
                  linkModify="/signup/skills"
                  noItemsText={ "" }
                  noItemsToShow={ false }
                />
                : 

                null
            }

            {
              userInfo?.capabilities && userInfo.capabilities.length ? 

                <SignupSummaryCapabilities 
                  capabilities={ userInfo.capabilities }
                  noItemsText={ "" }
                  noItemsToShow={ false }
                /> : 
                null
            }

            {
              userGoals?.goal.plan ||  (preferences?.formats && preferences.formats.length )? 
                <SignupSummaryPreferences 
                  formats={ preferences?.formats }
                  userGoals={ userGoals }
                />
                : 
                null

            }
          
            <StyledBottomBar 
              sx={ {
                justifyContent:"center"
              } }
            >
              <GuardedLink
                href="/"
                onClick={ handleSubmit }
                state= { {} }
              >
                { t("explore_start") }
              </GuardedLink>

            </StyledBottomBar>
           
          </>
        )
      }
    </Stack>
  );

  function handleSubmit() {
    postOnBoardingCompleted();
    setTimeout(()=> {
      navigate("/");
    },2000);
    return false;
  }
}



