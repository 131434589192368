import {
  Box,
  BoxProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

export type ListBoxProps =
  BoxProps & {
    component: "ol"| "ul",
    gap?: number
  }

export const ListBox = styled(Box)<ListBoxProps>(({
  gap=2,
  theme
}) => ({
  all: "unset",

  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(gap),

  "li": {
    all: "unset",

    "a": {
      color: theme.palette.primary?.main,
      textDecoration: "none"
    }
  }
}));
