import {
  InputBase,
  Stack,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledCommentsSection = styled(Stack)(() => ({
  display:"grid",
  gridTemplateRows:"0fr",
  height:0,
  opacity:0,
  transition: "all 0.5s linear",

  "& > *": {
    overflow: "hidden"
  },

  "&.visible":{
    gridTemplateRows: "1fr",
    height:"auto",
    opacity:1
  }
}));
export const CommentInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: theme.customColors.backgroundSecondary,
    border: `1px solid ${theme.customColors.borderTag}`,
    borderRadius: "2px",
    color: theme.customColors.textInput,
    gap:theme.spacing(1),
    padding:  theme.spacing(1, 5, 1, 2),
  
    "&::placeholder ":{
      color:theme.customColors.backgroundDisabled,
      fontFamily:"Inter",
      fontWeight:400,
      lineHeight: "21px",
      opacity:1,
      [theme.breakpoints.down("sm")]: {
        fontSize:"14px",
        lineHeight: "8px",
        width:"158px"
      }
    },

    "&.visible":{
      
    }
  },
  width: "100%"
}));

export const ForbiddenLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWarning,
  fontSize: "10px",
  fontWeight: "700"
}));
