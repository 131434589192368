import {
  Box,
  BoxProps,
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { HTMLProps } from "react";

export const CardGrid = styled(Grid)(({ theme }) => ({
  display:"flex",
  gap:theme.spacing(3),
  width:"calc(100% - 50px)",

  [`${theme.breakpoints.down("sm")}`]:{
    flexDirection: "column-reverse",
    width:"100%"
  }
}));

export const CardBodyGrid = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  display:"flex",
  flexDirection: "column",
  justifyContent: "space-between",

  gap:"16px",
  height:"fit-content",
  padding:"1rem 0",
  width:"calc(100% - 290px)",

  [`${theme.breakpoints.down("sm")}`]:{
    padding: "0",
    width:"100%"
  }
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize:"1.25rem",
  lineHeight:"1.5rem",

  [`${theme.breakpoints.down("sm")}`]:{
    fontSize: "16px"
  },

  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "4",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export const ImageBox = styled(Box)<
  BoxProps & HTMLProps<HTMLImageElement>
>(() => ({
  aspectRatio: "9 / 7",
  borderRadius:"8px",
  boxShadow:"box-shadow: 16px 16px 22px rgba(0, 0, 0, 0.4);",
  objectFit: "cover",
  padding:0,
  paddingBlock: " 0 !important",

  height:"100%",
  width: "100%"
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.3125,

  [`${theme.breakpoints.down("sm")}`]:{
    fontSize: "24px",
    width:"100%"
  }
}));


