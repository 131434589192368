import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FilterGrid = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  color: theme.customColors.textPrimary,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  minWidth: "56px",
  padding: "0 26px",
  position: "relative",
  transition: "transform .5s ease",

  ".filter-name": {
    textTransform: "capitalize"
  },


  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05) translateY(0.5px)"
  },

  "&.is-active": {
    color: theme.palette.secondary?.main,

    "&:before": {
      height: "2px"
    },

    "svg path": {
      fill: theme.palette.secondary?.main
    }
  },

  [theme.breakpoints.down("sm")]: {
    padding:"0 8px"
  }
}));


// export const ImageBox = styled(Box)(() => ({
export const ImageBox = styled("img")(() => ({
  objectFit: "contain",

  height:"20px",
  width: "20px"

}));




