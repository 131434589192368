import { Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { ButtonText } from "../../components/Button";

export const ContainerStack = styled(Stack)(({ theme }) => ({
  height: "27.55rem",
  marginBottom: "5rem",
  maxWidth:"546px",
  //minHeight: "26.375rem",

  [theme.breakpoints.down("sm")]: {
    height: "auto",
    marginBottom: "0",
    width: "100%"
  }
}));

export const DateContainerStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  maxWidth: "-webkit-fill-available",
  padding: "1rem 0.6875rem",
  width: "50vw",

  [theme.breakpoints.down("sm")]: {
    marginTop: "16px",
    padding: theme.spacing(2, 1),
    width: "335px"
  }
}));

export const EventsStack = styled(Stack)(() => ({
  width: "100%"
}));

export const DurationStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  background: alpha(theme.customColors.backgroundPrimaryCta, 0.6),
  borderRadius: "0.5rem",
  color: theme.customColors.textTag,
  padding: "0.5rem",

  [theme.breakpoints.down("sm")]: {
    padding: "0.25rem"
  }
}));

export const TitleStack = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    marginTop: "0.4375rem"
  }
}));

export const NoEventsStack = styled(Stack)(() => ({
  height: "100%",
  justifyContent: "center",
  maxWidth: "-webkit-fill-available",
  width: "50vw"
}));

export const DayTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: "700",
  lineHeight: "3rem",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    lineHeight: "1.875rem"
  }

}));

export const MonthTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "700",
  lineHeight: "2.0625rem",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    lineHeight: "1.125rem"
  }
}));

export const InfoTypography = styled(Typography)(() => ({
  alignSelf: "center",
  fontSize: "1rem",
  fontWeight: "500",
  lineHeight: "0.875rem"
  
}));

export const DurationTypography = styled(Typography)(() => ({
  fontSize: "0.75rem",
  weight: "700"
}));

export const TypeTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.3125rem",
  weight: "400",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    fontWeight: "700",
    lineHeight: "0.875rem",
    textTransform: "uppercase"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight:"700",
  lineHeight:"1.875rem",
  maxWidth: "20rem",
  overflow: "hidden",
  overflowWrap:"break-word",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    lineHeight: "1.3125rem",
    maxWidth: "18rem",
    whiteSpace: "unset"
  }
}));

export const NoEventsTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  justifyContent: "center",
  weight: "700",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem"
  }
}));

export const ResumeButton = styled(ButtonText)(({ theme }) => ({
  height: "2.5rem",
  minWidth: "unset",

  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem"
  }
}));

