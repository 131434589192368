import {
  Grid,
  Stack,
  StackProps,
  Typography
} from "@mui/material";
import { common } from "@mui/material/colors";
import {
  alpha,
  styled
} from "@mui/material/styles";
import { ElementType } from "react";
import type { LearningObject } from "../../types";

export const HeroCardStack = styled(Stack)<
  & StackProps
  & Pick<LearningObject, "coverPublicURL">
  & { component: ElementType } // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'
>(({ coverPublicURL, theme }) => ({
  background:`${theme.linearGradients.gradientC}, url(${coverPublicURL})`,
  backgroundPosition: "",
  backgroundRepeat:"no-repeat",
  backgroundSize: "contain",
  borderRadius:"12px",
  flexDirection:"row",
  justifyContent: "flex-end",
  marginBottom:theme.spacing(6),
  maxWidth: "100%",
  minHeight: "450px",

  "& > *:not(button)" : {
    width: "40%"
  },

  [theme.breakpoints.down("sm")]: {
    backgroundPosition: "top",
    backgroundRepeat:"no-repeat",
    backgroundSize: "auto 290px",
    borderRadius:"8px",
    marginBottom:theme.spacing(3),
    marginLeft: "-20px",
    marginRight: "-20px",
    minWidth: "calc(100% + 40px)",
    padding:0,

    "& > *:not(button)" : {
      width: "100%"
    },

    "& div": {
      padding: "0 40px"
    }
  }
}));

export const TextGrid = styled(Grid)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(3),
  justifyContent: "center",

  ">button":{
    width:"fit-content"
  },

  [theme.breakpoints.down("sm")]: {
    alignItems:"center",
    gap:theme.spacing(2),
    justifyContent:"flex-end"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight:"700",
  lineHeight: "56px",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,
  width: "551px",

  [theme.breakpoints.down("sm")]: {
    fontSize:"1.5rem",
    lineHeight: "normal",
    width: "100%"
  }
}));


export const DetailTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: 1.1875,

  [theme.breakpoints.down("sm")]: {
    fontSize:"0.875rem",
    lineHeight: "1.3125rem",
    marginBottom:"8px",
    textAlign:"start"
  }
}));
