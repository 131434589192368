import  { CAPABILITY_TYPE } from "../consts";
import type { CapabilityPurpose } from "../types";

export function setLevelCapabilityBucket(id:number){
  switch(id){
  case 0: return CAPABILITY_TYPE.IN_DEPTH as CapabilityPurpose;
  case 1: return CAPABILITY_TYPE.TRAINING as CapabilityPurpose;
  default: return CAPABILITY_TYPE.TRAINING as CapabilityPurpose;
  }
}
export function getPurposeBucket(purpose:CapabilityPurpose){
  switch(purpose){
  case CAPABILITY_TYPE.TRAINING : return 1 ;
  case CAPABILITY_TYPE.IN_DEPTH : return 0 ;
  default: return 0;
  }
}
