import {
  Autocomplete,
  Box, Button, Checkbox, CircularProgress,
  Divider, FormControl, FormControlLabel,
  Grid, ListItemText, MenuItem, Radio, RadioGroup, Select,
  Stack,
  Step,
  StepLabel,
  Stepper, TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NavigationProps } from "./types";
import { useRegisterState } from "../../context/RegisterContext";
import {
  RegistrationStepField,
  useOptionsList,
  Option
} from "../../queries/autoreg";
import { bytesToMB } from "../../utils/general";
import { isEmail, isPhoneNumberValid } from "../../utils/register";
import { ButtonText } from "../Button";
import ButtonUploadCst from "../ButtonUploadCst";
import { Icon } from "../Icon";
import DataPickerCst from "../InputCst/DataPickerCst";
import SelectCst from "../InputCst/SelectCst";
import { Link } from "../Link";
import OutputRegister from "../Message/OutputRegister.component";
import { ModalBasic } from "../ModalBasic";

interface Dependency {
  [key: string]: string;
}

interface OptionCall {
  fieldKey: string;
  dependsOn: Dependency[];
}

interface DynamicOptions {
  options: {
    key: string;
    options?: Option[];
  }[]
}

// TODO refactoring: rendere Navigation riutilizzabile. Ad es. non tutte le Navigation hanno bisogno di fare la get di optionsList
const Navigation: React.FC<NavigationProps> = ({ steps , onSubmit, formType, idCorporate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState<Record<string, string | boolean>>({});
  const [formData, setFormData] = useState(steps);
  const theme = useTheme();
  const { state, dispatch } = useRegisterState();
  const { state: routerState } = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [optionCall, setOptionCall] = useState<OptionCall>();
  const [dynamicOptions, setDynamicOptions] = useState<DynamicOptions>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: list } = useOptionsList({ formType: formType, fieldKey: optionCall?.fieldKey, dependsOn: optionCall?.dependsOn, idCorporate });

  useEffect(() => {
    if (list) {
      setDynamicOptions(prevState => {
        const currentOptions = prevState?.options || [];
        const existingIndex = currentOptions.findIndex(opt => opt.key.includes(list.key));

        if (existingIndex !== -1) {
          // Sostituisci le opzioni esistenti se la chiave è già presente
          const updatedOptions = [...currentOptions];
          updatedOptions[existingIndex] = {
            key: list.key,
            options: list.options
          };
          return { options: updatedOptions };
        } else {
          // Aggiungi nuove opzioni se la chiave non esiste
          return {
            options: [
              ...currentOptions,
              {
                key: list.key,
                options: list.options
              }
            ]
          };
        }
      });
    }
  }, [list]);

  useEffect(() => {
    steps.forEach(step => {
      step.fields.forEach(field => {
        if (field.options && field.options.length > 0) {
          setDynamicOptions(prevState => {
            const currentOptions = prevState?.options || [];
            const existingIndex = currentOptions.findIndex(opt => opt.key === field.key);

            if (existingIndex !== -1) {
              // Sostituisci le opzioni esistenti se la chiave è già presente
              const updatedOptions = [...currentOptions];
              updatedOptions[existingIndex] = {
                key: field.key,
                options: field.options
              };
              return { options: updatedOptions };
            } else {
              // Aggiungi nuove opzioni se la chiave non esiste
              return {
                options: [
                  ...currentOptions,
                  {
                    key: field.key,
                    options: field.options
                  }
                ]
              };
            }
          });
        }
      });
    });
  }, [steps]);

  const handleNext = () => {
    if (validateStep(formData[activeStep].fields)) {
      if ((activeStep + 1) === formData?.length) {
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: "activeStep",
            value: { label: "Conferma la tua registrazione" }
          }
        });
      } else {
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: "activeStep",
            value: formData[activeStep + 1]
          }
        });
      }
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep > 0 ? prevStep - 1 : prevStep);
  };

  const validateStep = (fields: RegistrationStepField[]) => {
    let valid = true;
    const newErrors: Record<string, boolean | string> = {};

    const fieldsList: RegistrationStepField[] = [];
    fields.forEach((field) => {

      if(field.visible_on){
        const [visibleOnKey, visibleOnValue] = field.visible_on.split(":");
        if(!state[visibleOnKey] || state[visibleOnKey] !== visibleOnValue) return;
      }

      if(field.type === "GROUP" && field.fields){
        fieldsList.push(...field.fields);
      }else{
        fieldsList.push(field);
      }
    });

    const emails: string[] = [];

    fieldsList.forEach((currentField) => {

      if (state[currentField.key]) {

        if ((currentField.type === "EMAIL")) {
          if(!isEmail(state[currentField.key])){
            newErrors[currentField.key] = "error_type_email";
            valid = false;
          }else{
            if(emails.includes(state[currentField.key].toUpperCase())){
              newErrors[currentField.key] = "error_email_already_present";
              valid = false;
            }else{
              emails.push(state[currentField.key].toUpperCase());
            }
          }
        }

        if ((currentField.key === "phone") && !isPhoneNumberValid(state[currentField.key])) {
          newErrors[currentField.key] = "error_type_phone";
          valid = false;
        }

        if ((currentField.type === "INTEGER") ) {
          const fieldValue = Number(state[currentField.key]);
          if (isNaN(fieldValue) || fieldValue < 0) {
            newErrors[currentField.key] = "error_type_number";
            valid = false;
          }
        }
      }

      if(currentField.type === "GROUP_ADD"){
        if(fieldsList.filter((field) => (field.type !== "GROUP_ADD" && field.key.includes(currentField.key))).length === 0){
          newErrors[currentField.key] = "required_fields_group";
          valid = false;
        }
      } else if (!currentField.fields && currentField.required && !state[currentField.key]) {
        newErrors[currentField.key] = "required_field";
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleFinalSubmit = async () => {

    if(validateStep(formData[activeStep].fields)){

      const { ...formData } = state;

      if(formData.activeStep) delete formData.activeStep;

      setIsSubmitting(true);

      const response = await onSubmit(formData);

      setIsSubmitting(false);

      dispatch({
        payload: {
          key: "response",
          value: response
        },
        type: "SET_INPUT_VALUES"
      });
    }
  };

  const handleInputChange = (value: string | number | (string | number)[], name: string) => {

    dispatch({
      payload: {
        key: name,
        value
      },
      type: "SET_INPUT_VALUES"
    });

    checkFieldDependencies(name);
  };

  const handleDateChange = (name: string) => (date: Date | null) => { //ONCHANGE DATE FIELD
    if (date) {
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: {
          key: name,
          value: date
        }
      });
    }
  };

  const initNewForm = () => {
    const newFormId = state["response"].output.newForm.id;
    setFormData(state["response"].output.newForm.steps);
    dispatch({
      type: "RESET_INPUT_VALUES"
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: {
        key: "formId",
        value: newFormId
      }
    });
    setActiveStep(0);
  };

  const addStepsGroup = (parent: RegistrationStepField, newFields?: RegistrationStepField[]) => {
    if(!parent || !newFields) return;

    const totalGroups = formData[activeStep].fields.filter((field) => field.type === "GROUP").length;
    const parentIndex = formData[activeStep].fields.findIndex((field) => field.key === parent.key);

    const updatedFields = [...formData[activeStep].fields];
    updatedFields.splice((parentIndex + totalGroups + 1), 0, {
      depends_on: parent.depends_on,
      fields: newFields.map((field) => ({
        ...field,
        key: `${parent.key}:${totalGroups}:${field.key}`,
        row: true,
        visible_on: parent.visible_on
      })),
      key: `step_${activeStep}_group_${totalGroups}`,
      row: true,
      type: "GROUP",
      visible_on: parent.visible_on
    } as RegistrationStepField);

    const updatedFormData = [...formData];
    updatedFormData[activeStep].fields = updatedFields;

    setFormData(updatedFormData);
    checkFieldDependencies();
  };

  const deleteStepsGroup = (key: string) => {
    if(!key) return;

    const fieldsGroup = formData[activeStep].fields.find((field) => field.key === key);
    if(!fieldsGroup) return;

    let updatedFields = [...formData[activeStep].fields];
    updatedFields = updatedFields.filter((field) => field.key !== key);

    const updatedFormData = [...formData];
    updatedFormData[activeStep].fields = updatedFields;

    fieldsGroup.fields?.forEach((field) => {
      if(Object.prototype.hasOwnProperty.call(state, field.key)) delete state[field.key];
    });

    setFormData(updatedFormData);
  };

  const checkFieldDependencies = (key?: string) => {

    const fields: RegistrationStepField[] = [];
    formData.forEach((step) => {
      step.fields.forEach((field) => {
        if(field.type === "GROUP"){
          if(field.fields) fields.push(...field.fields);
        }else{
          fields.push(field);
        }
      });
    });

    //Resetto i campi che dipendono dal valore del field che sto modificando
    fields.forEach((actualField) => {

      if(!key || (actualField.key === key)){

        const dependentFields = fields.filter((field) => field.depends_on && field.depends_on.includes(actualField.key));

        dependentFields.forEach((dependentField) => {

          if(dependentField && (dependentField.type === "LIST" || dependentField.type === "MULTI_SELECT") && dependentField.key) {

            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: dependentField.key,
                value: undefined
              }
            });
          }
        });
      }
    });
  };

  //Quando cambia lo state controllo se ci sono dei fields SELECT | MULTI_SELECT con delle dipendenze aggiornando le opzioni disponibili
  useEffect(() => {
    const fields: RegistrationStepField[] = [];
    formData.forEach((step) => {
      step.fields.forEach((field) => {
        if(field.type === "GROUP"){
          if(field.fields) fields.push(...field.fields);
        }else{
          fields.push(field);
        }
      });
    });

    fields.forEach((field) => {
      if(field.depends_on && (field.type === "LIST" || field.type === "MULTI_SELECT")){
        const dependsOn = field.depends_on.map((depKey: string) => ({
          [depKey]: state[depKey]
        }));

        const allValuesValid = dependsOn.every(dependency => {
          const key = Object.keys(dependency)[0];
          const value = dependency[key];
          return value !== undefined && value !== "";
        });

        const splittedFieldKey = field.key.split(":");
        const fieldKey = splittedFieldKey[splittedFieldKey.length -1];

        const param = {
          dependsOn,
          fieldKey
        };

        if(allValuesValid) {
          setOptionCall((old) => ({
            ...old,
            ...param
          }));
        }
      }
    });
  }, [formData, state]);

  const getFieldComponent = (field: RegistrationStepField) => {

    if(field.visible_on){
      const [visibleOnKey, visibleOnValue] = field.visible_on.split(":");
      if(!state[visibleOnKey] || state[visibleOnKey] !== visibleOnValue) return null;
    }

    switch(field.type){
    case "PARAGRAPH": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item>
          <Typography
            variant="body1"
            sx={ {
              textAlign: "center",
              my: "5px"
            } }
          >
            { field.label }
          </Typography>
        </Grid>
      );
    }

    case "DIVIDER": {
      return (
        <Divider
          key={ field.key ?? `${field.type}_${field.order}` }
          sx={ {
            mx: "auto",
            mt: "30px",
            mb: "15px",
            span: {
              alignItems: "center",
              display: "flex",
              fontSize: theme.spacing(1.5),
              height: "14px",
              justifyContent: "center",
              padding: theme.spacing(0, 3),
              width: "24.187%"
            },
            borderColor: theme.customColors.border,
            justifyContent: "center",
            width: isMobile ? "100%" : "430px"
          } }
        />
      );
    }

    case "TEXT": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 } >
          <Box
            sx={ {
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              [theme.breakpoints.down("lg")]: {
                alignItems: "start",
                flexDirection: "column"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                marginRight: 2,
                width: "40%",
                [theme.breakpoints.down("lg")]: {
                  marginBottom: 1,
                  marginRight: 0,
                  width: "100%"
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                display: "flex",
                flexDirection: "column",
                width: "100%"
              } }
            >
              <TextField
                error={ Boolean(errors[field.key]) }
                id={ `${field.key}-id` }
                placeholder={ `${t(field.label)} ${field.required ? "*" : ""}` }
                name={ field.key }
                type={ field.key === "phone" ? "number" : "text" }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                size={ isMobile ? "small" : "medium" }
                value={ state[field.key] || "" }
                sx={ {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  },
                  width: "100%"
                } }
                required={ field.required }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "EMAIL": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              [theme.breakpoints.down("lg")]: {
                alignItems: "start",
                flexDirection: "column"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                marginRight: 2,
                width: "40%",
                [theme.breakpoints.down("lg")]: {
                  marginBottom: 1,
                  marginRight: 0,
                  width: "100%"
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                display: "flex",
                flexDirection: "column",
                width: "100%"
              } }
            >
              <TextField
                error={ Boolean(errors[field.key]) }
                id={ `${field.key}-id` }
                placeholder={ `${t(field.label)} ${field.required ? "*" : ""}` }
                type={ "email" }
                name={ field.key }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                size={ isMobile ? "small" : "medium" }
                value={ state[field.key] || "" }
                sx={ {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  },
                  width: "100%"
                } }
                required={ field.required }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "INTEGER": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >
              <TextField
                error={ Boolean(errors[field.key]) }
                id={ `${field.key}-id` }
                placeholder={ `${t(field.label)} ${field.required ? "*" : ""}` }
                name={ field.key }
                type={ "number" }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                size={ isMobile ? "small" : "medium" }
                value={ state[field.key] || "" }
                sx={ {
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  }
                } }
                required={ field.required }
                InputProps={ {
                  inputProps: { min: 0 }
                } }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "LIST": {

      let options = field.options;
      if(dynamicOptions && dynamicOptions.options){
        const optionsGroup = dynamicOptions.options.find((e) => e.key === field.key);
        if(optionsGroup && optionsGroup.options) options = optionsGroup.options;
      }

      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <FormControl
              sx={ {
                width: "100%",

                display: "flex",
                flexDirection: "column"
              } }
            >
              <Autocomplete
                key={ `autocomplete_${field.key}` }
                disablePortal
                options={ options ?? [] }
                sx={ {
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.customColors.borderTag
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme.customColors.textPrimary
                  }
                } }
                value={ state[field.key] || "" }
                isOptionEqualToValue={ (option, value) => {
                  return (value !== "" && (option.value === value.value));
                } }
                onChange={ (_, selectedOption) => handleInputChange(selectedOption.value, field.key) }
                disableClearable={ true }
                renderInput={ (params) => (
                  <TextField
                    { ...params }
                    placeholder={ `${t(field.label)} ${field.required ? "*" : ""}` }
                    required={ field.required }
                    error={ Boolean(errors[field.key]) }
                  />
                ) }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </FormControl>
          </Box>
        </Grid>
      );
    }

    case "MULTI_SELECT": {

      let options = field.options;
      if(dynamicOptions && dynamicOptions.options){
        const optionsGroup = dynamicOptions.options.find((e) => field.key.includes(e.key));
        if(optionsGroup && optionsGroup.options) options = optionsGroup.options;
      }

      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <FormControl
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >

              <Select
                key={ `select_${field.key}` }
                multiple
                displayEmpty
                required={ field.required }
                value={ (state[field.key] && state[field.key] !== "") ? state[field.key] : [] }
                onChange={ ( { target: { value } } ) => {
                  handleInputChange(value ?? "", field.key);
                } }
                sx={ {
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: (errors[field.key]) ? theme.palette.primary?.main : theme.customColors.borderTag
                    },
                    "&:hover fieldset": {
                      borderColor: (errors[field.key]) ? theme.palette.primary?.main : theme.customColors.borderTag
                    }
                  },
                  "& .MuiSvgIcon-root": {
                    color: (errors[field.key]) ? theme.palette.primary?.main : theme.customColors.textPrimary
                  }
                } }
                renderValue={ (selected) => {
                  if(!selected || selected.length === 0) return <Typography sx={ {
                    color: "gray"
                  } }>{ `${t(field.label)} ${field.required ? "*" : ""}` }</Typography>;
                  return (selected.length > 1 ? selected.join(", ") : selected);
                } }
              >
                { options && options.map((option) => {
                  return (
                    <MenuItem key={ `select_${field.key}_option_${option.value}` } value={ option.value }>
                      <Checkbox checked={ !!(state[field.key] && state[field.key].includes(option.value)) } />
                      <ListItemText primary={ option.value } />
                    </MenuItem>
                  );
                }) }
              </Select>
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </FormControl>
          </Box>
        </Grid>
      );
    }

    case "DATE": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >
              <DataPickerCst
                id={ 1 }
                label={ t(field.label) }
                name={ field.key }
                onChange={ handleDateChange(field.key) }
                value={ state[field.key] || "" }
                required={ field.required }
                error={ !!errors[field.key] }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "BOOLEAN": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >
              <SelectCst
                name={ field.key }
                placeholder={ `${t(field.label)} ${field.required ? "*" : ""}` }
                value={ state[field.key] || "" }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                required={ field.required }
                error={ Boolean(errors[field.key]) }
                options={ [
                  { label: t("yes"), value: "true", id: "option_yes" },
                  { label: t("no"), value: "false", id: "option_no" }
                ] }
              ></SelectCst>
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "RADIO": {

      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: (field.row) ? "row" : "column",
              alignItems: (field.row) ? "center" : "start",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.label &&
              <Typography sx={ {
                width: (field.row) ? "40%" : "100%",
                marginRight: (field.row) ? 2 : 0,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >
              <RadioGroup
                row={ field.row }
                value={ state[field.key] || ""  }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
              >
                { field.options && field.options.map((option, optionIndex) => {
                  return (
                    <Box
                      key={ `radio_${field.key}_option_${option.value}` }
                      sx={ {
                        border: "1px solid",
                        borderColor: theme.customColors.border,
                        borderRadius: 1,
                        width: (field.row) ? "auto" : "100%",
                        flex: 1,
                        marginBottom: (field.row ? 0 : 1),
                        marginRight: (field.row && optionIndex < (field.options!.length -1)) ? 1 : 0,
                        paddingX: 2,
                        [theme.breakpoints.down("lg")]: {
                          marginBottom: 1,
                          marginRight: (optionIndex < (field.options!.length -1)) ? 1 : 0,
                          width: "100%"
                        },
                        [theme.breakpoints.down(530)]: {
                          marginRight: 0
                        }
                      } }
                    >
                      <FormControlLabel sx={ {
                        fontSize: "1rem",
                        paddingY: 1
                      } } value={ option.value } control={ <Radio /> } label={ t(option.label) } />
                    </Box>
                  );
                }) }
              </RadioGroup>
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "DOWNLOAD": {

      let resourceLink = field.file_link;

      if(field.file_link && field.depends_on && field.depends_on.length > 0){
        const searchParams = new URLSearchParams();
        field.depends_on.forEach((depFieldKey) => {
          if(state[depFieldKey]) searchParams.append(depFieldKey, state[depFieldKey]);
        });

        if(searchParams.toString() !== "") resourceLink = `${resourceLink}?${searchParams.toString()}`;
      }

      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >
              <a href={ resourceLink || "#" } target={ "_blank" } rel={ "noopener noreferrer" }>
                <Button variant={ "outlined" } endIcon={
                  <Icon
                    className="icon-download"
                    icon={ "download" }
                    width={ "20px" }
                    height={ "20px" }
                    color={ theme.palette.primary?.main }
                  />
                } sx={ {
                  width: "100%"
                } }>
                  { field.file_name || "Download" }
                </Button>
              </a>
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "UPLOAD": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start"
              }
            } }
          >
            { field.row && field.label &&
              <Typography sx={ {
                width: "40%",
                marginRight: 2,
                color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                  marginBottom: 1,
                  marginRight: 0
                }
              } }>{ t(field.label) }</Typography>
            }
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "column"
              } }
            >
              <ButtonUploadCst id={ field.key } required={ field.required } maxSize={ field.upload_max_size } onChange={ (base64) => {
                handleInputChange(base64, field.key);
                setErrors((current) => ({ ...current, [field.key]: "" }));
              } } onError={ (reason) => {
                const message = (reason === "FILE_SIZE_TOO_LARGE") ?
                  t("upload_file_size_error", { maxSize: bytesToMB(field.upload_max_size || (1024 * 1024)).toFixed(2) }) :
                  t("upload_file_error");
                setErrors((current) => ({ ...current, [field.key]: message }));
              } }/>
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Box>
          </Box>
        </Grid>
      );
    }

    case "GROUP_ADD": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: 2,
              userSelect: "none"
            } }
          >
            <Box
              sx={ {
                cursor: "pointer",
                color: "primary.main",
                borderBottom: "1px solid",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              } }
              onClick={ () => addStepsGroup(field, field.fields) }
            >
              <Box sx={ {
                marginRight: 1
              } }>
                <Icon
                  className="icon-plus"
                  icon={ "plus" }
                  width={ "15px" }
                  height={ "15px" }
                  color={ theme.customColors.textPrimaryCta }
                />
              </Box>
              <Typography sx={ {
                fontSize: 18,
                fontWeight: 400
              } }>{ t(field.label) }</Typography>
            </Box>
            { errors[field.key] && (
              <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
            ) }
          </Box>
        </Grid>
      );
    }

    case "GROUP": {
      return (
        <Grid key={ field.key ?? `${field.type}_${field.order}` } item xs={ 12 } sm={ (field.row) ? 12 : 6 } md={ (field.row) ? 12 : 6 }>
          <Box
            sx={ {
              border: "1px solid",
              borderColor: theme.customColors.border,
              marginTop: 1,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              borderRadius: "4px",
              position: "relative",
              width: "100%"
            } }
          >
            <Box
              sx={ {
                position: "absolute",
                top: 0,
                right: "10px",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                alignItems: "center",
                transform: "translate(0, -50%)"
              } }
              onClick={ () => deleteStepsGroup(field.key) }
            >
              <Button variant={ "outlined" } size={ "small" } endIcon={ (
                <Icon
                  className="icon-delete"
                  icon={ "Icons_delete" }
                  width={ "15px" }
                  height={ "15px" }
                  color={ theme.customColors.textPrimaryCta }
                />
              ) } sx={ {
                "&:hover": {
                  backgroundColor: theme.customColors.backgroundPrimary
                },
                backgroundColor: theme.customColors.backgroundPrimary
              } } >
                { t("delete") }
              </Button>
            </Box>

            <Grid key={ field.key ?? `${field.type}_${field.order}` } spacing={ 2 } container sx={ {
              padding: 2,
              width: "100%"
            } }>
              { field.fields && field.fields?.map((subField) => {
                return getFieldComponent(subField);
              }) }
            </Grid>
          </Box>
        </Grid>
      );
    }

    default: return null;
    }
  };

  return (
    <>
      <Box sx={ {
        width: "80%",
        [theme.breakpoints.down("lg")]: {
          width: "100%"
        }
      } } >
        { formData?.length > 1 && (
          <Box sx={ {
            mx: "auto",
            width: "80%",
            [theme.breakpoints.down("lg")]: {
              width: "100%"
            }
          } } >
            <Stepper
              activeStep={ activeStep }
              sx={ {
                justifyContent: "center",
                mb: "30px",
                ".MuiSvgIcon-root:not(.Mui-completed)": {
                  color: theme.customColors.backgroundDisabled
                },
                ".MuiSvgIcon-root.Mui-active": {
                  color: theme.customColors.systemPrimary02
                }
              } }
            >
              { formData?.map((step) => {
                return (
                  <Step key={ step.label }>
                    <StepLabel>
                    </StepLabel>
                  </Step>
                );
              }) }
            </Stepper>
          </Box>
        ) }
        { (activeStep == formData?.length -1 && state["response"]?.output) ? (
          <OutputRegister
            output={ state["response"]?.output }
            status={ state["response"] ? state["response"]?.status : null }
            onClick={ () => {
              dispatch({
                payload: {
                  key: "response",
                  value: null
                },
                type: "SET_INPUT_VALUES"
              });
              handleBack();
            } }
            initNewForm={ initNewForm }
          />
        ) : (
          <>
            { formData[activeStep].description && (
              <Typography
                variant="h6"
                sx={ {
                  mb: 3,
                  textAlign: "center"
                } }
              >
                { formData[activeStep]?.description }
              </Typography>
            ) }
            <Grid container spacing={ 2 } sx={ {
              width: "100%"
            } }>
              { formData[activeStep]?.fields.map((field: RegistrationStepField) => getFieldComponent(field)) }
            </Grid>
          </>
        ) }
        { !state?.response ? (
          <>
            <Stack
              direction={ "row" }
              flexWrap="wrap"
              gap={ isMobile ? 2 : 0 }
              justifyContent="space-evenly"
              alignItems="center"
              mt={ 3 }
              sx={ { marginTop: "30px", mx: "auto", width: "65%"  } }
            >
              <ButtonText
                variant="outlined"
                disabled={ activeStep === 0 }
                onClick={ handleBack }
              >
                { t("back") }
              </ButtonText>
              <ButtonText
                variant="contained"
                onClick={ (activeStep === formData?.length - 1) ? handleFinalSubmit : handleNext }
              >
                { (activeStep === formData?.length - 1) ? t("finish") : t("continue") }
              </ButtonText>
            </Stack>
            <Stack
              justifyContent={ isMobile ? "flex-start" : "center" }
              gap={ 1 }
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              } }>
              <Divider sx={ {
                "&::after": {
                  width: isMobile ? "50%" : "153px"
                },
                "&::before": {
                  width: isMobile ? "50%" : "153px"
                },
                borderColor: theme.customColors.border,
                justifyContent: "center",
                mx: "auto",
                my: "16px",
                "span": {
                  alignItems: "center",
                  display: "flex",
                  fontSize: theme.spacing(1.5),
                  height: "14px",
                  justifyContent: "center",
                  padding: theme.spacing(0, 3),
                  width: "24.187%"
                },
                width: isMobile ? "100%" : "430px"
              } }
              >
                { t("or") }
              </Divider>
            </Stack>
            <Stack
              justifyContent={ isMobile ? "flex-start" : "center" }
              gap={ 1 }
              sx={ {
                display: "hidden",
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              } }
            >
              <Link
                href="/access/login"
                state={ {
                  ...routerState,
                  recover: true
                } }
              >
                <Typography
                  textAlign={ "center" }
                  fontSize={ isMobile ? "0.75rem" : "1.125rem" }
                  lineHeight={ isMobile ? "0.875rem" : (21 / 20) }
                  textTransform="capitalize"
                >
                  { t("login") }
                </Typography>
              </Link>
            </Stack>
          </>
        ) : (
          <></>
        ) }
      </Box>

      <ModalBasic
        open={ isSubmitting }
        handleClose={ () => {} }
        title={ t("registration_modal_title") }
        content={ (
          <Box sx={ {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          } }>
            <CircularProgress size={ 50 } />
            <Typography sx={ {
              marginTop: 3
            } }>{ t("registration_modal_description") }</Typography>
          </Box>
        ) }
      />
    </>
  );
};

export default Navigation;
