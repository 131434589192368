import {
  Box,
  BoxProps,
  Chip,
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { HTMLProps } from "react";

export const CardGrid = styled(Grid)(({ theme }) => ({
  "> *": {
    padding: theme.spacing(1.5),
    paddingBlock: theme.spacing(0.25)
  },
  justifyContent: "space-between",

  ".card-cta":{

    ">div":{
      maxWidth:"300px"
    }
  },
  ".card-signature":{
    flexBasis:"content",
    marginLeft:"auto",
    padding:"0",

    ".expiration":{
      display:"none"
    }
  }
}));

export const CardBodyGrid = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "space-between",

  gap:"16px",
  height:"fit-content",

  ">span":{
    color:theme.customColors.accentVariantA,
    fontSize:"1.25rem",
    fontWeight:"700",
    textTransform:"uppercase",
    
    ".icon":{
      marginRight:"4px"
    }
  },
  [theme.breakpoints.down("sm")]:{
    gap:".5rem",

    ">span":{
      fontSize:"0.95rem"
    }
  }
}));

export const DescriptionTypography = styled(Typography)(() => ({
  lineHeight: 1.3125,
  textAlign: "start",
  // width: "95%",

  /* CLAMP TEXT TO 2 LINES */
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "2",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export const ImageBox = styled(Box)<
  BoxProps & HTMLProps<HTMLImageElement>
>(({ ...props }) => ({
// export const ImageBox = styled("img")(() => ({

  aspectRatio: "9 / 7",
  backgroundImage:`url("${props.src}")`,
  backgroundPosition:"center",
  backgroundRepeat:"no-repeat",
  backgroundSize:"cover",
  borderRadius:"8px",
  boxShadow:"16px 16px 22px rgba(0, 0, 0, 0.4);",
  objectFit: "cover",
  padding:  "6px 0 ",
  paddingBlock: " 0 !important",

  height:"236px",
  width: "100%"
}));

export const TitleTypography = styled(Typography)(() => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.3125
}));

export const CategoryChip = styled(Chip)(() => ({
  borderRadius: "4px",
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: 1,
  padding: "8px",
  textTransform: "uppercase"
}));
