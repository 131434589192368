import { GridMain } from "../../components";

export function Landing() {
  return (
    <GridMain>  
      <>
        <p>hero 1</p>
        <p>hero ..</p>
        <p>cta 1</p>
        <p>cta 2</p>
        <p>cta ..</p>
        <p>carosello 1</p>
        <p>carosello 2</p>
        <p>carosello ..</p>
      </>
    </GridMain>
  );
}
