import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserInfoStack } from "./SignupSummary.styles";
import { Chip } from "../../components/Chip";
import { PreferenceStack } from "../../components/PreferenceStack";

export function SignupSummarySector({
  descriptionJobTab,
  descriptionProfessionTab,
  isProfilePage = false,
  profession,
  job
}:{
    descriptionProfessionTab?:string
    descriptionJobTab?:string
    isProfilePage?:boolean,
    profession?:  {
        id: number
        label: string
      } | null,
    job?: {
        id: number
        label: string
      } | null
}){
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  

  return (
    <PreferenceStack
      direction={ isMobile ? "column" : "row" }
      gap={ isProfilePage && isMobile ? 4 : isMobile ? 2 : 0 }
      padding={ 3 }
      width="100%"
      flexWrap={ "wrap" }
      maxWidth={ isProfilePage ? isMobile ? "100%" : "1011px" : undefined }
    >
      
      <UserInfoStack
        paddingRight={ isMobile ? 0 : 5 }
        borderRight={ isMobile || isProfilePage ? "none" : `1px solid ${theme.customColors.border}` }
        gap={ isProfilePage ? 2 : 4 }
      >
        <div className="top-stack">
          <Typography
            fontSize={ isMobile ? "1rem" : "1.5rem" }
            fontWeight={ 700 }
            lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
            width={ "80%" }
          >
            { t("your_profession") }
          </Typography>
                       
        </div>
        {
          profession  ? 
            <>
              {
                descriptionProfessionTab ? 

                  <Typography
                    fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                    fontWeight={ 400 }
                    lineHeight={ isMobile ? (21 / 16) : 1.05 }
                    marginBottom={ isMobile ? "0" : "24px" }
                    width={ "80%" }
                  >
                    { descriptionProfessionTab }
                  </Typography> : null
              }  

              <Chip
                isProfile={ true }
                isDruggable={ false }
                active={ false }
                isDisabled={ false }
                label={ profession.label  }
                name={ profession.label  }

                sx={ {
                  marginTop: "auto !important"
                } }
              />  
            </> : 

            <Typography lineHeight={ isMobile ? "1.3125" : "1.05" }
              fontSize={ isMobile ? "0.875rem" : "1.25rem" }>{ t("no_profession_text") }</Typography>
        }   
        

      </UserInfoStack> 
       
      {
        job ? 
          <UserInfoStack
            gap={ isProfilePage ? 2 : 4 }
            paddingLeft={ isMobile ? 0 : 5 }
            borderTop={ isProfilePage && isMobile ? `1px solid ${theme.customColors.border}` : "none" }
          >

            <div className="top-stack">
              <Typography
                fontSize={ isMobile ? "1rem" : "1.5rem" }
                fontWeight={ 700 }
                lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
                width={ "80%" }
                marginTop={ isProfilePage && isMobile ? "32px" : "0" }
              >
                { t("your_role") }
              </Typography>
            </div>

            
            {
              descriptionJobTab ? 

                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  fontWeight={ 400 }
                  lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  marginBottom={ isMobile ? "0" : "24px" }
                  width={ "80%" }
                >
                  { descriptionJobTab }
                </Typography> : null
            }          
            <Chip
              isProfile={ isProfilePage }
              isDruggable={ false }
              active={ false }
              isDisabled={ false }
              label={ job.label }
              name={ job.label }

              sx={ {
                marginTop: "auto !important"
              } }
            />
                       
          </UserInfoStack> 
          : !profession ? 
            null : 
            <Typography>{ t("no_job_text") }</Typography>
      }

             
    </PreferenceStack>
  );
}
