import { Box, Typography, Divider, useTheme } from "@mui/material";
import {
  useLevel,
  useType
} from "../../hooks";
import type { ContentType, Level } from "../../types";


export function TypeLevel({
  type,
  learningObjectTypology
}: {
    type: ContentType
    learningObjectTypology: Level
}) {

  const typeLabel  = useType(type, learningObjectTypology);
  const learningObjectTypologyLabel = useLevel(learningObjectTypology);
  const theme = useTheme();


  return <Box
    display={ "flex" }>
    <Typography
      color="primary"
      fontSize="0.75rem"
      lineHeight={ 1 }
      margin= "0px 3px 0px 0px"
      sx= { {
        [theme.breakpoints.down("sm")]: {
          color: theme.customColors.backgroundPrimaryCta,
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "14px",
          textTransform: "uppercase"
        }
      } }
     
    >
      { typeLabel }
    </Typography>
    <Divider
      orientation="vertical"
      sx={ {
        height: "14px",
        marginLeft: "2px",
        marginRight: "5px",
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      } }
    />
    <Typography
      color="black"
      fontSize="0.75rem"
      lineHeight={ 1 }
      textTransform="capitalize"
      sx= { {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      } }
    >
      { learningObjectTypologyLabel }
    </Typography>
  </Box>;
}
