import {
  Grid,
  Stack,
  StackProps,
  Typography
} from "@mui/material";
import { common } from "@mui/material/colors";
import {
  alpha,
  styled
} from "@mui/material/styles";
import { ElementType } from "react";

export const HeroCardStack = styled(Stack)<
  & StackProps

  & {
    $coverPublicURL:string
    component: ElementType // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'
  }
>(({ $coverPublicURL, theme }) => ({
  background: `${theme.linearGradients.gradientC}, url(${$coverPublicURL})`,
  backgroundPosition: "",
  backgroundRepeat:"no-repeat",
  backgroundSize: "contain",
  borderRadius:"12px",
  flexDirection:"row",
  justifyContent: "flex-end",
  marginBottom:theme.spacing(6),
  maxWidth: "100%",
  minHeight: "450px",

  "& > *:not(button)" : {
    width: "40%"
  },

  [theme.breakpoints.down("sm")]: {
    background: `${theme.linearGradients.gradientC}, url(${$coverPublicURL})`,
    backgroundPosition: "top",
    backgroundRepeat:"no-repeat",
    backgroundSize: "auto 280px",
    borderRadius:"8px",
    marginBottom:theme.spacing(6),
    marginLeft: "-20px",
    marginRight: "-20px",
    minWidth: "100%",
    padding:0,

    "& > *:not(button)" : {
      width: "100%"
    },

    "& div": {
      alignItems: "start",
      display: "flex",
      padding: "0 40px",

      "& p":{
        textAlign: "start"
      }
    }
  }
}));

export const TextGrid = styled(Grid)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(3),
  justifyContent: "center",

  ">button":{
    width:"fit-content"
  },

  [theme.breakpoints.down("sm")]: {
    alignItems:"end",
    gap:theme.spacing(2),
    justifyContent:"flex-end"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight:"700",
  lineHeight: 7 / 6,
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,

  [theme.breakpoints.down("sm")]: {
    fontSize:"1.5rem",
    textWrap: "balance"
  }
}));


export const DetailTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: 1.1875,

  [theme.breakpoints.down("sm")]: {
    fontSize:"0.875rem",
    marginBottom:"8px",
    textAlign:"center"
  }
}));
