import { Grid, Typography, alpha } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { iconList } from "react-icomoon";
import avatarIcon from "../../assets/avatar.svg";
// import background from "../../assets/profile_bg.png";
import { GridMain, Icon } from "../../components";
import { StyledAvatar } from "../../components/ChatBot/ChatBot.styles";
import { useAuthStore } from "../../stores";
import iconSet from "../../styles/icon-json/selection.json";

export function IconSetPage() {
  const icons = iconList(iconSet);
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  return (
    <>
      <GridMain>
        <Typography
          fontSize={ "50px" }
          padding={ "40px" }
        >
          Icone :
        </Typography>
        <Grid
          item
          container
          display={ "flex" }
          flexWrap={ "wrap" }
          gap={ "50px" }
          padding={ "40px" }
        >
          {
            icons.map((icon,index) => {
              return(
                <Grid
                  alignItems={ "center" }
                  display={ "flex" }
                  flexDirection={ "column" }
                  gap={ "8px" }
                  key={ index }
                  width={ "calc(17% - 50px)" }
                  justifyContent={ "flex-end" }
                  sx={ {
                    [theme.breakpoints.down("md")]: {
                      width:"calc(25% - 50px)"
                    }
                  } }
                >
                  <Icon icon={ icon } size={ 30 }/>
                  <Typography
                    fontSize={ "1rem" }
                    sx={ {
                      wordBreak:"break-all"
                    } }>
                    { icon }
                  </Typography>
                </Grid>
              );
            })
          }
        </Grid>
        <Grid
          width={ "320px" }
          height={ "700px" }
          sx={ {
            // eslint-disable-next-line max-len
            background: `radial-gradient(34px at 52.93% 16px, #0000 112%, ${alpha(theme.customColors.backgroundSecondary,0.2)} 101%) 60% 110% / calc(5.5* 100%) 100% no-repeat`,
            // backgroundImage:`${theme.linearGradients.gradientD}, url(${background})`,
            position:"relative",
            borderRadius:"16px",
            border:"1px solid red",

            "&:before":{
              content:"''",
              position:"absolute"

            }
          } }
  
        >
          <StyledAvatar src={ avatarIcon }/>
        </Grid>
      </GridMain>
    </>
  );
}
