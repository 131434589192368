import {
  Dialog,
  Divider,
  Stack,
  SwipeableDrawer
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  compareAsc,
  format,
  isSameDay
} from "date-fns";
import it from "date-fns/locale/it";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarArrow,
  CalendarContainerStack,
  CurrentDayOfWeekTypography,
  CurrentDayTypography,
  DotSpan,
  LabelTypography,
  SmartLearningButton,
  StyledPickersDay
} from "./CalendarCard.style";
import { SmartLearningModalContent } from "./SmartLearningModalContent.component";
import { switchLocaleImport } from "../../components/Calendar/LocaleLogic";
import { Icon } from "../../components/Icon";
import { useAuthStore, useLanguageStore } from "../../stores";
import type { CalendarDateObject } from "../../types";
import {
  useDayColor,
  withPickersDayProps
} from "../../utils/calendar";

export function CalendarCard({
  dateObjects = [],
  dateNumbersToHighlight,
  selectedExternal,
  setSelectedExternal,
  setSelectedMonth,
  setSelectedYear
}: {
  dateObjects?: CalendarDateObject[]
  dateNumbersToHighlight: number[]
  selectedExternal: Date
  setSelectedExternal: Dispatch<SetStateAction<Date>>
  setSelectedMonth: Dispatch<SetStateAction<number>>
  setSelectedYear: Dispatch<SetStateAction<number>>
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smartLearning = useAuthStore(state => state.smartConfiguration?.smartLearning);
  const currentDay = new Date();
  const [selected, setSelected] = useState<Date>(selectedExternal);

  // const [daysToHighlightState, setDaysToHighlightState] = useState<number[]>([]);
  // const [dateObjectsState, setDateObjectsState] = useState<CalendarDateObject[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const language = useLanguageStore(state => state.language)?.id;

  const [locale, setLocale] = useState<Locale>(it);

  useEffect(() => {
    (async () => {
      try {
        const newLocale = await switchLocaleImport(language);
        setLocale(newLocale.default);
      } catch(err) {
        // eslint-disable-next-line no-console
        console.error("import date-fns locale:", err);
      }
    })();
  }, [language]);

  // useEffect(() => {
  //   if(dateObjects) {
  //     setDateObjectsState(dateObjects);
  //   }
  // }, [dateObjects]);

  // useEffect(() => {
  //   if(daysToHighlight) {
  //     setDaysToHighlightState(daysToHighlight);
  //   }
  // }, [daysToHighlight]);

  return (
    <>
      <CalendarContainerStack>
        <Stack direction="row">
          {
            useMediaQuery(theme.breakpoints.up("sm")) ? (
              <Stack>
                <CurrentDayOfWeekTypography>
                  { format(currentDay, "EEEE", { locale: locale }) }
                </CurrentDayOfWeekTypography>
                <CurrentDayTypography>
                  { format(currentDay, "dd", { locale: locale }) }
                </CurrentDayTypography>
              </Stack>
            ) : null
          }
          <LocalizationProvider 
            dateAdapter={ AdapterDateFns } 
            adapterLocale={ locale }>
            <DateCalendar
              disableHighlightToday={ true }
              value={ selected }
              onChange={ (newDate) => {
                newDate?.setHours(0, 0, 0);
                setSelected(newDate ?? new Date());
                setSelectedExternal(newDate ?? new Date());
              } }
              onMonthChange={ (month) => {
                month.setHours(0, 0, 0);
                setSelected(month);
                setSelectedExternal(month);
                setSelectedMonth(parseInt(format(month, "MM")));
                setSelectedYear(parseInt(format(month, "yyyy")));
              } }
              onYearChange={ (year) => {
                year.setHours(0, 0, 0);
                setSelected(year);
                setSelectedExternal(year);
                setSelectedYear(parseInt(format(year, "yyyy")));
              } }
              slots={ {
                day: SeededCalendarCardDay({ dateNumbersToHighlight, dateObjects, setSelected }),
                leftArrowIcon:
                () => <CalendarArrow icon={ "arrow_left_glow" } iconGlow={ "Icons_arrow_left_glow" } />,
                rightArrowIcon:
                () => <CalendarArrow icon={ "Icons_arrow-right_glow" } iconGlow={ "arrow_right_filled_glow" } />
              } }
              showDaysOutsideCurrentMonth={ false }
            />
          </LocalizationProvider>
        </Stack>
        { smartLearning &&
          <SmartLearningButton
            id={ "smart_learning_button" }
            onClick={ () => setIsOpen(true) }
          >
            <Stack
              direction={ "row" }
              divider={
                <Divider
                  orientation="vertical"
                  sx={ {
                    borderColor: theme.customColors.textPrimary,
                    height: "auto",
                    margin: "0 16px"
                  } }
                />
              }
            >
              <Icon
                color={ theme.customColors.backgroundSaved }
                icon={ "smart_learning" }
                width={ "25px" }
                height={ "32px" }
              />
              <LabelTypography>
                { t("smart_learning") }
              </LabelTypography>
            </Stack>
          </SmartLearningButton>
        }
        { isMobile ?
          <SwipeableDrawer
            anchor={ "bottom" }
            open={ isOpen }
            onClose={ () => setIsOpen(false) }
            onOpen={ () => setIsOpen(true) }
            className={ "smart-drawer" }
            sx={ {
              "& .MuiModal-backdrop": {
                bottom: "60px",

                [theme.breakpoints.down("sm")]:{
                  height: "calc(100% - 120px)"
                }
              },

              "& .MuiPaper-root": {
                background: theme.linearGradients.gradientB,
                border: "1px solid #A6FF03",
                borderColor: theme.customColors.borderAccent,
                borderRadius: "8px 8px 0 0",
                bottom: "60px",
                boxShadow: `0 0 4px 0 ${theme.customColors.borderAccent}`,
                padding: "16px 20px",
                transition: "none",

                [theme.breakpoints.down("sm")]:{
                  height: "calc(100% - 120px)"
                }
              }
            } }
          >
            <SmartLearningModalContent
              onClose={ () => setIsOpen(false) }
              selectedDate={ selected }
            />
          </SwipeableDrawer> :
          <Dialog
            maxWidth={ false }
            open={ isOpen }
            onClose={ (event, reason) => {
              if (reason == "backdropClick" || reason == "escapeKeyDown") {
                return;
              }
              setIsOpen(false);
            } }
            scroll={ "paper" }
            sx={ {
              ".MuiDialog-paper ": {
                background: theme.linearGradients.gradientB,
                borderRadius: "4px",
                bottom: "10%",
                //maxHeight:"calc(100% - 32px)",
                padding: "24px 40px",
                width: "494px"
              }
            } }
          >
            <SmartLearningModalContent
              onClose={ () => setIsOpen(false) }
              selectedDate={ selected }
            />
          </Dialog> }
      </CalendarContainerStack>
    </>
  );
}

function CalendarCardDay({
  dateObjects,
  day,
  dateNumbersToHighlight,
  outsideCurrentMonth,
  selected,
  ...props
}: PickersDayProps<Date> & {
  dateObjects: CalendarDateObject[]
  setSelected?: Dispatch<SetStateAction<Date>>
  dateNumbersToHighlight: number[]
}) {
  let thisDayCourses: CalendarDateObject[] = [];
  if (dateObjects.length > 0) {
    thisDayCourses = dateObjects.filter((dateObject) => {
      const courseDate = format(new Date(dateObject.date.join("/")), "yyyy/MM/dd");
      const thisDayDate = format(day, "yyyy/MM/dd");
      const compare = compareAsc(new Date(thisDayDate), new Date(courseDate)) == 0;
      return compare;
    });
  }
  const activityColor = useDayColor(thisDayCourses);

  const currentDay = new Date();

  const language = useLanguageStore(state => state.language)?.id;

  const [locale, setLocale] = useState<Locale>(it);

  useEffect(() => {
    (async () => {
      try {
        const newLocale = await switchLocaleImport(language);
        setLocale(newLocale.default);
      } catch(err) {
        // eslint-disable-next-line no-console
        console.error("import date-fns locale:", err);
      }
    })();
  }, [language]);


  return <StyledPickersDay
    outsideCurrentMonth={ outsideCurrentMonth }
    day={ day }
    $activityColor={ activityColor }
    $sameDay={ isSameDay(day, currentDay) }
    $selected={ selected ?? false }
    { ...props }
  >
    <span
      style={ {
        height: "1.5625rem",
        lineHeight: "1.5625rem",
        width: "1.5625rem"
      } }
    >
      { format(day, "d", { locale: locale }) }
    </span>
    { /* (
      thisDayCourses.length > 0
        && !outsideCurrentMonth
        && !selected
    ) ? <DotSpan /> : null
    */ }
    {
      (
        dateNumbersToHighlight
        && dateNumbersToHighlight.includes(day.getDate())
        && !outsideCurrentMonth
        && !selected
      ) ? <DotSpan /> : null
    }
  </StyledPickersDay>;
}

/**
 * SeededCalendarDay is a "useless" wrapper, meant to appease typescript and the mui calendar prop types
 * @param CalendarDay props
 * @returns a CalendarDay component, typed with additional mui calendar props
 */
const SeededCalendarCardDay = (props: {
  dateObjects: CalendarDateObject[],
  dateNumbersToHighlight: number[],
  setSelected: Dispatch<SetStateAction<Date>>
}) => withPickersDayProps<{
  dateObjects: CalendarDateObject[]
  dateNumbersToHighlight: number[]
  setSelected: Dispatch<SetStateAction<Date>>
}>({
  component: CalendarCardDay,
  ...props
});

