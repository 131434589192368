import {
  Box,
  Divider,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import {
  alpha,
  styled,
  useTheme
} from "@mui/material/styles";
import DOMPurify from "dompurify";
import { t } from "i18next";
import {
  useEffect,
  useState
} from "react";
import logoFooterFallback from "../../assets/digited_logo.png";
import {
  Link,
  Logo,
  Modal
} from "../../components";
import { useSmartConfiguratorGenericQuery } from "../../queries";
import { useNavbarStore } from "../../stores";
import type { SmartConfigurationGeneric } from "../../types";
import { getError } from "../../utils/error";
import { checkCustomLogicFe, getCustomLogicFeValue, getEnvironmentVariables } from "../../utils/general";

type LinkObject = {
  text: string,
  to: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  margin:0,
  padding:0,
  width:"50%",
  [theme.breakpoints.down("sm")]: {
    width:"100%"
  }
}));

const StyledTypography = styled(Box)(({ theme }) => ({
  fontSize:"0.875rem",
  marginTop:"16px",
  maxWidth:"50%",
  [theme.breakpoints.down("sm")]: {
    fontSize:"0.75rem",
    lineHeight: "0.875rem" /* 116.667% */,
    marginTop: "0",
    maxWidth: "100%",
    paddingBottom: "2rem"
  }
}));
const StyledFooter = styled(Box)(({ theme }) => ({
  background:theme.palette.background?.paper,
  padding:"40px 42px",

  [theme.breakpoints.down("sm")]: {
    marginBottom:"60px",
    padding:"32px 20px"
  }
}));

export function Footer({ isPreLogin = false }) {
  const { digitedLogoFooter, customLogicFe } = getEnvironmentVariables();

  const theme = useTheme();
  const {
    data: smartConfigurationTexts,
    error: smartConfigurationTextsError
  } = useSmartConfiguratorGenericQuery({});
  const [showError, setShowError] = useState(false);
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);


  useEffect(() => {
    if (smartConfigurationTextsError) {
      setShowError(true);
    }
  }, [smartConfigurationTextsError]);

  const { caption, links, privacyText } = getConfigurations(isPreLogin, smartConfigurationTexts);
  return(
    <StyledFooter
      alignItems="center"
      component="footer"
      display="flex"
      minHeight="10vh"
      justifyContent="center"
    >
      <Toolbar disableGutters
        sx={ {
          alignItems:"flex-end",
          flexWrap:"wrap",
          maxWidth: theme.breakpoints.values.max,
          width:"100%"
        } }>
        <StyledBox>
          { !isPreLogin && !checkCustomLogicFe({ envVariable: customLogicFe, strValueToCheck: "hide_logo" }) && <Link
            href="/per-te"
            onClick={ ()=> {
              setNavbarItem("FOR_YOU");
            } }
          >
            <Logo
              alt="logo"
              height="32px"
              width= "auto"
              src={ digitedLogoFooter ?? logoFooterFallback }
            />
          </Link> }

          <Divider
            sx={ {
              background:theme.customColors.border,
              display:"none",
              height:"1px",
              width:"100%",

              margin:"32px 0",

              [theme.breakpoints.down("sm")]: {
                display:"flex"
              }
            } }/>

          { !isPreLogin && <StyledTypography  dangerouslySetInnerHTML={ {
            __html: DOMPurify.sanitize(caption)
          } } >
          </StyledTypography> }
        </StyledBox>
        <StyledBox>
          <Stack
            component={ "ul" }
            direction="row"
            divider={ <Spacer /> }
            flexGrow={ 1 }
            flexWrap={ "wrap" }
            gap={ 2 }
            justifyContent="flex-end"
            sx={ {
              listStyle: "none",
              marginBlock: 0,
              paddingInlineStart: 0,
              [theme.breakpoints.down("sm")]: {
                justifyContent:"flex-start"
              }
            } }
          >
            { links?.map((link, idx) => (
              <li key={ `footerlink--${idx}` }>
                <Link
                  target="_blank"
                  key={ `footerlink--${idx}` } href={ link.to }
                  state={ {
                    privacyPolicy:link.to === "/privacy"
                  } }>
                  <Typography
                    textTransform="capitalize"
                    sx={ {
                      color:theme.customColors.textPrimary,
                      textDecoration:"underline",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                        lineHeight: "1.125rem",
                        textDecoration:"none"
                      }
                    } }>{ link.text }</Typography>
                </Link>
              </li>
            )) }
          </Stack>
          <StyledTypography
            sx={ {
              margin:" 32px 0 0 auto",
              maxWidth:"100%",
              textAlign:"end",

              [theme.breakpoints.down("sm")]: {
                display:"none"
              }
            } }
          >
            { !isPreLogin && privacyText }
          </StyledTypography>
        </StyledBox>

      </Toolbar>
      <Modal
        description={ getError(smartConfigurationTextsError)?.[0]?.message ?? "" }
        open={ showError }
        title={ getError(smartConfigurationTextsError)?.[0]?.name ?? "" }
        onClose={ () => { setShowError(false) } }
      />
    </StyledFooter>
  );

  function getConfigurations(isPreLogin: boolean, configuration?: SmartConfigurationGeneric[]) {
    // const links: LinkObject[] = [/*{ text: "Privacy Policy", to: "/privacy" }*/];
    let links: LinkObject[] = [];
    let customPrivacyPolicy = getCustomLogicFeValue({ envVariable: customLogicFe, strValueToCheck: "privacy_link" });
    let emailSupport = getCustomLogicFeValue({ envVariable: customLogicFe, strValueToCheck: "mail_support" });
    links = isPreLogin ? [ // aggiungo informativa privacy solo in prelogin
      {
        text: t("privacy_agreement_label"),
        to: customPrivacyPolicy || "https://manuals.openlearning.digitedacademy.net/202312_Digit'Ed_Privacy%20policy%20piattaforma%20online_v1.pdf"
      }
    ] : [];

    if (isPreLogin && emailSupport){
      links.push({
        text: t("email_support"),
        to: `mailto:${emailSupport}`
      });
    }
    // eslint-disable-next-line max-len
    let caption = "Digit’Ed S.p.A. a Socio unico <br>Sede legale: Via San Vigilio 1 – 20142 Milano <br>REA MI-1948007 | P.I. 07490560633 <br>Capitale sociale pari a Euro 774.600,00 i.v.";
    let privacyText = "Copyright Digit’Ed S.p.A. - P.IVA 07490560633";

    configuration?.forEach((conf) => {
      switch (conf.key4) {
      case "FooterURL":
        links.push({
          text: conf.value1,
          to: conf.value2
        });
        break;
      case "footerText":
        caption = conf.value1;
        break;
      case "footerSubText":
        privacyText = conf.value1;
        break;
      default:
        break;
      }
    });

    return { caption, links, privacyText };
  }
}

function Spacer(): JSX.Element {
  const theme = useTheme();
  return ( <span style={ { color: alpha(theme.palette.text?.primary ?? "", 1) } }>|</span> );
}
