import { TypeTypography } from "./CalendarCarouselCard.style";
import {
  useLevel
} from "../../hooks";
import type { Level } from "../../types";

export function LevelLabel({ learningObjectTypology }: { learningObjectTypology?: Level}) {
  const learningObjectTypologyLabel = useLevel(learningObjectTypology);
  return <TypeTypography>{ learningObjectTypologyLabel }</TypeTypography>;
}

