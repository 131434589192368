import {
  Box,
  Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  CtaOverlayIcon,
  OverlayDivider,
  OverlayGroupStack,
  OverlayStack,
  OverlayTypography
  // CtaOverlayIcon
} from "./CtaCommunityOverlay.styles";
import { CtaCommunityOverlayProps } from "./CtaCommunityOverlay.types";
// import commentIcon from "../../assets/comment_icon.png";
// import commentFilledIcon from "../../assets/comment_icon_filled.png";
// import likeIcon from "../../assets/like_icon.png";
// import likeFilledIcon from "../../assets/like_icon_filled.png";
import { useLikePostQuery, useLikeQuery, useLikeDeleteQuery } from "../../queries/community";
import { useAuthStore } from "../../stores";

export function CtaCommunityOverlay({
  comments,
  id,
  likes,
  // commentsOpened,
  commentsClickAction,
  selectedFilter,
  selectedTopic
} : CtaCommunityOverlayProps & {
  commentsOpened: boolean,
  commentsClickAction: CallableFunction
}) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { mutate: mutateLike } = useLikePostQuery({ 
    entityId: id,
    selectedFilter:selectedFilter,
    selectedTopic:selectedTopic
    
  });
  const { mutate: mutateDeleteLike } = useLikeDeleteQuery({ 
    entityId: id,
    selectedFilter:selectedFilter,
    selectedTopic:selectedTopic
  });

  
  const userId = useAuthStore(state => state.userData?.id_user);

  const { data: likeData, isLoading } = useLikeQuery({
    entityId: id ?? 0,
    userId: userId?.toString() ?? ""
  });

  // console.log("likeData: ", likeData);

  const liked = !isLoading && !!likeData?.data.find((likeInfo) => likeInfo.user_id == (userId?.toString() ?? ""));

  return (
    <OverlayStack
      component={ "span" }
      gap={ 2 }
    >
      <OverlayGroupStack
        divider={
          <OverlayDivider orientation={ isMobile ? "vertical" : "horizontal" }/>
        }
        gap={ isMobile ? 0.5 : 1.5 }
      >
        <Box
          onClick={ () => commentsClickAction() }
          position={ "relative" }
          sx={ {
            "&:hover":{ 
              filter: `drop-shadow(0px 0px 2px ${theme.customColors.systemPrimary02})` 
            },

            "&:before":{
              background:"transparent",
              content:"''",
              display:"block",
              height:"36px",
              width:"12px",

              pointerEvents: "none",
              position:"absolute",
              right:"48px"
            }
          } }
        >
          <Stack 
            alignItems="center" 
            direction={ isMobile ? "row" : "column" }
            gap={ "4px" }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                padding: "6px 0"
              }
            } }
          >
            <CtaOverlayIcon
              icon={ Number(comments) > 0 ? "comment-filled" : "comment-outlined" }
              size={ isMobile ? 20 :  25 }
            />
            <OverlayTypography>{ comments }</OverlayTypography>
          </Stack>
        </Box>
        <Box
          onClick={ () => {
            if(liked) {
              mutateDeleteLike();
            } else {
              mutateLike();
            }
          } }
          position={ "relative" }
          sx={ {
            "&:hover":{ 
              filter: `drop-shadow(0px 0px 2px ${theme.customColors.systemPrimary02})` 
            },

            "&:before":{
              background:"transparent",
              content:"''",
              display:"block",
              height:"36px",
              width:"12px",

              pointerEvents: "none",
              position:"absolute",
              right:"48px"
            }
          } }
        >
          <Stack 
            alignItems="center" 
            direction={ isMobile ? "row" : "column" }
            gap={ "4px" }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                padding: "6px 0"
              }
            } }
          >
            <CtaOverlayIcon 
              icon={ liked ? "like-filled" : "like-outlined" } 
              size={ isMobile ? 20 : 25 }
            />
            <OverlayTypography>
              { likes }
            </OverlayTypography>
          </Stack>
        </Box>
      </OverlayGroupStack>
    </OverlayStack>
  );

}
