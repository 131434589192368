import { queryClient } from "./router";
import { SELF_SIGNUP_TYPE } from "../consts";

export type UserMgmtParams = {
  basePath: string
  clientId: string
}

export type UserMgmtResponse = {
  id: number,
  name: string,
  self_signup?: SELF_SIGNUP_TYPE,
  self_signup_enabled?: boolean, // @deprecated
  splash: boolean,
  tutorial: boolean,
  tutorial_forced: boolean,
  maintenance_page_global: boolean | null
}

// fetch usata nel loader di register per selfSignup (non si può usare hook useQuery nel loader)
const fetchUserMgmt = async ({ basePath, clientId }: UserMgmtParams): Promise<UserMgmtResponse> =>  {
  const url = `${basePath}/usermgmt/v3/signup/corporates?client_id=${clientId}`;
  return await queryClient.fetchQuery([url], { queryFn: async () => (await fetch(url)).json() });
};

export default fetchUserMgmt;
