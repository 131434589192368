import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import type { SelectProps } from "./Select.types";
import { Icon } from "../../components/Icon";

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  "svg.MuiSelect-iconOpen": {
    fill: theme.palette.secondary?.main
  }
}));

export function Select({
  checkedIcon = false,
  label,
  labelId,
  options,
  ...props
}: SelectProps) {
  const theme = useTheme();

  return (
    <FormControl fullWidth data-testid="form-select">
      <InputLabel id={ labelId } >
        { label }
      </InputLabel>
  
      <StyledSelect
        { ...props }
        labelId={ labelId }
        id={ props.id ?? labelId }
        label={ label }
      >
        {
          options?.map((menuItem, idx) => (
            <MenuItem
              key={ `${label}--${idx}` }
              value={ menuItem.value }
              sx={ {
                borderBottom:`1px solid ${theme.customColors.border}`,
                fontSize: theme.spacing(1.75),
                margin: theme.spacing(0,2),
                minWidth: theme.spacing(12.6),
                padding: theme.spacing(2,0),
                textTransform: "capitalize",

                [theme.breakpoints.down("sm")]:{
                  fontSize: "0.75rem",
                  lineHeight: "0.875rem",
                  margin: theme.spacing(0,1),
                  minHeight: "30px",
                  minWidth: theme.spacing(10.25),
                  padding: theme.spacing(1,0)
                },

                ".checked-icon": {
                  display: "none !important"
                },

                "&:last-child":{
                  border:"none"
                },

                "&.Mui-selected": {
                  background: "none",
                  color: theme.palette.primary?.main,
                  fontWeight: 600,

                  ".checked-icon": {
                    display: "block !important",
                    marginLeft: "auto",
                    marginRight: "20px"
                  }
                }
              } }
            >
              { menuItem.children }
              { checkedIcon && <Icon
                className="checked-icon"
                icon={ "completato" }
                size={ "24px" }
                color={ theme.customColors.systemPrimary02 }
              /> }
            </MenuItem>
          ))
        }
      </StyledSelect>
    </FormControl>
  );
}
