import {
  Modal as MuiModal,
  Stack,
  Typography
} from "@mui/material";
import {
  alpha,
  styled,
  useTheme
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ButtonText } from "..";
import { useAuthStore } from "../../stores";


const StyledModal = styled(MuiModal)(({ theme }) => {
  const contentWidth = "20rem";
  const modalWidth = "25rem";
  const paddingWidth = "2.5rem"; // (modalWidth - contentWidth) / 2
  return {

    backdropFilter:"blur(20px)",
    backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.1),
    borderRadius:theme.spacing(1),
    boxShadow: "24px",
    height: "fit-content",
    left: "50%",
    padding: `1rem ${paddingWidth} 1.5rem 2.5rem`,
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: modalWidth,

    ".MuiModal-backdrop":{
      backdropFilter:"none",
      background:"none",
      borderRadius:theme.spacing(1)
  
    },

    "p": {
      maxWidth: contentWidth,
      overflowWrap:"break-word"
    }
  };});

export function UnAuthorizedModal({
  open
}: {
  open: boolean
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const signOut = useAuthStore(state => state.signOut);
  
  return (
    <StyledModal
      aria-labelledby="modal"
      open={ open }
      slotProps={ {
        backdrop: {
          sx: {
            background: alpha(theme.customColors.backgroundPrimary, 0.95),
            border: `${theme.spacing(0.4)} solid ${theme.customColors.borderPrimaryCta}`,
            boxShadow: `0 0 1rem 0rem ${alpha(theme.customColors.backgroundPrimaryCta, 0.95)},
0 0 2rem 0.25rem ${alpha(theme.customColors.backgroundPrimary, 0.95)}`
          }
        }
      } }
    >
      <Stack
        alignItems="center"
        gap={ 4 }
        sx={ { "&:focus-visible": {
          outline: "none"
        } } }
      >
        <Stack
          alignItems="center"
          gap={ 1 }
        >
          <Typography
            aria-labelledby="modal-modal-title"
            color={ theme.customColors.textPrimaryCta }
            fontSize="2.2rem"
            fontWeight={ 900 }
          >
            { t("attention") }
          </Typography>
          <Typography
            aria-describedby="modal-modal-description"
            fontSize="1.15rem"
          >
            { t("session_expired") }
          </Typography>
        </Stack>
        <ButtonText
          aria-labelledby="modal-modal-continue"
          onClick={ ()=> {
            signOut();
          } }
          sx={ {
            ":hover": { backgroundColor: alpha(theme.customColors.backgroundPrimaryCta, 0.9) }
          } }
        >
          { t("login_return") }
        </ButtonText>
      </Stack>
    </StyledModal>
  );
}
