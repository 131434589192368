import { useTheme , Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CarouselCard, GridMain } from "../../components";
import { StyledIcon } from "../../components/Video/Video.styles";
import { STATUS_LO } from "../../consts";
//import { useType } from "../../hooks";
import { CardsBox } from "../../routes/Category/Category.styles";
import { StyledButtonLink } from "../../routes/NotFound/NotFound.styles";
import { useAuthStore } from "../../stores";
import type {  ReferencedLearningActivity } from "../../types";
import { createEndDate, createStartDate } from "../../utils/general";




export function ActivitiesDetailPage(){
  const { state: routerState } = useLocation();
  const contentActivities = routerState?.contentActivities  as ReferencedLearningActivity[];
  const title = routerState?.title ?? "" as string;
  const areCardsEnabled = routerState?.areCardsEnabled;
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);

  return (
    <>
      <GridMain>
        <Box 
          sx={ { padding: "1rem" } }
        >
          <StyledButtonLink
            aria-label= { t("explore_back") }
            onClick={ ()=> { navigate(-1) } }
            sx={ { padding:0 } }
          >
            <StyledIcon
              icon={ "arrow_left" }
              height={ "20px" }
              width={ "16px" }
            />
            { t("explore_back") }
          </StyledButtonLink>
          <Typography
            variant={ "h1" }
            fontSize={ "2.125rem" }
            fontWeight={ "700" }
            lineHeight={ "1.235" }
            paddingTop={ "32px" }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.5rem",
                marginBottom: "16px",
                paddingLeft: "0",
                paddingRight: "0"
              }
            } }
          >
            { title }
          </Typography>
        </Box>
      
        <Stack 
          maxWidth="calc(100vw - (100vw - 100%))"
          minWidth={ "100%" }
        >
          <CardsBox
            $isLoading={ false }
            sx={ {
              gridTemplateColumns: "repeat(auto-fill, 310px)",
        
              [theme.breakpoints.down("sm")]:{
                gridTemplateColumns: "auto"
              }
            } }
          >
            {
              contentActivities?.map((card) => (
                <CarouselCard
                  booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                  categories={ card.topicTags || [] }
                  courseId={ card.courseId ?? routerState?.courseId }
                  coverPublicURL={ card?.coverPublicURL }
                  coverVideoPublicURL={ card.coverVideoPublicURL }
                  disabled={ !card.isEnable || areCardsEnabled  }
                  duration={ card.duration  } // TODO: api adapter should do this seconds
                  ecm_specialization={ card.ecm_specialization }
                  ecmRegistration={ card.ecmRegistration }
                  endDate={ createEndDate(card) }
                  enrollType={ card.enrollType }
                  expirationDate={  card.expirationDate } //{ card.expirationDate }
                  id={ card.id }
                  isAutoSubscribeEnable={ true } //{ card.isAutoSubscribeEnable }
                  isFavourite={ card.isFavourite }
                  isSurvey={ card.isSurvey }
                  isTest={ card.isTest }
                  isToj={ false }
                  key={ `carousel-detail-zoom--card-${card.id}` }
                  learningObjectType={ card.learningObjectType }
                  learningObjectTypology={ card.learningObjectTypology }
                  percentageOfCompletion={ card.percentageOfCompletion }
                  rootId={ routerState?.rootId ?? routerState?.loRootId }
                  shortDescription={ card.shortDescription }
                  startDate={ createStartDate(card) }
                  small={ true }
                  status={ card.status || "N" }
                  title={ card.title }
                />
              ))
            }
          </CardsBox>
     

        </Stack>
      </GridMain>
    </>
  
  );
}
