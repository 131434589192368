import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useRef } from "react";
import { HomeAlertProps } from "./HomeAlert.types";
import { Icon ,GridMain } from "..";
import { useElementSize } from "../../hooks";

export function HomeAlert({
  HomeAlertText

}: HomeAlertProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen]= useState(true);

  const alertBoxRef = useRef<HTMLDivElement | null>(null);
  const alertTextRef = useRef<HTMLDivElement | null>(null);


  const alertBoxSize = useElementSize(alertBoxRef)?.width || 0;
  const alertTextSize = useElementSize(alertTextRef)?.width || 0;

  const hasOverflow = alertTextSize  > alertBoxSize ;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (

    <Box
      sx={ {

        background:theme.linearGradients.gradientB,
        borderRadius:"2px",
        boxShadow: "8px 8px 15px rgba(0, 0, 0, 0.15)",
        height:isOpen ? "auto" : 0,
        left: "0",
        opacity:isOpen ? 1 : 0,
        padding: isOpen ? theme.spacing(2, 0) : 0,
        transition:"all .2s linear",
        [theme.breakpoints.down("sm")]:{
          display: isOpen? "flex" : "none",
          minHeight: "60px"
        },

        ".icon":{
          cursor: "pointer"
        }
      }
      }
    >
      <GridMain
        alignItems={ "center" }
        display={ "flex" }
        flexDirection={ "row" }
        flexWrap={ "nowrap" }
        gap= { theme.spacing(2) }
        margin={ "0 auto" }
        paddingBottom={ 0 }
        justifyContent= { "space-between" }
        padding={ isMobile ? "0 24px" : "0 20px" }
        sx={ {
          height:isOpen ? "auto" : 0,
          transition:"height .2s linear"
        }
        }
      >
        <Box ref={ alertBoxRef }

          sx={ {
            overflow: "hidden",
            width: "100%"
          } }
        >
          <Typography ref={ alertTextRef }
            onAnimationIteration={ (event)=> {
              const currentAnimation =  event.currentTarget.getAnimations();
              currentAnimation[0].pause();
              setTimeout(()=> {
                currentAnimation[0].play();
              },5000);
            } }
            sx={ {
              animation: hasOverflow ? "textScroll 35s linear infinite"  : "none",
              whiteSpace:"pre",
              width:"fit-content",
              [theme.breakpoints.down("sm")]:{
                lineHeight: "21px"
              },

              "@keyframes textScroll" :{
                "from": {
                  "-moz-transform": "translateX(0)",
                  "-webkit-transform": "translateX(0)",
                  webkitTransform:" translateX(0)"
                },

                "to": {
                  "-moz-transform": "translateX(-100%)",
                  "-webkit-transform": "translateX(-100%)",
                  transform: "translateX(-100%)"

                }
              }
            } }

            color= { theme.customColors.textPrimary }
            fontSize = { theme.spacing(2) }
            fontWeight= { 400 }
            textAlign={ "center" }

          >  { HomeAlertText }
          </Typography>
        </Box>
        <Icon
          icon="close"
          size={ 20 }
          color= { theme.palette.primary?.main }
          max-width="20"
          onClick={ ()=> {
            setIsOpen(!isOpen);
          } }
        />
      </GridMain>
    </Box>

  );
}
