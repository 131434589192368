import {
  Grid,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  StyledFilterBox
} from "./Filters.styles";
import type { FiltersProps } from "./Filters.types";

import { Icon } from "../Icon";
import { Select } from "../Select/Select.component";

/**
 * this component expects filter and setFilter to be set as state of the parent,
 * where an unfiltered case is represented by the empty string
 * @param param0 
 * @returns 
 */
export function Filters<T extends string>({
  filter,
  options,
  setFilter
}: FiltersProps<T>) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
 
  const { t } = useTranslation();


  return (
    <StyledFilterBox>
      <Grid
        className={ `${(filter && filter.length > 0 ) || open ? "filters selected" : " filters" }` }
        alignItems={ "center" }
        display={ "flex" }
        flexDirection={ "column" }
        position={ "relative" }
        sx={ {
          ".MuiFormControl-root ":{
            position:"absolute",
            top:"-1.875rem"
          },
          [theme.breakpoints.down("sm")]:{
            marginRight:"12px"
          }
        } }
      >
        <Icon
          size={ 20 }
          color={ open ? theme.palette.primary?.main : theme.customColors.systemPrimary01 }
          icon={ "icons_filtri" }
          onClick={ ()=> {
            setOpen(!open);}
          }
        />
        <Select
          options={ options }
          id={ t("filter") || undefined }
          multiple
          open={ open }
          onClose={ () => setOpen(false) }
          onOpen={ () => setOpen(true) }
          onChange={ (changeEvent) => {
            if (!setFilter) return;
            setFilter(changeEvent.target.value as [T]);
          } }
          value={ filter }
        />
        <Typography
          sx={ {
            [theme.breakpoints.down("sm")]:{
              fontSize: "0.75rem"
            }
          } }
        >
          { t("filter") }
        </Typography>
      </Grid>
    
    </StyledFilterBox>
  );
}
