import {
  alpha,
  Divider,
  Stack,
  StackProps,
  Theme,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";
import { Icon } from "../Icon";

export const OverlayDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.customColors.border,
  transform: "scaleX(0.6)"
}));

export const OverlayStack = styled(Stack)<
  Omit<
    StackProps,
    "component"
  > & {
    $commentModalOpen:boolean,
    component: ElementType
  }
>(({ $commentModalOpen, theme }) => ({
  cursor:"pointer",
  position: "absolute",
  right: theme.spacing(3),
  top: "50%",
  transform: "translateY(-50%)",
  width: "3rem",
  zIndex: $commentModalOpen ? "14050 !important" : "1150",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "static",
    transform: "none",
    width: "100%"
  }
}));

export const OverlayGroupStack = styled(Stack)<StackProps>(({ theme }) => ({
  //paddingBlock: theme.spacing(1.5),
  position: "relative",

  ">div":{
    //paddingInline: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      minWidth: "48px",
      paddingInline: "8px"
    }
  },

  "&::before": {
    backgroundColor: theme.customColors.systemSecondary05 && alpha(theme.customColors.systemSecondary05, 0.8),
    borderRadius: "0.5rem",
    boxShadow: `0.25rem 0.25rem 0.75rem ${theme.palette.common?.black && alpha(theme.palette.common?.black, 0.25)}`,
    content: "''",
    height: "100%",
    left: 0,
    opacity: 0.6,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: -1
  },

  [theme.breakpoints.down("sm")]: {

    alignItems: "center",
    backgroundColor: theme.customColors.backgroundPrimary,
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    maxHeight: "48px",
    minHeight: "48px",
    paddingBlock: 0,
    paddingInline: theme.spacing(1),
    position: "static",
    transform: "none",

    ">div":{
      paddingBlock: theme.spacing(1),
      paddingInline:0
    },

    "&::before": {
      content: "none"
    },

    "& a": { margin: "auto" },

    "& a div": {
      flexDirection: "row"
    }
  }
}));

export const OverlayTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.systemPrimary02,
  fontSize: "0.75rem"
}));

export const CtaOverlayIcon = styled(Icon)(({ icon, theme }:{icon:string, theme: Theme}) => ({
  "&":
    {
      "&.withpadding": {
        width: "100% !important",
        height: "44px !important",
        padding: "12px"
      },
      "&.star": {
        marginTop: "10px"
      },
      "path":
      {
        fill:  theme.customColors.systemPrimary02,
        stroke:  theme.customColors.systemPrimary02,
        transform:
          icon === "Icons_rating_active"
            ? "scale(1.2) translate(-190px, -190px)"
            : icon ===
            "Star3-5" ? "scale(1.2) translate(-170px, -190px)" :
              icon === "Icons_salva-preferito_glow" ?
                "scale(1.2) translate(-50px, -50px)"
                : undefined
      }
    }
}));
