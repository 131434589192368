import {
  Divider,
  Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import {
  DescriptionTypography,
  TitleTypography
} from "./CalendarCard.style";
import { ButtonText } from "../../components/Button";
import { Icon } from "../../components/Icon";
import { Spinner } from "../../components/Spinner";
import { useSlotsDeleteQuery } from "../../queries/smartLearning";

export function SmartLearningModalDeleteContent({
  onClose,
  slotId
}: {
    onClose?: () => void
    slotId: number
}) {

  const { 
    mutate: deleteSlot,
    isError,
    isIdle,
    isLoading
  } = useSlotsDeleteQuery();

  const theme = useTheme();
  const { t } = useTranslation();
  
  return (
    <>
      { isIdle ?
        <Stack
          gap={ "24px" }
        >
          <Stack
            gap={ "32px" }
            sx={ {
              "& .icon": {
                alignSelf: "flex-end"
              }
            } }
          >
            <Stack
              gap={ "8px" }
            >
              <TitleTypography>
                { t("slot_question_delete") }
              </TitleTypography>
              <DescriptionTypography>
                { t("slot_warning_delete") }
              </DescriptionTypography>
            </Stack>
            <Stack 
              direction={ "row" }
              gap={ "16px" }
              justifyContent={ "center" }
            >
              <ButtonText
                sx={ {
                  backgroundColor: theme.customColors.drawerWidgetBg,
                  border: "1px solid",
                  borderColor: theme.customColors.textPrimaryCta,
                  borderRadius: "2px",
                  color: theme.customColors.textPrimaryCta,
                  fontSize: "16px",
                  fontWeight: "700",
                  height: "40px",
                  lineHeight: "21px",
                  padding: "9.5px 16px",

                  "&.Mui-disabled": {
                    backgroundColor: theme.customColors.drawerWidgetBg,
                    borderColor: theme.customColors.border
                  }
                } }
                onClick={ onClose }
              >
                { t("annul") }
              </ButtonText>
              <ButtonText
                sx={ {
                  borderRadius: "2px",
                  fontSize: "16px",
                  fontWeight: "700",
                  height: "40px",
                  lineHeight: "21px",
                  padding: "9.5px 16px"
                } }
                onClick={ ()=> {
                  deleteSlot({ slotId: slotId });
                } }
              >
                { t("delete") }
              </ButtonText>
            </Stack>
          </Stack>
        </Stack> : 
        <Stack
          gap={ "24px" }
          divider={ <Divider
            sx={ {
              background:theme.customColors.border,
              height:"2px",
              width: "auto"
            } }
          /> }>
          <Stack
            gap={ "24px" }
            sx={ {
              "& .icon": {
                alignSelf: "flex-end"
              }
            } }
          >
            <Icon
              icon={ "close" }
              size={ "19px" }
              cursor="pointer"
              color={ theme.palette.primary?.main }
              onClick={ ()=> {if(onClose){onClose()}} }
            />
            {
              isLoading ?
                <Stack
                  gap={ "8px" }
                >
                  <Spinner
                    size={ 200 }
                    thickness={ 3 }
                  />
                </Stack> : 
                isError ?
                  <Stack
                    gap={ "8px" }
                  >
                    <TitleTypography>
                      { t("error_message") }
                    </TitleTypography>
                    { /* <DescriptionTypography>
                      { "messaggio di errore" }
                    </DescriptionTypography> */ }
                  </Stack> : 
                  <Stack
                    gap={ "8px" }
                  >
                    <TitleTypography>
                      { t("slot_deleted") }
                    </TitleTypography>
                  </Stack>
            }
          </Stack>
          
        </Stack>
      }
    </>
  );
}
