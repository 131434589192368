import {
  Box,
  Typography,
  useTheme
} from "@mui/material";
import { FilterGrid } from "./CarouselCardFilters.styles";
import { CardFilterProps } from "./CarouselCardFilters.types";
import { filterIcon } from "../../utils/styles";

export function CardFilter({
  color,
  coverPublicURL,
  // muted=false,
  title,
  onClickhandler,
  selected=false,
  ...props
}: CardFilterProps) {
  const theme = useTheme();



  return (
    <FilterGrid
      container
      onClick={ onClickhandler }
      { ...props }
    >
      <Box 
        sx={ {
          background: `url(${coverPublicURL})`,
          backgroundSize: "contain",
          // eslint-disable-next-line max-len
          filter:selected ? filterIcon({
            color:theme.customColors.systemSecondary01 } ):filterIcon({
            color:theme.customColors.textPrimary } ),
          height: "30px",
          width: "30px"
        } }
      />
      <Typography
        component="span"
        color={ selected ? theme.customColors.systemSecondary01 : color }
        fontWeight={ selected ? 700 : 400 }
        textTransform="capitalize"
      >
        { title }
      </Typography>
    </FilterGrid>
  );

  // function getColor() {
  //   if (muted) {
  //     return theme.customColors.textDisabled;
  //   } else if (selected) {
  //     return theme.customColors.textMandatory;
  //   } else {
  //     return color;
  //   }
  // }
}
