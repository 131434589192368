import { TabsContentProfileProps } from "./TabsContentProfile.types";
import {
  ContentGoals,
  ContentKnowledge,
  ContentPreferences,
  ContentSector
} from "../../components";
import { 
  TAB_ITEMS_PROFILE 
} from "../../consts";

export function TabsContentProfile({
 
  tabTypeProfile
}: TabsContentProfileProps) {
  switch (tabTypeProfile) {
  case TAB_ITEMS_PROFILE.GOALS:
    return (
      <ContentGoals
      />
    );
  case TAB_ITEMS_PROFILE.SECTOR:
    return (
      <ContentSector/>
    );

  case TAB_ITEMS_PROFILE.KNOWLEDGE:
    return (
      <ContentKnowledge/>
    );

  case TAB_ITEMS_PROFILE.PREFERENCES:
    return (
      <ContentPreferences/>
    );

  default:
    return (
      <></>
    );
  }
}
