//Creazione store for localStorage, 3 keyword for searchHistory
import { create } from "zustand";
import type { ContentType, Level } from "../types";

type Search = {
  id: number | null,
  title: string,
  learningObjectType: ContentType | null,
  learningObjectTypology: Level | null
}

type LatestResearch = {
    latestSearch: Search[]
};

type LatestResearchActions = {
  readonly pushSearch: (props: Search) => void
}

const serializedSearchState = localStorage.getItem("searchState");
const searchStore = !serializedSearchState ? [] : JSON.parse(atob(serializedSearchState));
const defaultSearchState: LatestResearch = {
  latestSearch: []
};
const initialLatestSearch = Object.assign(defaultSearchState.latestSearch, searchStore);
const initialSearchState = {
  latestSearch: initialLatestSearch
};

export const useSearchStore = create<LatestResearch & LatestResearchActions>((set, get) => ({
  ...initialSearchState,
  pushSearch: ({ 
    id,
    title,
    learningObjectType,
    learningObjectTypology
  }: Search) => {
    const newState = get();
    newState.latestSearch = Array.from(newState.latestSearch);
    const findIndex = newState.latestSearch.findIndex((search) => search.title == title);
    if (newState.latestSearch.length >= 3 && findIndex == -1) {
      newState.latestSearch.pop();
    } else if(findIndex != -1) {
      newState.latestSearch.splice(findIndex, 1);
    }
    newState.latestSearch.unshift({
      id,
      learningObjectType,
      learningObjectTypology,
      title
    });
    localStorage.setItem("searchState", btoa(JSON.stringify(newState.latestSearch)));
    set({ latestSearch: newState.latestSearch });
  }
}));
