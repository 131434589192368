import { 
//   Box,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { OpenCalendarArrow } from "./CalendarCard.style";
import { Icon } from "../../components/Icon";

export function SelectTime({
  disabled,
  onChange,
  options,
  value
}: {
    disabled: boolean
    onChange: (event: SelectChangeEvent) => void
    options: Array<{timeOption: string, bookable:boolean, disabled: boolean}>
    value: string
}) {

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Select
      disabled={ disabled }
      value={ value }
      onChange={ onChange }
      onOpen={ () => setIsOpen(true) }
      onClose={ () => setIsOpen(false) }
      IconComponent={ (props) => (
        <OpenCalendarArrow 
          isOpen={ isOpen }
          { ...props }
        />
      ) }
      startAdornment={
        <InputAdornment position="start">
          <Icon
            icon={ "Icons_time" }
            size={ "17px" }
            color={ theme.customColors.systemSecondary05Alternative }
          />
        </InputAdornment>
      }
      sx={ {
        backgroundColor: theme.customColors.backgroundSecondary,
        borderRadius: "2px",
        color: theme.customColors.textInput,
        fontSize: "14px",
        height: "40px",
        lineHeight: "18px",
        paddingLeft: "16px",

        "& .MuiOutlinedInput-notchedOutline": {
          border: "none"
        }
        
      } }
      MenuProps={ {
        className:"from-select-menu",
        sx: {
          "& .MuiMenu-paper": {
            backgroundColor: theme.customColors.backgroundSecondary,
            border: "none",
            borderRadius: "2px",
            // height: "240px",
            margin: "3px 0",
            overflow: "hidden",
            padding: "4px 4px 4px 16px",
            
            "& ul.MuiList-root": {
              height: "240px",
              overflowY: "auto",

              "::-webkit-scrollbar": {
                width: "4px"
              },
              "::-webkit-scrollbar-thumb":{
                backgroundColor: theme.customColors.systemDisabled,
                borderRadius: "8px"
              }
            }
          }
        }
      } }
    >
      <MenuItem 
        key={ "from-select" }
        value={ value }
        disabled={ true }
        sx={ { display: "none" } }
      >
        { "select" }
      </MenuItem>
      {
        options.map((option) => (
          <MenuItem 
            key={ `from-${option.timeOption}` }
            value={ option.timeOption }
            disabled={ option.disabled }
            sx={ {
              color: theme.customColors.textInput,
              fontSize: "14px",
              lineHeight: "18px",
              padding: "4px 0"
            } }
          >
            { option.timeOption }
          </MenuItem>
        ))
      }
    </Select>
  );
}
