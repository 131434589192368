import { Avatar, Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentBox, ContentInputBase } from "./CreateContentCommunity.styles";
import { CreateContentCommunityProps } from "./CreateContentCommunity.types";
import { ButtonText, CreateContentModal } from "../../components";
import { POST_TYPE } from "../../consts";
import { useGetProfileImageQuery } from "../../queries";
import { useAuthStore } from "../../stores";
import { stringAvatar } from "../../utils/community";

export function CreateContentCommunity({
  selectedFilter,
  selectedTopic
}: CreateContentCommunityProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
 
  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  // get profile img
  const { data: profileImage  } = useGetProfileImageQuery({});
  const anchorEl = document.body;
  const userData = useAuthStore(state => state.userData);




  return (
    <Box>
      <ContentBox
      >
        <Stack
          direction={ "row" }
          gap={ isMobile ? "8px" : "16px" }
        >
          <Avatar
            alt={ `${userData?.name} ${userData?.surname} ` }
            { ...stringAvatar(`${userData?.name} ${userData?.surname} `) }

            src={ profileImage }
          />
          <ContentInputBase 
            onClick={ () => handleClickOpen() }
            placeholder={ `${t("add_new_post")}` }
            readOnly={ true }
            inputProps={ { "aria-label": t("add_new_post") } }
          />
          <ButtonText
            onClick={ () => handleClickOpen() }
            sx={ {
              borderRadius:"4px",
              minWidth:"42px",

              [theme.breakpoints.down("sm")]: {
                fontSize: "12px"
              }
            } }
          >
            { t("publish") }
          </ButtonText>
        </Stack>
      </ContentBox>
      {
        isOpen ? (
          <CreateContentModal 
            selectedFilter={ selectedFilter }
            selectedTopic={ selectedTopic }
            anchorEl={ anchorEl }
            anchorOrigin={ {
              horizontal: "center",
              vertical: "center"
            } }
            transformOrigin={ {
              horizontal: "center",
              vertical: "top"
            } }
            closeDrawer={ handleClose }
            onClose={  handleClose }
            open={ isOpen }
            postType={ POST_TYPE.FREE }

          />
        ) : null
      }
    </Box>
  );

}
