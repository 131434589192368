import {
  Modal as MuiModal,
  Stack,
  Typography
} from "@mui/material";
import {
  alpha,
  styled,
  useTheme
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonText } from "../../components";
import { CustomRegex } from "../../utils/regex";


const StyledModal = styled(MuiModal)(({ theme }) => {
  const contentWidth = "20rem";
  const modalWidth = "25rem";
  const paddingWidth = "2.5rem"; // (modalWidth - contentWidth) / 2
  return {

    backdropFilter:"blur(20px)",
    backgroundColor:alpha(theme.customColors.backgroundSecondary, 0.1),
    borderRadius:theme.spacing(1),
    boxShadow: "24px",
    height: "fit-content",
    left: "50%",
    padding: `1rem ${paddingWidth} 1.5rem 2.5rem`,
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: modalWidth,

    ".MuiModal-backdrop":{
      backdropFilter:"none",
      background:"none",
      borderRadius:theme.spacing(1)
  
    },

    "p": {
      maxWidth: contentWidth,
      overflowWrap:"break-word"
    }
  };});

export function Modal({
  description,
  onClose,
  open,
  title
}: {
  description: string
  onClose: () => void
  open: boolean
  title: string
}) {
  const { t } = useTranslation();
  const { pathname, state:routerState } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  
  const currentLocation = CustomRegex.matchDirectChild(pathname, "signup");

  return (
    <StyledModal
      aria-labelledby="modal"
      open={ open }
      onClose={ onClose }
      slotProps={ {
        backdrop: {
          sx: {
            background: alpha(theme.customColors.backgroundPrimary, 0.95),
            border: `${theme.spacing(0.4)} solid ${theme.customColors.borderPrimaryCta}`,
            boxShadow: `0 0 1rem 0rem ${alpha(theme.customColors.backgroundPrimaryCta, 0.95)},
0 0 2rem 0.25rem ${alpha(theme.customColors.backgroundPrimary, 0.95)}`
          }
        }
      } }
    >
      <Stack
        alignItems="center"
        gap={ 4 }
        sx={ { "&:focus-visible": {
          outline: "none"
        } } }
      >
        <Stack
          alignItems="center"
          gap={ 1 }
        >
          <Typography
            aria-labelledby="modal-modal-title"
            color={ theme.customColors.textPrimaryCta }
            fontSize="2.2rem"
            fontWeight={ 900 }
          >
            { title }
          </Typography>
          <Typography
            aria-describedby="modal-modal-description"
            fontSize="1.15rem"
          >
            { description }
          </Typography>
        </Stack>
        <ButtonText
          aria-labelledby="modal-modal-continue"
          onClick={ ()=> {
            onClose();
            if(
              ( currentLocation === "job" || 
            currentLocation === "profession" || 
            currentLocation === "skills"     ||
            currentLocation === "capabilities" ||
            currentLocation === "preferences")
            ){
              if(routerState.first){
                navigate("/logout");
              }
              else {
                navigate("/profile");
              }
       
            }
          
          } }
          sx={ {
            ":hover": { backgroundColor: alpha(theme.customColors.backgroundPrimaryCta, 0.9) }
          } }
        >
          { t("continue") }
        </ButtonText>
      </Stack>
    </StyledModal>
  );
}
