import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { VisuallyHiddenInputStyle } from "./style";
import { VisuallyHiddenInputProps } from "./type";
import { Icon } from "../../Icon";

export const VisuallyHiddenInput: React.FC<VisuallyHiddenInputProps> =
  ({ name, label, type, accept, onChange, error, errorMessage }) => {
    const theme = useTheme();

    return (
      <Box
        component="label"
        htmlFor={ name }
        sx={ {
          "&:hover": {
            backgroundColor: theme.customColors.backgroundPrimaryCta
          },
          alignItems: "center",
          border: `1px dashed ${ theme.customColors.border }`,
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: theme.spacing(1.2),
          paddingRight: theme.spacing(0.8),
          minHeight: "56px",
          position: "relative"
        } }
      >
        <Typography variant="body1" component="span">
          { label }
        </Typography>
        <IconButton component="span" sx={ { marginLeft: 1 } }>
          <Icon icon="Icons_upload" size={ 15 } />
        </IconButton>
        <VisuallyHiddenInputStyle
          id={ name }
          type={ type }
          accept={ accept }
          onChange={ onChange }
        />
        { error && (
          <div
            style={ {
              color: "red",
              left: 0,
              position: "absolute",
              right: "0%",
              top: "100%"
            } }
          >
            { errorMessage }
          </div>
        ) }
      </Box>
    );
  };
