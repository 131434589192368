import { BoxProps } from "@mui/material";
import { useRef } from "react";
import { StyledCoverVideo } from "./VideoCover.styles";


type VideoCoverProps = BoxProps & {
  img?:string
  loop?:boolean
  onEnded?:()=> void
  url?:string
}
export function VideoCover({
  img,
  loop=true,
  onEnded,
  url,
  ...props
}:  VideoCoverProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const video = videoRef.current as HTMLVideoElement;

  if(video){
    video.onended = ( ) => {
      if(onEnded){
        onEnded();
      }
    };
  }

  return (
    <StyledCoverVideo
      $image={ !url ? img : undefined }
      { ...props }
    >
      <video
        ref={ videoRef }
        
        autoPlay={ true }
        loop={ loop }
        muted={ true }
        src={ url || "" }
      />
    </StyledCoverVideo>
  );


}
