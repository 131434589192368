import {
  styled,
  Button,
  Stack,
  Typography
} from "@mui/material";

export const ActionButton = styled(Button)(() => ({
  fontSize: "16px",
  fontWeight: "500",
  justifyContent: "flex-start",
  padding: "0",
  textTransform: "none",

  "&:hover": {
    background: "none"
  }
}));

export const CommentStack = styled(Stack)(({ theme }) => ({
  backgroundColor: `${theme.customColors.backgroundSecondaryAlternative}1A`,
  borderRadius: "0.5rem",
  padding: theme.spacing(2)
}));

export const NameTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "21px",

  [theme.breakpoints.down("sm")]:{
    fontSize: "14px"
  }
}));

export const CommentTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21px",

  [theme.breakpoints.down("sm")]:{
    fontSize: "14px"
  }
}));

export const CreatedTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "14px"
}));

export const AuthorTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: `${theme.customColors.textPrimaryCta}99`,
  borderRadius: "4px",
  color: theme.customColors.textPrimary,
  fontSize: "12px",
  fontWeight: "700",
  lineHeight: "14px",
  padding: "5px 8px"
}));
