import {
  Grid,
  GridProps
} from "@mui/material";
  
import { styled } from "@mui/material/styles";
import { 
  TopicCardBody, 
  TopicCardContainerStack 
} from "../../components/TopicCard/TopicCard.styles";
  
export const StyledCommentGrid = styled(Grid)<GridProps>(({ theme }) => ({

  alignItems:"center", 
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(4),
  paddingBottom:"80px",
 
  [`${TopicCardContainerStack}`]:{
    width:"900px"
  },
  [`${TopicCardBody}`]:{
    gap:theme.spacing(1),
    minHeight:"unset"
  },

  [theme.breakpoints.down("sm")]:{
    paddingBottom:"40px"
  }
  
}));
