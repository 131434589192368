import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { IndustriesBox, StyledIndustryBoxItem } from "./Profile.Profession.styles";
import {
  GuardedLink, Spinner
} from "..";
import { useIntersectionObserver } from "../../hooks";
import { useUserProfessionsQuery, type OnBoardingItemType } from "../../queries/onboarding";
import { useAuthStore } from "../../stores";
import { StyledBottomBar } from "../OnBoarding/OnBoarding.styles";
import { Progress } from "../Progress/Progress.component";


export function ProfileProfession() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { state:routerState }= useLocation();
  const userDataProfession = useAuthStore(state => state.userInfo?.profession) ?? routerState.profession;



  const [professionSelected, setProfessionSelected] = useState<OnBoardingItemType | null>(
    userDataProfession ? {
      id:userDataProfession.id,
      name:userDataProfession.label
    } as OnBoardingItemType : null
  );

  const { t }= useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const pageSize = 6;

  const {
    data:industries,
    hasNextPage:hasMoreProfession,
    fetchNextPage: fetchMoreProfession,
    isFetchingNextPage:isFetchingMoreProfession,
    isInitialLoading:isLoading
  } = useUserProfessionsQuery({ pageSize:pageSize });

  const industriesContainerRef = useRef<HTMLDivElement | null>(null);

  const intersectionObserver = useIntersectionObserver({
    callback: () => {
      hasMoreProfession && !isFetchingMoreProfession && fetchMoreProfession(); 
    },
    container: industriesContainerRef
  });

  const ApiIndustries = industries?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as OnBoardingItemType[]);

  return (

    <Stack
      alignItems="center"
      gap={ isMobile ? 2 : 6 }
      height="100%"
      justifyContent="space-between"
      width={ "100%" }

    >
      <Stack
        gap={ isMobile ? 1 :  2 }
        width={ "100%" }
      >
        <Typography
          color="primary"
          fontSize={ isMobile ? "1.25rem" :  "2rem" }
          fontWeight={ 700 }
          lineHeight={ 19 / 16 }
          textAlign={ "start" }
        >
          { t("your_industry") }
        </Typography>
        <Typography
          fontSize={ isMobile ? "0.875rem" : "1.25rem" }
          lineHeight={ 21 / 20 }
          textAlign={ "start" }
        >
          { t("select_industry") }
        </Typography>
      </Stack>

      {
        isLoading  ? 
          <Spinner/> : 
          null
      }
      {
        ApiIndustries?.length ? 
          <IndustriesBox
            className="scrollable"
            ref={ industriesContainerRef }
          >
            {
              ApiIndustries.map((industry, index)=> (

                <IndustryBoxItem
                  key={ `industry-${index}` }
                  observer={ intersectionObserver }
                  observeIndustry={ (index + (pageSize - 1 ))  === ApiIndustries.length   }
                  setIndustrySelected={ setProfessionSelected }
                  industrySelected={ professionSelected }
                  industryItem={ industry }
                />
              )
              )
            }

            {
              isFetchingMoreProfession ? 
                <Spinner/> : 
                null
            }
          </IndustriesBox> : 
          null
      }
     
      <StyledBottomBar>
   
        <Progress 
          percentageOfCompletion={ !professionSelected ?  "0%" : "20%" }
        />

        <GuardedLink
          disabled={ !professionSelected } // TODO: check allowed values from set
          href={ "../job" }
          onClick={ () => true }
          state= { {
            ...routerState,
            onBoardingPercentage:"20%",
            profession:{ ...professionSelected },
            step: 1
          } }
        >
          { t("continue") }
        </GuardedLink>
      
      </StyledBottomBar>
     
    </Stack>
  );

}
function IndustryBoxItem({
  observer,
  observeIndustry=false,
  industryItem,
  industrySelected,
  setIndustrySelected
  // ...props
} : {
  observer: IntersectionObserver | null
  observeIndustry?: boolean
  industryItem: OnBoardingItemType
  industrySelected:OnBoardingItemType | null
  setIndustrySelected:Dispatch<SetStateAction<OnBoardingItemType| null>>
}){

  const selfRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    observeIndustry && selfRef.current && observer?.observe(selfRef.current);
  }, [observer, observeIndustry]);

  return (
    <StyledIndustryBoxItem 
      ref={ selfRef }
      bg={ industryItem.cover }
      isActive={  industrySelected?.id === industryItem.id }
    
      onClick={ ()=>{
        setIndustrySelected({ ...industryItem });
      } }
    >
      <Typography>{ industryItem.name }</Typography>
    </StyledIndustryBoxItem>
  );
}
