import { styled } from "@mui/material";
// import { forwardRef } from "react";
import IcoMoon, { IconProps } from "react-icomoon";
import iconSet from "../../styles/icon-json/selection.json";

/**
 * react-icomoon does NOT implement forwardRefs with the IcoMoon
 * component, if you need a ref to the icon place it in a box
 * and get a reference for the wrapper...
 */

// const _Icon = forwardRef<SVGElement, IconProps>(
//   function _Icon(
//     props,
//     ref
//   ) {
//     return (
//       <IcoMoon
//         className={ "icon" }
//         iconSet={ iconSet }
//         ref={ ref }
//         { ...props }
//       />
//     );
//   }
// );

// export const Icon = styled(_Icon)(({ color }) => ({
//   path: {
//     fill: color
//   }
// }));

const StyledIcoMoon = styled(IcoMoon)(({ color }) => ({
  "&.icon path": {
    fill: color
  }
}));

export function Icon (props: IconProps){
  return(
    <StyledIcoMoon
      className={ "icon" }
      iconSet={ iconSet }
      { ...props }
    />
  );
}
