import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { DataPickerCstProps } from "./types";

const DataPickerCst: React.FC<DataPickerCstProps> = (props) => {
  const {
    id,
    label,
    value = null,
    onChange,
    customWidth,
    error,
    errorMessage,
    disabled,
    required,
    sx,
    maxDate,
    minDate
  } = props;

  const theme = useTheme();

  return (
    <Box
      id={ `${ id }` }
      sx={ {
        display: "flex",
        minWidth: "200px",
        width: customWidth ? customWidth : "auto",
        ...sx
      } }
    >
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <DatePicker
          label={ label }
          value={ value }
          onChange={ onChange }
          maxDate={ maxDate ? maxDate : null }
          minDate={ minDate ? minDate : null }
          sx={ {
            width: "100%",
            marginBottom: "5px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.customColors.border,
                minHeight: "58px"
              }
            },
            "& .MuiFormLabel-root": {
              color: theme.customColors.textPrimary,
              top: "65%", // Position label vertically centered
              transform: "translateY(-50%)", // Move label up to vertically center it
              left: "12px", // Optional: adjust based on padding for visual alignment
              position: "absolute", // Ensure it doesn't shift with content
              pointerEvents: "none" // Prevent interaction
            },
            "& .MuiInputLabel-root.Mui-focused": {
              top: 0, // Reset position when focused
              transform: "translateY(-100%) scale(0.75)", // Scale and move label up
              left: "12px",
              color: theme.customColors.borderPrimaryCta
            },
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              top: 15, // When input is focused or has content
              transform: "translateY(-100%) scale(0.75)",
              left: "15px"
            },
            "& .MuiSvgIcon-root": {
              color: theme.customColors.textPrimary,
              marginRight: 0, // Adjust margin if needed
              position: "relative", // Position adjustment if required
              top: "8px"
            },
            "& .MuiInputBase-input": {
              transform: "translateY(20%)"
            }
          } }
          slotProps={ {
            textField: {
              required: required,
              size: "small",
              error: error,
              helperText: error ? errorMessage : ""
            },
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10]
                  }
                }
              ],
              sx: {
                zIndex: 1500,
                "& .MuiPickersArrowSwitcher-root button": {
                  color: "white"
                },
                "& .MuiIconButton-root": {
                  color: "white"
                }
              }
            }
          } }
          disabled={ disabled }
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DataPickerCst;
