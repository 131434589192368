import {
  Stack,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { t } from "i18next";
import {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import {
  CarouselBox,
  HeroBox
} from "./Explore.styles";
import {
  BigCarouselCard,
  CardFilter,
  Carousel,
  CarouselCard,
  CarouselSkeleton,
  FiltersCarousel,
  GridMain,
  Spinner,
  WidgetDrawer
} from "../../components";
import {
  STATUS_LO,
  CAROUSEL_TYPE,
  PAGE_NAME,
  SERVICE_TYPES
} from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import {
  RelativePath,
  useContentQuery,
  useStructuresQuery,
  useTopicsQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import type {
  LearningObject,
  MetaData
} from "../../types";
import { createEndDate, createStartDate } from "../../utils/general";

export function Explore() {
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [selectedTopics, setSelectedTopics] = useState<number[]>([]);
  const { data: pageStructure } = useStructuresQuery({
    pageName: PAGE_NAME.EXPLORE_OVERVIEW
  });
  const {
    data: allTopics,
    isFetching: topicsAreFetching
  } = useTopicsQuery({});
  const [topCarousel, setTopCarousel] = useState<RelativePath | null>(null);
  const [carousels, setCarousels] = useState<RelativePath[]>([]);
  const itemsRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { setNavigation } = useContext(UserNavigationContext);

  const topicsQueryString = selectedTopics.reduce(
    /* the uri arrives with ..topics= */
    (currentTopicsQuery, currentTopic, currentTopicIndex) => {
      if (currentTopicIndex == 0 && currentTopic) {
        return currentTopicsQuery + currentTopic;
      } else {
        return currentTopicsQuery + "&topics=" + currentTopic;
      }
    },
    ""
  );

  useEffect(() => {
    setNavigation && setNavigation([]);
    setNavigation && setNavigation(["/esplora"]);
  }, [location.pathname]);

  useEffect(() => {
    const newTopCarousel = pageStructure?.relativePaths.find((relativePath) => (
      relativePath.serviceType === SERVICE_TYPES.sliderTopContentExplore
    ));
    setTopCarousel(newTopCarousel ?? null);

    const newCarousels = pageStructure?.relativePaths.filter((relativePath) => (
      relativePath.serviceType !== SERVICE_TYPES.sliderTopContentExplore
    ));

    const filteredCarousels = newCarousels?.filter((c) => c.serviceType !== "carConteSpeciDurata");

    setCarousels(filteredCarousels ?? []);

  }, [pageStructure]);

  return (
    <GridMain >
      <WidgetDrawer />
      {
        topCarousel ? (
          <ExploreBigCarousel
            key={ `carousel--${topCarousel.id}` }
            relativePath={ topCarousel }
            topicsQueryString= { topicsQueryString }
          />
        ) : null
      }
      {
        topicsAreFetching ? (
          <Spinner width="100%"/>
        ) : (
          <FiltersCarousel label={ "filters-carousel" }>
            {
              allTopics?.map((topic) => (
                (topic.learning_object_associated > 0) ? (
                  <CardFilter
                    key={ `explore-filter--filter-${topic.id}` }
                    coverPublicURL={ topic.url.public_url }
                    onClickhandler={ () => {
                      const currentSelectedCategories = [...selectedTopics];
                      if (selectedTopics.includes(topic.id)) {
                        currentSelectedCategories.splice(
                          currentSelectedCategories.indexOf(topic.id),
                          1
                        );
                      } else {
                        currentSelectedCategories.push(topic.id);
                        currentSelectedCategories.sort();
                      }
                      setSelectedTopics(currentSelectedCategories);
                    } }
                    title={ topic.name }
                    selected={ selectedTopics.includes(topic.id) }
                  />
                ) : null
              ))
            }
          </FiltersCarousel>
        )
      }
      <Stack
        minHeight={ "calc(100vh - 550px)" }
        ref={ containerRef }
        width={ "100%" }
        gap={ isMobile ? 3 : undefined }
      >
        {
          carousels.map((carousel, index) => (
            <ExploreCarousel
              key={ `carousel--${carousel.id}` }
              reference={ index === carousels.length - 1 ? itemsRef : null } 
              relativePath={ carousel }
              topicsQueryString= { topicsQueryString }
            />
          ))
        }
      </Stack>
    </GridMain>
  );
}

function ExploreBigCarousel({
  relativePath
} : {
  relativePath: RelativePath
  topicsQueryString: string
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: carouselCards,
    isFetching
  } = useContentQuery<
    & Record<string, LearningObject[]>
    & { metadata: MetaData }
  >({
    path: relativePath.apiPath,
    queryKey: [relativePath.title],

    // additional params
    topics: ""
  });

  const responseKey = carouselCards ? (Object.keys(carouselCards).find((key) => key !== "metadata") ?? "") : "";
  const carouselData = (carouselCards && responseKey) ? carouselCards[responseKey] : [];

  return isFetching ? ( 
    <Spinner
      // similar height of BigCarouselCard
      minHeight={ isMobile ? "366px" : "450px" }
      size={ 90 }
      width="100%"
    /> 
  ) : (
    (carouselCards && carouselCards.metadata?.totalElements > 0) ? (
      <HeroBox>
        <Carousel
          carouselType={ CAROUSEL_TYPE.HERO }
          label="hero-explorePage"
        >
          {
            carouselData?.map((card, index) => (
              <BigCarouselCard
                key={ `explore-hero--card-${index}` }
                coverPublicURL={ card.coverPublicURL }
                duration={ card.duration }
                editionNumber={ card?.editionNumber }
                endDate={ createEndDate(card) }
                enrollType={ card.enrollType }
                expirationDate={ card.expirationDate }
                id={ card.id }
                isToj={ card.isToj }
                isHighLightEdition={ card?.editions?.[0]?.isHighlightEdition ?? false }
                labelMoreInfo={ true }
                learningObjectType={ card.learningObjectType }
                learningObjectTypology={ card.learningObjectTypology }
                percentageOfCompletion={ card.percentageOfCompletion }
                shortDescription={ card.shortDescription }
                simple={ true }
                startDate={ createStartDate(card) }
                status={ card.status || "N" }
                title={ card.title }
              />
            )
            ) 
          }
        </Carousel>
      </HeroBox>
    ) : null
  );
}

function ExploreCarousel({
  relativePath,
  reference,
  topicsQueryString
} : {
  relativePath: RelativePath
  reference:RefObject<HTMLDivElement> | null
  topicsQueryString: string
}) {
  const {
    data: carouselCards,
    isFetching
  } = useContentQuery<
    & Record<string, LearningObject[]>
    & { metadata: MetaData }
  >({
    path: relativePath.apiPath,
    queryKey: [relativePath.title, topicsQueryString],

    // additional params
    topics: topicsQueryString
  });

  const title = (relativePath?.serviceType && relativePath?.serviceType !== "visCarCategory") ? 
    t(relativePath?.serviceType) : relativePath?.title;

  const responseKey = carouselCards ? (Object.keys(carouselCards).find((key) => key !== "metadata") ?? "") : "";
  const carouselData = (carouselCards && responseKey) ? carouselCards[responseKey] : [];

  return (
    (isFetching ?  <CarouselSkeleton></CarouselSkeleton> : 
      (Number(carouselCards?.metadata?.totalElements) > 0) ? (
        <CarouselBox
          ref={ reference }   >
          <Carousel
            carouselType={ CAROUSEL_TYPE.ZOOM }
            id={ `explore-zoom-${relativePath.title}-${relativePath.id}` }
            // label is used as unique id for url param, rehydration from url navigation, etc.
            hasNavigation={ true }
            isFetching= { isFetching }
            label={ relativePath.title }
            title={ title }
            itemsLength={ carouselCards?.metadata?.totalElements }
          >
            {
              carouselData?.map((card) => (
                <CarouselCard
                  enrollType={ card.enrollType }
                  booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                  categories={ card.topicTags || [] }
                  coverPublicURL={ card?.coverPublicURL?.toString() || "" }
                  coverVideoPublicURL={ card.videoPublicURL }
                  disabled={ false }
                  duration={ card.duration }
                  ecm_specialization={ card.ecm_specialization }
                  ecmRegistration={ card.ecmRegistration }
                  ecmType={ card.ecmType }
                  editionNumber={ card.editionNumber }
                  endDate={ createEndDate(card) }
                  expirationDate={ card.expirationDate }
                  id={ card.id }
                  isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                  isFavourite={ card.isFavourite }
                  isMandatory={ card.isMandatory }
                  isSurvey={ card.isSurvey }
                  isTest={ card.isTest }
                  isToj={ card.isToj }
                  key={ `carousel-explore-zoom-${relativePath.title.replaceAll(" ", "-")}--card-${card.id}` }
                  learningObjectType={ card.learningObjectType }
                  learningObjectTypology={ card.learningObjectTypology  }
                  percentageOfCompletion={ card.percentageOfCompletion }
                  shortDescription={ card.shortDescription }
                  startDate={ createStartDate(card)  }
                  status={ card.status  || "N" }
                  title={ card.title }
                // updateQueryFunction={ (method)=> {
                //   setTimeout(()=> {
                //     setMandatoryUpdate(`${method}-${card.id}`);
                //   },100);
                // } }
                />
              ))
            }
          </Carousel>
        </CarouselBox>
      
      ) : null
    )
  );
}
