import {
  format
} from "date-fns";

export function getLastUpdateDate(
  assignmentDate?: string | number | null, 
  exerciseDate?: string | number | null,
  reviewDate?: string | number | null) {
  
  const assignmentDateValue = assignmentDate ? new Date(assignmentDate).getTime() : 0;
  const exerciseDateValue = exerciseDate  ? new Date(exerciseDate).getTime() : 0;
  const reviewDateValue = reviewDate ? new Date(reviewDate).getTime() : 0;

  const lastDateValue = Math.max(assignmentDateValue, exerciseDateValue, reviewDateValue);

  const lastDate = lastDateValue ?  format(new Date(lastDateValue), "dd/MM/yyyy") : null;

  return lastDate;
}

export function getLastUpdateProfilePic(
  assignmentDate?: string | null, 
  exerciseDate?: string | number | null, 
  reviewDate?: string | number | null) {

  const assignmentDateValue = assignmentDate ? new Date(assignmentDate).getTime() : 0;
  const exerciseDateValue = exerciseDate  ? new Date(exerciseDate).getTime() : 0;
  const reviewDateValue = reviewDate ? new Date(reviewDate).getTime() : 0;
  
  const lastDateValue = Math.max(assignmentDateValue, exerciseDateValue, reviewDateValue);

  if(lastDateValue === exerciseDateValue || lastDateValue === reviewDateValue ) {
    return "teacher";
  } else if(lastDateValue === assignmentDateValue) {
    return "learner";
  } else {
    return null;
  }
}
