import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoalButton, GoalSelectionGroup } from "./GoalSelection.styles";
import { GoalSelectionProps } from "./GoalSelection.types";
import { useGetLearnerGoalsConfigsQuery, useGetLearnerGoalsQuery } from  "../../queries";
    
export function GoalSelection({
  changePlan,
  isOnBoarding
} : GoalSelectionProps) {
  const { t } = useTranslation();
  const theme = useTheme();


  const { data: planGoalsConfigs, isSuccess: planGoalsConfigsSuccess } = useGetLearnerGoalsConfigsQuery();
  const { data: userGoals, isSuccess: userGoalsIsSuccess } = useGetLearnerGoalsQuery();
  const [selectedPlan, setSelectedPlan] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("max"));

  function handleSetPlan(plan: string) {
    if(plan === selectedPlan && !!isOnBoarding && !userGoals?.goal.plan) {
      setSelectedPlan("");
      changePlan("");
    } else {
      setSelectedPlan(plan);
      changePlan(plan);
    }
  }
  
  const learningPlansObj : { [key: string]: { hoursPerMonth: number } } = planGoalsConfigs ? 
    planGoalsConfigs?.yourGoals.learningPlans as { [key: string]: { hoursPerMonth: number } } : {};

  const planGoalsList: { plan: string, hoursPerMonth: number }[] = 
    Object.keys(learningPlansObj).map(plan => ({
      hoursPerMonth: learningPlansObj[plan].hoursPerMonth,
      plan
    }));

  useEffect(()=> {
    if(userGoalsIsSuccess && userGoals && userGoals.goal.plan) {
      setSelectedPlan(userGoals.goal.plan.toLowerCase());
    }
  }, [userGoalsIsSuccess, userGoals]
  );

  return (

    <Stack
      alignItems={ isMobile || isOnBoarding ? "flex-start" : "center" }
      gap={ isOnBoarding ? 2 : 3 }
    >
      <Typography
        color={ theme.customColors.textPrimary }
        fontSize={ isMobile ? "1.5rem" : "2rem" }
        fontWeight={ 700 }
        lineHeight={ isMobile ? "1.875rem" : "2.25rem" }
        textAlign={ isMobile || isOnBoarding ? "start" : "center" }
        paddingBottom={ "8px" }
      >
        { t("goal_selection_title") }
      </Typography>
      <Typography
        color={ theme.customColors.textPrimary }
        fontSize={ isMobile ? "1rem" : "1.25rem" }
        fontWeight={ 400 }
        paddingBottom={ isOnBoarding ? "0px" : isMobile ? "16px" : "24px" }
        lineHeight={ isMobile? "1.313rem" : "1.5rem" }
        textAlign={ isMobile || isOnBoarding ? "start" : "center" }

        sx={ {
          borderBottom: isOnBoarding ? "none" : `1px solid ${theme.customColors.border}`
        } }
      >
        { t("goal_selection_description", { count: planGoalsConfigs?.yourGoals?.durationInMonths ?? 0 }) }
      </Typography>
      { planGoalsConfigs && planGoalsConfigsSuccess && 
        <GoalSelectionGroup 
          sx={ {
            paddingTop: isOnBoarding ? "16px" : isMobile ? "32px" : "24px",
            width: isMobile ? "100%" : isLaptop && isOnBoarding ? "60%" : "100%"
          } }>
          { 
            planGoalsList.map((item) => (
              <GoalButton 
                key={ item.plan } 
                onClick={ () => handleSetPlan(item.plan) 
                } 
                className={ `${selectedPlan === item.plan ? "selected" : ""}` }>
                <span className="label-plan">{ item.plan }</span>
                <span className="label-hours">
                  { `${item.hoursPerMonth} ${item.hoursPerMonth > 1 ? 
                    t("goal_plan_hours") : t("goal_plan_hour")}` }</span>
              </GoalButton>
            ))
          }
        </GoalSelectionGroup> }
    </Stack>
  );
}
