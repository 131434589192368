import { Modal, styled } from "@mui/material";

export const StyledModal = styled(Modal)(({ theme }) => ({
  alignItems:"center",
  display:"flex",
  justifyContent:"center",
   

  ">.MuiStack-root":{
    alignItems:"center",
    background: theme.linearGradients.gradientB,
    borderRadius:theme.spacing(0.5),
    display:"flex",
    flexDirection:"column",
    gap:theme.spacing(1),
    height: "fit-content",
    padding: theme.spacing(3,5),
    width: 630,

    ".description":{
      fontSize:theme.spacing(2.5)
    },
    ".title":{
      fontSize:theme.spacing(4),
      fontWeight:700
    }
  }
}));
