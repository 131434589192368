import { Button, ButtonProps } from "@mui/material";
import React from "react";

const ButtonCst: React.FC<
  ButtonProps & {
  id: string;
  text?: string;
}
> = (props) => {
  const { text, children, color = "primary" } = props;

  return (
    <Button { ...props } color={ color }>
      { children }
      { text && text }
    </Button>
  );
};

export default ButtonCst;
