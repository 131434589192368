import {
  Typography
} from "@mui/material";

import { styled } from "@mui/material/styles";

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: 1.21875,
  marginBottom:"8px",
  textTransform: "capitalize",

  [theme.breakpoints.down("sm")]:{
    fontSize: "0.875rem",
    marginBottom:"0"
  }

}));





