import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { 
  Dispatch,
  SetStateAction,
  useEffect, 
  useRef, 
  useState 
} from "react";

import { useDrag, useDrop , DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  ExecutivesKnowledgeAreasContainer,
  PersonalSkillsBox,
  PersonalSkillsItemsBox,
  StyledBucketGridItem, 
  StyledBucketsContainer, 
  StyledKnowledgeAreasAccordion,
  StyledKnowledgeWrapper,
  StyledOpenPersonalSkillsButton,
  StyledSwipeableDrawer
} from "./DndStack.styles";
import type {
  BucketGridItemProps,
  AssignedSkill,
  DndStackProps,
  CategoriesSkills
} from "./DndStack.types";
import { Icon } from "../../components/Icon";
import { SelectInput } from "../../components/SelectInput";
import { useIntersectionObserver } from "../../hooks";
import { 
  type OnBoardingItemType, 
  useUserDomainsPersonal,
  useUserDomainsArea
} from "../../queries/onboarding";
import { useAuthStore } from "../../stores";
import {
  Chip,
  ChipProps
} from "../Chip";

export function DndStack({
  callBack,
  buckets,
  state,
  stateAreas,
  ...props
}: DndStackProps) {
  /** IMPORTANT,
   * as it is, this component is heavily coupled with ProfileSetup, if a more
   * generic version is required additional development should be expected
   */
  const accordionContainerRef = useRef<HTMLDivElement | null>(null);
  const accordionIntersectionObserver = useIntersectionObserver({
    callback: () => {callBack()},
    container: accordionContainerRef
  });

  const theme = useTheme();
  const { t }= useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [skills, setSkills] = state;
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const [activeSkill, setActiveSkill] = useState<string>();
  const isTouchDevice = matchMedia("(hover: none)").matches;
  const isOnlyPersonal = corporateInfo?.onboarding_personal_job && !corporateInfo?.onboarding_executive_job;
  const personalSkillToOpen = corporateInfo?.onboarding_personal_job && !isOnlyPersonal;
  const [openedPersonalSkills, setOpenedPersonalSkills]=useState(Boolean(isOnlyPersonal));
  const [domainSearched, setDomainSearched] = useState<string>("");
  const [domainSelected, setDomainSelected] = useState<Pick<OnBoardingItemType, "id" | "name"> | null>(null);
  const [drawerOpened, setDrawerOpened] = useState(false);


  const pageSize = 6;
 

  const {
    data:personalDomains,
    hasNextPage:hasMorePersonalDomains,
    isFetchingNextPage:isFetchingOthersDomains,
    fetchNextPage:fetchOthersDomains
  } = useUserDomainsPersonal({ 
    domainsSearched:domainSearched,
    pageSize:pageSize
  });


  const ApiPersonalDomains = personalDomains?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as OnBoardingItemType[]);

 
  useEffect(()=>{
    if(domainSelected){
      setSkills((prev) => {
        if(prev && prev?.length > 0  ){
          return prev.concat([domainSelected].map((category) => ({
            active:undefined,
            bucket:null,
            disabled:false,
            id: category.id.toString(),
            name:category.name,
            type:"personal"
          })) as unknown as AssignedSkill[]);
        }
        else {
          return [domainSelected].map((category) => ({
            active:undefined,
            bucket:null,
            disabled:false,
            id: category.id.toString(),
            name:category.name,
            type:"personal"
          })) as unknown as AssignedSkill[];
        }
      });
   
    }

  },[
    domainSelected, 
    setSkills
  ]);

  return (
    <Stack
      className={ isMobile ? "scrollable" : "" }
      gap={ 2 }
      display={ "flex" }
      flexDirection={ isMobile ? "column" :  "row" }
      marginTop={ isMobile ? 3 : 0 }
      { ...props }
    >
      <DndProvider backend={ isTouchDevice ? TouchBackend : HTML5Backend }>
        <StyledKnowledgeWrapper>
          {
            personalSkillToOpen ? 
           
              <StyledOpenPersonalSkillsButton
                className={ `visible ${openedPersonalSkills ? "right" : "left"}` }
                onClick={ () => { setOpenedPersonalSkills(!openedPersonalSkills)} }
              >
                <Icon 
                  color={ theme.customColors.systemSecondary05 }
                  icon={ openedPersonalSkills ? "arrow_widget_open" : "arrow_widget_close" } 
                  size={ 18 }/>
              </StyledOpenPersonalSkillsButton>
          
              :  null
          }
       
          {
            openedPersonalSkills  ? 

              <PersonalSkillsBox
                gap={ isMobile ? 2 : 3 }
                height="100%"
              >
            
                <Stack
                  gap={ 0.5 }
                  width={ "100%" }
                >
                  <span className="tag">{ t("optional") }</span>
                  <Typography
                    color={ theme.customColors.textPrimary }
                    fontSize={ isMobile ? theme.spacing(2.5) : theme.spacing(3) }
                    fontWeight={ 700 }
                    lineHeight={ 19 / 16 }
                    textAlign={ "start" }
                  >
                    { t("personal_skills_add") }
                  </Typography>
                </Stack>
                <Typography
                  fontSize={ isMobile ? theme.spacing(1.75) : theme.spacing(2) }
                  lineHeight={ 21 / 20 }
                  textAlign={ "start" }
                >
                  { t("personal_skills_text") }
                </Typography>
          
                <SelectInput
                  type="cancel"
                  fullWidth
                  callBack={ () => {  
                    hasMorePersonalDomains && !isFetchingOthersDomains && fetchOthersDomains(); } }
                  resultsApi={ ApiPersonalDomains }
                  label={ t("search_knowledge") }
                  setValue={ setDomainSearched }
                  value={ domainSearched }
                  setValueSelected={ setDomainSelected }
                  onChange={ (target)=> {
                    setDomainSearched(target.currentTarget.value);
                    setDomainSelected(null);
                  } }
                  onMouseDown={ ()=> {
                    setDomainSearched("");
                    setDomainSelected(null);
          
                  } }
                  placeholder={ t("placeholder_skills") }
                />
                
                {
                  skills && skills.length > 0  ? 
                    <PersonalSkillsItemsBox>
                      {
                        skills
                          .filter((item) => ( item.bucket === null && item.type === "personal" ))
                          .map((skill) => (
                            <DraggableCategory
                              key={ `category-pill--${skill.id}` }
                              label={ skill.name }
                              onMouseDown={ () =>{
                                setActiveSkill(skill.id);
                              } }
                              onTouchStart={ ()=> {
                                if(isTouchDevice){
                                  setActiveSkill(skill.id);
                                  setDrawerOpened(true);
                                }
                              } }
                              onClick={ ()=> {
                                if(isMobile){
                                  setActiveSkill(skill?.id );

                                }
                             
                              } }
                              onMouseUp={ () => setActiveSkill("") }
                              sx={ {
                                textAlign: "center",
                                width: (isMobile ? "90%" : undefined)
                              } }
                              { ...skill }
                            />
                          ))
                      }
                    </PersonalSkillsItemsBox> : 
                    null
                }
                
              </PersonalSkillsBox> : 
              
              stateAreas?.length ? 

                <ExecutivesKnowledgeAreasContainer ref={ accordionContainerRef } 
                  className="scrollable">
             
                  {
                    stateAreas?.map((area, idx)=> (
                      <KnowledgeAreasAccordion
                        observerAccordion={ accordionIntersectionObserver }
                        observeAreaAccordion={ (idx + 1) === (stateAreas.length - 2) }
                        onDropMobile={ ()=> {
                          setDrawerOpened(true);
                        } }
                        setSkills={ setSkills }
                        setActiveSkill={ setActiveSkill }
                        skills={ skills }
                        area={ area }
                        key={ `accordion-knloedge-area-${area.id}` }
                      />
                    ))
                  }
                </ExecutivesKnowledgeAreasContainer> : 
                <Typography padding={ "20px" }>{ t("no_executive_knowledge") }</Typography>
              
           
          }
            
        </StyledKnowledgeWrapper>

        <StyledBucketsContainer
        >
          {
            buckets
              .map((bucket, idx) => (
                <BucketGridItem
                  item
                  key={ `bucket--${bucket.id}` }
                  disabled={ openedPersonalSkills }
                  contents={
                    skills
                      ?.filter(({ bucket }) => bucket === idx) ?? null
                  }
                  onDelete={ (item)=> {
                    setBucket(null, item);
                  } }
                  onDrop={ () => {
                    setBucket(idx, activeSkill);
                  } }
                  setActiveCategory={ setActiveSkill }
                  title= { bucket.label }
                />
              ))
          }
        </StyledBucketsContainer> 
  
      </DndProvider>

      <StyledSwipeableDrawer
        id={ `area-drawer-${activeSkill}` }
        anchor="bottom"
        onOpen={ () => {} }
        onClose={ () => setDrawerOpened(false) }
        open={ drawerOpened }
        variant={ "temporary" }
      >
        <Typography
          fontWeight={ 700 }
          fontSize={ theme.spacing(3) }
        >{ t("level_select") ! }</Typography>
        {
          buckets.map((bucket,idx)=> (
            <Box 
              key={ `${idx}-${bucket.label}` }
              borderRadius={ 1 }
              border={ `1px solid ${theme.customColors.borderTag}` }
              padding={ 1 }
              width={ "100%" }
              textAlign={ "center" }
              onClick={ ()=> {
                setBucket(idx, activeSkill);
                setDrawerOpened(false);
              } }
            >
              { bucket.label }
            </Box>
          ))
        }
      </StyledSwipeableDrawer>
    </Stack>
  );

  function setBucket(
    bucket: number | null,
    targetId?: string
  ) {
    setSkills((prev) => {
      if(prev){
        prev.find(({ id }) => (id === targetId))!.bucket = bucket;
        return [ ...prev ];
      }
      else {
        return null;
      }
     
    });
  }
 
}

function DraggableCategory({
  ...props
}: ChipProps) {
  const [, drag] = useDrag(() => ({
    /**
     * since I need the isDragging state in the parent component I can't really
     * make use of the hook state without incurring in issues with concurrency
     * of state updates
     */
    // const [{ isDragging }, drag] = useDrag(() => ({
    collect: (monitor) => ({ isDragging: Boolean(monitor.isDragging()) }),
    type: "skills"
  }));

  return (
    <Chip
      ref={ drag }
      { ...props }
    />
  );
}

function BucketGridItem({
  disabled,
  contents,
  onDelete,
  onDrop,
  setActiveCategory,
  title,
  ...props
}: BucketGridItemProps) {


  const [{ isOver }, drop] = useDrop({
    accept: "skills",
    collect: (monitor) => ({
      isOver: Boolean(monitor.isOver())
    }),
    drop: onDrop
  });
  const theme = useTheme();
  const { t }= useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledBucketGridItem
      className="scrollable"
      $active={ isOver }
      alignItems="center"
      ref={ drop }
      { ...props }
    >

      <Stack
        direction={ "column" }
        gap={ isMobile ? 0.5 : 1 }
        width={ "fit-content" }
      >
        <Typography
          fontSize={ isMobile ? theme.spacing(2) : theme.spacing(2.5) }
          fontWeight={ 700 }
          lineHeight={ "normal" }
          sx={ { "user-select": "none" } }
        >
          { title }
        </Typography>
        <Typography
          alignItems="center"
          fontSize={ isMobile ? theme.spacing(1.5) : theme.spacing(1.75) }
          lineHeight={ "normal" }
          sx={ { "user-select": "none" } }
        >
          { t("element", { count: contents?.length ?? 0 }) }
        </Typography>
    
      </Stack>
      {
        contents?.map((category) => (
          <DraggableCategory
            key={ `category-pill--${category.id}` }
            onMouseDown={ () => setActiveCategory(category.id) }
            onMouseUp={ () => {setActiveCategory("")} }
            { ...category }
            onClickIcon={ ()=>{ 
              if(onDelete && category.id){
                onDelete(category.id);
              }
            } }
            active={ true }
            label={ category.name }
            disabled={ category.type === "personal" ? !disabled : disabled }
            isDisabled={ category.type === "personal" ? !disabled : disabled }
            sx={ {
              "&:nth-child(odd)":{
                alignSelf:"flex-end"
              },
              fontWeight:700
            } }
          />
        ))
      } 

       
    </StyledBucketGridItem>
  );
}

function KnowledgeAreasAccordion(
  {
    area,
    skills,
    observerAccordion,
    observeAreaAccordion,
    onDropMobile,
    setSkills,
    setActiveSkill
  }:
{
  area:OnBoardingItemType
  skills:CategoriesSkills
  observerAccordion: IntersectionObserver | null
  observeAreaAccordion?: boolean
  onDropMobile:()=>void
  setSkills:Dispatch<SetStateAction<CategoriesSkills>>
  setActiveSkill: (value?: string) => void,
})
{
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTouchDevice = matchMedia("(hover: none)").matches;
  const [enableId, setEnableId]=useState<number | undefined>(undefined);
  const userInfo = useAuthStore(state => state.userInfo);
  const location  = useLocation();
  const job = location?.state?.job?.id ?? userInfo?.job?.id;

  const pageSize = 6;

  const selfRefAreas = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    observeAreaAccordion && selfRefAreas.current && observerAccordion?.observe(selfRefAreas.current);
  }, [observerAccordion, observeAreaAccordion]);

  

  const { 
    data:areaDomains, 
    hasNextPage:hasMoreAreasDomains, 
    isFetchingNextPage:isFetchingMoreAreasDomains,
    fetchNextPage:fetchMoreAreasDomains,
    isSuccess
  }=useUserDomainsArea({
    enabled: Boolean(enableId) && Boolean(area.id === enableId),
    job:job,
    knowledgeAreadId:area.id,
    pageSize:pageSize
  });

  const selfRefAreasDomains = useRef<HTMLDivElement | null>(null);
  const areasDomainsContainerRef = useRef<HTMLDivElement | null>(null);
  const areasDomainsIntersectionObserver = useIntersectionObserver({
    callback: () => { hasMoreAreasDomains && !isFetchingMoreAreasDomains && enableId && fetchMoreAreasDomains() },
    container: areasDomainsContainerRef
  });
  
  useEffect(() => {
    selfRefAreasDomains.current && 
    areasDomainsIntersectionObserver?.observe(selfRefAreasDomains.current);
  }, [ 
    areasDomainsIntersectionObserver
  ]);

  const ApiAreasDomains = areaDomains?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as OnBoardingItemType[]);

  useEffect(()=> {
    if(ApiAreasDomains && enableId && enableId === area.id){
      setSkills((prev) => {
        if(prev && prev?.length > 0  ){
          return prev.concat(ApiAreasDomains.flatMap((category) => {
            const isCategoryPresent = prev.find((item) => 
              item.id === category.id.toString()
            );
            const isCategorySelected = prev.find((item) => 
              item.id === category.id.toString() && item.bucket !== null 
            );
            if(!isCategorySelected && !isCategoryPresent){
              return     {
                active:undefined,
                bucket:null,
                disabled:false,
                id: category.id.toString(),
                knowledgeArea:area.id,
                name:category.name,
                type:"executive"
              };
            }
            else {
              return [];
            }
          }) as unknown as AssignedSkill[]);
        }
        else {
          return ApiAreasDomains.map((category) => ({
            active:undefined,
            bucket:null,
            disabled:false,
            id: category.id.toString(),
            knowledgeArea:area.id,
            name:category.name,
            type:"executive"
          })) as unknown as AssignedSkill[];
        }
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    isFetchingMoreAreasDomains, 
    isSuccess, 
    enableId
  ]);


  return( 
    <StyledKnowledgeAreasAccordion ref={ selfRefAreas }
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={ <Icon icon="dropdown" size={ 20 }/> }
        id={ `accordion-knloedge-area-${area.id}` }
        onClick={ ()=> {
          setEnableId(area.id);
        } }
      >
        <Typography 
          fontSize={ theme.spacing(2.5) }
          fontWeight={ 700 }
        >{ area.name }</Typography>
      </AccordionSummary >
      <AccordionDetails className="scrollable">
        {
          skills
            ?.filter(({ bucket, knowledgeArea, type }) => ( 
              bucket === null && 
              type === "executive" && 
              knowledgeArea === area.id))
            .map((skill, idx) => (
              <DraggableCategory
                ref={ (idx + 1) === (skills
                  ?.filter(({ bucket }) => ( bucket === null )).length - 2) ? selfRefAreasDomains : null }

                key={ `category-pill--${skill.id}` }
                label={ skill.name }
                onClick={ ()=> {
                  if(isMobile){
                    setActiveSkill(skill?.id ?? "");
                    onDropMobile();
                  }
               
                } }
                onMouseDown={ () =>{ 
                  setActiveSkill(skill?.id ?? "");
                } }
          
                onTouchStart={ ()=> {
                  if(isTouchDevice){
                    setActiveSkill(skill.id ?? "");
                  }
                } }
                onMouseUp={ () => setActiveSkill("") }
                sx={ {
                  textAlign: "center",
                  width: (isMobile ? "90%" : undefined)
                } }
                { ...skill }
              />
            ))
        }

      </AccordionDetails>
    </StyledKnowledgeAreasAccordion >
  );
}
