import { 
  Dialog,
  Stack, 
  Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { 
  useEffect, useState
} from "react";

import { useTranslation } from "react-i18next";
import {
  DeleteContentModalBox
} from "./DeleteContentModal.styles";
import { DeleteContentModalProps } from "./DeleteContentModal.types";

import { 
  ButtonText, 
  Icon, 
  Spinner
} from "..";
import { TitleTypography } from "../../components/CalendarCard/CalendarCard.style";
import { POST_TYPE } from "../../consts";
import { 
  usePostDeleteQuery
} from "../../queries/community";


export function DeleteContentModal({
  closeDrawer,
  id,
  isModerator,
  onClose,
  open,
  postType,
  selectedFilter,
  selectedTopic,
  ...props
}: DeleteContentModalProps) {
  const { t } = useTranslation();


  const theme = useTheme();
  const [showSuccess, setShowSuccess]=useState(false);



  const { 
    mutate: mutateDeleteTopic,
    isLoading: deleteIsloading,
    isIdle: deleteIsIdle,
    isSuccess: deleteIsSuccess,
    isError: deleteIsError,
    reset:deleteReset
  } = usePostDeleteQuery( 
    postType === POST_TYPE.FREE ? 
      { 
        id:id,
        isModerator:isModerator,
        postType:postType,
        selectedFilter:selectedFilter,
        selectedTopic:selectedTopic 
      } : {
        id:id,
        isModerator:isModerator,
        loId:"loId" in props ? props.loId : "",
        postType:postType,
        selectedFilter:selectedFilter ,
        selectedTopic:selectedTopic
      });
  
  useEffect(() => {
    if(!deleteIsIdle && !deleteIsloading && deleteIsSuccess) {
      setShowSuccess(true);
      deleteReset();

      setTimeout(()=> {
        closeDrawer();
      },500);
    
    }
  },[
    closeDrawer, 
    deleteIsIdle, 
    deleteIsSuccess, 
    deleteIsloading, 
    deleteReset]);

  return (
    <>
      
      <Dialog
        maxWidth={ false }
        open={ open }
        onClose={ onClose }
        scroll={ "paper" }
        sx={ {
          ".MuiDialog-paper ": {
            background: theme.linearGradients.gradientB,
            borderRadius:"8px",
            maxHeight:"calc(100% - 32px)",
            padding: "10px"
          }
        } }
      >
     
        <DeleteContentModalBox
          container
          { ...props }
        >
          { 
            !deleteIsIdle && deleteIsloading ?
              ( <Spinner/>) :
              !deleteIsIdle && deleteIsError ?
                ( <TitleTypography>{ t("error_occurred") }</TitleTypography>) :
                showSuccess ? 
                  <Stack 
                    alignItems={ "center" }
                    flexDirection={ "row" } 
                    gap={ 3 }>
                    <Icon 
                      color={ theme.customColors.accentVariantB }
                      icon="Icons_modulo-completato" 
                      size={ 30 }
                    />
                    { t("deleted_post") }
                 
                  </Stack> : 
                  ( <Stack gap={ "24px" }>
                    <Typography
                      sx={ {
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "24px",
                        textAlign: "center",
                        width: "537px"
                      } }
                    >{ t("delete_question") + " ?" }
                    </Typography>
                    <Stack 
                      direction={ "row" } 
                      justifyContent={ "center" } 
                      gap={ "24px" }>
                      <ButtonText onClick={ () =>{ 
                        mutateDeleteTopic();
                      } }
                      >
                        { t("yes") }
                      </ButtonText>
                      <ButtonText onClick={ () => { 
                        closeDrawer();
                      } }>
                        { t("no") }
                      </ButtonText>
                    </Stack>
                  </Stack>)
          }
       
        </DeleteContentModalBox>
      </Dialog>
    </>
  );

}
