import { StyledCommentGrid } from "./TabsContent.styles";
import { CardShareTabs } from "../../components";
import type { SharedPost } from "../../queries/community.types";

export function TabsContentCommunity({
  loId,
  sharedPosts
} : {
  loId:string,
  sharedPosts: SharedPost
}
) {
  return (

    <StyledCommentGrid>
      {
        sharedPosts?.data.map((sharedPost) =>{
          const ownerUserId = sharedPost.owner_id;
          const ownerUser = sharedPosts.enrichedInformation?.users?.find(user => user.owner_id === ownerUserId);
          return (
            <CardShareTabs
              postId={ sharedPost.id }
              topicOwner={ ownerUserId }
              key={ `comment--${sharedPost.id}` }
              body={ sharedPost.body }
              created={ sharedPost.created }
              loId={ loId }
              ownerName={ `${ownerUser?.name} ${ownerUser?.surname}` || "" }
              ownerImage={ ownerUser?.profileImageUrl }
            />
          );}
        )
      }
    </StyledCommentGrid>
   
  );
}
