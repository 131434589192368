import React, { createContext, useContext, useReducer, ReactNode } from "react";
import { RegisterGlobalStateInterface } from "../components/Register/types";

interface GlobalStateProviderProps {
  children: ReactNode;
}

interface Action {
  type: "SET_INPUT_VALUES" | "RESET_INPUT_VALUES";
  payload?: {
    key: string;
    value: number | string | unknown;
  };
}

const RegisterContext = createContext<
  | {
  state: RegisterGlobalStateInterface;
  dispatch: React.Dispatch<Action>;
}
  | undefined
>(undefined);

const initialState: RegisterGlobalStateInterface = {};

const reducer = (
  state: RegisterGlobalStateInterface,
  action: Action
): RegisterGlobalStateInterface => {
  switch (action.type) {
  case "SET_INPUT_VALUES":
    return {
      ...state,
      [action.payload!.key]: action.payload!.value
    };
  case "RESET_INPUT_VALUES":
    return initialState;
  default:
    return state;
  }
};

export const RegisterStateProvider = ({
  children
}: GlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RegisterContext.Provider value={ { state, dispatch } }>
      { children }
    </RegisterContext.Provider>
  );
};

export const useRegisterState = () => {
  const context = useContext(RegisterContext);
  if (!context) {
    throw new Error(
      "useRegisterState must be used within a GlobalStateProvider"
    );
  }
  return context;
};
