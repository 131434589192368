import { PopUpTeacher as  PopUpTeacher } from "./PopUpTeacher.component";
import { PopUpTeacherProps } from "./PopUpTeacher.types";
import {
  Default as DefaultCardTeacherProps

} from "./PopUpTeacher.variants";

export const DefaultPopUpTeacher = ({ ...props }: Partial<PopUpTeacherProps>) => (
  <PopUpTeacher { ...{ ...DefaultCardTeacherProps, ...props } }/>
);

export const PopUpTeacherComponent = PopUpTeacher;

