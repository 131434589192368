import {
  Box,
  Grid,
  GridProps,
  MenuItem,
  MenuItemProps,
  Stack
} from "@mui/material";
  
import { styled } from "@mui/material/styles";
  
export const CardGoalsBox = styled(Grid)<GridProps & 
  { $onBoardingPage:boolean } >(({ theme,  $onBoardingPage }) => ({
    background: $onBoardingPage ? `${theme.customColors.backgroundSecondary}1A` : theme.linearGradients.gradientB,
    border: $onBoardingPage ? `0.5px solid ${theme.customColors.borderTag}` : "none",
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    maxHeight: "50rem",
    minHeight: $onBoardingPage ? "430px" : "unset",
    padding: theme.spacing(3),
    width: $onBoardingPage ? "70%" : "auto",

    [`${theme.breakpoints.down("lg")}`]: {
      width: $onBoardingPage ? "60%" : "auto"
    },

    [`${theme.breakpoints.down("sm")}`]: {
      padding: theme.spacing(2),
      width: $onBoardingPage ? "100% !important" : "auto"
    }
  })) ;

export const CardGoalsItemsBox = styled(Grid)<GridProps>(({ theme }) => ({
  height:"150px",
  width:"150px",
  
  alignItems:"center",
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(3),
  justifyContent:"center",
  margin:"auto",
  position:"relative"

}));

export const StyledLabelStatus = styled(Grid)<GridProps>(({ theme }) => ({
  alignItems:"center",
  display:"flex",
  fontSize:theme.spacing(1.5),
  gap:theme.spacing(1)
})) ;

export const StyledLevelBox = styled(Grid)<GridProps>(({ theme }) => ({
  width:"100%",

  alignItems:"center",
  display:"flex",
  fontSize:theme.spacing(1.75),
  gap:theme.spacing(0.5),

  ".accent":{
    color:theme.customColors.accentVariantB,
    fontWeight:700,
    textTransform:"capitalize"
  }
  
}));

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  borderBottom:`1px solid ${theme.customColors.border}`,
  color: theme.customColors.textPrimary,
  fontSize:theme.spacing(1.75),
  fontWeight: 500,
  margin: theme.spacing(0, 2),
  minWidth:"133px",
  padding: theme.spacing(2, 0),
  textAlign: "center",
  textTransform: "capitalize",

  "&:last-child":{
    border:"none"
  }
  
}));

export const StyledTimeBox = styled(Grid)<GridProps>(({ theme }) => ({
  width:"100%",
  
  alignItems:"center",
  border:`1px solid ${theme.customColors.accentVariantB}`,
  borderRadius:theme.spacing(1),
  boxShadow:`0px 0px 8px 0px ${theme.customColors.accentVariantB}`,
  display:"flex",
  fontSize:theme.spacing(1.5),
  gap:theme.spacing(3),
  justifyContent:"space-between",
  padding:theme.spacing(1,2),

  "span":{
    fontSize:theme.spacing(1.75),
    fontWeight:700,

    "&.bigger":{
      fontSize:theme.spacing(2.25)
    }
  }
}));

export const CardGoalSmall = styled(Box)(({ theme }) => ({
  background:`${theme.customColors.backgroundSecondary}1A`,
  border: `0.5px solid ${theme.customColors.borderTag}`,
  borderRadius: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  minHeight: "195px",
  padding: "16px",

  ".goals-time": {
    ".number": {
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "38px"
    },

    ".label": {
      fontSize: "14px"
    }
  }
}));

export const CardGoalSmallContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  width: "30%",

  [`${theme.breakpoints.down("lg")}`]: {
    gap: "20px",
    width:"40%",

    [`${theme.breakpoints.down("sm")}`]: {
      width: "100%"
    }
  }
}));

