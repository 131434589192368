import { useTranslation } from "react-i18next";
import { TabsContentProps } from "./TabsContent.types";
import { CardLinks } from "..";
import { TypographyDescription } from "../../styles/global_styles";

export function TabsContentInstruments({
  contentLinks,
  contentTools,
  currentLO
} :
  & (
    | Required<
      | Pick<
        TabsContentProps,
        | "contentLinks"
      > & { contentTools: undefined }
    >
    | Required<
      | Pick<
        TabsContentProps,
        | "contentTools"
      > & { contentLinks: undefined }
    >
    | Required<
      Pick<
        TabsContentProps,
        | "contentLinks"
        | "contentTools"
      >
    >
  )
  & Pick<
    TabsContentProps,
    | "currentLO"
  >
) {
  const { t } = useTranslation();

  const isDisabled =
    currentLO
      ? (currentLO?.learningObjectType === "ASYNC" || currentLO?.learningObjectType === "BLENDED")
        ? currentLO.status === "N" && currentLO.enrollType === "TOP_DOWN"
        : currentLO.status === "N"
      : true;

  return (
    <>
      <TypographyDescription>
        { t("doc_text") }
      </TypographyDescription>
      <CardLinks
        cardTabsLinks={ contentLinks }
        cardTabsTools={ contentTools }
        disabled={ isDisabled }
      />
    </>
  );
}
