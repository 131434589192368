import { CardProfile as  CardProfileComponent } from "./CardProfile.component";
import { CardProfileProps } from "./CardProfile.types";
import {
  Default as DefaultCardTeacherProps

} from "./CardProfile.variants";

export const DefaultCardProfile = ({ ...props }: Partial<CardProfileProps>) => (
  <CardProfileComponent { ...{ ...DefaultCardTeacherProps, ...props } }/>
);

export const CardProfile =  CardProfileComponent;

