import {
  useInfiniteQuery,
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type {
  LearningObject,
  ContentType,
  Level,
  MetaData
} from "../types";
import { getEnvironmentVariables } from "../utils/general";

type SearchPreview = {
  availableEditions: number
  coverImage: {
    id: number
    value: string
  },
  duration: number
  expirationDate: number
  id: number
  learningObjectType: ContentType
  shortDescription: string
  type: Level
  title: string
  topics: number[]
}

type SearchPreviewResponseType = {
  results: SearchPreview[]
  metadata: MetaData
}

type SearchResponseType = {
  learningObjects: LearningObject[]
  metadata: MetaData
}
  
const { basePath } = getEnvironmentVariables();

export const useSearchPreviewQuery = ({
  freeText,
  topics=[]
} : {
  freeText: string
  topics?: number[]
}) => {
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  const pageSize = 10;
  const page = 0;

  let topicQueryParam = "";
  if (topics.length > 0) {
    topicQueryParam = "&topic=" + topics.join("&topic=");
  }

  const uri = "learning-catalogue/current-session";
  const params = `free_text=${freeText}&page=${page}&page_size=${pageSize}${topicQueryParam}`;
  
  return useQuery<
    unknown,
    unknown,
    SearchPreview[],
    QueryKey
  >({
    enabled: Boolean(
      accessToken &&
      sessionToken
    ) && freeText.length >= 3 && !isSeedingSession,
    queryFn:
    () => fetcher(
      `${basePath}/${uri}?${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      // .then((data: SearchPreviewResponseType) => ({
      //   metadata: data.metadata,
      //   results: apiAdapter(data.results)
      // }))
      .then((data: SearchPreviewResponseType) => (
        apiAdapter(data.results)
      ))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, isSeedingSession, params]
  });
};

export const useInfiniteSearchQuery = ({
  enabled=true,
  freeText,
  pageSize=12,
  topics=[]
} : {
  enabled?: boolean
  freeText: string
  pageSize?: number
  topics?: number[]
}) => {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);


  let topicQueryParam = "";
  if (topics.length > 0) {
    topicQueryParam = "&topics=" + topics.join("&topics=");
  }

  const uri = `learning-catalogue/${corporateId}/${initiativeId}/freeTextSearch`;

  const isEnabled = Boolean(
    accessToken &&
    enabled &&
    corporateId &&
    initiativeId &&
    sessionToken &&
    freeText.length >= 3
  ) && !isSeedingSession;

  return useInfiniteQuery<
    unknown,
    unknown,
    SearchResponseType,
    QueryKey
  >({
    enabled: isEnabled,
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = (lastPage as SearchResponseType)?.metadata?.totalPages ?? 0;
      return (nextPage < totalPages) ? { nextPage } : undefined;
    },
    queryFn: ({ pageParam }) => fetcher(
      `${basePath}/${uri}?${getApiPathParams({ pageNumber: pageParam?.nextPage ?? 0 })}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: SearchResponseType) => ({
        learningObjects: apiAdapter(data.learningObjects),
        metadata: data.metadata
      }))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, freeText, topics, pageSize, isSeedingSession]
  });

  function getApiPathParams({ pageNumber=0 }) {
    return `freeText=${freeText}&pageNumber=${pageNumber}&pageSize=${pageSize}${topicQueryParam}`;
  }
};
