import {
  Modal as MuiModal
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";

import { ScormWrapper } from "../../components";
import type { Level } from "../../types";



const StyledModalToj = styled(MuiModal)(({ theme }) => {

  return {
    display:"flex",

    ".MuiStack-root":{
      background:theme.linearGradients.gradientB,
      borderRadius:"8px",
      height:"80%",
      margin:"auto",
      maxHeight:"750px",
      width:"630px"
    },
    "iframe":{
      borderRadius:"8px"
    }
  };
});

export function ScormTojModal({
  courseId,
  rootId,
  activityLevel,
  idString,
  open
}: {
  courseId:string | null
  idString:number
  activityLevel:Level
  open: boolean
  rootId:string | null
}) {

  
  return (
    <StyledModalToj
      aria-labelledby="modal"
      open={ open }
    >
      <ScormWrapper
        activityId={ idString }
        courseId={ courseId }
        isOpen={ open }
        learningObjectTypology={ activityLevel }
        parentId={ rootId }
      />
    </StyledModalToj>
  );

 
}

