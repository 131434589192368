export function getError(error: unknown) {
  if (error instanceof Error) {
    return [error];
  } else if (typeof error === "string") {
    try {
      const parsedError = JSON.parse(error ?? {});
      if (parsedError.errors instanceof Object) {
        const errorAccumulator = Object.values(parsedError.errors)
          .reduce((acc1: Error[], errorCategory) => {
            if (Array.isArray(errorCategory)) {
              const categoryErrors: Error[] = errorCategory.reduce((acc2: Error[], errorItem) => {
                // return acc2 + JSON.stringify({
                //   message: errorItem.message,
                //   name: errorItem.key
                // });
                const errorObject = new Error(errorItem.message);
                errorObject.name = errorItem.category;
                errorObject.cause = errorItem.code;
                return acc2.concat(errorObject);
              }, acc1);
              return categoryErrors;
            } else {
              return acc1;
            }
          }, [] as Error[]);
        // console.log(errorAccumulator);
        return errorAccumulator;
      } else {
        return [new Error(error)];
      }
    } catch {
      return [new Error(error)];
    }
  } else if (error == null) { // loose equality to cast undefined to null
    return null;
  } else {
    const stringifiedError = String(error);
    if (stringifiedError) {
      return [new Error(stringifiedError)];
    } else {
      return [new Error("unknown error")];
    }
  }
}
