import { styled } from "@mui/material/styles";
import { Link, LinkProps } from "../../components";

/** LinkWithInteractableOverlay
 *  allows the nearest relatively positioned parent to activate
 *  the link's action (typically used in cards wich have internal links)
 */
export const LinkWithInteractableOverlay = styled(Link)<
  LinkProps & { $hideCta?: boolean, $disableLink?:boolean, }
>(({ $disableLink=false,$hideCta=false, theme }) => ({
  "::before": {
    bottom: 0,
    content: "''",
    left: 0,
    pointerEvents: $disableLink ? "none" : "auto",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: theme.zIndex.mobileStepper,

    [theme.breakpoints.down("sm")]:{
      pointerEvents:"none"
    }
  },
  maxHeight: $hideCta ? 0 : undefined,
  overflow: $hideCta ? "hidden" : undefined
}));
