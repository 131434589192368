import {
  useInfiniteQuery,
  type QueryKey
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
import type { LeaderboardResponse } from "../types";
import { getEnvironmentVariables } from "../utils/general";
  
const { basePath } = getEnvironmentVariables();
  
export function useLeaderboardQuery({
  enabled = true,
  pageSize=6
} : {
  enabled?: boolean
  pageSize?: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  
  const fetcher = fetcherFactory();
  
  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/learnerLeaderboard`;
  
  return useInfiniteQuery<
  unknown,
    unknown,
    LeaderboardResponse,
    QueryKey
  >({
    enabled: Boolean(
      enabled
        && initiativeId
        && sessionToken
        && corporateId
        && initiativeId
    ) && !isSeedingSession,
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as LeaderboardResponse)?.metadata)?.totalPages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      `${basePath}${uri}?pageNumber=${pageParam?.nextPage ?? 0}&pageSize=${pageSize}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: LeaderboardResponse) => ({
        callerRanking: data.callerRanking ? apiAdapter([data.callerRanking])[0] : null,
        leaderboards: apiAdapter(data.leaderboards ?? []),
        metadata: data.metadata
      }))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, isSeedingSession, pageSize]
  });
}
