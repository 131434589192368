import { create } from "zustand";
  
export type CoverState = {
  numbers: number[]
}
  
export type CoverActions = {
  readonly get: () => string
}
  
const defaultCoverState: CoverState = {
  numbers: Array.from(Array(28).keys()).sort(() => Math.random() - 0.5)
};

/* we assume that 1) there are n files named {{n}}.webp, they are in a known folder */
// store cover formati
export const useCoverStore = create<CoverState & CoverActions>((set, get) => ({
  ...defaultCoverState,
  get: ()  => {
    const prev = get().numbers;
    const next = (prev.length < 1)
      ? Array.from(Array(28).keys()).sort(() => Math.random() - 0.5)
      : prev;
    const number = next.pop() ?? Math.ceil(Math.random() * 28);
    set({ numbers: next });
    return `/formats/${number + 1 || Math.ceil(Math.random() * 28)}.webp`;
  }
}));
// store cover profession
export const useCoverProfessionStore = create<CoverState & CoverActions>((set, get) => ({
  ...defaultCoverState,
  get: ()  => {
    const prev = get().numbers;
    const next = (prev.length < 1)
      ? Array.from(Array(30).keys()).sort(() => Math.random() - 0.5)
      : prev;
    const number = next.pop() ?? Math.ceil(Math.random() * 30);
    set({ numbers: next });
    return `/professions/${number + 1 || Math.ceil(Math.random() * 30)}.jpg`;
  }
}));
  
