import { 
  OutlinedInput, 
  Stack, 
  Typography, 
  useTheme } from "@mui/material";
import { 
  ChangeEvent,
  useEffect,
  useState } from "react";
import { useTranslation } from "react-i18next";

import { StyledIconArrow, deleteItemSelected,
  handleSetSelectedValues, resetOptionSelected, setOptions } from "./ModalEcm.component";
import { SponsorStepProps } from "./ModalEcm.types";
import { StyledOptionsSelected } from "./modalsEcomm.styles";
import { OptionSelectedBox } from "./OptionSelectedChip";
import { Radio } from "../../components/Radio";
import { Select } from "../../components/Select/Select.component";
import type { ecmItemType as sponsorType } from "../../queries/ecm";

export function SponsorStep({
  setUserType,
  sponsors,
  userTypeBody
}:SponsorStepProps){
  const { t } = useTranslation();
  const theme = useTheme();

  const[open, setOpen] = useState(false);
  const[selectedSponsors, setSelectedSponsors] = useState(
    userTypeBody?.sponsors?.length ? userTypeBody.sponsors.map(sp => sp.id) : [] as number[]
  );

  const[selectedSponsorsList, setSelectedSponsorsList] = useState(
    userTypeBody?.sponsors?.length ? userTypeBody.sponsors : [] as sponsorType[]
  );
  const[agencyRecruited, setAgencyRecruited] = useState(userTypeBody.isSponsored);


  const sponsorOptions = setOptions(sponsors);

  useEffect(()=> {
    setUserType((prev) => {
      return {
        ...prev,
        isSponsored:agencyRecruited,
        sponsors:!agencyRecruited ? [] : selectedSponsorsList
      };
    
    });
  },[agencyRecruited, selectedSponsorsList, setUserType]);

  return(
    <>
      <Stack gap={ 3 } width={ "100%" }>
        <Typography sx={ { marginRight: "auto" } }>
          { t("agency_recruited") }
        </Typography>
        <Radio
          labelId="agency-recruited"
          name="agency-recruited"
          onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
            setAgencyRecruited(changeEvent.target.value === "true" ? true : false);
          } }
          options={ [
            { label: t("radio_label_yes"), value: true } ,
            { label: t("radio_label_no"), value: false } 
          ] }
          value={ agencyRecruited }
          sx={ {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          } }
        />
      </Stack>
      { agencyRecruited && <Stack gap={ 1 } width={ "100%" }>
        <Typography sx={ { marginBottom: "8px",marginRight: "auto"  } }>
          { t("sponsor_selection") }
        </Typography>
        <Select
          sx ={ {
            background: theme.customColors.backgroundSecondary,
            color: theme.customColors.textInput,

            ".MuiSelect-select": {
              padding: "8px 16px !important",

              "span": {
                fontSize: "14px"
              }
            }
          } }
          checkedIcon ={ true }
          options={ sponsorOptions }
          IconComponent={ (props) => (
            <StyledIconArrow 
              isOpen={ open }
              { ...props }
            />
          ) }
          multiple
          input={ <OutlinedInput /> }
          open={ open }
          onClose={ () => setOpen(false) }
          onOpen={ () => setOpen(true) }
          onChange={ (changeEvent) => {
            handleSetSelectedValues({
              options:sponsors,
              selectedValues:changeEvent.target.value as number[],
              setSelectedOptions:setSelectedSponsors,
              setSelectedValues:setSelectedSponsorsList
            });
          } }
          value={ selectedSponsors }
          inputProps={ { "aria-label": "Without label" } }
          renderValue={ (selectedSponsors) => {
            if (selectedSponsors) {
              return <span>{ t("field_sponsor") }</span>;
            }
          } }
          displayEmpty
          MenuProps={ {
            className:"from-select-menu",
            sx: {
              "& .MuiMenu-paper": {
                backgroundColor: theme.customColors.backgroundSecondary,
                border: "none",
                borderRadius: "2px",
                margin: "3px 0",
                overflow: "hidden",
                padding: "4px 4px 4px 0px",

                "li": {
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  color: theme.customColors.textInput,
                  margin: "0",
                  paddingBlock: theme.spacing(1),
                  paddingLeft: theme.spacing(2)
                },
              
                "& ul.MuiList-root": {
                  height: "auto",
                  overflowY: "auto",
  
                  "::-webkit-scrollbar": {
                    width: "4px"
                  },
                  "::-webkit-scrollbar-thumb":{
                    backgroundColor: theme.customColors.systemDisabled,
                    borderRadius: "8px"
                  }
                }
              }
            }
          } }
        />
        {
          selectedSponsorsList.length && agencyRecruited ?
            <StyledOptionsSelected>
              {
                selectedSponsorsList.map((sponsor,index)=> (
                  <OptionSelectedBox 
                    key={ `option-sponsor--${index}` }
                    setDeleteItem={ () => {
                      resetOptionSelected({ 
                        itemToFilter:sponsor.id,
                        selectionTarget:selectedSponsors,
                        setSelectionTarget:setSelectedSponsors
                      });
                      deleteItemSelected({ 
                        itemToFilter:sponsor.id,
                        selectionTarget:selectedSponsorsList,
                        setSelectionTarget:setSelectedSponsorsList
                      });
                    } }
                    option={ sponsor }
                  />
                ))
              }
            </StyledOptionsSelected> : 
            null
        }
      </Stack> }
    </>
  );
}
  
