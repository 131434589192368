import {
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  TopicChip,
  TopicsStack
} from "./Community.styles";
import { 
  CreateContentCommunity,
  Filters,
  GridMain,
  Icon,
  Spinner,
  TopicCard,
  TopicCardPost,
  TopicCardShare,
  WidgetDrawer
} from "../../components";
import { POST_TYPE_FILTER } from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import { useInfiniteFeedQuery } from "../../queries/community";
import type { CommunityTopicResponse } from "../../queries/community.types";
import { useAuthStore } from "../../stores";
import type { PostTypeFilter } from "../../types";

export function Community() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("scrollTo");
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [allTopics, setAllTopics] = useState<CommunityTopicResponse[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const itemsRef = useRef<HTMLDivElement[] | null[]>([]);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);

  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);
  const funcCommunity = useAuthStore(state => state.smartConfiguration?.funcCommunity);
  const [selectedFilter, setSelectedFilter] = useState<[PostTypeFilter] | []>([]);
  const { setNavigation } = useContext(UserNavigationContext);

  useEffect(() => {
    setNavigation && setNavigation([]);
    setNavigation && setNavigation(["/community"]);
  }, [location.pathname]);

  const {
    data: results,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    refetch
  } = useInfiniteFeedQuery({ 
    postType:selectedFilter?.toString(),
    sharedId: sharedId ?? "", 
    topics: selectedTopics
  });

  useEffect(()=> {
    refetch();
  }, [refetch, sharedId]);

  useEffect(() => {
    if (results && results.pages?.[0]?.topic) {
      setAllTopics(results.pages?.[0]?.topic); //to prevent blinking effect
    }
  }, [results]);

  useEffect(()=> {
    if(sharedId && itemsRef && itemsRef.current.length > 0 ){
      const topciShareFirst = itemsRef?.current[0]; 
      topciShareFirst?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sharedId, results]);

  const pagesContainData = results?.pages && results.pages[0]?.data && results.pages[0].data.length > 0;

  let filterOptions = [];

  if(smartConfiguration?.communityFreePost === true){
    filterOptions.push({
      children: t("post"),
      value: POST_TYPE_FILTER.FREE
    });
  }
  if(smartConfiguration?.communityShareContent === true){
    filterOptions.push({
      children: t("shared"),
      value:POST_TYPE_FILTER.SHARED
    });
  }
  if(smartConfiguration?.communityDiscussion === true){
    filterOptions.push({
      children: t("talk"),
      value: POST_TYPE_FILTER.DISCUSSION
    });
  }
  return (
    enableCommunityFlag === false
    || (enableCommunityFlag === true && funcCommunity === false ||
      (smartConfiguration?.communityFreePost === false && 
        smartConfiguration?.communityShareContent === false &&
        smartConfiguration?.communityDiscussion === false
      )
    )
  )
    ? (
      <GridMain justifyContent={ "center" }>
        <Typography 
          padding={ "42px 80px" }
          textAlign={ "center" }
        >
          { t("no_community_available") }
        </Typography>
      </GridMain>
    ) : (
      <GridMain justifyContent={ "center" }>
        <WidgetDrawer />
        <Stack 
          gap={ "24px" } 
          paddingTop={ "24px" } 
          maxWidth={ "894px" }
          width={ "100%" }
        >
          { smartConfiguration?.communityFreePost && <CreateContentCommunity 
            selectedFilter={ selectedFilter?.toString() || null }
            selectedTopic={ selectedTopics?.join(",") || null }
          /> }
          <Filters<PostTypeFilter>
            filter={ selectedFilter }
            options={ filterOptions }
            setFilter={ setSelectedFilter }
          />
          {
            allTopics?.length > 0 ? (
              <TopicsStack>
                <Typography
                  sx={ {
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "21px"
                  } }
                >
                  { t("your_topic") }
                </Typography>
                <Stack
                  direction="row"
                  gap="8px"
                  maxWidth={ "100%" }
                  flexWrap={ "wrap" }

                >
                  { allTopics.map((t) => (
                    <TopicChip
                      key={ `category--${t.name}` }
                      label={ t.name }
                      variant="outlined"
                      onClick={ () => {
                        setSelectedTopics((prev) => {
                          const newState = [ ...prev ];
                          const topicIndex = newState.indexOf(t.code);
                          if (topicIndex >= 0) {
                            newState.splice(topicIndex, 1);
                          } else {
                            newState.push(t.code);
                          }
                          return newState.sort();
                        });
                      } }
                      selected={ selectedTopics.includes(t.code) }
                    />
                  )) }
                </Stack>
              </TopicsStack>
            ) : null
          }
          {
            isLoading ? (
              <Spinner
                size={ isMobile? 100 : 200 }
                thickness={ 3 }
              />
            ) : null
          }
          {
            isError ? (
              <Typography 
                padding={ "42px 80px" }
                textAlign={ "center" }
              >
                { t("error_occurred") }
              </Typography>
            ) : null
          }
          {
            pagesContainData ? (
              results.pages.map((page) => (
                page?.data?.map((discussion, i) => {
                  const ownerUser =
                    page?.enrichedInformation?.users?.find(user =>
                      user.owner_id === discussion.owner_id
                    );
 
                  return (
                    <>
                      {
                        discussion?.post_type === "free" ? ( 
                          <TopicCardPost
                            attachments={ discussion.attachments }
                            body={ discussion.body }
                            changed={ discussion.changed }
                            commentsNumber={ discussion.comment_count }
                            created={  discussion.created  }
                            id={ discussion.id }
                            key={ `topic-${discussion.id}` }
                            likes={ discussion.likes }
                            ownerName={ `${ownerUser?.name} ${ownerUser?.surname}` || "" }
                            ownerImage={ ownerUser?.profileImageUrl || "" }
                            selectedFilter={ selectedFilter?.toString() || null  }
                            selectedTopic={ selectedTopics?.join(",") || null }
                            topicOwner={ discussion.owner_id }
                          />
                        ) : null
                      }
                      {
                        discussion?.post_type === "shared" ?  (
                          <TopicCardShare
                            selectedFilter={ selectedFilter?.toString() || null  }
                            selectedTopic={ selectedTopics?.join(",") || null }
                            key={ `topic-${discussion.id}` }
                            body={ discussion.body }
                            changed={ discussion.changed }
                            commentsNumber={ discussion.comment_count }
                            created={ discussion.created }
                            id={ discussion.id }
                            learningObjectTypology={ discussion.lo_type }
                            ownerImage={ ownerUser?.profileImageUrl || "" }
                            likes={ discussion.likes }
                            ownerName={ `${ownerUser?.name} ${ownerUser?.surname}` || "" }
                            sharedLo={ page?.enrichedInformation?.los?.find(lo => lo.lo_id === discussion.lo_id) }
                            topicOwner={ discussion.owner_id }
                            ref={ el => itemsRef.current[i] = el } 
                          />
                        ) : null
                      }
                      {
                        (
                          discussion?.post_type !== "free"
                             && discussion?.post_type !== "shared"
                        )
                          ? (
                            <TopicCard
                              key={ `topic-${discussion.id}` }
                              body={ discussion.body }
                              topic={ discussion?.topic?.map((t) => t.name) }
                              commentsNumber={ discussion.comment_count }
                              created={ discussion.publishDate }
                              id={ discussion.id }
                              ownerImage={ ownerUser?.profileImageUrl || "" }
                              likes={ discussion.likes }
                              ownerName={ `${ownerUser?.name} ${ownerUser?.surname}` || "" }
                              title={  discussion.title }
                              subtitle={ discussion.subtitle }
                              image={ discussion.attachments.image?.[0].content }
                              attachments={ discussion.attachments }
                              selectedFilter={ selectedFilter?.toString() || null  }
                              selectedTopic={ selectedTopics?.join(",") || null }
                            />
                          ) : null
                      }
                    </>
                  );
                })
              ))
            ) : (
              <Grid 
                alignItems={ "center" }
                display={ "flex" }
                flexDirection={ "column" }
                justifyContent={ "center" }
                gap={ 2 }
 
              >
                <Typography 
                  padding={ "42px 80px" }
                  textAlign={ "center" }
                >
                  { t("no_posts_available") }
                </Typography>
                <Button
                  onClick={ ()=> {
                    setSelectedTopics([]);
                    setSelectedFilter([]);
                  } }
                >{ t("remove_filters") }</Button>
              </Grid>
            )
          }
          {
            (hasNextPage && !isFetchingNextPage) ? (
              <Button
                disabled={ !hasNextPage }
                disableRipple
                onClick={ () => fetchNextPage() }
                sx={ {
                  gap:"0.5rem",
                  margin:"0 auto",
                  width:"fit-content"
                } }
              >
                { t("load_more") }
                <Icon 
                  color={ theme.customColors.systemPrimary02 }
                  icon="dropdown" 
                  size={ 20 } 
                />
              </Button>
            ) : null
          }
        </Stack>
      </GridMain>
    );
}
