/* eslint-disable react-hooks/exhaustive-deps */
import {  OutlinedInput,Stack, Typography, useTheme } from "@mui/material";
import { ChangeEvent, UIEvent, useEffect, useState } from "react";
import {
  useTranslation } from "react-i18next";
import type  { EcmUser } from "types";
import { StyledIconArrow, deleteItemSelected, 
  handleSetSelectedValues, resetOptionSelected, setDisciplineSelectedOption, setOptions } from "./ModalEcm.component";
import {  UserTypeStepProps } from "./ModalEcm.types";
import { StyledOptionsSelected } from "./modalsEcomm.styles";
import { OptionSelectedBox } from "./OptionSelectedChip";
import { Radio } from "../../components";
import { Select } from "../../components/Select/Select.component";
import { ECM_USER } from "../../consts";
import { ecmItemType, useEcmDisciplineQuery, useEcmProfessionsQuery } from "../../queries/ecm";


export function UserTypeStep({
  setUserType,
  userTypeBody
}:UserTypeStepProps){
  const { t } = useTranslation();
  const theme = useTheme();

  const[openProfession, setOpenProfession] = useState(false);
  const[openDiscipline, setOpenDiscipline] = useState(false);
  const[ecmTypeSelected,setEcmTypeSelected]=useState<EcmUser>(
    userTypeBody.type ? userTypeBody.type : ECM_USER.FREELANCE
  );

  const [professionOptionSelected,setProfessionOptionSelected]=useState<number[]| []>( 
    userTypeBody.professions.length ? userTypeBody.professions.map(prof => prof.id) : []
  );
  const [professionSelected,setProfessionSelected]=useState<ecmItemType[]| []>(
    userTypeBody.professions.length ? userTypeBody.professions : []
  );

  const [disciplineOptionSelected,setDisciplineOptionSelected]=useState<number[]| []>(
    userTypeBody.disciplines.length ? userTypeBody.disciplines.map(disc => disc.id) : []
  );

  const [disciplineSelected,setDisciplineSelected]=useState<ecmItemType[]| []>(
    userTypeBody.disciplines.length ? userTypeBody.disciplines : []
  );
  
  const { 
    data:professionData,
    hasNextPage:hasMoreProfession,
    fetchNextPage: fetchMoreProfession,
    isFetchingNextPage:isFetchingMoreProfession
  } = useEcmProfessionsQuery({ pageSize:6 });

  const apiprofession = professionData?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as ecmItemType[]);

  const professions = apiprofession?.length ? apiprofession :  [];
  const professionOptions = setOptions(professions);

  const { 
    data:disciplineData,
    hasNextPage:hasMoreDiscipline,
    fetchNextPage: fetchMoreDiscipline,
    isFetchingNextPage:isFetchingMoreDiscipline
  } = useEcmDisciplineQuery({ pageSize:6, profession: professionOptionSelected });

  const apiDiscipline = disciplineData?.pages?.reduce((acc, page) => {
    const contents = page.output ?? [];
    return acc.concat(contents);
  }, [] as ecmItemType[]);

  const disciplines= apiDiscipline?.length ? apiDiscipline : [];

  const disciplineOptions = setOptions(disciplines, professions);

  useEffect(()=> {
    setUserType({
      disciplines:professionSelected.length ? disciplineSelected : [],
      isSponsored: userTypeBody ? userTypeBody.isSponsored : false,
      professions:professionSelected,
      sponsors: userTypeBody ? userTypeBody.sponsors : [],
      type:ecmTypeSelected
    });
  },
  [
    disciplineSelected, 
    ecmTypeSelected, 
    professionSelected, 
    setUserType
  ]);

  useEffect(()=> {
    if(!professionSelected.length) {
      setDisciplineOptionSelected([]);
      setDisciplineSelected([]);
    }
  },[
    professionSelected
  ]);
  
  return(
    <Stack 
      flexDirection={ "column" }
      gap={ 4 }
      justifyContent="flex-start"
      width={ "100%" }
    >
      <Typography>
        { t("role_indicate") + " :" }
      </Typography>
       
  
      <Radio 
        labelId="select-userType"
        name="select-userType"
        onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
          setEcmTypeSelected(changeEvent.currentTarget.value as EcmUser);
        } }
        options={ [
          { 
            label:t(ECM_USER.FREELANCE.toLowerCase()),
            value:ECM_USER.FREELANCE },
          { 
            label:t(ECM_USER.EMPLOYEE.toLowerCase()), 
            value:ECM_USER.EMPLOYEE }
        ] }
        value={ ecmTypeSelected }
        sx={ {
          flexFlow:"row",
          gap:theme.spacing(4),
  
          ">label":{
            gap:theme.spacing(1),
            margin:0,
            maxWidth:`calc(50% - ${theme.spacing(4)})`,

            "span":{
              padding:0
            }
          }
        } }
       
      />
      
      <Stack gap={ 1 }>
        <Select 
          sx ={ {
            background: theme.customColors.backgroundSecondary,
            color: theme.customColors.textInput,

            ".MuiSelect-select": {
              padding: "8px 16px !important",

              "span": {
                fontSize: "14px"
              }
            }
          } }
          checkedIcon ={ true }
          multiple 
          onChange={ (e )=> {
            handleSetSelectedValues({
              options:professions,
              selectedValues:e.target.value as number[],
              setSelectedOptions:setProfessionOptionSelected,
              setSelectedValues:setProfessionSelected
            });
          } }
          input={ <OutlinedInput /> }
          options={ professionOptions }
          IconComponent={ (props) => (
            <StyledIconArrow 
              isOpen={ openProfession }
              { ...props }
            />
          ) }
          open={ openProfession }
          onClose={ () => setOpenProfession(false) }
          onOpen={ () => setOpenProfession(true) }
          value={ professionOptionSelected }
          inputProps={ { "aria-label": "Without label" } }
          renderValue={ (professionOptionSelected) => {
            if (professionOptionSelected) {
              return <span>{ t("field_profession") }</span>;
            }
          } }
          displayEmpty
          MenuProps={ {
            className:"from-select-menu",
            PaperProps:{
              onScroll:(e)=>{
                loadMoreItems({
                  event:e,
                  hasOtherPage:Boolean(hasMoreProfession),
                  isFetchingNewPage:isFetchingMoreProfession,
                  loadMore:fetchMoreProfession
                }); }
            },
            sx: {
              "& .MuiMenu-paper": {
                backgroundColor: theme.customColors.backgroundSecondary,
                border: "none",
                borderRadius: "2px",
                margin: "3px 0",
                maxHeight:"200px",
                overflow:  "auto",
                padding: "4px 4px 4px 0px",
           
                "::-webkit-scrollbar": {
                  width: "4px"
                },
                "::-webkit-scrollbar-thumb":{
                  backgroundColor: theme.customColors.systemDisabled,
                  borderRadius: "8px"
                },

                "li": {
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  color: theme.customColors.textInput,
                  margin: "0",
                  paddingBlock: theme.spacing(1),
                  paddingLeft: theme.spacing(2)
                },
              
                "& ul.MuiList-root": {
                  height: "100%"
                }
              }
            }
          } }
        />
        {
          professionSelected.length ?

            <StyledOptionsSelected>
              {
                professionSelected.map((profession,index)=> (
                  <OptionSelectedBox 
                    key={ `option-profession--${index}` }
                    setDeleteItem={ () => {
                    
                      resetOptionSelected({ 
                        itemToFilter:profession.id,
                        selectionTarget:professionOptionSelected,
                        setSelectionTarget:setProfessionOptionSelected
                      });
                      deleteItemSelected({ 
                        itemToFilter:profession.id,
                        selectionTarget:professionSelected,
                        setSelectionTarget:setProfessionSelected
                      });
                    } }
                    option={ profession }
                  />
                ))
              }
            </StyledOptionsSelected> : 
            null
        }
      </Stack>
      <Stack gap={ 1 }>
        <Select 
          sx ={ {
            background: theme.customColors.backgroundSecondary,
            color: theme.customColors.textInput,

            ".MuiSelect-select": {
              padding: "8px 16px !important",

              "span": {
                fontSize: "14px"
              }
            }
          } }
          multiple 
          checkedIcon ={ true }
          IconComponent={ (props) => (
            <StyledIconArrow 
              isDisabled={ !professionOptionSelected.length }
              isOpen={ openDiscipline }
              { ...props }
            />
          ) }
          inputProps={ { "aria-label": "Without label" } }
          renderValue={ (disciplineOptionSelected) => {
            if (disciplineOptionSelected) {
              return <span>{ t("field_discipline") }</span>;
            }
          } }
          input={ <OutlinedInput /> }
          open={ openDiscipline }
          onClose={ () => setOpenDiscipline(false) }
          onOpen={ () => setOpenDiscipline(true) }
          disabled={ !professionOptionSelected.length }
          onChange={ (e )=> {
            handleSetSelectedValues({
              options:disciplines,
              selectedValues:e.target.value as number[],
              setSelectedOptions:setDisciplineOptionSelected,
              setSelectedValues:setDisciplineSelected
            });
          } }
          options={ disciplineOptions }
          value={ disciplineOptionSelected }
          displayEmpty
          MenuProps={ {
            className:"from-select-menu",
            PaperProps:{
              onScroll:(e)=>{
                loadMoreItems({
                  event:e,
                  hasOtherPage:Boolean(hasMoreDiscipline),
                  isFetchingNewPage:isFetchingMoreDiscipline,
                  loadMore:fetchMoreDiscipline
                });
              }
            },
            sx: {
              "& .MuiMenu-paper": {
                backgroundColor: theme.customColors.backgroundSecondary,
                border: "none",
                borderRadius: "2px",
                margin: "3px 0",
                maxHeight:"200px",
                overflowY: "scroll",
                padding: "4px 4px 4px 0px",

                "::-webkit-scrollbar": {
                  width: "4px"
                },
                "::-webkit-scrollbar-thumb":{
                  backgroundColor: theme.customColors.systemDisabled,
                  borderRadius: "8px"
                },

                "li": {
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  color: theme.customColors.textInput,
                  margin: "0",
                  paddingBlock: theme.spacing(1),
                  paddingLeft: theme.spacing(2)
                },
              
                "& ul.MuiList-root": {
                  height: "100%"
  
                }
              }
            }
          } }
        />
        {
          disciplineSelected.length && professionSelected.length ?
            <StyledOptionsSelected>
              {
                disciplineSelected.map((discipline,index)=> (
                  <OptionSelectedBox 
                    key={ `option-profession--${index}` }
                    setDeleteItem={ () => {
                    
                      resetOptionSelected({ 
                        itemToFilter:discipline.id,
                        selectionTarget:disciplineOptionSelected,
                        setSelectionTarget:setDisciplineOptionSelected
                      });
                      deleteItemSelected({ 
                        itemToFilter:discipline.id,
                        selectionTarget:disciplineSelected,
                        setSelectionTarget:setDisciplineSelected
                      });
                    } }
                    option={ setDisciplineSelectedOption(discipline,professions) }
                  />
                ))
              }
            </StyledOptionsSelected> : 
            null
        }
      </Stack>
    </Stack>
  );
  function loadMoreItems({
    event,
    hasOtherPage,
    isFetchingNewPage,
    loadMore
  }:{
    event:UIEvent<HTMLElement>, 
    loadMore:()=> void, 
    hasOtherPage:boolean,
    isFetchingNewPage:boolean
  }) {
    if (event.currentTarget.scrollTop+ 200 === event.currentTarget.scrollHeight && hasOtherPage && !isFetchingNewPage) {
      loadMore();
    } 
  }
}
